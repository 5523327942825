import proj4 from "proj4";

export interface LngLatDisplay {
  lng: string;
  lat: string;
}

export interface EastingNorthingDisplay {
  easting: string;
  northing: string;
}

const getCardinality = (degrees: number, type: keyof LngLatObject) => {
  if (type === "lat") {
    return degrees < 0 ? "S" : "N";
  }

  return degrees < 0 ? "W" : "E";
};

const formatDmsCoordinate = (value: number, type: keyof LngLatObject) => {
  const [rawDegrees, rawMinutesAndSeconds = "0"] = value.toFixed(6).split(".");
  // Split the decimal content after degrees into minutes and seconds
  const [rawMinutes, rawSeconds = "0"] = `${
    Number(`0.${rawMinutesAndSeconds}`) * 60
  }`.split(".");

  // If we're showing a positive number, add a space for consistent width
  const sign = value < 0 ? "-" : " ";
  const degrees = `${Math.abs(Number(rawDegrees))}`.padStart(3, "0");
  const minutes = `${Math.floor(Number(rawMinutes))}`.padStart(2, "0");
  const seconds = `${Math.floor(
    Number(Number(`0.${rawSeconds}`)) * 60,
  )}`.padStart(2, "0");
  const cardinality = getCardinality(Number(rawDegrees), type);

  // If we're showing a positive number, add a space for consistent width
  return `${sign}${degrees}° ${minutes}′${seconds}″ ${cardinality}`;
};

const formatDdmCoordinate = (value: number, type: keyof LngLatObject) => {
  const [rawDegrees, rawMinutes = "0"] = value.toFixed(6).split(".");

  // If we're showing a positive number, add a space for consistent width
  const sign = value < 0 ? "-" : " ";
  const degrees = `${Math.abs(Number(rawDegrees))}`.padStart(3, "0");
  // We always expect a 2 digit minute, a decimal separator, and then 2 digits of precision, eg 05.30
  const minutes = (Number(`0.${rawMinutes}`) * 60).toFixed(2).padStart(5, "0");
  const cardinality = getCardinality(Number(rawDegrees), type);

  return `${sign}${degrees}° ${minutes}' ${cardinality}`;
};

const formatDdCoordinate = (value: number) => {
  // If we're showing a positive number, add a space for consistent width
  const sign = value < 0 ? "-" : " ";
  // We always expect a 3 digit degree, a decimal separator, and then 6 digits of precision, eg 003.563100
  const degrees = Math.abs(value).toFixed(6).padStart(10, "0");

  return `${sign}${degrees}`;
};

export const getDmsCoordinates = (baseCoords: LngLatObject): LngLatDisplay => ({
  lng: formatDmsCoordinate(baseCoords.lng, "lng"),
  lat: formatDmsCoordinate(baseCoords.lat, "lat"),
});

export const getDdmCoordinates = (baseCoords: LngLatObject): LngLatDisplay => ({
  lng: formatDdmCoordinate(baseCoords.lng, "lng"),
  lat: formatDdmCoordinate(baseCoords.lat, "lat"),
});

export const getDdCoordinates = (baseCoords: LngLatObject): LngLatDisplay => ({
  lng: formatDdCoordinate(baseCoords.lng),
  lat: formatDdCoordinate(baseCoords.lat),
});

export interface UtmZone {
  centralMeridian: number;
  zone: number;
}

export const getUtmZoneFromZone = (zone: number) => ({
  centralMeridian: zone * 6 - 183,
  zone,
});

export const getUtmZoneFromLng = (lng: number): UtmZone => {
  const zone = Math.floor((lng + 180) / 6) + 1;

  return getUtmZoneFromZone(zone);
};

// https://www.spatial.nsw.gov.au/surveying/geodesy/projections
const gdaProjections = {
  // https://epsg.io/28356 - GDA94 / MGA zone 56
  gda94: ({ centralMeridian, zone }: UtmZone) => `
    PROJCS["GDA94 / MGA zone ${zone}",
      GEOGCS["GDA94",
          DATUM["Geocentric_Datum_of_Australia_1994",
              SPHEROID["GRS 1980",6378137,298.257222101],
              TOWGS84[0,0,0,0,0,0,0]],
          PRIMEM["Greenwich",0,
              AUTHORITY["EPSG","8901"]],
          UNIT["degree",0.0174532925199433,
              AUTHORITY["EPSG","9122"]],
          AUTHORITY["EPSG","4283"]],
      PROJECTION["Transverse_Mercator"],
      PARAMETER["latitude_of_origin",0],
      PARAMETER["central_meridian",${centralMeridian}],
      PARAMETER["scale_factor",0.9996],
      PARAMETER["false_easting",500000],
      PARAMETER["false_northing",10000000],
      UNIT["metre",1,
          AUTHORITY["EPSG","9001"]],
      AXIS["Easting",EAST],
      AXIS["Northing",NORTH],
      AUTHORITY["EPSG","28356"]]
  `,
  // https://epsg.io/7856 - GDA2020 / MGA zone 56
  gda2020: ({ centralMeridian, zone }: UtmZone) => `
    PROJCS["GDA2020 / MGA zone ${zone}",
      GEOGCS["GDA2020",
          DATUM["Geocentric_Datum_of_Australia_2020",
              SPHEROID["GRS 1980",6378137,298.257222101],
              TOWGS84[0,0,0,0,0,0,0]],
          PRIMEM["Greenwich",0,
              AUTHORITY["EPSG","8901"]],
          UNIT["degree",0.0174532925199433,
              AUTHORITY["EPSG","9122"]],
          AUTHORITY["EPSG","7844"]],
      PROJECTION["Transverse_Mercator"],
      PARAMETER["latitude_of_origin",0],
      PARAMETER["central_meridian",${centralMeridian}],
      PARAMETER["scale_factor",0.9996],
      PARAMETER["false_easting",500000],
      PARAMETER["false_northing",10000000],
      UNIT["metre",1,
          AUTHORITY["EPSG","9001"]],
      AXIS["Easting",EAST],
      AXIS["Northing",NORTH],
      AUTHORITY["EPSG","7856"]]
  `,
};

export const convertLngLatToGdaProjection = (
  { lng, lat }: LngLatObject,
  projection: keyof typeof gdaProjections,
): EastingNorthingDisplay | undefined => {
  const zone = getUtmZoneFromLng(lng);

  try {
    const result = proj4(gdaProjections[projection](zone), [lng, lat]);

    return {
      easting: `${Math.round(result[0])} E`,
      northing: `${Math.round(result[1])} N Zone ${zone.zone} S`,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.debug(
      `Error in convertLngLatToGdaProjection from proj4 for ${projection}:`,
      error,
    );
  }
};

export const convertGdaProjectionToLngLat = (
  { easting, northing }: EastingNorthingDisplay,
  projection: keyof typeof gdaProjections,
): LngLatObject | undefined => {
  const zoneSplit = northing.split(" Zone ");
  const zoneValue = zoneSplit.length === 2 && parseInt(zoneSplit[1], 10);

  // Make sure we get an actual zone to work with
  if (typeof zoneValue !== "number" || Number.isNaN(zoneValue)) {
    return undefined;
  }

  const zone = getUtmZoneFromZone(zoneValue);

  try {
    const result = proj4(gdaProjections[projection](zone), "WGS84", [
      parseFloat(easting),
      parseFloat(northing),
    ]);

    return { lng: result[0], lat: result[1] };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.debug(
      `Error in convertGdaProjectionToLngLat from proj4 for ${projection}:`,
      error,
    );
  }
};

/** Take a comma separated coordinate pair string (eg "-029°S 08′45″, 140°E 20′27″") and return the values */
const splitCoordinatePair = (coordinatePairString: string) => {
  // We also treat tabs as a separator for easier copying from coordinates table
  const splitCoords = coordinatePairString
    .replaceAll(/\t/g, ",")
    .split(",")
    .flatMap((coord) => coord.trim() || []);

  return splitCoords.length === 2
    ? ([splitCoords[0], splitCoords[1]] as const)
    : null;
};

const validateLngLatValues = ({ lng, lat }: LngLatObject) => {
  const isLngValid = !(Number.isNaN(lng) || lng < -180 || lng > 180);
  const isLatValid = !(Number.isNaN(lat) || lat < -90 || lat > 90);

  return isLngValid && isLatValid ? { lng, lat } : null;
};

/** Take a comma separated lat, lng string (eg "-029°S 08′45″, 140°E 20′27″") and return DD coords */
export const parseDmsCoordinates = (coordinatePairString: string) => {
  const splitCoords = splitCoordinatePair(coordinatePairString);

  if (!splitCoords) {
    return null;
  }

  // -029° 08′45″ S, 140° 20′27″ E
  const regex = /^\D*?(-?)(\d+)\D*?(\d+)\D+?(\d+)\D*?$/;
  const lat = regex.exec(splitCoords[0]);
  const lng = regex.exec(splitCoords[1]);

  if (!lat || !lng) {
    return null;
  }

  return validateLngLatValues({
    lng:
      (lng[1] ? -1 : 1) *
      (Number(lng[2]) + Number(lng[3]) / 60 + Number(lng[4]) / (60 * 60)),
    lat:
      (lat[1] ? -1 : 1) *
      (Number(lat[2]) + Number(lat[3]) / 60 + Number(lat[4]) / (60 * 60)),
  });
};

/** Take a comma separated lat, lng string (eg "032° 57.94' S, 139° 55.77' E") and return DD coords */
export const parseDdmCoordinates = (coordinatePairString: string) => {
  const splitCoords = splitCoordinatePair(coordinatePairString);

  if (!splitCoords) {
    return null;
  }

  // -032° 57.94' S, 139° 55.77' E
  const regex = /^\D*?(-?)(\d+)\D*?(\d+)\D+?(\d+)\D*?$/;
  const lat = regex.exec(splitCoords[0]);
  const lng = regex.exec(splitCoords[1]);

  if (!lat || !lng) {
    return null;
  }

  return validateLngLatValues({
    lng:
      (lng[1] ? -1 : 1) *
      (Number(lng[2]) + Number(lng[3]) / 60 + Number(lng[4]) / (60 * 60)),
    lat:
      (lat[1] ? -1 : 1) *
      (Number(lat[2]) + Number(lat[3]) / 60 + Number(lat[4]) / (60 * 60)),
  });
};

/** Take a comma separated lat, lng string (eg "-028.608312, 140.716283") and return DD coords */
export const parseDdCoordinates = (coordinatePairString: string) => {
  const splitCoords = splitCoordinatePair(coordinatePairString);

  if (!splitCoords) {
    return null;
  }

  return validateLngLatValues({
    lng: Number(splitCoords[1]),
    lat: Number(splitCoords[0]),
  });
};

/** Take a comma separated northing, easting string (eg "6160385 N Zone 54 S, 647459 E") and return DD coords */
const parseGda94Coordinates = (coordinatePairString: string) => {
  const splitCoords = splitCoordinatePair(coordinatePairString);

  if (!splitCoords) {
    return null;
  }

  const values = convertGdaProjectionToLngLat(
    { easting: splitCoords[1], northing: splitCoords[0] },
    "gda94",
  );

  return values ? validateLngLatValues(values) : null;
};

/** Take a comma separated northing, easting string (eg "6160385 N Zone 54 S, 647459 E") and return DD coords */
const parseGda2020Coordinates = (coordinatePairString: string) => {
  const splitCoords = splitCoordinatePair(coordinatePairString);

  if (!splitCoords) {
    return null;
  }

  const values = convertGdaProjectionToLngLat(
    { easting: splitCoords[1], northing: splitCoords[0] },
    "gda2020",
  );

  return values ? validateLngLatValues(values) : null;
};

export const coordinateParseFunctions = {
  dms: {
    example: "-029° 08′45″ S, 140° 20′27″ E",
    conversion: parseDmsCoordinates,
  },
  ddm: {
    example: "-032° 57.94' S, 139° 55.77' E",
    conversion: parseDdmCoordinates,
  },
  dd: {
    example: "-028.608312, 140.716283",
    conversion: parseDdCoordinates,
  },
  gda94: {
    example: "6160385 N Zone 54 S, 647459 E",
    conversion: parseGda94Coordinates,
  },
  gda2020: {
    example: "6160385 N Zone 54 S, 647459 E",
    conversion: parseGda2020Coordinates,
  },
} as const satisfies Record<
  string,
  {
    example: string;
    conversion: (coordinatePairString: string) => LngLatObject | null;
  }
>;
