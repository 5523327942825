import { showToast } from "@app/design-system";
import { keepPreviousData } from "@tanstack/react-query";
import type { FeatureCollection } from "geojson";
import { useEffect } from "react";
import { useGetBrigades } from "../../../../.rest-hooks/brigades";
import getBrigadeCoverageSnapshotType from "../../../config/getBrigadeCoverageSnapshotType";
import getCoverageGapSeverity from "../../../config/getCoverageGapSeverity";
import getGeometryVariant from "../../../config/getGeometryVariant";
import { REFETCH_INTERVAL_PERIODIC } from "../../../config/refetch";
import useMapContext from "../Map/useMapContext";
import useMapBbox from "../hooks/useMapBbox/useMapBbox";
import useMapZoomLevel from "../hooks/useMapZoomLevel/useMapZoomLevel";
import { isGeoJsonSource } from "../types";
import { BRIGADES_SOURCE_ID } from "./constants";

const useBrigadesMapData = () => {
  const map = useMapContext();
  const bbox = useMapBbox("cartesian", { debounced: true });
  const zoomLevel = useMapZoomLevel();

  const { data: brigadesData } = useGetBrigades(
    {
      ...bbox,
      returnGeometry: true,
      returnGeometryVariant: getGeometryVariant(zoomLevel),
      page: 1,
      perPage: 2000,
    },
    {
      query: {
        placeholderData: keepPreviousData,
        refetchInterval: REFETCH_INTERVAL_PERIODIC,
      },
    },
  );

  const brigades = brigadesData?.data.data;
  const isFbiAvailable = brigadesData?.data.meta;

  useEffect(() => {
    if (!brigades || !isFbiAvailable) {
      return;
    }

    if (!isFbiAvailable?.isFbiAvailable) {
      showToast({
        id: `noCoverage`,
        variant: "warning",
        title: "Unable to highlight coverage gaps",
        message:
          "There are currently no Fire Danger Ratings for the state of New South Wales",
      });
    }

    const data: FeatureCollection = {
      type: "FeatureCollection",
      features: brigades.map((brigade) => {
        const filteredSnapshot = getBrigadeCoverageSnapshotType(
          brigade.attributes.coverage,
        );
        return {
          type: "Feature",
          geometry: {
            type: "MultiPolygon",
            coordinates: brigade.attributes.geometry?.coordinates ?? [],
          },
          properties: {
            brigadeId: brigade.attributes.coverage?.brigadeId,
            brigadeName: brigade.attributes.name,
            coverage: brigade.attributes.coverage,
            coverageImpactSeverity: getCoverageGapSeverity({
              isFbiAvailable: isFbiAvailable.isFbiAvailable,
              snapshot: filteredSnapshot,
            }),
            districtId: brigade.attributes.districtId,
          },
        };
      }),
    };

    const source = map.getSource(BRIGADES_SOURCE_ID);

    if (isGeoJsonSource(source)) {
      source.setData(data);
    }
  }, [brigades, isFbiAvailable, map]);
};

export default useBrigadesMapData;
