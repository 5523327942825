import { telephoneExchangeLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import TelephoneExchangeLayer from "./TelephoneExchangeLayer";

const TelephoneExchange = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(telephoneExchangeLayer.id)) {
    return null;
  }

  const state = getLayerState(telephoneExchangeLayer.id);

  return <TelephoneExchangeLayer opacity={state?.opacity} />;
};

export default TelephoneExchange;
