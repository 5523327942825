import { alertStatusStateTodayLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import AlertStatusStateLayer from "./AlertStatusState";

const AlertStatusStateToday = () => {
  const { isLayerActive, getLayerState } = useActiveLayersContext();

  if (!isLayerActive(alertStatusStateTodayLayer.id)) {
    return null;
  }

  return (
    <AlertStatusStateLayer
      layer="11"
      opacity={getLayerState(alertStatusStateTodayLayer.id)?.opacity}
    />
  );
};

export default AlertStatusStateToday;
