import { showToast } from "@app/design-system";
import { keepPreviousData } from "@tanstack/react-query";
import { useEffect } from "react";
import { useGetIncidents } from "../../../../.rest-hooks/incidents";
import { REFETCH_INTERVAL_PERIODIC } from "../../../config/refetch";
import { useIncidentsScope } from "../../incidents/IncidentsScopeProvider/IncidentsScopeProvider";
import { MAX_INCIDENTS } from "../../incidents/StateView/StateView";
import type { IncidentPointFeatureCollection } from "../IncidentPoint/utils";
import getIncidentPoint from "../utils/getIncidentPoint";

const useGetIncidentPointCollection = ():
  | IncidentPointFeatureCollection
  | undefined => {
  const { params } = useIncidentsScope();

  const { data: incidentPointCollection, error } = useGetIncidents(
    {
      ...params,
      perPage: MAX_INCIDENTS,
      page: 1,
    },
    {
      query: {
        placeholderData: keepPreviousData,
        refetchInterval: REFETCH_INTERVAL_PERIODIC,
        select: (data) => {
          const incidents = data.data.data;

          const features = incidents.map(getIncidentPoint);

          const incidentPointFeatureCollection: IncidentPointFeatureCollection =
            {
              type: "FeatureCollection",
              features,
            };

          return incidentPointFeatureCollection;
        },
      },
    },
  );

  useEffect(() => {
    if (error) {
      showToast({
        variant: "error",
        title: "Unable to retrieve incidents",
      });
    }
  }, [error]);

  return incidentPointCollection;
};

export default useGetIncidentPointCollection;
