import { AlertModal, Text, TextButton } from "@app/design-system";
import { useState } from "react";
import type { SocialTwitter } from "../../../../.rest-hooks/types";
import ValidateTweetModalForm from "../ModalTweetDetail/ValidateTweetModalForm";
import type { PostLink } from "../SocialFeed/socialFeedQuery";

type LinkToIncidentModalView = "alreadyValidated" | "validate";

interface LinkTweetToIncidentButtonProps {
  link: PostLink;
  tweet: SocialTwitter;
}

const LinkTweetToIncidentButton = ({
  link,
  tweet,
}: LinkTweetToIncidentButtonProps) => {
  const [modalView, setModalView] = useState<LinkToIncidentModalView | null>(
    null,
  );

  const onClick = () => {
    const isValidated = !!tweet.attributes.validated;
    if (isValidated) {
      setModalView("alreadyValidated");
    } else {
      setModalView("validate");
    }
  };

  const onClose = () => {
    setModalView(null);
  };

  return (
    <>
      <Text size="bodyLg">
        <TextButton onClick={onClick}>Link to an incident</TextButton>
      </Text>
      <AlertModal
        isOpen={modalView === "alreadyValidated"}
        title="Unable to link to incident"
        onDismiss={onClose}
      >
        This post is already validated.
      </AlertModal>
      <ValidateTweetModalForm
        isOpen={modalView === "validate"}
        link={link}
        onClose={onClose}
        tweet={tweet}
        variant="link"
      />
    </>
  );
};

export default LinkTweetToIncidentButton;
