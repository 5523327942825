import ACTESAResourcesPopup from "../../popup/ACTESAResourcesPopup/ACTESAResourcesPopup";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import { getPropertiesFromFeature } from "./interactions";
import useACTESAResourcesMapLayer from "./useACTESAResourcesMapLayer";

const ACT_VEHICLES_ID = "actEsaResources";

interface ACTESAResourcesLayerProps {
  opacity?: number;
}

const ACTESAResourcesLayer = ({ opacity }: ACTESAResourcesLayerProps) => {
  useACTESAResourcesMapLayer({ layerId: ACT_VEHICLES_ID, opacity });

  const { clickedState, deactivateClickState } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: ACT_VEHICLES_ID,
  });

  return (
    <ACTESAResourcesPopup onClose={deactivateClickState} state={clickedState} />
  );
};

export default ACTESAResourcesLayer;
