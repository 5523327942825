import {
  Field,
  FieldGrid,
  MenuSection,
  PilotLight,
  Select,
  SquareFoot,
  Text,
  type FormControlOption,
} from "@app/design-system";
import { convertArea, type Units } from "@turf/helpers";
import { area, convertDistance, lineDistance } from "@turf/turf";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useCounter } from "usehooks-ts";
import {
  areaMeasurement,
  distanceMeasurement,
  measurementTools,
} from "../../../../config/tools";
import useEscapeKeyDown from "../../../../hooks/useEscapeKeyDown";
import { EMDASH } from "../../../../lib/strings";
import useUnsafeMapContext from "../../../map/Map/useUnsafeMapContext";
import { useVisualiser } from "../../Visualiser/VisualiserProvider";
import MapRailDrawerCell from "../MapRailDrawerCell";
import { useMapRailContext } from "../MapRailProvider";
import { createMapRailItem } from "../useMapRail";
import MeasurementToolLayer from "./MeasurementToolLayer";
import {
  measurementToolArea,
  measurementToolDistance,
  type MeasurementUnit,
} from "./utils";

const railTabId = "measurements";
const railTabLabel = "Measurements";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  gap: 0.75rem;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  background: ${(p) => p.theme.colors.neutrals.background};
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
`;

const StyledToolsetsList = styled.div`
  display: grid;
  gap: 0.25rem;
`;

type AreaUnits = "feetUs" &
  Exclude<
    Units,
    | "centimeters"
    | "degrees"
    | "inches"
    | "millimeters"
    | "nauticalmiles"
    | "radians"
  >;
type DistanceUnits = "feetUs" &
  Exclude<
    Units,
    | "acres"
    | "centimeters"
    | "degrees"
    | "hectares"
    | "inches"
    | "millimeters"
    | "radians"
  >;

const areaUnits = {
  acres: "Acres",
  feet: "Sq Feet",
  feetUs: "Sq Feet (US)",
  hectares: "Hectares",
  kilometers: "Sq Kilometres",
  meters: "Sq Metres",
  miles: "Sq Miles",
  yards: "Sq Yards",
} satisfies Record<AreaUnits, string>;

const distanceUnits = {
  feet: "Feet",
  feetUs: "Feet (US)",
  kilometers: "Kilometres",
  meters: "Metres",
  miles: "Miles",
  nauticalmiles: "Nautical miles",
  yards: "Yards",
} satisfies Record<DistanceUnits, string>;

interface UnitGridProps<UnitType extends string> {
  setUnitOption: React.Dispatch<
    React.SetStateAction<FormControlOption<UnitType> | null>
  >;
  unitOption: FormControlOption<UnitType> | null;
  unitType: "area" | "distance";
  unitValue: number | undefined;
}

const UnitGrid = <UnitType extends AreaUnits>(
  props: UnitGridProps<UnitType>,
) => (
  <FieldGrid data-testid={`${props.unitType}-measurement-tool-units`}>
    <FieldGrid.Item labelAlign="center" label="Unit">
      <div style={{ marginRight: "auto" }}>
        <Select
          aria-label="Unit"
          onChange={props.setUnitOption}
          options={Object.entries(
            props.unitType === "area" ? areaUnits : distanceUnits,
          ).map(([value, label]) => ({ label, value: value as UnitType }))}
          size="xs"
          value={props.unitOption}
        />
      </div>
    </FieldGrid.Item>
    <FieldGrid.Item label={props.unitOption?.label || EMDASH}>
      {(() => {
        if (props.unitOption && props.unitValue !== undefined) {
          if (props.unitType === "area") {
            // US Feet aren't supported by turf so just calculate it ourselves - distance has a different const to area!
            return props.unitOption.value === "feetUs"
              ? (
                  convertArea(props.unitValue, "meters", "feet") * 0.999996
                ).toLocaleString()
              : convertArea(
                  props.unitValue,
                  "meters",
                  props.unitOption.value,
                ).toLocaleString();
          }

          if (props.unitType === "distance") {
            // US Feet aren't supported by turf so just calculate it ourselves - distance has a different const to area!
            return props.unitOption.value === "feetUs"
              ? (
                  convertDistance(props.unitValue, "meters", "feet") * 0.999998
                ).toLocaleString()
              : convertDistance(
                  props.unitValue,
                  "meters",
                  props.unitOption.value,
                ).toLocaleString();
          }
        }

        return EMDASH;
      })()}
    </FieldGrid.Item>
    {props.unitType === "distance" && props.unitValue !== undefined && (
      <FieldGrid.Item label="Hose lengths (30m)">
        {Math.ceil(props.unitValue / 30).toLocaleString()}
      </FieldGrid.Item>
    )}
  </FieldGrid>
);

const MeasurementToolDrawer = () => {
  const { map } = useUnsafeMapContext();
  const { selectedItems, remove } = useMapRailContext();
  const { onToolComplete, visualiserDispatch, visualiserState } =
    useVisualiser();
  const { count: toolId, increment: incrementToolId } = useCounter();
  const [areaUnit, setAreaUnit] = useState<FormControlOption<AreaUnits> | null>(
    ["meters" as AreaUnits].map((value) => ({
      label: areaUnits[value],
      value,
    }))[0],
  );
  const [distanceUnit, setDistanceUnit] =
    useState<FormControlOption<DistanceUnits> | null>(
      ["meters" as DistanceUnits].map((value) => ({
        label: distanceUnits[value],
        value,
      }))[0],
    );
  const [measurementUnit, setMeasurementUnit] = useState<MeasurementUnit>();
  const isMeasureAreaActive =
    measurementUnit?.type === "area" ||
    visualiserState.activeTool?.value === areaMeasurement.value;
  const isMeasureDistanceActive =
    measurementUnit?.type === "distance" ||
    visualiserState.activeTool?.value === distanceMeasurement.value;

  const endMeasurementTool = useCallback(() => {
    if (
      visualiserState.activeTool?.value === areaMeasurement.value ||
      visualiserState.activeTool?.value === distanceMeasurement.value
    ) {
      onToolComplete();

      if (measurementUnit?.isActive) {
        setMeasurementUnit({ ...measurementUnit, isActive: false });
      }

      if (map) {
        map.getCanvas().style.cursor = "";
      }
    }
  }, [map, measurementUnit, onToolComplete, visualiserState.activeTool?.value]);

  // If we close the measurement tab, close the tools with it
  useEffect(() => {
    if (!selectedItems.get(railTabId)) {
      endMeasurementTool();
      setMeasurementUnit(undefined);
    }
  }, [endMeasurementTool, selectedItems]);

  useEffect(() => {
    if (!measurementUnit?.isActive) {
      endMeasurementTool();
    }
  }, [endMeasurementTool, measurementUnit?.isActive]);

  useEscapeKeyDown(endMeasurementTool);

  return (
    <MapRailDrawerCell
      label={railTabLabel}
      onClose={() => remove({ id: railTabId })}
    >
      {/* Add the layers we're using for the tools here */}
      {isMeasureAreaActive && measurementUnit && (
        <MeasurementToolLayer
          key={toolId}
          measurementTool={measurementToolArea}
          measurementUnit={measurementUnit}
          setMeasurementUnit={setMeasurementUnit}
        />
      )}
      {isMeasureDistanceActive && measurementUnit && (
        <MeasurementToolLayer
          key={toolId}
          measurementTool={measurementToolDistance}
          measurementUnit={measurementUnit}
          setMeasurementUnit={setMeasurementUnit}
        />
      )}

      <StyledContainer>
        <Field label="Select tool">
          <StyledToolsetsList>
            {measurementTools.tools.map((tool) => (
              <PilotLight
                key={tool.value}
                checked={visualiserState.activeTool?.value === tool.value}
                icon={tool.button.icon}
                label={tool.button.label}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  incrementToolId();

                  if (map) {
                    map.getCanvas().style.cursor = "crosshair";
                  }

                  setMeasurementUnit(
                    tool.value === "measureArea"
                      ? { type: "area", isActive: true, value: undefined }
                      : { type: "distance", isActive: true, value: undefined },
                  );

                  visualiserDispatch({
                    payload: event.currentTarget.checked ? tool : null,
                    type: "setActiveTool",
                  });
                }}
                data-testid={tool.value}
              />
            ))}
          </StyledToolsetsList>
        </Field>

        <MenuSection label="Result">
          {!isMeasureAreaActive && !isMeasureDistanceActive && (
            <Text size="footnote" variant="weak">
              Select a tool above to begin measuring
            </Text>
          )}

          {measurementUnit?.type === "area" && (
            <UnitGrid
              setUnitOption={setAreaUnit}
              unitOption={areaUnit}
              unitType={measurementUnit.type}
              unitValue={
                measurementUnit.value ? area(measurementUnit.value) : 0
              }
            />
          )}
          {measurementUnit?.type === "distance" && (
            <UnitGrid
              setUnitOption={setDistanceUnit}
              unitOption={distanceUnit}
              unitType={measurementUnit.type}
              unitValue={
                measurementUnit.value
                  ? lineDistance(measurementUnit.value, { units: "meters" })
                  : 0
              }
            />
          )}
        </MenuSection>
      </StyledContainer>
    </MapRailDrawerCell>
  );
};

export const measurementToolMapRailItem = createMapRailItem({
  component: MeasurementToolDrawer,
  icon: SquareFoot,
  iconOn: SquareFoot,
  label: "Measurements",
  id: "measurements",
});

export default MeasurementToolDrawer;
