import { DebouncedTextInput, Search, useTheme } from "@app/design-system";
import React, { useState } from "react";
import styled from "styled-components";
import type { Incident } from "../../../../.rest-hooks/types";
import type { IncidentCtaProps } from "../../drawers/IncidentsDrawerCell/IncidentCard";
import IncidentCardList from "../../drawers/IncidentsDrawerCell/IncidentCardList";
import useUnsafeMapContext from "../../map/Map/useUnsafeMapContext";
import useIncidentsByBbox from "../../map/hooks/useIncidentsByBbox/useIncidentsByBbox";
import type { PreferredPredictionAttribute } from "../PreferredPredictionValidIndicator/PreferredPredictionValidIndicator";

const StyledLayersBottomTab = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  padding: 0.5rem;
`;

const StyledSearch = styled.div`
  padding: 0.5rem;
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
`;

interface IncidentsBottomTabProps {
  cta: React.ComponentType<IncidentCtaProps>;
  isCopView?: boolean;
  onClose?: () => void;
  predictionAttribute: PreferredPredictionAttribute;
}

const IncidentsBottomTab = ({
  cta,
  isCopView,
  predictionAttribute,
  onClose,
}: IncidentsBottomTabProps) => {
  const theme = useTheme();
  const { map } = useUnsafeMapContext();

  const [term, setTerm] = useState("");

  const getIncidentsQuery = useIncidentsByBbox({
    term,
    useBbox: false,
  });

  const onLocateClick = (incident: Incident) => {
    onClose?.();

    const [lng, lat] = incident.attributes.location.coordinates;

    map?.easeTo({
      center: {
        lat,
        lng,
      },
      zoom: 12,
      duration: theme.anim.duration.lg,
    });
  };

  return (
    <StyledLayersBottomTab>
      <StyledSearch>
        <DebouncedTextInput
          iconStart={Search}
          isClearable
          onChange={(value) => setTerm(value)}
          placeholder="Search name or LGA..."
          value={term}
        />
      </StyledSearch>
      <IncidentCardList
        cta={cta}
        data-testid="incidents-list"
        getIncidentsQuery={getIncidentsQuery}
        isCopView={isCopView}
        isSpotlightFirstResultEnabled={false}
        layout="card"
        onLocateClick={onLocateClick}
        predictionAttribute={predictionAttribute}
        term={term}
      />
    </StyledLayersBottomTab>
  );
};

export default IncidentsBottomTab;
