import { AnchorButton, Link, OpenInNewTab } from "@app/design-system";
import styled from "styled-components";
import MapRailDrawerCell from "../MapRailDrawerCell";
import { useMapRailContext } from "../MapRailProvider";
import { createMapRailItem } from "../useMapRail";
import DownloadLightningDataButton from "./DownloadLightningDataButton";

const railTabId = "links";
const railTabLabel = "Links";

const StyledLinksDrawer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  gap: 0.75rem;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  background: ${(p) => p.theme.colors.neutrals.background};
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
`;

const LinksDrawer = () => {
  const { remove } = useMapRailContext();

  return (
    <MapRailDrawerCell
      label={railTabLabel}
      onClose={() => remove({ id: railTabId })}
    >
      <StyledLinksDrawer>
        <AnchorButton
          href="/forecasts/rart"
          icon={OpenInNewTab}
          target="_blank"
          variant="secondary"
        >
          RART standby status
        </AnchorButton>
        <AnchorButton
          href="/forecasts/alert-status/district"
          icon={OpenInNewTab}
          target="_blank"
          variant="secondary"
        >
          Alert status
        </AnchorButton>
        <DownloadLightningDataButton />
      </StyledLinksDrawer>
    </MapRailDrawerCell>
  );
};

export const linksMapRailItem = createMapRailItem({
  component: LinksDrawer,
  icon: Link,
  iconOn: Link,
  label: "Links",
  id: "links",
});

export default LinksDrawer;
