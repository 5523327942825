import { boolean, object, string } from "yup";
import type { IncidentCardLayout } from "../../../drawers/IncidentsDrawerCell/IncidentCard";

export interface IncidentsDrawerSettings {
  categoryFilter: string;
  layout: IncidentCardLayout;
  isSearchWithinViewEnabled: boolean;
  isSpotlightFirstResultEnabled: boolean;
  statusFilter: string;
}

export const incidentsDrawerSchema = object<IncidentsDrawerSettings>().shape({
  isSearchWithinViewEnabled: boolean().default(false).required(),
  isSpotlightFirstResultEnabled: boolean().default(true).required(),
  layout: string().oneOf(["card", "list"]).default("list").required(),
  categoryFilter: string().default("any").required(),
  statusFilter: string().default("all").required(),
});

export const isIncidentsDrawerSettings = (
  value: unknown,
): value is IncidentsDrawerSettings => {
  try {
    incidentsDrawerSchema.validateSync(value, {
      strict: true,
    });
    return true;
  } catch (error) {
    return false;
  }
};
