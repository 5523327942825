import { useTheme } from "@app/design-system";
import mapboxgl from "mapbox-gl";
import maplibregl from "maplibre-gl";
import { useCallback, useRef } from "react";
import useUnsafeMapContext from "../../../map/Map/useUnsafeMapContext";

export interface UseSearchMarkerResult {
  addMarker: (lngLat: LngLatObject) => void;
  removeMarker: () => void;
}

export const useSearchMarker = (): UseSearchMarkerResult => {
  const theme = useTheme();
  const { lib, map } = useUnsafeMapContext();

  const searchMarkerRef = useRef<mapboxgl.Marker | maplibregl.Marker | null>(
    null,
  );

  const removeMarker = useCallback(() => {
    if (searchMarkerRef.current) {
      searchMarkerRef.current.remove();
      searchMarkerRef.current = null;
    }
  }, []);

  const addMarker = (lngLat: LngLatObject) => {
    removeMarker();

    if (map) {
      const options = {
        color: theme.colors.informative.icon,
        scale: 0.8,
      } satisfies mapboxgl.MarkerOptions & maplibregl.MarkerOptions;

      if (lib === "mapbox") {
        searchMarkerRef.current = new mapboxgl.Marker(options)
          .setLngLat(lngLat)
          .addTo(map);
      } else if (lib === "maplibre") {
        searchMarkerRef.current = new maplibregl.Marker(options)
          .setLngLat(lngLat)
          .addTo(map);
      }
    }
  };

  return { addMarker, removeMarker };
};
