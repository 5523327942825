import { useQuery } from "@tanstack/react-query";
import getMapServerProxyBasepath from "../utils/getMapServerProxyBasepath";
import useAuthAccessToken from "./useAuthAccessToken";

interface AttachmentInfo {
  id: string;
  contentType: string;
  size: number;
  name: string;
  globalId: string;
  parentGlobalId: string;
}

interface Attachments {
  attachmentInfos: AttachmentInfo[];
}

interface FetchAttachmentsParams {
  accessToken: string;
  signal?: AbortSignal;
  url: string;
}

const fetchAttachments = async ({
  accessToken,
  url,
}: FetchAttachmentsParams) => {
  const response = await fetch(url, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const json = await response.json();

  return json as Attachments;
};

interface UseAttachmentsQueryParams {
  objectId?: string;
  urlPath: string;
}

const useAttachmentsQuery = ({
  urlPath,
  objectId,
}: UseAttachmentsQueryParams) => {
  const accessToken = useAuthAccessToken();
  const attachmentsUrl = `${getMapServerProxyBasepath()}${urlPath}/${objectId}/attachments?f=json`;

  const { data, isError } = useQuery({
    enabled: !!objectId && !!accessToken,
    queryFn: () =>
      fetchAttachments({ accessToken: accessToken!, url: attachmentsUrl }),
    queryKey: [attachmentsUrl],
  });

  return { data, isError };
};

export default useAttachmentsQuery;
