import { rtaIncidents } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import RtaIncidentsLayer from "./RtaIncidentsLayer";

const RtaIncidents = () => {
  const { isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(rtaIncidents.id)) {
    return null;
  }

  return <RtaIncidentsLayer />;
};

export default RtaIncidents;
