import {
  FieldGrid,
  Skeleton,
  SkeletonButton,
  useIsMinWidth,
} from "@app/design-system";
import getConfig from "next/config";
import { useHasPrivilege } from "../../../hooks/useHasPrivilege";
import { StyledPreferredPredictionValidIndicator } from "../../ui/PreferredPredictionValidIndicator/PreferredPredictionValidIndicator";
import MapPopupView from "../SpatialPopup/MapPopupView";
import {
  StyledBody,
  StyledIncidentPopupContent,
  StyledHeader,
  StyledSubtitle,
  StyledTitle,
  StyledHeading,
} from "./IncidentPopupView";

const { publicRuntimeConfig } = getConfig();
const { AVIATION_SAFETY_ENABLED } = publicRuntimeConfig;

interface SkeletonIncidentPopupProps {
  isCopView?: boolean;
  onClose: () => void;
}

const SkeletonIncidentPopup = ({
  isCopView,
  onClose,
}: SkeletonIncidentPopupProps) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  const { hasPrivilege: hasAviationPrivilege } = useHasPrivilege({
    requestedPermissions: ["aviation-safety:read"],
  });

  return (
    <MapPopupView
      header={
        <StyledHeader>
          <Skeleton width={32} />
        </StyledHeader>
      }
      onClose={onClose}
    >
      <StyledIncidentPopupContent>
        <StyledBody>
          <StyledHeading>
            <StyledTitle>
              <Skeleton />
            </StyledTitle>
            <StyledSubtitle>
              <Skeleton />
            </StyledSubtitle>
          </StyledHeading>
          {/* COP view does not display preferred prediction information */}
          {!isCopView && (
            <StyledPreferredPredictionValidIndicator>
              <Skeleton />
            </StyledPreferredPredictionValidIndicator>
          )}
          <FieldGrid>
            {/* On mobile we have an extra row for status */}
            {!isTabletLandscapeAndAbove && (
              <FieldGrid.Item label={<Skeleton />}>
                <Skeleton />
              </FieldGrid.Item>
            )}
            <FieldGrid.Item label={<Skeleton />}>
              <Skeleton />
            </FieldGrid.Item>
            <FieldGrid.Item label={<Skeleton />}>
              <Skeleton />
            </FieldGrid.Item>
            <FieldGrid.Item label={<Skeleton />}>
              <Skeleton />
            </FieldGrid.Item>
            <FieldGrid.Item label={<Skeleton />}>
              <Skeleton />
            </FieldGrid.Item>
            {!isCopView && AVIATION_SAFETY_ENABLED && hasAviationPrivilege && (
              <FieldGrid.Item label={<Skeleton />}>
                <Skeleton />
              </FieldGrid.Item>
            )}
            {/* COP view has one less field grid item */}
            {!isCopView && (
              <FieldGrid.Item label={<Skeleton />}>
                <Skeleton />
              </FieldGrid.Item>
            )}
          </FieldGrid>
        </StyledBody>
        {/* COP view is unable to view incident map */}
        {!isCopView && <SkeletonButton size="sm" />}
      </StyledIncidentPopupContent>
    </MapPopupView>
  );
};

export default SkeletonIncidentPopup;
