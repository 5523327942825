import {
  CopyToClipboard,
  IconButton,
  type IconButtonSize,
  showToast,
} from "@app/design-system";
import QueryParams from "../../../config/queryParams";
import type { PostLink } from "../SocialFeed/socialFeedQuery";

interface GetTweetUrlParams {
  link: PostLink;
  tweetId: string;
}

export const getTweetUrl = ({ link, tweetId }: GetTweetUrlParams) => {
  const url = `${window.location.origin}/social/tweet/${tweetId}`;

  if (link.linkedTo !== "linked_to_incident") {
    return url;
  }

  // Attach the incidentId to the URL as a query param so that the view tweet
  // page can display the correct primary incident, e.g. in the linked incidents
  // display and for the behaviour of the view tweet on map button
  return `${url}?${QueryParams.INCIDENT_ID}=${link.incidentId}`;
};

interface CopyLinkToTweetButton {
  link: PostLink;
  size?: IconButtonSize;
  tweetId: string;
}

const CopyLinkToTweetButton = ({
  link,
  size = "md",
  tweetId,
}: CopyLinkToTweetButton) => {
  const onCopyLinkClick = () => {
    const url = getTweetUrl({ link, tweetId });
    navigator.clipboard.writeText(url).then(
      () => {
        showToast({
          title: "Copied",
          message: "Tweet link copied to clipboard",
          variant: "success",
          id: `${tweetId}-clipboard-success`,
        });
      },
      () => {
        showToast({
          title: "Unable to copy",
          message: "Error copying tweet link to clipboard",
          variant: "error",
          id: `${tweetId}-clipboard-error`,
        });
      },
    );
  };
  return (
    <IconButton
      icon={CopyToClipboard}
      label="Copy link to tweet"
      onClick={onCopyLinkClick}
      size={size}
      variant="ghost"
    />
  );
};

export default CopyLinkToTweetButton;
