import type { Feature, Point } from "geojson";

export type FieldObsWeatherFeature = Feature<
  Point,
  FieldObsWeatherFeatureProps
>;

// Determined using:
//  - https://shared-athena.apps.rfs.nsw.gov.au/arcgis/rest/services/FieldObs/WeatherObPresentation/FeatureServer/0?f=pjson
//  - https://desktop.arcgis.com/en/arcmap/latest/manage-data/geodatabases/arcgis-field-data-types.htm
export interface FieldObsWeatherFeatureProps {
  OBJECTID: number | string;
  GlobalID: string;
  Humidity: number | null;
  Temperature: number | null;
  WindDirection: number | null; // Coded value.
  WindSpeed: number | null;
  WindTrend: string | null; // Coded value.
}

export const FIELD_OBS_WEATHER_GIS_FIELD_NAMES = [
  "OBJECTID",
  "GlobalID",
  "Humidity",
  "Temperature",
  "WindDirection",
  "WindSpeed",
  "WindTrend",
];

export const FIELD_OBS_WEATHER_WIND_DIRECTION_CODED_VALUES = {
  1: "North",
  2: "North-East",
  3: "East",
  4: "South-East",
  5: "South",
  6: "South-West",
  7: "West",
  8: "North-West",
  9: "Variable",
} as const satisfies Record<number, string>;

export const FIELD_OBS_WEATHER_WIND_TREND_CODED_VALUES = {
  Increasing: "Increasing",
  Decreasing: "Decreasing",
  Steady: "Steady",
} as const satisfies Record<string, string>;
