import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";

const FUEL_TYPE_QUERY = buildMapboxBBoxQuery({
  SERVICE: "WMS",
  REQUEST: "GetMap",
  FORMAT: "image/png",
  TRANSPARENT: "TRUE",
  STYLES: "",
  VERSION: "1.3.0",
  LAYERS: "0",
  WIDTH: "512",
  HEIGHT: "512",
  CRS: "EPSG:3857",
});

const FUEL_TYPE_ID = "fuelType";

interface FuelTypeLayerProps {
  opacity?: number;
}

const FuelTypeLayer = ({ opacity = 1 }: FuelTypeLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(FUEL_TYPE_ID, {
      type: "raster",
      tileSize: 512,
      tiles: [
        `${getMapServerProxyBasepath()}/arcgis/services/Reference/PredictedFuelLoad/MapServer/WMSServer?${FUEL_TYPE_QUERY}`,
      ],
    });

    map.addLayer(
      {
        id: FUEL_TYPE_ID,
        type: "raster",
        source: FUEL_TYPE_ID,
        minzoom: 8,
        paint: {
          "raster-opacity": 0.8,
        },
      },
      MapLevel.BACKGROUND,
    );

    return () => {
      if (map.getLayer(FUEL_TYPE_ID)) {
        map.removeLayer(FUEL_TYPE_ID);
      }
      if (map.getSource(FUEL_TYPE_ID)) {
        map.removeSource(FUEL_TYPE_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    map?.setPaintProperty(FUEL_TYPE_ID, "raster-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default FuelTypeLayer;
