import {
  Alert,
  Combobox,
  DropdownOptionItem,
  Field,
  FieldGroup,
  FieldRow,
  MenuSection,
  RadioButtonGroup,
  Search,
  Select,
  Text,
  TextInput,
  onPromise,
  type FormControlOption,
} from "@app/design-system";
import { useMutation } from "@tanstack/react-query";
import type { BBox2d } from "@turf/helpers/dist/js/lib/geojson";
import getConfig from "next/config";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { HERE_MAPS_URL } from "../../../../config/general";
import { useHasPrivilege } from "../../../../hooks/useHasPrivilege";
import { coordinateParseFunctions } from "../../../../utils/coordinateConversion";
import type { BfNumberFeature } from "./SearchDrawerView";

const { publicRuntimeConfig } = getConfig();
const { NEXT_HERE_API_KEY } = publicRuntimeConfig;

type SearchMode = "locationName" | "locationCoordinates" | "bfNumber";

export type SearchDrawerFormError =
  | "searchError"
  | "noBfNumberResultsError"
  | "noCallSignResultsError"
  | "invalidCoordinatesError"
  | "bfNumberError";

export type SearchDrawerResult =
  | {
      type: "coordinates";
      value: LngLatObject;
    }
  | {
      type: "bounds";
      value: {
        bounds: BBox2d;
        center: LngLatObject;
      };
    }
  | {
      type: "resource";
      value: {
        resource: BfNumberFeature;
        coordinates: LngLatObject;
      };
    };

export interface SearchDrawerFormValues {
  searchMode: SearchMode;
  locationCoordinates: {
    type: keyof typeof coordinateParseFunctions;
    value: string;
  };
  bfNumber: string;
}

export const getDefaultSearchDrawerFormValues = (): SearchDrawerFormValues => ({
  searchMode: "locationName",
  locationCoordinates: { type: "dd", value: "" },
  bfNumber: "",
});

const StyledSearchDrawerForm = styled.form`
  display: grid;
  gap: 1rem;
  padding: 0.75rem 0.75rem 0.75rem;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};
`;

interface HereMapsResult {
  items: {
    title: string;
    id: string;
    mapView: {
      west: number;
      south: number;
      east: number;
      north: number;
    };
    scoring: {
      queryScore: string;
    };
  }[];
}

const QQ = "state=NSW;country=Australia";

const getHereMapsLocation = async (q: string): Promise<HereMapsResult> => {
  const queryString = new URLSearchParams({
    apiKey: NEXT_HERE_API_KEY,
    q,
    qq: QQ,
    limit: "10",
  }).toString();
  const res = await fetch(`${HERE_MAPS_URL}?${queryString}`);
  const data = await res.json();
  return data as HereMapsResult;
};

interface SearchDrawerFormProps {
  formError: SearchDrawerFormError | null;
  id: string;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  onComplete: (result: SearchDrawerResult) => void;
}

const SearchDrawerForm = ({
  formError,
  id,
  onSubmit,
  onComplete,
}: SearchDrawerFormProps) => {
  const { control, getValues, setValue } =
    useFormContext<SearchDrawerFormValues>();

  // using prediction:read to distinguish cop users from internal users.
  const { hasPrivilege } = useHasPrivilege({
    requestedPermissions: ["prediction:read"],
  });

  const { mutateAsync: mutateLocation } = useMutation({
    mutationFn: getHereMapsLocation,
  });

  const [result, setResult] = useState<HereMapsResult>({ items: [] });
  const options =
    result?.items?.map((v) => ({
      label: v.title,
      value: v.id,
    })) || [];

  const onStreetChange = async (value: React.ChangeEvent<HTMLInputElement>) => {
    const data = await mutateLocation(value.target.value);
    setResult(data);
  };

  const onStreetSelected = (value: FormControlOption | null) => {
    const mapView = result.items.find((v) => v.id === value?.value)?.mapView;
    if (mapView) {
      onComplete({
        type: "bounds",
        value: {
          bounds: [mapView.west, mapView.south, mapView.east, mapView.north],
          center: {
            lng: (mapView.west + mapView.east) / 2,
            lat: (mapView.north + mapView.south) / 2,
          },
        },
      });
    }
  };

  return (
    <StyledSearchDrawerForm id={id} onSubmit={onSubmit}>
      <FieldGroup>
        <Controller
          control={control}
          name="searchMode"
          render={({ field, fieldState }) => {
            const coordinateValue =
              field.value === "locationCoordinates" &&
              getValues().locationCoordinates;

            return (
              <MenuSection label="Search for">
                <FieldGroup>
                  <RadioButtonGroup
                    error={fieldState.error}
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                  >
                    <RadioButtonGroup.Item
                      label="Location name"
                      value="locationName"
                    />
                    <RadioButtonGroup.Item
                      label="Coordinates"
                      value="locationCoordinates"
                    />
                    {hasPrivilege && (
                      <RadioButtonGroup.Item
                        label="Appliance"
                        value="bfNumber"
                      />
                    )}
                  </RadioButtonGroup>

                  {coordinateValue && (
                    <FieldRow label="Format">
                      <Select
                        onChange={(type) =>
                          setValue("locationCoordinates", {
                            ...coordinateValue,
                            type:
                              (type?.value as typeof coordinateValue.type) ||
                              "dd",
                          })
                        }
                        options={Object.keys(coordinateParseFunctions).map(
                          (value) => ({
                            label: value.toUpperCase(),
                            value,
                          }),
                        )}
                        size="xs"
                        value={{
                          label: coordinateValue.type.toUpperCase(),
                          value: coordinateValue.type,
                        }}
                      />
                    </FieldRow>
                  )}
                </FieldGroup>
              </MenuSection>
            );
          }}
        />
        <Controller
          control={control}
          name="searchMode"
          render={({ field: searchModeField }) => {
            switch (searchModeField.value) {
              case "locationName": {
                return (
                  <Combobox
                    icon={Search}
                    label="Location name"
                    options={options}
                    onChangeInput={onPromise(onStreetChange)}
                    onChange={onStreetSelected}
                    placeholder="4 Murray Rose Ave, Homebush"
                    renderOption={({
                      focused,
                      disabled,
                      hasSelection,
                      selected,
                      itemProps,
                      option,
                    }) => (
                      <DropdownOptionItem
                        disabled={disabled}
                        focused={focused}
                        hasSelection={hasSelection}
                        selected={selected}
                        {...itemProps}
                      >
                        <Text size="bodyDefault">
                          <strong>{option.label}</strong>
                        </Text>
                      </DropdownOptionItem>
                    )}
                  />
                );
              }
              case "locationCoordinates": {
                return (
                  <Controller
                    control={control}
                    key={searchModeField.value}
                    name={searchModeField.value}
                    render={({ field, fieldState }) => {
                      return (
                        <Field error={fieldState.error} label="Coordinates">
                          <TextInput
                            {...field}
                            iconStart={Search}
                            isClearable
                            onChange={(event) =>
                              field.onChange({
                                ...field.value,
                                value: event.currentTarget.value,
                              })
                            }
                            onClearClick={() =>
                              field.onChange({ ...field.value, value: "" })
                            }
                            placeholder={
                              coordinateParseFunctions[field.value.type].example
                            }
                            value={field.value.value}
                          />
                        </Field>
                      );
                    }}
                  />
                );
              }
              case "bfNumber": {
                return (
                  <Controller
                    control={control}
                    key={searchModeField.value}
                    name={searchModeField.value}
                    render={({ field, fieldState }) => {
                      return (
                        <Field
                          error={fieldState.error}
                          label="BF number or call sign"
                        >
                          <TextInput
                            {...field}
                            iconStart={Search}
                            isClearable
                            onClearClick={() => field.onChange("")}
                            placeholder="BF05654"
                          />
                        </Field>
                      );
                    }}
                  />
                );
              }
            }
          }}
        />
      </FieldGroup>
      {formError === "noBfNumberResultsError" && (
        <Alert title="No BF number results found" variant="error">
          {`The BF number you searched for may be incorrect or belong to an
            appliance that hasn't reported it's location in the last 60 minutes.`}
        </Alert>
      )}
      {formError === "noCallSignResultsError" && (
        <Alert title="No call sign results found" variant="error">
          {`The call sign you searched for may be incorrect or belong to an
            appliance that hasn't reported it's location in the last 60 minutes.`}
        </Alert>
      )}
      {formError === "invalidCoordinatesError" && (
        <Alert title="Invalid coordinates" variant="error">
          Coordinate values are not valid.
        </Alert>
      )}
      {formError === "searchError" && (
        <Alert title="Unable to find location" variant="error">
          Please adjust your search and try again.
        </Alert>
      )}
      {formError === "bfNumberError" && (
        <Alert title="Unable to find BF Number" variant="error">
          Please adjust your search and try again.
        </Alert>
      )}
    </StyledSearchDrawerForm>
  );
};

export default SearchDrawerForm;
