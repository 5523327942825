import { Field, FieldGroup, Text } from "@app/design-system";
import listStyles from "@app/design-system/src/lib/styled/listStyles";
import styled from "styled-components";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";

const StyledTweetValidationReasons = styled.div`
  display: grid;
  gap: 8px;
  ${listStyles};
`;

const getReadableValidationReason = (validationReason: string) => {
  return validationReason
    .match(/[A-Z][a-z]+|[0-9]+/g)
    ?.join(" ")
    .toString();
};

export interface TweetValidationReasonsProps {
  validatedAt: number | undefined;
  validatedBy: string | undefined;
  validationNotes: string | undefined;
  validationReasons: string[] | undefined;
}

const TweetValidationReasons = ({
  validatedAt,
  validatedBy,
  validationNotes,
  validationReasons,
}: TweetValidationReasonsProps) => {
  return (
    <StyledTweetValidationReasons>
      <FieldGroup title="Validation details">
        <Field label="Reasons for validation">
          {validationReasons ? (
            <ul>
              {validationReasons.map((reason) => (
                <Text as="li" key={reason} size="bodyDefault">
                  {getReadableValidationReason(reason)}
                </Text>
              ))}
            </ul>
          ) : (
            <Text size="bodyDefault">&mdash;</Text>
          )}
        </Field>
        <Field label="Additional notes">
          <Text size="bodyDefault">{validationNotes || <>&mdash;</>}</Text>
        </Field>
        <Field label="Validated by">
          <div>
            <Text size="bodyDefault">{validatedBy || <>&mdash;</>}</Text>
            <Text size="footnote">
              {validatedAt ? (
                getFormattedDateAndTime(validatedAt * 1000)
              ) : (
                <>&mdash;</>
              )}
            </Text>
          </div>
        </Field>
      </FieldGroup>
    </StyledTweetValidationReasons>
  );
};

export default TweetValidationReasons;
