import { oilAndGasPipelinesLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import OilAndGasPipelinesLayer from "./OilAndGasPipelinesLayer";

const OilAndGasPipelines = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(oilAndGasPipelinesLayer.id)) {
    return null;
  }

  const state = getLayerState(oilAndGasPipelinesLayer.id);

  return <OilAndGasPipelinesLayer opacity={state?.opacity} />;
};
export default OilAndGasPipelines;
