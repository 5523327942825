import { Switch } from "@app/design-system";
import React from "react";
import styled from "styled-components";

const StyledSwitchMapRailPill = styled.div`
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
  box-shadow: ${(p) => p.theme.boxShadows.md};
  background-color: ${(p) => p.theme.colors.neutrals.background};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${(p) => p.theme.typography.variants.buttonDefault}
  color: ${(p) => p.theme.colors.neutrals.text};
  transition: color
    ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  cursor: default;

  &[data-disabled="true"] {
    cursor: not-allowed;
    color: ${(p) => p.theme.colors.neutrals.textDisabled};
  }
`;

interface SwitchMapRailPillProps {
  count?: number;
  isDisabled?: boolean;
  isSelected?: boolean;
  label: string;
  onChange?: (isSelected: boolean) => void;
}

const SwitchMapRailPill = ({
  count,
  isDisabled,
  isSelected,
  label,
  onChange,
}: SwitchMapRailPillProps) => {
  return (
    <StyledSwitchMapRailPill data-disabled={isDisabled}>
      <Switch
        aria-label={label}
        isDisabled={isDisabled}
        isSelected={isSelected}
        onChange={onChange}
        size="sm"
      />
      {label}
      {count === undefined ? "" : ` (${count})`}
    </StyledSwitchMapRailPill>
  );
};

export default SwitchMapRailPill;
