import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";

const LAND_USE_QUERY = buildMapboxBBoxQuery({
  dpi: "96",
  format: "png32",
  layers: "show:3",
  transparent: "true",
  bboxSR: "3857",
  imageSR: "3857",
  size: "512,512",
  f: "image",
});

const LAND_USE_ID = "landUse";

const LAND_USE_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/Athena/MapServer/export?${LAND_USE_QUERY}`;

interface LandUseLayerProps {
  opacity?: number;
}

const LandUseLayer = ({ opacity = 1 }: LandUseLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(LAND_USE_ID, {
      type: "raster",
      tileSize: 512,
      tiles: [LAND_USE_URL],
    });

    map.addLayer(
      {
        id: LAND_USE_ID,
        type: "raster",
        source: LAND_USE_ID,
        minzoom: 8,
      },
      MapLevel.BACKGROUND,
    );

    return () => {
      if (map.getLayer(LAND_USE_ID)) {
        map.removeLayer(LAND_USE_ID);
      }
      if (map.getSource(LAND_USE_ID)) {
        map.removeSource(LAND_USE_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    map.setPaintProperty(LAND_USE_ID, "raster-opacity", opacity);
  }, [opacity, map]);

  return null;
};

export default LandUseLayer;
