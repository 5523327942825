import { iconNswLayer } from "../../../config/layers/baseLayers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import IconNSWLayer from "./IconNSWLayer";

const IconNSW = () => {
  const { isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(iconNswLayer.id)) {
    return null;
  }

  return <IconNSWLayer />;
};

export default IconNSW;
