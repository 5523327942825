import {
  AnchorButton,
  Checkbox,
  Download,
  Field,
  FieldGroup,
  RadioButtonGroup,
  Text,
  TextInput,
  media,
} from "@app/design-system";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

const StyledLightningDownloadForm = styled.div`
  display: grid;
  gap: 1rem;
`;

const StyledRadioButtonGroup = styled.div`
  display: grid;
  gap: 0.25rem;

  @media ${media.lg} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const StyledTerms = styled.div`
  display: grid;
  gap: 0.125rem;
`;

const StyledActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

type TimePeriod = "2" | "4" | "8" | "12" | "24" | "48" | "72";
type QueryType = "period" | "range";

export interface LightningDownloadFormValues {
  queryType: QueryType;
  timePeriod: TimePeriod | "";
  intervalFrom: string;
  intervalTo: string;
  termsAndConditions: boolean;
}

export const getDefaultLightningDownloadFormValues =
  (): LightningDownloadFormValues => {
    return {
      queryType: "period",
      timePeriod: "",
      intervalFrom: "",
      intervalTo: "",
      termsAndConditions: false,
    };
  };

const LightningDownloadForm = () => {
  const {
    control,
    formState: { errors },
    register,
    watch,
  } = useFormContext<LightningDownloadFormValues>();

  const queryType = watch("queryType");

  return (
    <StyledLightningDownloadForm>
      <FieldGroup>
        <Controller
          control={control}
          name="queryType"
          render={({ field, fieldState }) => (
            <RadioButtonGroup
              error={fieldState.error}
              {...field}
              label="Query type"
            >
              <RadioButtonGroup.Item label="Time period" value="period" />
              <RadioButtonGroup.Item label="Date range" value="range" />
            </RadioButtonGroup>
          )}
        />
        {queryType === "period" && (
          <Controller
            control={control}
            name="timePeriod"
            render={({ field, fieldState }) => (
              <RadioButtonGroup
                error={fieldState.error}
                {...field}
                label="Select time period"
              >
                <StyledRadioButtonGroup>
                  <RadioButtonGroup.Item label="Last 2 hours" value="2" />
                  <RadioButtonGroup.Item label="Last 4 hours" value="4" />
                  <RadioButtonGroup.Item label="Last 8 hours" value="8" />
                  <RadioButtonGroup.Item label="Last 12 hours" value="12" />
                  <RadioButtonGroup.Item label="Last 24 hours" value="24" />
                  <RadioButtonGroup.Item label="Last 48 hours" value="48" />
                  <RadioButtonGroup.Item label="Last 72 hours" value="72" />
                </StyledRadioButtonGroup>
              </RadioButtonGroup>
            )}
            rules={{
              validate: (value) => !!value || "Please select a time period",
            }}
          />
        )}
        {queryType === "range" && (
          <>
            <Field
              error={errors.intervalFrom}
              htmlFor="intervalFrom"
              label="From date"
            >
              <TextInput
                id="intervalFrom"
                {...register("intervalFrom", {
                  required: "Please enter a from date",
                })}
                validationStatus={errors.intervalFrom && "error"}
                type="date"
                // only 14 days date range is allowed
                max={new Date().toISOString().split("T")[0]}
                min={
                  new Date(Date.now() - 13 * 24 * 3600 * 1000)
                    .toISOString()
                    .split("T")[0]
                }
              />
            </Field>
            <Field
              error={errors.intervalTo}
              htmlFor="intervalTo"
              label="To date"
            >
              <TextInput
                id="intervalTo"
                {...register("intervalTo", {
                  required: "Please enter a to date",
                })}
                validationStatus={errors.intervalTo && "error"}
                type="date"
                // only 14 days date range is allowed
                max={new Date().toISOString().split("T")[0]}
                min={
                  new Date(Date.now() - 13 * 24 * 3600 * 1000)
                    .toISOString()
                    .split("T")[0]
                }
              />
            </Field>
          </>
        )}
      </FieldGroup>
      <FieldGroup>
        <StyledTerms>
          <Text size="label" variant="weak">
            Usage terms
          </Text>
          <Text size="footnote">
            This data is provided by agreement between the NSW Government,
            represented by the NSW Rural Fire Service and you. Prior to
            downloading, please read the terms and conditions carefully. If you
            tick the &ldquo;Agree to terms and conditions&rdquo; box below you
            will have accepted and be bound by them.
          </Text>
        </StyledTerms>
        <Controller
          control={control}
          name="termsAndConditions"
          render={({ field, fieldState }) => (
            <Checkbox
              checked={!!field.value}
              error={fieldState.error}
              onChange={(event) => field.onChange(event.currentTarget.checked)}
              label="Agree to terms and conditions"
            />
          )}
          rules={{
            required: "Please accept the terms and conditions",
          }}
        />
      </FieldGroup>
      <StyledActions>
        <AnchorButton
          href="/pdf/LightningUseConditions.pdf"
          icon={Download}
          size="sm"
          target="_blank"
          variant="secondary"
        >
          Terms and conditions
        </AnchorButton>
        <AnchorButton
          href="/pdf/MapInstruction.pdf"
          icon={Download}
          size="sm"
          target="_blank"
          variant="secondary"
        >
          Data use instructions
        </AnchorButton>
      </StyledActions>
    </StyledLightningDownloadForm>
  );
};

export default LightningDownloadForm;
