import BrigadeCoverageSnapshotPopup from "../../popup/BrigadeCoverageSnapshotPopup/BrigadeCoverageSnapshotPopup";
import useInteractionFeatureState from "../MapInteractions/useInteractionFeatureState";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import { BRIGADES_FILL_LAYER_ID, BRIGADES_SOURCE_ID } from "./constants";
import { getPropertiesFromFeature } from "./interactions";
import useBrigadesMapData from "./useBrigadesMapData";
import useBrigadesMapLayer from "./useBrigadesMapLayer";

interface RFSBrigadesLayerProps {
  opacity?: number;
}

const RFSBrigadesLayer = ({ opacity }: RFSBrigadesLayerProps) => {
  useBrigadesMapData();
  useBrigadesMapLayer({ opacity });

  const { clickedState, hoveredState, deactivateClickState } =
    useLayerInteractions({
      getPropertiesFromFeature,
      layerId: BRIGADES_FILL_LAYER_ID,
    });

  useInteractionFeatureState({
    clickedState,
    hoveredState,
    sourceId: BRIGADES_SOURCE_ID,
  });

  return (
    <BrigadeCoverageSnapshotPopup
      onClose={deactivateClickState}
      state={clickedState}
    />
  );
};

export default RFSBrigadesLayer;
