import { GeometryVariant } from "../../.rest-hooks/types/common.yml";

const getGeometryVariant = (zoomLevel: number | null) => {
  if (!zoomLevel) {
    return GeometryVariant["very-simplified"];
  }

  if (zoomLevel > 11) {
    return GeometryVariant.simplified;
  }

  return GeometryVariant["very-simplified"];
};

export default getGeometryVariant;
