import { mobileBlackspotsLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import MobileBlackspotsLayer from "./MobileBlackspotsLayer";

const MobileBlackspots = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(mobileBlackspotsLayer.id)) {
    return null;
  }

  const state = getLayerState(mobileBlackspotsLayer.id);

  return <MobileBlackspotsLayer opacity={state?.opacity} />;
};

export default MobileBlackspots;
