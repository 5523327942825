import type { AircraftPositionsInteractionState } from "../../map/AircraftPositions/interactions";
import type { MapPopupType } from "../SpatialPopup/MapPopup";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import AircraftPositionPopupSummary from "./AircraftPositionPopupSummary";
import AircraftPositionsPopupHeader from "./AircraftPositionsPopupHeader";
import AircraftPositionsPreview from "./AircraftPositionsPreview";

interface AircraftPositionsPopupProps {
  "data-testid"?: string;
  isCopView: boolean;
  onClose: () => void;
  state: AircraftPositionsInteractionState;
  type: MapPopupType;
}

const AircraftPositionsPopup = ({
  "data-testid": dataTestId,
  isCopView,
  onClose,
  state,
  type,
}: AircraftPositionsPopupProps) => {
  const isClickPopup = type === "click";

  return (
    <SpatialPopup
      isOpen={state.isActive}
      lngLat={state.properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        id: state.id,
        offset: 16,
        size: "lg",
        type,
        fullWidth: isClickPopup,
      }}
    >
      {state.properties && (
        <MapPopupView
          data-testid={dataTestId}
          hasClose={isClickPopup}
          header={
            <AircraftPositionsPopupHeader status={state.properties.status} />
          }
          onClose={onClose}
        >
          {type === "hover" ? (
            <AircraftPositionsPreview
              callSign={state.properties.callSign}
              reportAge={state.properties.reportAge}
            />
          ) : (
            <AircraftPositionPopupSummary
              isCopView={isCopView}
              properties={state.properties}
            />
          )}
        </MapPopupView>
      )}
    </SpatialPopup>
  );
};

export default AircraftPositionsPopup;
