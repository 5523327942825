import type { BrigadeInteractionState } from "../../map/RFSBrigades/interactions";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import BrigadeCoverageSnapshotPopupView from "./BrigadeCoverageSnapshotPopupView";

interface BrigadeCoverageSnapshotPopupProps {
  onClose: () => void;
  state: BrigadeInteractionState;
}

const BrigadeCoverageSnapshotPopup = ({
  onClose,
  state,
}: BrigadeCoverageSnapshotPopupProps) => {
  const { id, isActive, properties } = state;

  return (
    <SpatialPopup
      isOpen={isActive}
      onClose={onClose}
      popupConfig={{
        id,
        offset: 8,
        type: "click",
        size: "lg",
      }}
      lngLat={properties?.lngLat}
    >
      <BrigadeCoverageSnapshotPopupView
        onClose={onClose}
        properties={properties}
      />
    </SpatialPopup>
  );
};

export default BrigadeCoverageSnapshotPopup;
