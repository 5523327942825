import { agedCareLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import AgedCareLayer from "./AgedCareLayer";

const AgedCare = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(agedCareLayer.id)) {
    return null;
  }

  const state = getLayerState(agedCareLayer.id);

  return <AgedCareLayer opacity={state?.opacity} />;
};

export default AgedCare;
