import { FieldGrid, Text } from "@app/design-system";
import styled from "styled-components";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import { getFormattedDate } from "../../../utils/formatDate/formatDate";
import type { BurntAreaCurrentFireSeasonInteractionProperties } from "../../map/BurntAreaCurrentFireSeason/interactions";

const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export interface BurntAreaCurrentFireSeasonPopupDetailProps {
  properties: BurntAreaCurrentFireSeasonInteractionProperties;
}

const BurntAreaCurrentFireSeasonPopupDetail = ({
  properties,
}: BurntAreaCurrentFireSeasonPopupDetailProps) => {
  return (
    <StyledBody>
      <Text size="subtitleMd">
        {properties.data.incidentName ||
          (properties.data.date ? (
            getFormattedDate(properties.data.date)
          ) : (
            <FallbackElement />
          ))}
      </Text>
      <FieldGrid layout="compact">
        {properties.data.incidentName && (
          <FieldGrid.Item label="Captured date:">
            {properties.data.date ? (
              getFormattedDate(properties.data.date)
            ) : (
              <FallbackElement />
            )}
          </FieldGrid.Item>
        )}

        <FieldGrid.Item label="Burnt area:">
          {properties.data.burntArea || <FallbackElement />}
        </FieldGrid.Item>
      </FieldGrid>
    </StyledBody>
  );
};

export default BurntAreaCurrentFireSeasonPopupDetail;
