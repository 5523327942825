import type { FormControlOption } from "@app/design-system";
import sortBy from "lodash/sortBy";
import type { Incident } from "../../../../.rest-hooks/types";

export const getIncidentMap = (
  incidents: Incident[] = [],
): Map<string, Incident> => {
  const incidentsById = incidents.map<[string, Incident]>((incident) => [
    incident.id,
    incident,
  ]);

  return new Map(incidentsById);
};

export const getIncidentOptions = (
  incidents: Incident[] = [],
): FormControlOption<string>[] => {
  const options = incidents.map((incident) => ({
    label: incident.attributes.name,
    value: incident.id,
  }));

  return sortBy(options, (option) => option.label);
};
