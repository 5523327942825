import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import { NSW_BORDER } from "./nswBorder";

const ICON_NSW_ID = "iconNSW";
const NSW_FILL = "fillNSW";

const IconNSWLayer = () => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(ICON_NSW_ID, {
      type: "raster",
      tileSize: 256,
      tiles: [
        `${getMapServerProxyBasepath()}/arcgis/rest/services/Cache/NSW_Vectors_WM/MapServer/tile/{z}/{y}/{x}`,
      ],
    });

    map.addLayer(
      {
        id: ICON_NSW_ID,
        type: "raster",
        source: ICON_NSW_ID,
      },
      MapLevel.BASE,
    );

    map.addLayer(
      {
        type: "fill",
        id: NSW_FILL,
        source: {
          type: "geojson",
          data: NSW_BORDER,
        } satisfies maplibregl.GeoJSONSourceSpecification,
        paint: {
          "fill-color": "#fff",
        },
      },
      ICON_NSW_ID,
    );

    return () => {
      if (map.getLayer(ICON_NSW_ID)) {
        map.removeLayer(ICON_NSW_ID);
      }
      if (map.getSource(ICON_NSW_ID)) {
        map.removeSource(ICON_NSW_ID);
      }
      if (map.getLayer(NSW_FILL)) {
        map.removeLayer(NSW_FILL);
      }
      if (map.getSource(NSW_FILL)) {
        map.removeSource(NSW_FILL);
      }
    };
  }, [map]);

  return null;
};

export default IconNSWLayer;
