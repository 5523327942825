import { useTheme } from "@app/design-system";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import { useMapRailContext } from "../../ui/MapRail/MapRailProvider";
import useMapContext from "../Map/useMapContext";
import { InteractionStateType } from "../MapInteractions/types";
import useExpandClusterOnClick from "../MapInteractions/useExpandClusterOnClick";
import useInteractionFeatureState from "../MapInteractions/useInteractionFeatureState";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import { isGeoJsonSource } from "../types";
import {
  SOCIAL_MEDIA_CLUSTERS_CLUSTER_COUNT_LAYER_ID,
  SOCIAL_MEDIA_CLUSTERS_CLUSTER_LAYER_ID,
  SOCIAL_MEDIA_CLUSTERS_MAX_ZOOM,
  SOCIAL_MEDIA_CLUSTERS_POINT_LAYER_ID,
  SOCIAL_MEDIA_CLUSTERS_POINT_SHADOW_LAYER_ID,
  SOCIAL_MEDIA_CLUSTERS_SOURCE_ID,
} from "./constants";
import {
  type TweetInteractionProperties,
  type TweetInteractionState,
  getPropertiesFromFeature,
} from "./interactions";
import type { TweetFeatureCollection } from "./types";
import { getTimeSteps } from "./utils";

interface TweetPopupRenderProps {
  onClose: () => void;
  state: TweetInteractionState;
}

interface SocialMediaClustersProps {
  renderPopup?: (props: TweetPopupRenderProps) => React.ReactNode;
  geojson: TweetFeatureCollection;
}

const SocialMediaClusters = (props: SocialMediaClustersProps) => {
  const { renderPopup, geojson } = props;

  const map = useMapContext();
  const theme = useTheme();

  useEffect(() => {
    map.addSource(SOCIAL_MEDIA_CLUSTERS_SOURCE_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      promoteId: "tweetId",
      cluster: true,
      clusterMaxZoom: SOCIAL_MEDIA_CLUSTERS_MAX_ZOOM,
      clusterMinPoints: 10,
      clusterRadius: 50,
    });

    map.addLayer(
      {
        id: SOCIAL_MEDIA_CLUSTERS_CLUSTER_LAYER_ID,
        type: "circle",
        source: SOCIAL_MEDIA_CLUSTERS_SOURCE_ID,
        filter: ["has", "point_count"],
        paint: {
          "circle-color": theme.colors.mapping.socialFill,
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            50,
            30,
            100,
            35,
            500,
            40,
          ],
        },
      },
      MapLevel.FS_SYMBOLS,
    );

    map.addLayer(
      {
        id: SOCIAL_MEDIA_CLUSTERS_CLUSTER_COUNT_LAYER_ID,
        type: "symbol",
        source: SOCIAL_MEDIA_CLUSTERS_SOURCE_ID,
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-size": 14,
          "text-optional": false,
          "text-allow-overlap": true,
        },
        paint: {
          "text-color": theme.colors.neutrals.textInverse,
        },
      },
      MapLevel.FS_SYMBOLS,
    );

    map.addLayer(
      {
        id: SOCIAL_MEDIA_CLUSTERS_POINT_SHADOW_LAYER_ID,
        type: "circle",
        source: SOCIAL_MEDIA_CLUSTERS_SOURCE_ID,
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": theme.colors.mapping.socialShadow,
          "circle-radius": [
            "case",
            ["boolean", ["feature-state", InteractionStateType.CLICKED], false],
            20,
            14,
          ],
          "circle-opacity": [
            "case",
            [
              "any",
              [
                "boolean",
                ["feature-state", InteractionStateType.HOVERED],
                false,
              ],
              [
                "boolean",
                ["feature-state", InteractionStateType.CLICKED],
                false,
              ],
            ],
            0.4,
            0,
          ],
        },
      },
      MapLevel.FS_SYMBOLS,
    );

    const timeSteps = getTimeSteps();

    map.addLayer(
      {
        id: SOCIAL_MEDIA_CLUSTERS_POINT_LAYER_ID,
        type: "circle",
        source: SOCIAL_MEDIA_CLUSTERS_SOURCE_ID,
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": [
            "case",
            [
              "any",
              [
                "boolean",
                ["feature-state", InteractionStateType.HOVERED],
                false,
              ],
              [
                "boolean",
                ["feature-state", InteractionStateType.CLICKED],
                false,
              ],
            ],
            theme.colors.mapping.socialActiveFill,
            [
              "step",
              ["get", "postedAt"],
              "#ffdae8",
              timeSteps.twentyFourHoursAgo,
              "#fdbad5",
              timeSteps.twelveHoursAgo,
              "#f895bf",
              timeSteps.sixHoursAgo,
              "#f36ea7",
              timeSteps.twoHoursAgo,
              "#dc015d",
            ],
          ],
          "circle-radius": 6,
          "circle-stroke-width": 2,
          "circle-stroke-color": theme.colors.mapping.socialStroke,
        },
      },
      MapLevel.FS_SYMBOLS,
    );

    return () => {
      if (map.getLayer(SOCIAL_MEDIA_CLUSTERS_CLUSTER_LAYER_ID)) {
        map.removeLayer(SOCIAL_MEDIA_CLUSTERS_CLUSTER_LAYER_ID);
      }
      if (map.getLayer(SOCIAL_MEDIA_CLUSTERS_CLUSTER_COUNT_LAYER_ID)) {
        map.removeLayer(SOCIAL_MEDIA_CLUSTERS_CLUSTER_COUNT_LAYER_ID);
      }
      if (map.getLayer(SOCIAL_MEDIA_CLUSTERS_POINT_LAYER_ID)) {
        map.removeLayer(SOCIAL_MEDIA_CLUSTERS_POINT_LAYER_ID);
      }
      if (map.getLayer(SOCIAL_MEDIA_CLUSTERS_POINT_SHADOW_LAYER_ID)) {
        map.removeLayer(SOCIAL_MEDIA_CLUSTERS_POINT_SHADOW_LAYER_ID);
      }
      if (map.getSource(SOCIAL_MEDIA_CLUSTERS_SOURCE_ID)) {
        map.removeSource(SOCIAL_MEDIA_CLUSTERS_SOURCE_ID);
      }
    };
  }, [
    map,
    theme.colors.mapping.socialActiveFill,
    theme.colors.mapping.socialFill,
    theme.colors.mapping.socialShadow,
    theme.colors.mapping.socialStroke,
    theme.colors.neutrals.text,
    theme.colors.neutrals.textInverse,
  ]);

  useExpandClusterOnClick({
    layerId: SOCIAL_MEDIA_CLUSTERS_CLUSTER_LAYER_ID,
    sourceId: SOCIAL_MEDIA_CLUSTERS_SOURCE_ID,
  });

  useEffect(() => {
    const source = map.getSource(SOCIAL_MEDIA_CLUSTERS_SOURCE_ID);
    if (isGeoJsonSource(source)) {
      source.setData(geojson);
    }
  }, [geojson, map]);

  const { pushModal, selectedModalItems } = useMapRailContext();

  const { clickedState, deactivateClickState, hoveredState } =
    useLayerInteractions<TweetInteractionProperties>({
      getPropertiesFromFeature,
      layerId: SOCIAL_MEDIA_CLUSTERS_POINT_LAYER_ID,
      // If there is no map popup configured for this layer, then we should
      // trigger the social drawer modal when a user clicks a feature.
      ...(!renderPopup && {
        onClick: (properties) => {
          pushModal({
            item: {
              id: "social",
              props: {
                tweetId: properties.tweetId,
              },
              type: "modal",
            },
          });
        },
      }),
    });

  useInteractionFeatureState<TweetInteractionProperties>({
    clickedFeatureId: selectedModalItems.get("social")?.props.tweetId,
    clickedState: renderPopup ? clickedState : null,
    hoveredState,
    sourceId: SOCIAL_MEDIA_CLUSTERS_SOURCE_ID,
  });

  if (!renderPopup) return null;

  return (
    <>{renderPopup({ state: clickedState, onClose: deactivateClickState })}</>
  );
};
export default SocialMediaClusters;
