import { accommodationFacilitiesLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import AccommodationFacilitiesLayer from "./AccommodationFacilitiesLayer";

const AccommodationFacilities = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(accommodationFacilitiesLayer.id)) {
    return null;
  }

  const state = getLayerState(accommodationFacilitiesLayer.id);

  return <AccommodationFacilitiesLayer opacity={state?.opacity} />;
};

export default AccommodationFacilities;
