import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";

const EMERGENCY_FACILITIES_ID = "emergencyFacilities";

const EMERGENCY_FACILITIES = {
  EMERGENCY_FACILITY_NSW_AMBULANCE_HELI_STATION:
    "/icons/emergency-facility-nsw-ambulance-heli-station.png",
  EMERGENCY_FACILITY_NSW_AMBULANCE_OPERATIONS_CENTRE:
    "/icons/emergency-facility-nsw-ambulance-operations-centre.png",
  EMERGENCY_FACILITY_NSW_AMBULANCE_SERVICE_CENTRE:
    "/icons/emergency-facility-nsw-ambulance-operations-centre.png",
  EMERGENCY_FACILITY_NSW_AMBULANCE_STATION:
    "/icons/emergency-facility-nsw-ambulance-operations-centre.png",
  EMERGENCY_FACILITY_NSW_COMMUNITY_FIRE_UNIT:
    "/icons/emergency-facility-nsw-community-fire-unit.png",
  EMERGENCY_FACILITY_NSW_FIRE_BRIGADE_STATION:
    "/icons/emergency-facility-nsw-fire-bridge-station.png",
  EMERGENCY_FACILITY_NSW_POLICE_SERVICE_STATION:
    "/icons/emergency-facility-nsw-police-service-station.png",
  EMERGENCY_FACILITY_RFS_BRIGADE_STATION:
    "/icons/emergency-facility-rfs-bridge-station.png",
  EMERGENCY_FACILITY_SES_UNIT_STATION:
    "/icons/emergency-facility-ses-unit-station.png",
  EMERGENCY_FACILITY_STATE_RESCUE_UNIT:
    "/icons/emergency-facility-state-rescue-unit.png",
  EMERGENCY_FACILITY_UNKNOWN: "/icons/emergency-facility-unknown.png",
};

const EMERGENCY_FACILITIES_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/10/query`;
const QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: "symbolname",
  },
};

interface EmergencyFacilitiesLayerProps {
  opacity?: number;
}

const EmergencyFacilitiesLayer = ({
  opacity = 1,
}: EmergencyFacilitiesLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(
    EMERGENCY_FACILITIES_URL,
    QUERY_OPTIONS,
  );

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(EMERGENCY_FACILITIES_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    Promise.all(
      Object.entries(EMERGENCY_FACILITIES).map(([imageId, src]) =>
        loadImage({ imageId, map, src, signal: controller.signal }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: EMERGENCY_FACILITIES_ID,
          type: "symbol",
          source: EMERGENCY_FACILITIES_ID,
          layout: {
            "icon-image": [
              "coalesce",
              ["image", ["get", "symbolname"]],
              ["image", "EMERGENCY_FACILITY_UNKNOWN"],
            ],
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
          minzoom: 8,
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      if (map.getLayer(EMERGENCY_FACILITIES_ID)) {
        map.removeLayer(EMERGENCY_FACILITIES_ID);
      }
      if (map.getSource(EMERGENCY_FACILITIES_ID)) {
        map.removeSource(EMERGENCY_FACILITIES_ID);
      }
      Object.keys(EMERGENCY_FACILITIES).forEach((id) => {
        if (map.hasImage(id)) {
          map.removeImage(id);
        }
      });
    };
  }, [map]);

  useEffect(() => {
    const source = map?.getSource(EMERGENCY_FACILITIES_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(EMERGENCY_FACILITIES_ID, "icon-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default EmergencyFacilitiesLayer;
