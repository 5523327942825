import {
  BottomSheetActionType,
  BottomTabItem,
  BottomTabs,
  Draw,
  Fire,
  FireFilled,
  Layers,
  LayersFilled,
  Measure,
  Search,
  useBottomSheet,
} from "@app/design-system";
import { useState } from "react";
import type { Tool } from "../../../config/tools";
import type { IncidentCtaProps } from "../../drawers/IncidentsDrawerCell/IncidentCard";
import LayersBottomTab from "../../drawers/LayersDrawerCell/LayersBottomTab";
import MarkupToolsetsBottomTab from "../../drawers/LayersDrawerCell/MarkupToolsetsBottomTab";
import { useSearchMarker } from "../MapRail/SearchDrawer/useSearchMarker";
import type { PreferredPredictionAttribute } from "../PreferredPredictionValidIndicator/PreferredPredictionValidIndicator";
import { useVisualiser } from "../Visualiser/VisualiserProvider";
import ActiveLegendModal from "./ActiveLegendModal";
import IncidentsBottomTab from "./IncidentsBottomTab";
import SearchBottomTab from "./SearchBottomTab";
import VisualiserBottomTabsAdornment from "./VisualiserBottomTabsAdornment";

interface StateViewBottomTabsProps {
  cta: React.ComponentType<IncidentCtaProps>;
  isCopView?: boolean;
  predictionAttribute: PreferredPredictionAttribute;
  showPredictionControls?: boolean;
}

const StateViewBottomTabs = ({
  cta,
  isCopView,
  predictionAttribute,
  showPredictionControls,
}: StateViewBottomTabsProps) => {
  const {
    visualiserState: { activeTool, toolsets },
  } = useVisualiser();

  const [prevActiveTool, setPrevActiveTool] = useState<Tool | null>(activeTool);

  const bottomSheet = useBottomSheet();

  if (activeTool !== prevActiveTool) {
    setPrevActiveTool(activeTool);
    if (activeTool) {
      bottomSheet.dispatch({
        position: "down",
        type: BottomSheetActionType.SET_POSITION,
      });
    }
  }

  const onClose = () => {
    bottomSheet.dispatch({
      position: "down",
      type: BottomSheetActionType.SET_POSITION,
    });
  };

  const { addMarker, removeMarker } = useSearchMarker();

  return (
    <>
      <ActiveLegendModal />
      <BottomTabs
        adornment={
          <VisualiserBottomTabsAdornment
            activeTool={activeTool}
            showPredictionControls={showPredictionControls}
          />
        }
        bottomSheet={bottomSheet}
        isAdornmentActive={!!activeTool || showPredictionControls}
      >
        <BottomTabItem
          icon={Layers}
          iconOn={LayersFilled}
          id="layers"
          textValue="Layers"
        >
          <LayersBottomTab />
        </BottomTabItem>
        <BottomTabItem
          icon={Fire}
          iconOn={FireFilled}
          id="incidents"
          textValue="Incidents"
        >
          <IncidentsBottomTab
            cta={cta}
            isCopView={isCopView}
            onClose={onClose}
            predictionAttribute={predictionAttribute}
          />
        </BottomTabItem>
        <BottomTabItem
          icon={Search}
          iconOn={Search}
          id="search"
          textValue="Search"
        >
          <SearchBottomTab
            onAddMarker={addMarker}
            onClose={onClose}
            onRemoveMarker={removeMarker}
          />
        </BottomTabItem>
        <BottomTabItem
          icon={Measure}
          iconOn={Measure}
          id="measure"
          textValue="Measure"
        >
          <div />
        </BottomTabItem>
        <BottomTabItem
          icon={Draw}
          iconOn={Draw}
          id="markup"
          isHidden={!toolsets.length}
          textValue="Markup"
        >
          <MarkupToolsetsBottomTab />
        </BottomTabItem>
      </BottomTabs>
    </>
  );
};

export default StateViewBottomTabs;
