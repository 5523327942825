import { FieldGrid, Text } from "@app/design-system";
import capitalize from "lodash/capitalize";
import escapeRegExp from "lodash/escapeRegExp";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import type {
  RtaIncidentFeatureProperties,
  RtaIncidentInteractionProperties,
} from "../../map/RtaIncidents/interactions";

const formatDisplayName = (properties: RtaIncidentFeatureProperties) => {
  // Most examples of the displayName duplicate the mainCategory at the start, so strip this out if present
  const stripped = properties.displayName.replace(
    new RegExp(`^${escapeRegExp(properties.mainCategory)}\\s*`),
    "",
  );

  // Capitalise the first letter
  return `${capitalize(stripped.slice(0, 1))}${stripped.slice(1)}`;
};

export interface RtaIncidentPopupDetailProps {
  properties: RtaIncidentInteractionProperties;
}

const RtaIncidentPopupDetail = ({
  properties,
}: RtaIncidentPopupDetailProps) => {
  return (
    <>
      <Text size="subtitleMd">
        {formatDisplayName(properties.data) || <>&mdash;</>}
      </Text>
      <FieldGrid>
        <FieldGrid.Item label="Updated:">
          {getFormattedDateAndTime(properties.data.lastUpdated)}
        </FieldGrid.Item>
        <FieldGrid.Item label="Description:">
          {properties.data.headline || <>&mdash;</>}
        </FieldGrid.Item>
      </FieldGrid>
    </>
  );
};

export default RtaIncidentPopupDetail;
