import { showToast } from "@app/design-system";
import bboxPolygon from "@turf/bbox-polygon";
import { fromBlob, Pool } from "geotiff";
import { useEffect, useRef, type MutableRefObject } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import * as plotty from "../../../thirdParty/plotty/plotty";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import { useHimawariTemperature } from "../../util/HimawariTemperatureProvider/HimawariTemperatureProvider";
import useMapContext from "../Map/useMapContext";
import {
  AUSTRALIA_BBOX_3857,
  AUSTRALIA_BBOX_4326,
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  COLOR_SCALE,
  DEFAULT_TEMPERATURE_CLAMP_C,
  NULL_VALUE,
} from "./constants";
import { celsiusToKelvin, normalizeTemperature } from "./utils";

function createCanvas() {
  const canvas = document.createElement("canvas");
  canvas.width = CANVAS_WIDTH;
  canvas.height = CANVAS_HEIGHT;
  canvas.setAttribute(
    "style",
    "position:absolute; left:-10000px; top:-10000px;",
  );
  document.body.appendChild(canvas);
  return canvas;
}

const ERRORS = {
  FAILED_TO_FETCH: "Failed to fetch Himawari data",
  FAILED_TO_REACH_SERVER: "Failed to reach Himawari data server",
  DATA_ISSUE: "There was an issue with the Himawari data, please try again.",
};

const QUERY = new URLSearchParams({
  service: "WMS",
  version: "1.1.1",
  request: "GetMap",
  layers: "aig:himawari_ide00207_t",
  bbox: AUSTRALIA_BBOX_3857.join(","),
  width: CANVAS_WIDTH.toString(),
  height: CANVAS_HEIGHT.toString(),
  srs: "EPSG:3857",
  styles: "",
  format: "image/geotiff",
  transparent: "true",
}).toString();

const LAYER_ID = "himawari";
const POLYGON_LAYER_ID = "himawari-polygon";
const SOURCE_ID = "himawari";

async function fetchImageAndDrawToCanvas({
  ref,
  canvas,
  accessToken,
  timestamp,
}: {
  ref: MutableRefObject<plotty.plot | undefined>;
  canvas: HTMLCanvasElement;
  accessToken: string | undefined;
  timestamp: string;
}) {
  let response;
  try {
    response = await fetch(
      `${getMapServerProxyBasepath()}/geoserver/aig/wms?${QUERY}&time=${timestamp}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  } catch {
    throw new Error(ERRORS.FAILED_TO_REACH_SERVER);
  }

  if (!response.ok) {
    throw new Error(ERRORS.FAILED_TO_FETCH);
  }

  const blob = await response.blob();
  const tiff = await fromBlob(blob);
  if (!tiff) {
    throw new Error(ERRORS.DATA_ISSUE);
  }

  const image = await tiff.getImage();

  const pool = new Pool();
  const data = await image.readRasters({ pool });
  const firstDataLayer = data[0];
  if (typeof firstDataLayer === "number") {
    throw new Error(ERRORS.DATA_ISSUE);
  }

  const temperatureInKelvin = celsiusToKelvin(DEFAULT_TEMPERATURE_CLAMP_C);
  const normalizedValue = normalizeTemperature(temperatureInKelvin);

  plotty.addColorScale("defaultScale", COLOR_SCALE, [
    0,
    normalizedValue,
    normalizedValue,
    1,
  ]);

  // eslint-disable-next-line no-param-reassign, new-cap
  ref.current = new plotty.plot({
    canvas,
    data: firstDataLayer,
    width: CANVAS_WIDTH,
    height: CANVAS_HEIGHT,
    domain: [0, 255],
    displayRange: [0, 255],
    colorScale: "defaultScale",
    noDataValue: NULL_VALUE,
  });
  ref.current?.render();
}

const HimawariSurfaceTemperatureLayer = () => {
  const map = useMapContext();
  const accessToken = useAuthAccessToken();
  const { temperature, timestamp, resetTemperatureValues } =
    useHimawariTemperature();
  const plottyRef = useRef<plotty.plot>();

  useEffect(() => {
    const canvas = createCanvas();
    const polygon = bboxPolygon(AUSTRALIA_BBOX_4326);
    const { coordinates } = polygon.geometry;
    const sourceCoordinates = coordinates[0].slice(0, 4).reverse() as [
      [number, number],
      [number, number],
      [number, number],
      [number, number],
    ];
    map.addSource(SOURCE_ID, {
      type: "canvas",
      canvas,
      coordinates: sourceCoordinates,
      animate: true,
    });
    map.addLayer(
      {
        id: LAYER_ID,
        type: "raster",
        source: SOURCE_ID,
        paint: {
          "raster-opacity": 0.8,
        },
      },
      MapLevel.BACKGROUND,
    );
    map.addLayer(
      {
        id: POLYGON_LAYER_ID,
        type: "line",
        source: {
          type: "geojson",
          data: polygon,
        } satisfies maplibregl.GeoJSONSourceSpecification,
      },
      MapLevel.BACKGROUND,
    );

    fetchImageAndDrawToCanvas({
      ref: plottyRef,
      canvas,
      accessToken,
      timestamp,
    }).catch((reason) => {
      showToast({
        title: reason.message,
        variant: "error",
      });
    });

    return () => {
      if (map.getLayer(LAYER_ID)) {
        map.removeLayer(LAYER_ID);
      }
      if (map.getSource(SOURCE_ID)) {
        map.removeSource(SOURCE_ID);
      }
      if (map.getLayer(POLYGON_LAYER_ID)) {
        map.removeLayer(POLYGON_LAYER_ID);
      }
      if (map.getSource(POLYGON_LAYER_ID)) {
        map.removeSource(POLYGON_LAYER_ID);
      }

      canvas.remove();
    };
  }, [map, accessToken, timestamp]);

  useEffect(() => {
    const temperatureInKelvin = celsiusToKelvin(temperature);
    const normalizedValue = normalizeTemperature(temperatureInKelvin);
    const scaleName = `himawari-${temperature}`;
    plotty.addColorScale(scaleName, COLOR_SCALE, [
      0,
      normalizedValue,
      normalizedValue,
      1,
    ]);
    plottyRef.current?.setColorScale(scaleName);
    plottyRef.current?.render();
  }, [temperature]);

  useEffect(() => {
    return () => {
      resetTemperatureValues();
    };
  }, [resetTemperatureValues]);

  return null;
};

export default HimawariSurfaceTemperatureLayer;
