import { Divider } from "@app/design-system";
import Skeleton from "react-loading-skeleton";
import { StyledLinkedIncidents } from "../LinkedIncidents/LinkedIncidents";
import SkeletonLinkedIncident from "../LinkedIncidents/SkeletonLinkedIncident";
import SkeletonSocialAttributes from "./SkeletonSocialAttributes";
import { StyledActions, StyledHeader, StyledSocialPost } from "./SocialPost";

interface SkeletonSocialPostProps {
  showOverflowMenu?: boolean;
}

const SkeletonSocialPost = ({
  showOverflowMenu = true,
}: SkeletonSocialPostProps) => {
  return (
    <StyledSocialPost>
      <StyledHeader>
        <StyledActions>
          <Skeleton height={28} width={115} />
          {showOverflowMenu && <Skeleton height={24} width={24} />}
        </StyledActions>
        <StyledLinkedIncidents>
          <SkeletonLinkedIncident />
        </StyledLinkedIncidents>
      </StyledHeader>
      <Divider />
      <SkeletonSocialAttributes />
      <Skeleton height={120} />
      <Skeleton />
    </StyledSocialPost>
  );
};

export default SkeletonSocialPost;
