import { SocialComment, SocialLike, SocialRepost } from "@app/design-system";
import styled, { css } from "styled-components";
import type { SocialTwitterAttributes } from "../../../../.rest-hooks/types/social-twitter.yml";
import TweetMetric from "./TweetMetric";

interface StyledTweetMetricsProps {
  fullWidth?: boolean;
}

export const StyledTweetMetrics = styled.div<StyledTweetMetricsProps>`
  display: flex;
  ${(p) =>
    p.fullWidth &&
    css`
      justify-content: space-between;
    `}
  gap: 2rem;
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

interface TweetMetricsProps {
  fullWidth?: boolean;
  likeCount: SocialTwitterAttributes["likeCount"];
  replyCount: SocialTwitterAttributes["replyCount"];
  retweetCount: SocialTwitterAttributes["retweetCount"];
}

const TweetMetrics = ({
  fullWidth,
  likeCount,
  replyCount,
  retweetCount,
}: TweetMetricsProps) => {
  return (
    <StyledTweetMetrics fullWidth={fullWidth}>
      <TweetMetric
        data-testid="likes"
        icon={SocialLike}
        count={likeCount}
        label="Likes"
      />
      <TweetMetric
        data-testid="reposts"
        icon={SocialRepost}
        count={retweetCount}
        label="Reposts"
      />
      <TweetMetric
        data-testid="replies"
        icon={SocialComment}
        count={replyCount}
        label="Replies"
      />
    </StyledTweetMetrics>
  );
};

export default TweetMetrics;
