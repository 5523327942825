import { fieldObsWeatherUnder3HrsLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FieldObsWeatherLayer from "./components/FieldObsWeatherLayer";

const FIELD_OBS_WEATHER_3HRS_ID = "fieldObsWeatherUnder3Hrs";
const FIELD_OBS_WEATHER_3HRS_SOURCE_LAYER_ID = 1;

const FieldObsWeatherUnder3Hrs = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(fieldObsWeatherUnder3HrsLayer.id)) {
    return null;
  }

  const state = getLayerState(fieldObsWeatherUnder3HrsLayer.id);

  return (
    <FieldObsWeatherLayer
      mapLayerId={FIELD_OBS_WEATHER_3HRS_ID}
      opacity={state?.opacity}
      popupTitle="Under 3 hrs"
      sourceLayerId={FIELD_OBS_WEATHER_3HRS_SOURCE_LAYER_ID}
    />
  );
};

export default FieldObsWeatherUnder3Hrs;
