import { useEffect } from "react";
import useMapContext from "../Map/useMapContext";
import setInteractionFeatureState from "../utils/setInteractionFeatureState";
import {
  InteractionStateType,
  type FeatureInteractionProperties,
  type FeatureInteractionState,
} from "./types";

interface UseInteractionFeatureStateParams<
  P extends FeatureInteractionProperties,
> {
  /**
   * Sometimes state "outside" of `useLayerInteractions` wants to control the
   * clicked appearance of a map feature. In these cases, you can pass a
   * `clickedFeatureId` in instead of managed `clickedState` in order to control
   * it more directly. An example is that clicking a social post on the map
   * in the `SocialMediaClusters` layer will trigger the social map rail modal,
   * which remains active even once another feature from a different layer gets
   * clicked. The clicked state of that layer is therefore "owned" by the modal
   * state and not `MapInteractionState`, and can be controlled using this prop.
   */
  clickedFeatureId?: string | number;
  clickedState: FeatureInteractionState<P> | null;
  hoveredState: FeatureInteractionState<P> | null;
  sourceId: string;
}

/**
 * Used for synchronising the Mapbox/Maplibre "feature state" of a feature in
 * response to changes in the clicked and hovered states provided by
 * `useLayerInteractions`. You only need to synchronise this feature state if
 * you are using it inside the layer styles to set a hover or click style on
 * your layer: if the symbol or polygon doesn't need to change style when the
 * user hovers or clicks on it, then you don't need this hook.
 *
 * @see https://docs.mapbox.com/help/tutorials/create-interactive-hover-effects-with-mapbox-gl-js/#feature-state-and-expressions
 */
const useInteractionFeatureState = <P extends FeatureInteractionProperties>({
  clickedState,
  hoveredState,
  sourceId,
  ...props
}: UseInteractionFeatureStateParams<P>) => {
  const map = useMapContext();

  const clickedFeatureId =
    (clickedState?.isActive && clickedState.properties.featureId) ||
    props.clickedFeatureId;

  useEffect(() => {
    if (!clickedFeatureId) return;

    setInteractionFeatureState({
      featureId: clickedFeatureId,
      isActive: true,
      map,
      sourceId,
      type: InteractionStateType.CLICKED,
    });

    return () => {
      setInteractionFeatureState({
        featureId: clickedFeatureId,
        isActive: false,
        map,
        sourceId,
        type: InteractionStateType.CLICKED,
      });
    };
  }, [clickedFeatureId, map, sourceId]);

  useEffect(() => {
    if (!hoveredState?.isActive) return;

    const hoveredFeatureId = hoveredState.properties.featureId;

    setInteractionFeatureState({
      featureId: hoveredFeatureId,
      isActive: true,
      map,
      sourceId,
      type: InteractionStateType.HOVERED,
    });

    return () => {
      setInteractionFeatureState({
        featureId: hoveredFeatureId,
        isActive: false,
        map,
        sourceId,
        type: InteractionStateType.HOVERED,
      });
    };
  }, [hoveredState, map, sourceId]);
};

export default useInteractionFeatureState;
