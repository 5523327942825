import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";

const FRNSW_JURISDICTIONAL_BOUNDARIES_ID = "FRNSWJurisdictionalBoundaries";
const FRNSW_JURISDICTIONAL_BOUNDARIES_TEXT_ID = `${FRNSW_JURISDICTIONAL_BOUNDARIES_ID}-text`;

const FRNSW_JURISDICTIONAL_BOUNDARIES_QUERY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/33/query`;
const QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: "FIRE_DISTRICT",
  },
};

interface FRNSWJurisdictionalBoundariesLayerProps {
  opacity?: number;
}

const FRNSWJurisdictionalBoundariesLayer = ({
  opacity = 1,
}: FRNSWJurisdictionalBoundariesLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(
    FRNSW_JURISDICTIONAL_BOUNDARIES_QUERY_URL,
    QUERY_OPTIONS,
  );

  useEffect(() => {
    map.addSource(FRNSW_JURISDICTIONAL_BOUNDARIES_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: FRNSW_JURISDICTIONAL_BOUNDARIES_ID,
        type: "line",
        source: FRNSW_JURISDICTIONAL_BOUNDARIES_ID,
        paint: {
          "line-color": "#ff00e6",
          "line-width": 1,
        },
      },
      MapLevel.BACKGROUND,
    );

    map.addLayer(
      {
        id: FRNSW_JURISDICTIONAL_BOUNDARIES_TEXT_ID,
        type: "symbol",
        source: FRNSW_JURISDICTIONAL_BOUNDARIES_ID,
        layout: {
          "text-field": ["get", "FIRE_DISTRICT"],
          "symbol-placement": "point",
        },
        paint: {
          "text-color": "rgb(39, 37, 39)",
          "text-halo-color": "rgb(255, 0, 230)",
          "text-halo-blur": 0.5,
          "text-halo-width": 1,
        },
      },
      MapLevel.BACKGROUND,
    );

    return () => {
      if (map.getLayer(FRNSW_JURISDICTIONAL_BOUNDARIES_ID)) {
        map.removeLayer(FRNSW_JURISDICTIONAL_BOUNDARIES_ID);
      }
      if (map.getLayer(FRNSW_JURISDICTIONAL_BOUNDARIES_TEXT_ID)) {
        map.removeLayer(FRNSW_JURISDICTIONAL_BOUNDARIES_TEXT_ID);
      }
      if (map.getSource(FRNSW_JURISDICTIONAL_BOUNDARIES_ID)) {
        map.removeSource(FRNSW_JURISDICTIONAL_BOUNDARIES_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    const source = map?.getSource(FRNSW_JURISDICTIONAL_BOUNDARIES_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(
      FRNSW_JURISDICTIONAL_BOUNDARIES_ID,
      "line-opacity",
      opacity,
    );
    map.setPaintProperty(
      FRNSW_JURISDICTIONAL_BOUNDARIES_TEXT_ID,
      "text-opacity",
      opacity,
    );
  }, [map, opacity]);

  return null;
};

export default FRNSWJurisdictionalBoundariesLayer;
