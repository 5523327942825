import { Skeleton } from "@app/design-system";
import {
  StyledDetail,
  StyledLinkedIncident,
  StyledTitle,
  StyledSubtitle,
} from "./LinkedIncident";

const SkeletonLinkedIncident = () => {
  return (
    <StyledLinkedIncident>
      <Skeleton width={20} height={20} />
      <StyledDetail>
        <StyledTitle>
          <Skeleton />
        </StyledTitle>
        <StyledSubtitle>
          <Skeleton />
        </StyledSubtitle>
      </StyledDetail>
    </StyledLinkedIncident>
  );
};

export default SkeletonLinkedIncident;
