import { AnchorButton } from "@app/design-system";
import styled from "styled-components";
import type { SocialTwitter } from "../../../../.rest-hooks/types";
import type { PostLink } from "../../social/SocialFeed/socialFeedQuery";
import ValidateTweetButton from "./ValidateTweetButton";

const StyledSocialDrawerFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
`;

interface SocialDrawerFooterProps {
  link: PostLink;
  tweet: SocialTwitter;
}

const SocialDrawerFooter = ({ link, tweet }: SocialDrawerFooterProps) => {
  const { externalId: postId, username } = tweet.attributes;

  const externalSocialUrl = `https://twitter.com/${username}/status/${postId}`;

  return (
    <StyledSocialDrawerFooter>
      <AnchorButton
        fullWidth
        href={externalSocialUrl}
        rel="noreferrer"
        target="_blank"
        variant="ghost"
      >
        View Post
      </AnchorButton>
      <ValidateTweetButton fullWidth tweet={tweet} link={link} />
    </StyledSocialDrawerFooter>
  );
};

export default SocialDrawerFooter;
