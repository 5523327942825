import { interstateIncidentsLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import InterstateIncidentsLayer from "./InterstateIncidentsLayer";
import {
  INTERSTATE_INCIDENTS_LAYER_OPTIONS,
  INTERSTATE_INCIDENTS_LAYER_OPTION_ID_TO_GEOCODE,
  INTERSTATE_INCIDENTS_SOURCE_URL,
} from "./constants";

const InterstateIncidents = () => {
  const { isLayerActive, getLayerStateFromConfig } = useActiveLayersContext();

  if (!isLayerActive(interstateIncidentsLayer.id)) {
    return null;
  }

  const state = getLayerStateFromConfig(interstateIncidentsLayer);

  const activeGeocodes = INTERSTATE_INCIDENTS_LAYER_OPTIONS.filter(
    (option) => !!state?.[option.id]?.isActive,
  ).map((option) => INTERSTATE_INCIDENTS_LAYER_OPTION_ID_TO_GEOCODE[option.id]);

  return (
    <InterstateIncidentsLayer
      opacity={state?.opacity}
      sourceUrl={INTERSTATE_INCIDENTS_SOURCE_URL}
      showGeocodes={activeGeocodes}
    />
  );
};

export default InterstateIncidents;
