import {
  DEFAULT_ERROR_MESSAGE,
  ModalForm,
  ModalHighlightSlot,
  showToast,
  onPromise,
} from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import { getGetSocialFeedQueryKey } from "../../../../.rest-hooks/social-feed";
import {
  getGetSocialTweetsIdQueryKey,
  getGetSocialTweetsQueryKey,
  usePatchSocialTweetsValidateId,
} from "../../../../.rest-hooks/social-twitter";
import type {
  PatchSocialTweetsValidateIdBodyValidationReasonsItem,
  SocialTwitter,
} from "../../../../.rest-hooks/types";
import type { PostLink } from "../SocialFeed/socialFeedQuery";
import TweetHeader from "../TweetHeader/TweetHeader";
import TweetPost from "../TweetPost/TweetPost";
import TweetValidationReasons from "../TweetValidationReasons/TweetValidationReasons";
import ValidateTweetForm, {
  type ValidateTweetFormValues,
  getDefaultValidateSocialPostFormValues,
} from "./ValidateTweetForm";

const StyledValidateTweetModalForm = styled.div`
  display: grid;
  gap: 1rem;
  padding-top: 0.25rem;
`;

type ValidateTweetModalFormVariant = "validate" | "link";

const titleMap: Record<ValidateTweetModalFormVariant, string> = {
  link: "Link post to an incident",
  validate: "Validate post",
};

const submitLabelMap: Record<ValidateTweetModalFormVariant, string> = {
  link: "Link",
  validate: "Validate",
};

interface ValidateTweetModalFormProps {
  cancelLabel?: string;
  cancelLabelIcon?: ReactSVGComponent;
  isOpen: boolean;
  link: PostLink;
  onClose: () => void;
  tweet: SocialTwitter;
  variant?: ValidateTweetModalFormVariant;
}

const ValidateTweetModalForm = ({
  cancelLabel,
  cancelLabelIcon,
  isOpen,
  link,
  onClose,
  tweet,
  variant = "validate",
}: ValidateTweetModalFormProps) => {
  const queryClient = useQueryClient();

  const defaultValues = getDefaultValidateSocialPostFormValues();

  const form = useForm<ValidateTweetFormValues>({
    defaultValues,
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    if (!isOpen) {
      reset(getDefaultValidateSocialPostFormValues());
    }
  }, [isOpen, reset]);

  const username = tweet?.attributes.username;
  const id = tweet?.attributes.id;
  const incidentIds = tweet?.attributes.incidentIds;

  const { mutateAsync: createSocialPostValidationMutation } =
    usePatchSocialTweetsValidateId({
      mutation: {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getGetSocialTweetsIdQueryKey(id),
          });
          void queryClient.invalidateQueries({
            queryKey: getGetSocialTweetsQueryKey(),
          });
          void queryClient.invalidateQueries({
            queryKey: getGetSocialFeedQueryKey(),
          });

          showToast({
            variant: "success",
            title: "Validation successful",
            message: `Post from @${username || "—"} has been validated.`,
          });
        },
        onError: () => {
          showToast({
            variant: "error",
            title: "Validation unsuccessful",
            message: `We were unable to validate the post from @${
              username || "—"
            }. ${DEFAULT_ERROR_MESSAGE}`,
          });
        },
      },
    });

  const onSubmit: SubmitHandler<ValidateTweetFormValues> = async (values) => {
    const newIncidentIds = values.incidentIds.map((option) => option.value);
    await createSocialPostValidationMutation({
      id,
      data: {
        ...(newIncidentIds.length && { incidentIds: newIncidentIds }),
        validationReasons:
          values.validationReasons as PatchSocialTweetsValidateIdBodyValidationReasonsItem[],
        validationNotes: values.note,
      },
    });

    onClose();
  };

  return (
    <ModalForm
      cancelLabel={cancelLabel}
      cancelLabelIcon={cancelLabelIcon}
      id="validateTweet"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onSubmit))}
      size="md"
      submitLabel={submitLabelMap[variant]}
      title={titleMap[variant]}
    >
      <StyledValidateTweetModalForm>
        <ModalHighlightSlot>
          <TweetHeader link={link} tweet={tweet} />
          <TweetPost tweet={tweet} />
        </ModalHighlightSlot>
        {tweet.attributes.validated && (
          <TweetValidationReasons
            validatedAt={tweet.attributes.validatedAt}
            validatedBy={tweet.attributes.validatedUserEmail}
            validationNotes={tweet.attributes.validationNotes}
            validationReasons={tweet.attributes.validationReasons}
          />
        )}
        <FormProvider {...form}>
          <ValidateTweetForm incidentIds={incidentIds} tweet={tweet} />
        </FormProvider>
      </StyledValidateTweetModalForm>
    </ModalForm>
  );
};

export default ValidateTweetModalForm;
