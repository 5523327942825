import { Tooltip, media } from "@app/design-system";
import styled, { css } from "styled-components";

const svgSize = (size: number) => css`
  svg {
    height: ${size}px;
    margin-left: auto;
  }
`;

export const tweetMetricSizes = {
  sm: 24,
  lg: 24,
  xxl: 32,
} as const;

const StyledTweetMetric = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  ${(p) => p.theme.typography.variants.bodyDefault}

  ${svgSize(tweetMetricSizes.sm)}

  @media ${media.lg} {
    ${svgSize(tweetMetricSizes.lg)}
  }

  @media ${media.xxl} {
    ${svgSize(tweetMetricSizes.xxl)}
  }
`;

interface TweetMetricProps {
  count: number | undefined;
  label: string;
  icon: ReactSVGComponent;
  "data-testid"?: string;
}

const TweetMetric = ({
  count,
  icon: Icon,
  label,
  "data-testid": dataTestId,
}: TweetMetricProps) => {
  return (
    <Tooltip delay message={label}>
      <StyledTweetMetric data-testid={dataTestId} title={label}>
        <Icon />
        {count || "-"}
      </StyledTweetMetric>
    </Tooltip>
  );
};

export default TweetMetric;
