import SiteInformationPopup from "../../popup/SiteInformationPopup/SiteInformationPopup";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import { getPropertiesFromFeature } from "./interactions";
import useSiteInformationMapLayer from "./useSiteInformationMapLayer";

const SITE_INFORMATION_ID = "siteInformation";

interface SiteInformationLayerProps {
  opacity?: number;
}

const SiteInformationLayer = ({ opacity }: SiteInformationLayerProps) => {
  useSiteInformationMapLayer({
    layerId: SITE_INFORMATION_ID,
    opacity,
  });

  const { clickedState, deactivateClickState } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: SITE_INFORMATION_ID,
  });

  return (
    <SiteInformationPopup onClose={deactivateClickState} state={clickedState} />
  );
};

export default SiteInformationLayer;
