import {
  ExpandLess,
  ExpandMore,
  IconButton,
  Text,
  VerticalDivider,
} from "@app/design-system";
import React from "react";
import type { Incident } from "../../../../.rest-hooks/types";
import { getAlertLevelFromIncident } from "../../../config/alertLevel";
import makeTestId from "../../../utils/makeTestId";
import AlertLevelIcon from "../../ui/AlertLevelIcon/AlertLevelIcon";
import IncidentNotificationToggleButton from "../../ui/IncidentNotificationToggleButton/IncidentNotificationToggleButton";
import {
  StyledActions,
  StyledDivider,
  StyledHeader,
  StyledHeading,
  StyledOverline,
  StyledSubtitle,
} from "./styled";

interface IncidentCardHeaderProps {
  "data-testid"?: string;
  incident: Incident;
  isCopView: boolean;
  isExpanded: boolean;
  title: React.ReactNode;
  toggleProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

const IncidentCardHeader = ({
  incident,
  isCopView,
  isExpanded,
  title,
  toggleProps,
  "data-testid": dataTestId,
}: IncidentCardHeaderProps) => {
  return (
    <StyledHeader>
      <StyledOverline>
        <AlertLevelIcon
          size="md"
          alertLevel={getAlertLevelFromIncident(incident)}
        />
        <StyledActions>
          {!isCopView && (
            <IncidentNotificationToggleButton incidentId={incident.id} />
          )}
          <StyledDivider>
            <VerticalDivider height={20} />
          </StyledDivider>
          <IconButton
            {...toggleProps}
            icon={isExpanded ? ExpandLess : ExpandMore}
            label={isExpanded ? "Collapse" : "Expand"}
            size="sm"
            variant="ghost"
            data-testid={makeTestId(dataTestId, "trigger")}
          />
        </StyledActions>
      </StyledOverline>
      <StyledHeading>
        <Text size="subtitleMd">{title}</Text>
        <StyledSubtitle>
          {incident.attributes.lga || <>LGA &mdash;</>}
        </StyledSubtitle>
      </StyledHeading>
    </StyledHeader>
  );
};

export default IncidentCardHeader;
