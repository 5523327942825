import { useTheme } from "@app/design-system";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import BurntAreaHazardReductionPopup from "../../popup/BurntAreaHazardReductionPopup/BurntAreaHazardReductionPopup";
import useMapContext from "../Map/useMapContext";
import { InteractionStateType } from "../MapInteractions/types";
import useInteractionFeatureState from "../MapInteractions/useInteractionFeatureState";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import { useMapServerQueryData } from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import {
  getPropertiesFromFeature,
  type BurntAreaHazardReductionLayerBaseProps,
} from "./utils";

const useMapLayer = ({
  layerId,
  layerUrl,
  maxzoom,
  minzoom,
  opacity = 1,
  queryOptions,
}: BurntAreaHazardReductionLayerBaseProps) => {
  const map = useMapContext();
  const theme = useTheme();
  const { data } = useMapServerQueryData(layerUrl, queryOptions);

  useEffect(() => {
    const activeLayerId = `${layerId}-active`;
    const zoom = { ...(maxzoom && { maxzoom }), ...(minzoom && { minzoom }) };

    map.addSource(layerId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: layerId,
        type: "circle",
        ...zoom,
        source: layerId,
        paint: {
          "circle-radius": 6,
          "circle-color": "#ffa900",
          "circle-stroke-width": 4,
          "circle-stroke-color": [
            "case",
            [
              "any",
              [
                "boolean",
                ["feature-state", InteractionStateType.HOVERED],
                false,
              ],
              [
                "boolean",
                ["feature-state", InteractionStateType.CLICKED],
                false,
              ],
            ],
            "#07c3f5",
            "#0385a8",
          ],
        },
      },
      MapLevel.SYMBOLS,
    );

    map.addLayer(
      {
        id: activeLayerId,
        type: "circle",
        source: layerId,
        ...zoom,
        paint: {
          "circle-radius": 6,
          "circle-color": "#ffa900",
          "circle-stroke-width": 4,
          "circle-stroke-color": "#07c3f5",
          "circle-opacity": [
            "case",
            ["boolean", ["feature-state", InteractionStateType.CLICKED], false],
            1,
            0,
          ],
          "circle-stroke-opacity": [
            "case",
            ["boolean", ["feature-state", InteractionStateType.CLICKED], false],
            1,
            0,
          ],
        },
      },
      MapLevel.SYMBOLS,
    );

    return () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getLayer(activeLayerId)) {
        map.removeLayer(activeLayerId);
      }
      if (map.getSource(layerId)) {
        map.removeSource(layerId);
      }
    };
  }, [
    layerId,
    map,
    maxzoom,
    minzoom,
    theme.colors.decorative.manualBorderMedium,
    theme.colors.neutrals.text,
  ]);

  useEffect(() => {
    map.setPaintProperty(layerId, "circle-opacity", opacity);
    map.setPaintProperty(layerId, "circle-stroke-opacity", opacity);
  }, [layerId, map, opacity]);

  useEffect(() => {
    const source = map.getSource(layerId);

    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, layerId, map]);
};

const BurntAreaHazardReductionPointLayer = (
  props: BurntAreaHazardReductionLayerBaseProps,
) => {
  useMapLayer(props);

  const { clickedState, deactivateClickState, hoveredState } =
    useLayerInteractions({ getPropertiesFromFeature, layerId: props.layerId });

  useInteractionFeatureState({
    clickedState,
    hoveredState,
    sourceId: props.layerId,
  });

  return (
    <BurntAreaHazardReductionPopup
      onClose={deactivateClickState}
      state={clickedState}
    />
  );
};

export default BurntAreaHazardReductionPointLayer;
