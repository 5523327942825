import styled from "styled-components";
import PredictionController from "../../PredictionController/PredictionController";

const StyledPredictionControlDrawer = styled.div`
  padding: 0.5rem 1.5rem;
  margin-inline: auto;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  box-shadow: ${(p) => p.theme.boxShadows.md};
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
  z-index: ${(p) => p.theme.zIndexes.overlayDrawer};
  pointer-events: all;
`;

interface PredictionControlDrawerProps {
  disabled?: boolean;
}

const PredictionControlDrawer = ({
  disabled,
}: PredictionControlDrawerProps) => {
  return (
    <StyledPredictionControlDrawer>
      <PredictionController
        disabled={disabled}
        data-testid="prediction-control"
      />
    </StyledPredictionControlDrawer>
  );
};

export default PredictionControlDrawer;
