import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";

const FIELD_OBS_ICON_UNDER_3_HRS_QUERY = buildMapboxBBoxQuery({
  dpi: "96",
  format: "png32",
  transparent: "true",
  bboxSR: "3857",
  imageSR: "3857",
  size: "512,512",
  f: "image",
});

const FIELD_OBS_ICON_UNDER_3_HRS_ID = "fieldObsIconUnder3Hrs";
const FIELD_OBS_ICON_UNDER_3_HRS_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/FieldObs/FieldObsPresentation3Hr/MapServer/export?${FIELD_OBS_ICON_UNDER_3_HRS_QUERY}`;

interface FieldObsIconUnder3HrsLayerProps {
  opacity?: number;
}

const FieldObsIconUnder3HrsLayer = ({
  opacity = 1,
}: FieldObsIconUnder3HrsLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(FIELD_OBS_ICON_UNDER_3_HRS_ID, {
      type: "raster",
      tileSize: 512,
      tiles: [FIELD_OBS_ICON_UNDER_3_HRS_URL],
    });

    map.addLayer(
      {
        id: FIELD_OBS_ICON_UNDER_3_HRS_ID,
        type: "raster",
        source: FIELD_OBS_ICON_UNDER_3_HRS_ID,
      },
      MapLevel.SYMBOLS,
    );

    return () => {
      if (map.getLayer(FIELD_OBS_ICON_UNDER_3_HRS_ID)) {
        map.removeLayer(FIELD_OBS_ICON_UNDER_3_HRS_ID);
      }
      if (map.getSource(FIELD_OBS_ICON_UNDER_3_HRS_ID)) {
        map.removeSource(FIELD_OBS_ICON_UNDER_3_HRS_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    map.setPaintProperty(
      FIELD_OBS_ICON_UNDER_3_HRS_ID,
      "raster-opacity",
      opacity,
    );
  }, [map, opacity]);

  return null;
};

export default FieldObsIconUnder3HrsLayer;
