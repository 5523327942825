import { getMapDataUrl } from "./utils";

/**
 * The image data is taken from http://gis-maps.dev.apps.rfs.nsw.gov.au/arcgis/rest/services/Reference/BaseDynamicData/MapServer/legend
 * This will be updated with a custom designed icon later
 */

export enum CommunicationTowersId {
  RFS_PAGING = "RFS_PAGING",
  RFS_PMR = "RFS_PMR",
  ABC_LOCAL_RADIO = "ABC_LOCAL_RADIO",
}

interface CommunicationTower {
  imageUrl: string;
  title: string;
  drawOutline?: boolean;
  dataUrl: string;
}

export const COMMUNICATION_TOWERS: Record<
  CommunicationTowersId,
  CommunicationTower
> = {
  [CommunicationTowersId.RFS_PAGING]: {
    imageUrl: "/icons/communication-rfs-paging-tower.png",
    title: "RFS Paging Tower",
    drawOutline: true,
    dataUrl: getMapDataUrl("37"),
  },
  [CommunicationTowersId.RFS_PMR]: {
    imageUrl: "/icons/communication-pmr-tower.png",
    title: "PMR Tower",
    dataUrl: getMapDataUrl("38"),
  },
  [CommunicationTowersId.ABC_LOCAL_RADIO]: {
    imageUrl: "/icons/communication-abc-radio-tower.png",
    title: "ABC Radio Tower",
    dataUrl: getMapDataUrl("36"),
  },
};
