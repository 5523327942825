import { soviAggregationLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import SocialVulnerabilityAggregationLayer from "./SocialVulnerabilityAggregationLayer";

const SocialVulnerabilityAggregation = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(soviAggregationLayer.id)) {
    return null;
  }

  const state = getLayerState(soviAggregationLayer.id);

  return <SocialVulnerabilityAggregationLayer opacity={state?.opacity} />;
};

export default SocialVulnerabilityAggregation;
