import { habitablePropertiesLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import HabitablePropertiesLayer from "./HabitablePropertiesLayer";

const HabitableProperties = () => {
  const { isLayerActive, getLayerStateFromConfig } = useActiveLayersContext();

  const isHabitablePropertiesLayerActive = isLayerActive(
    habitablePropertiesLayer.id,
  );

  const state = getLayerStateFromConfig(habitablePropertiesLayer);

  const isSwimmingPoolsActive =
    !!state?.habitablePropertiesSwimmingPools?.isActive;

  if (!isHabitablePropertiesLayerActive) {
    return null;
  }

  return (
    <HabitablePropertiesLayer
      isSwimmingPoolsActive={isSwimmingPoolsActive}
      opacity={state?.opacity}
    />
  );
};

export default HabitableProperties;
