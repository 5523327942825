import { FieldGrid, Text } from "@app/design-system";
import styled from "styled-components";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import type { NeighbourhoodInteractionProperties } from "../../map/NeighbourhoodSaferPlaces/interactions";

const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export interface NeighbourhoodSaferPlacesPopupDetailProps {
  properties: NeighbourhoodInteractionProperties;
}

const NeighbourhoodSaferPlacesPopupDetail = ({
  properties,
}: NeighbourhoodSaferPlacesPopupDetailProps) => {
  return (
    <StyledBody>
      <Text size="subtitleMd">{properties.data.Title}</Text>
      <FieldGrid>
        <FieldGrid.Item label="Location:">
          {properties.data.Location || <FallbackElement />}
        </FieldGrid.Item>
      </FieldGrid>
    </StyledBody>
  );
};

export default NeighbourhoodSaferPlacesPopupDetail;
