import { buttonReset, focusStyles } from "@app/design-system";
import styled from "styled-components";

export const StyledIncidentCard = styled.div`
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};
`;

export const StyledHeader = styled.div`
  display: grid;
  gap: 0.25rem;
  padding: 0.5rem 0.75rem 0.75rem;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
`;

export const StyledOverline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const StyledDivider = styled.div`
  padding: 0 0.25rem;
  color: ${(p) => p.theme.colors.neutrals.borderDisabled};
`;

export const StyledHeading = styled.div``;

export const StyledTitle = styled.button`
  ${buttonReset}
  ${(p) => p.theme.typography.variants.subtitleSm}
  word-break: break-word;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

export const StyledSubtitle = styled.div`
  ${(p) => p.theme.typography.variants.bodyDefault}
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

export const StyledContent = styled.div``;

export const StyledContentInner = styled.div`
  display: grid;
  gap: 1.25rem;
  padding: 0.75rem;
`;

export const StyledBody = styled.div`
  display: grid;
  gap: 1rem;
`;

export const StyledFields = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export const StyledFooter = styled.div``;
