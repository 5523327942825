import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import useMapContext from "../Map/useMapContext";

const MOBILE_BLACKSPOTS_ID = "mobileBlackspots";

const MOBILE_BLACKSPOTS_WMS_QUERY = buildMapboxBBoxQuery({
  SERVICE: "WMS",
  REQUEST: "GetMap",
  FORMAT: "image/png",
  TRANSPARENT: "TRUE",
  STYLES: "",
  VERSION: "1.3.0",
  LAYERS: "database-group",
  WIDTH: "512",
  HEIGHT: "512",
  CRS: "EPSG:3857",
});

interface MobileBlackspotsLayerProps {
  opacity?: number;
}

const MobileBlackspotsLayer = ({ opacity = 1 }: MobileBlackspotsLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(MOBILE_BLACKSPOTS_ID, {
      type: "raster",
      tileSize: 512,
      tiles: [
        `https://programs.communications.gov.au/geoserver/mobile-black-spot-programme/wms?${MOBILE_BLACKSPOTS_WMS_QUERY}`,
      ],
    });

    map.addLayer(
      {
        id: MOBILE_BLACKSPOTS_ID,
        type: "raster",
        source: MOBILE_BLACKSPOTS_ID,
        minzoom: 10,
      },
      MapLevel.SYMBOLS,
    );

    return () => {
      if (map.getLayer(MOBILE_BLACKSPOTS_ID)) {
        map.removeLayer(MOBILE_BLACKSPOTS_ID);
      }
      if (map.getSource(MOBILE_BLACKSPOTS_ID)) {
        map.removeSource(MOBILE_BLACKSPOTS_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    map.setPaintProperty(MOBILE_BLACKSPOTS_ID, "raster-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default MobileBlackspotsLayer;
