import { fireTrailsLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FireTrailsLayer from "./FireTrailsLayer";

const FireTrails = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(fireTrailsLayer.id)) {
    return null;
  }

  const state = getLayerState(fireTrailsLayer.id);

  return <FireTrailsLayer opacity={state?.opacity} />;
};

export default FireTrails;
