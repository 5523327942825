import { rartStandbyTodayLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import RartLayer from "./RartLayer";

const RartStandbyToday = () => {
  const { isLayerActive, getLayerState } = useActiveLayersContext();

  if (!isLayerActive(rartStandbyTodayLayer.id)) {
    return null;
  }

  return (
    <RartLayer
      layer="0"
      opacity={getLayerState(rartStandbyTodayLayer.id)?.opacity}
    />
  );
};

export default RartStandbyToday;
