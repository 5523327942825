import { captureException } from "@sentry/react";

interface DownloadBlobAsFile {
  blob: Blob;
  filename: string;
}

const downloadBlobAsFile = ({ blob, filename }: DownloadBlobAsFile) => {
  try {
    const href = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", filename);

    document.body.appendChild(link);

    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export default downloadBlobAsFile;
