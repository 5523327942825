import {
  type IconButtonSize,
  StaticIconWrapper,
  Twitter,
} from "@app/design-system";
import styled from "styled-components";
import type { SocialTwitter } from "../../../../.rest-hooks/types";
import type { PostLink } from "../SocialFeed/socialFeedQuery";
import TweetValidationBadge from "../TweetValidationBadge/TweetValidationBadge";
import TweetHeaderActions from "./TweetHeaderActions";

export const StyledTweetHeader = styled.div`
  display: flex;
  align-items: start;
  gap: 0.5rem;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

interface TweetHeaderProps {
  actions?: React.ReactNode;
  actionsSize?: IconButtonSize;
  link: PostLink;
  tweet: SocialTwitter;
  viewPost?: boolean;
}

const TweetHeader = ({
  actions,
  actionsSize,
  link,
  tweet,
  viewPost,
}: TweetHeaderProps) => {
  return (
    <StyledTweetHeader>
      <StyledHeader>
        <StaticIconWrapper size="xl" icon={Twitter} />
        <TweetValidationBadge validated={tweet.attributes.validated} />
      </StyledHeader>
      <TweetHeaderActions
        link={link}
        size={actionsSize}
        tweet={tweet}
        viewPost={viewPost}
      >
        {actions}
      </TweetHeaderActions>
    </StyledTweetHeader>
  );
};

export default TweetHeader;
