import { useMouseLocation } from "../../../state/MouseProvider";
import getDMSConversion from "./getDMSConversion";

const useLngLat = () => {
  const { mouseLocation } = useMouseLocation();

  return {
    lng: getDMSConversion({
      value: mouseLocation.lng.toFixed(6),
      coordinateType: "longitude",
    }),
    lat: getDMSConversion({
      value: mouseLocation.lat.toFixed(6),
      coordinateType: "latitude",
    }),
  } as const;
};

export default useLngLat;
