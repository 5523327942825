import { fireMapper24HrsLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import type { SubLayerState } from "../../util/ActiveLayersProvider/useActiveLayers";
import FireMapperMediaRasterLayer from "./FireMapperMediaRasterLayer";
import FireMapperRasterLayer from "./FireMapperRasterLayer";
import { getFireMapperSubLayer, type FireMapperLayerProps } from "./utils";

// NOTE: Duplicated in all FireMapper layer components to avoid circular references
const fireMapperLayerComponents = {
  Labels: { component: FireMapperRasterLayer, dataKey: "lo_txt" },
  Points: { component: FireMapperRasterLayer, dataKey: "po_txt" },
  Photos: { component: FireMapperMediaRasterLayer, dataKey: "ph_txt" },
  PDFs: { component: FireMapperMediaRasterLayer, dataKey: "pd_txt" },
  Lines: { component: FireMapperRasterLayer, dataKey: "li_txt" },
  Areas: { component: FireMapperRasterLayer, dataKey: "ar_txt" },
} as const satisfies Record<
  FireMapperLayerProps["layer"],
  { component: React.FC<FireMapperLayerProps>; dataKey: string }
>;

const FireMapper24Hrs = () => {
  const { isLayerActive, getLayerState } = useActiveLayersContext();
  const layerType: FireMapperLayerProps["layerType"] = "24Hrs";

  if (!isLayerActive(fireMapper24HrsLayer.id)) {
    return null;
  }

  const state = getLayerState<
    SubLayerState<ReturnType<typeof getFireMapperSubLayer>>
  >(fireMapper24HrsLayer.id);

  return (
    <>
      {Object.entries(fireMapperLayerComponents).map(([layer, values]) => {
        const option = getFireMapperSubLayer(
          layer as FireMapperLayerProps["layer"],
          layerType,
        );

        const isOptionEnabled = !!state?.[option.id]?.isActive;

        if (!isOptionEnabled) {
          return null;
        }

        const { component: Component } = values;

        return (
          <Component
            key={layer}
            layer={layer as FireMapperLayerProps["layer"]}
            layerType={layerType}
            dataKey={values.dataKey}
          />
        );
      })}
    </>
  );
};

export default FireMapper24Hrs;
