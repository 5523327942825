import type { Feature } from "geojson";
import type { LngLatLike } from "mapbox-gl";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isPointFeature } from "../types";

export interface ACTESAResourcesFeatureProperties {
  AGC: string;
  INO: string;
  ITP: string;
  TIM: string;
  VCS: string;
  VDS: string;
  VGP: string;
  VSC: string;
  VTP: string;
}

export function getPropertiesFromFeature(
  feature: Feature,
): ACTESAResourcesInteractionProperties | null {
  if (!isPointFeature(feature)) {
    return null;
  }

  const lngLat = feature.geometry.coordinates as LngLatLike;

  return {
    featureId: feature.properties.TIM,
    data: {
      AGC: feature.properties?.AGC ?? "",
      INO: feature.properties?.INO ?? "",
      ITP: feature.properties?.ITP ?? "",
      TIM: feature.properties?.TIM ?? "",
      VCS: feature.properties?.VCS ?? "",
      VDS: feature.properties?.VDS ?? "",
      VGP: feature.properties?.VGP ?? "",
      VSC: feature.properties?.VSC ?? "",
      VTP: feature.properties?.VTP ?? "",
    },
    lngLat,
  };
}

export interface ACTESAResourcesInteractionProperties
  extends FeatureInteractionProperties {
  data: ACTESAResourcesFeatureProperties;
}

export type ACTESAResourcesInteractionState =
  FeatureInteractionState<ACTESAResourcesInteractionProperties>;
