import type { LngLatLike } from "mapbox-gl";
import type { GetPropertiesFromFeatureFn } from "../../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../../MapInteractions/types";
import { isPointFeature } from "../../types";

interface FieldObsSitRepData {
  GlobalID: string;
  Incident: string;
  OBJECTID: string;
  Threats: string;
}

export interface FieldObsSitRepInteractionProperties
  extends FeatureInteractionProperties {
  data: FieldObsSitRepData;
}

export type FieldObsSitRepInteractionState =
  FeatureInteractionState<FieldObsSitRepInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  FieldObsSitRepInteractionProperties
> = (feature) => {
  if (!isPointFeature(feature)) {
    return null;
  }
  const lngLat = feature.geometry.coordinates as LngLatLike;
  const { GlobalID, Incident, OBJECTID, Threats } = feature.properties;

  const data = {
    GlobalID,
    Incident,
    OBJECTID,
    Threats,
  };

  return {
    data,
    featureId: OBJECTID,
    lngLat,
  };
};
