import { Info, InfoFilled, ToggleIconButton } from "@app/design-system";
import { predictionLegend } from "../../legends/PredictionLegend/PredictionLegend";
import { useMapRailContext } from "../MapRail/MapRailProvider";

const PredictionLegendToggleButton = () => {
  const { pushModal, selectedModalItems, removeModal } = useMapRailContext();

  const isSelected =
    selectedModalItems.get("legend")?.props.id === predictionLegend.id;

  return (
    <ToggleIconButton
      iconOn={InfoFilled}
      iconOff={Info}
      label="Legend"
      onClick={() => {
        if (isSelected) {
          removeModal({ id: "legend" });
          return;
        }
        pushModal({
          item: {
            id: "legend",
            props: predictionLegend,
            type: "modal",
          },
        });
      }}
      selected={isSelected}
      variant="secondary"
      size="sm"
    />
  );
};

export default PredictionLegendToggleButton;
