import type { BrigadeCoverageSnapshot } from "../../../../.rest-hooks/types/coverage.yml";
import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isMultiPolygonFeature, isPolygonFeature } from "../types";

export type BrigadeInteractionProperties = FeatureInteractionProperties & {
  brigadeId: string;
  brigadeName: string;
  coverage: BrigadeCoverageSnapshot | string | undefined;
  districtId: string;
};

export type BrigadeInteractionState =
  FeatureInteractionState<BrigadeInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  BrigadeInteractionProperties
> = (feature, event) => {
  if (!isMultiPolygonFeature(feature) && !isPolygonFeature(feature)) {
    return null;
  }

  const { lngLat } = event;
  const { brigadeId, brigadeName, coverage, districtId } = feature.properties;

  return {
    brigadeId,
    brigadeName,
    coverage,
    districtId,
    featureId: brigadeId,
    lngLat,
  };
};
