/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type TweetFiltersParameterItem =
  (typeof TweetFiltersParameterItem)[keyof typeof TweetFiltersParameterItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TweetFiltersParameterItem = {
  has_media: "has_media",
  near_hotspot: "near_hotspot",
  linked_to_incident: "linked_to_incident",
  linked_to_hotspot: "linked_to_hotspot",
  unlinked: "unlinked",
  validated: "validated",
  unvalidated: "unvalidated",
} as const;
