import styled from "styled-components";

const StyledFixedLayout = styled.div`
  position: relative;
  flex: 1;
`;

const StyledFixedLayoutInner = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

interface FixedLayoutProps {
  children?: React.ReactNode;
}

const FixedLayout = ({ children }: FixedLayoutProps) => {
  return (
    <StyledFixedLayout>
      <StyledFixedLayoutInner>{children}</StyledFixedLayoutInner>
    </StyledFixedLayout>
  );
};

export default FixedLayout;
