import type { DeepMutable } from "@kablamo/kerosene";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";

const FAFT_COLOR_SCALE = [
  1,
  "#d11212",
  2,
  "#2a17da",
  3,
  "#4b4b4b",
  "#000",
] as const;
const FAFT_LINE_SCALE = [
  1,
  ["literal", []],
  7,
  ["literal", [1, 5]],
  9,
  ["literal", [5, 10]],
  ["literal", []],
] as const;

const FIRE_TRAILS_ID = "fire-trails";

const FIRE_TRAILS_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/FAFT/MapServer/1/query`;
const QUERY_PARAMS: QueryOptions = {
  queryParams: {
    outFields: "FAFT_Classification, FAFT_VCC",
  },
};

interface FireTrailsLayerProps {
  opacity?: number;
}

const FireTrailsLayer = ({ opacity = 1 }: FireTrailsLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(FIRE_TRAILS_URL, QUERY_PARAMS);

  useEffect(() => {
    map.addSource(FIRE_TRAILS_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: FIRE_TRAILS_ID,
        type: "line",
        source: FIRE_TRAILS_ID,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": [
            "match",
            ["get", "FAFT_Classification"],
            ...FAFT_COLOR_SCALE,
          ],
          "line-width": 2,
          "line-dasharray": [
            "match",
            ["get", "FAFT_VCC"],
            ...(FAFT_LINE_SCALE as DeepMutable<typeof FAFT_LINE_SCALE>),
          ],
        },
      },
      MapLevel.LINES,
    );

    return () => {
      if (map.getLayer(FIRE_TRAILS_ID)) {
        map.removeLayer(FIRE_TRAILS_ID);
      }
      if (map.getSource(FIRE_TRAILS_ID)) {
        map.removeSource(FIRE_TRAILS_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    const source = map.getSource(FIRE_TRAILS_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(FIRE_TRAILS_ID, "line-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default FireTrailsLayer;
