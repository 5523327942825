import startOfDay from "date-fns/startOfDay";
import subYears from "date-fns/subYears";
import type { DateRange } from "../../../../lib/shared";

export type FESMTimeSpan = "fesm-1-3" | "fesm-4-7" | "fesm-7+";

interface GetFESMDateRangeParams {
  currentTime: number;
  timeSpan: FESMTimeSpan;
}

export const getFESMDateRange = ({
  currentTime,
  timeSpan,
}: GetFESMDateRangeParams): Partial<DateRange> => {
  const roundedCurrentTime = startOfDay(currentTime);

  switch (timeSpan) {
    case "fesm-1-3":
      return {
        startDate: subYears(roundedCurrentTime, 3),
      };
    case "fesm-4-7":
      return {
        startDate: subYears(roundedCurrentTime, 7),
        endDate: subYears(roundedCurrentTime, 3),
      };
    case "fesm-7+":
      return {
        endDate: subYears(roundedCurrentTime, 7),
      };
  }
};

export const formatCqlFilter = ({
  startDate,
  endDate,
}: Partial<DateRange>): string => {
  const formattedStartDate = startDate
    ? `image_tag_date>'${startDate.toISOString()}'`
    : "";
  const formattedEndDate = endDate
    ? `image_tag_date<'${endDate.toISOString()}'`
    : "";
  return [formattedStartDate, formattedEndDate].filter(Boolean).join(" and ");
};
