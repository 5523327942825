import type { FormControlOption } from "@app/design-system";
import type { PatchSocialTweetsValidateIdBodyValidationReasonsItem } from "../../../../.rest-hooks/types";

export const validationReasonsOptions: FormControlOption<PatchSocialTweetsValidateIdBodyValidationReasonsItem>[] =
  [
    { label: "Trusted source", value: "TrustedSource" },
    { label: "Confirmed by other source(s)", value: "ConfirmedByOther" },
    {
      label: "Validated by reconnaissance or surveillance",
      value: "ValidatedByReconnaissance",
    },
    {
      label: "Post content is highly relevant to the incident",
      value: "RelevantToIncident",
    },
    { label: "Other", value: "Other" },
  ];
