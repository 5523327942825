import { AlertModal } from "@app/design-system";
import type { AlertModalProps } from "@app/design-system/src/components/Modal/AlertModal";
import type { ResourceHeavyPlant } from "../../../../.rest-hooks/types/resources.yml/resourceHeavyPlant";
import CopyLinkToResourceButton from "../CopyLinkToResourceButton/CopyLinkToResourceButton";
import type { HeavyPlantActivitySummaryProps } from "./HeavyPlantActivitySummary";
import HeavyPlantDetail from "./HeavyPlantDetail";

export interface HeavyPlantDetailModalProps
  extends Pick<AlertModalProps, "isOpen" | "onDismiss">,
    HeavyPlantActivitySummaryProps {
  heavyPlant: ResourceHeavyPlant;
}

const HeavyPlantDetailModal = ({
  heavyPlant,
  isOpen,
  onDismiss,
}: HeavyPlantDetailModalProps) => {
  return (
    <AlertModal
      actions={
        <CopyLinkToResourceButton
          resourceId={heavyPlant.id}
          size="md"
          variant="ghost"
        />
      }
      dismissLabel="Close"
      isOpen={isOpen}
      onDismiss={onDismiss}
      size="lg"
      title={heavyPlant.attributes.callSign || "Unknown heavy plant"}
    >
      <HeavyPlantDetail heavyPlant={heavyPlant} />
    </AlertModal>
  );
};

export default HeavyPlantDetailModal;
