import { Text } from "@app/design-system";
import type { TelephoneExchangeInteractionState } from "../../map/TelephoneExchange/interactions";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import TelephoneExchangePopupDetail from "./TelephoneExchangePopupDetail";

export interface TelephoneExchangePopupProps {
  "data-testid"?: string;
  onClose: () => void;
  state: TelephoneExchangeInteractionState;
}

const TelephoneExchangePopup = ({
  "data-testid": dataTestId,
  onClose,
  state: { id, isActive, properties },
}: TelephoneExchangePopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      lngLat={properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        id,
        offset: 24,
        size: "xl",
        type: "click",
      }}
    >
      {properties && (
        <MapPopupView
          data-testid={dataTestId}
          header={
            <Text size="eyebrowDefault" variant="weak">
              Telephone exchange
            </Text>
          }
          onClose={onClose}
        >
          <TelephoneExchangePopupDetail properties={properties} />
        </MapPopupView>
      )}
    </SpatialPopup>
  );
};

export default TelephoneExchangePopup;
