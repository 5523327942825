import {
  Button,
  DEFAULT_ERROR_MESSAGE,
  PlaceholderCard,
  media,
  mediumSurfaceOverrides,
} from "@app/design-system";
import type {
  InfiniteData,
  UseInfiniteQueryResult,
} from "@tanstack/react-query";
import range from "lodash/range";
import styled from "styled-components";
import type {
  GetIncidentsQueryError,
  GetIncidentsQueryResult,
} from "../../../../.rest-hooks/incidents";
import type { Incident } from "../../../../.rest-hooks/types";
import makeTestId from "../../../utils/makeTestId";
import type { PreferredPredictionAttribute } from "../../ui/PreferredPredictionValidIndicator/PreferredPredictionValidIndicator";
import IncidentCard, {
  type IncidentCardLayout,
  type IncidentCtaProps,
} from "./IncidentCard";
import SkeletonIncidentCard, {
  SkeletonCollapsedIncidentCard,
} from "./SkeletonIncidentCard";

export const INCIDENTS_DRAWER_LIST_PER_PAGE = 10;

interface StyledIncidentCardListProps {
  isLoading?: boolean;
}

export const StyledIncidentCardList = styled.div<StyledIncidentCardListProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media ${media.lg} {
    gap: 0.1875rem;
  }
`;

const StyledLoadMore = styled.div`
  ${mediumSurfaceOverrides}
`;

interface IncidentCardListProps {
  cta: React.ComponentType<IncidentCtaProps>;
  "data-testid"?: string;
  getIncidentsQuery: UseInfiniteQueryResult<
    InfiniteData<GetIncidentsQueryResult>,
    GetIncidentsQueryError
  >;
  isCopView?: boolean;
  /**
   * Default expand the first item in the list
   */
  isSpotlightFirstResultEnabled?: boolean;
  layout: IncidentCardLayout;
  onLocateClick?: (incident: Incident) => void;
  predictionAttribute: PreferredPredictionAttribute;
  term?: string;
}

const IncidentCardList = ({
  cta,
  "data-testid": dataTestId,
  getIncidentsQuery,
  isCopView = false,
  isSpotlightFirstResultEnabled = true,
  layout,
  onLocateClick,
  predictionAttribute,
  term,
}: IncidentCardListProps) => {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
  } = getIncidentsQuery;

  const incidents = data?.pages.flatMap((page) => page.data.data) ?? [];
  const firstIncident = incidents[0];

  if (isPending) {
    return (
      <StyledIncidentCardList>
        {range(6).map((index) =>
          index === 0 ? (
            <SkeletonIncidentCard key={index} />
          ) : (
            <SkeletonCollapsedIncidentCard key={index} />
          ),
        )}
      </StyledIncidentCardList>
    );
  }

  if (error) {
    return (
      <PlaceholderCard
        flex
        title="Unable to load incidents"
        subtitle={DEFAULT_ERROR_MESSAGE}
        status="error"
        size="sm"
        variant="border"
      />
    );
  }

  if (!incidents.length) {
    return (
      <PlaceholderCard
        flex
        title="No incidents"
        size="sm"
        status="empty"
        subtitle={
          term
            ? `No incidents matching term "${term}" were found in this area`
            : "There are no incidents in this area"
        }
        variant="border"
      />
    );
  }

  return (
    <StyledIncidentCardList>
      {incidents.map((incident) => (
        <IncidentCard
          cta={cta}
          data-testid={makeTestId(dataTestId, incident.id)}
          {...(isSpotlightFirstResultEnabled && {
            defaultExpanded: incident.id === firstIncident?.id,
          })}
          incident={incident}
          isCopView={isCopView}
          key={`${firstIncident?.id}${incident.id}`}
          layout={layout}
          onLocateClick={() => onLocateClick?.(incident)}
          predictionAttribute={predictionAttribute}
        />
      ))}
      {incidents.length >= INCIDENTS_DRAWER_LIST_PER_PAGE && (
        <StyledLoadMore>
          <Button
            disabled={!hasNextPage || isFetchingNextPage}
            fullWidth
            onClick={() => void fetchNextPage()}
            variant="ghost"
          >
            Load more
          </Button>
        </StyledLoadMore>
      )}
    </StyledIncidentCardList>
  );
};

export default IncidentCardList;
