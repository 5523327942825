import type { Feature, FeatureCollection, Point } from "geojson";
import type { SocialTwitter } from "../../../../.rest-hooks/types";
import type {
  SocialTwitterAttributes,
  SocialTwitterAttributesMediaItem,
  SocialTwitterAttributesTagsItem,
} from "../../../../.rest-hooks/types/social-twitter.yml";

export interface TweetFeatureProperties {
  keywords?: string[];
  media?: SocialTwitterAttributesMediaItem[];
  postedAt: number;
  tags?: SocialTwitterAttributesTagsItem[];
  tweetId: string;
  validated: boolean;
}

export type TweetFeature = Feature<Point, TweetFeatureProperties>;

export type TweetFeatureCollection = FeatureCollection<
  Point,
  TweetFeatureProperties
>;

export type SocialTwitterWithLocation = SocialTwitter & {
  attributes: SocialTwitterAttributes &
    Required<Pick<SocialTwitterAttributes, "location">>;
};

export const isTweetFeature = (feature: Feature): feature is TweetFeature => {
  const properties =
    feature.properties as Partial<TweetFeatureProperties> | null;
  return feature.geometry.type === "Point" && !!properties?.tweetId;
};

export const isTweetWithLocation = (
  tweet: SocialTwitter,
): tweet is SocialTwitterWithLocation => {
  return !!tweet.attributes.location;
};
