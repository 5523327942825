import type { FeatureCollection } from "geojson";

export const NSW_BORDER: FeatureCollection = {
  type: "FeatureCollection",
  features: [
    {
      id: "nswBorder",
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: [
          [
            [140.97606507295222, -28.980719044931945],
            [148.96081949121185, -28.95756200367579],
            [149.39314321109617, -28.56310191133052],
            [149.68430000203716, -28.50884421578909],
            [150.33719704839177, -28.493336887016277],
            [150.8312812996881, -28.60958625783497],
            [151.39594901545502, -28.980719044931945],
            [151.72239753863226, -28.80304947672179],
            [151.93414793204414, -28.787585591758784],
            [151.8900332667493, -28.47782727969156],
            [152.3929404511041, -28.19826563320069],
            [152.59586791145733, -28.2138161166013],
            [153.08995216275366, -28.27599540206262],
            [153.49580708346014, -28.097132362531788],
            [153.61050521322568, -28.299303284475897],
            [153.59285934710852, -28.57859897671289],
            [153.67226574463768, -28.72570712339172],
            [153.61050521322568, -28.880334469473837],
            [153.4781612173431, -29.11184430699243],
            [153.37228602063607, -29.381282038644663],
            [153.34581722146038, -29.75729861347355],
            [153.28405669004832, -29.93331213863319],
            [153.21347322557773, -30.131909646431303],
            [153.17818149334136, -30.307259720402833],
            [153.07230629663644, -30.512705838085964],
            [153.03701456440024, -30.83900270281263],
            [153.03736729456796, -30.838362173093586],
            [153.0539312950362, -30.878713783335733],
            [153.06959994413023, -30.87256606321828],
            [153.08347789046934, -30.882940112121176],
            [153.0919837285466, -30.90598953218469],
            [153.0906407014828, -30.92903340278321],
            [153.079448809273, -30.93824939663856],
            [153.0400533486972, -31.012329034585264],
            [153.05169291659496, -31.04148450673525],
            [153.06870459275274, -31.04647073308567],
            [153.0682569170649, -31.06487915212459],
            [153.04184405145048, -31.096318626735346],
            [152.9853127234794, -31.179010244477936],
            [152.98531272347861, -31.205356845459434],
            [152.96627329743848, -31.239835852074556],
            [152.9774729598164, -31.265208193393043],
            [152.97131314550916, -31.30157927061927],
            [152.98027287540987, -31.312105121657794],
            [152.95339368570563, -31.350849252716678],
            [152.92315459728962, -31.415387344108126],
            [152.94499393892522, -31.47462768312956],
            [152.85301009195746, -31.57883211176231],
            [152.85164049795247, -31.62029691386148],
            [152.8584349020207, -31.647137406626058],
            [152.81553419778226, -31.697113110527248],
            [152.78560413435184, -31.792158230803075],
            [152.75351397027742, -31.829247701636767],
            [152.76927916875587, -31.851569142499244],
            [152.69833577560024, -31.897311208143527],
            [152.61950978320544, -31.96532442939509],
            [152.56170405544856, -32.0332873067757],
            [152.55513522275038, -32.07003310419681],
            [152.5393700242695, -32.106764141561875],
            [152.52360482579104, -32.16127691759274],
            [152.58141055354793, -32.212422516727585],
            [152.55852625874132, -32.27503794088364],
            [152.55852625874132, -32.36581679530387],
            [152.5460353800267, -32.42277093784304],
            [152.5485335557696, -32.46493602382357],
            [152.23376341215027, -32.715409831731435],
            [152.25624699383667, -32.75113461214792],
            [151.83405529326632, -32.900183191147015],
            [151.89401151109905, -32.93793038757493],
            [151.7341282635465, -33.03432225647958],
            [151.65418663977022, -33.17243960729149],
            [151.42935082289887, -33.55009950105301],
            [151.37674849563706, -33.56892323706014],
            [151.23435247828507, -34.06704241162127],
            [151.17939261194033, -34.131170929641],
            [150.97953855249852, -34.31088469963766],
            [150.9545567950692, -34.39956705836002],
            [150.84213888663237, -34.89076180615274],
            [150.82465165643185, -34.96244948246581],
            [150.8771133470358, -34.99110701071004],
            [150.764695438599, -35.2322501518771],
            [150.66476840887924, -35.217964969620844],
            [150.56983773064292, -35.268971928073064],
            [150.36248914397447, -35.6697822858379],
            [150.2250894781065, -35.89878559637177],
            [150.20760224790598, -36.16142345047721],
            [150.26256211425283, -36.225938367882264],
            [150.15264238155896, -36.399058623306836],
            [150.07270075778268, -36.67204452902146],
            [150.00025366123828, -36.834173344777575],
            [149.9602828493489, -36.99196867412601],
            [149.98776278252365, -37.09764828227746],
            [150.06020987907112, -37.203180690389026],
            [150.07519893352872, -37.26483890354225],
            [150.02773359441056, -37.29068056352837],
            [149.9977554854953, -37.35623876953821],
            [149.98888246425366, -37.50276067594229],
            [149.98943219368886, -37.50263749526765],
            [149.97505823223185, -37.51930189063764],
            [149.95073306669087, -37.50439181735188],
            [149.84348120043694, -37.46139906099201],
            [148.82956407308342, -37.0636477335621],
            [148.20649579917284, -36.819470092979806],
            [148.10587806897763, -36.81061823096147],
            [148.07491876738004, -36.79025506398786],
            [148.1788535656026, -36.63070426921758],
            [148.11657700156218, -36.57793291833769],
            [148.0041332053766, -36.37066565136002],
            [147.99548368259337, -36.2019404992237],
            [147.96005523727024, -36.06171969408473],
            [147.70748917199109, -35.97916931166803],
            [147.49298100696, -36.02395313261779],
            [147.42551472924902, -35.98476868009118],
            [147.3615082606513, -36.08828530240893],
            [147.12797114549545, -36.056125791051564],
            [147.0224469675365, -36.14697742706638],
            [146.98541638969488, -36.11934956799491],
            [146.86605297528274, -36.15846704782131],
            [146.7466895608706, -36.08999866187209],
            [146.4716347363535, -36.011675985888324],
            [146.38859931763102, -36.078814480093406],
            [146.24155743031247, -36.06483201558138],
            [146.0218595516098, -36.04245490265739],
            [145.8713578551763, -35.9962820187414],
            [145.7762131045573, -36.01307528841357],
            [145.64647026280528, -35.94728122384166],
            [145.5271068483915, -35.85900317886231],
            [145.4371518114441, -35.85619908891604],
            [145.3056790651338, -35.888440134231956],
            [145.1690166051535, -35.864611061188725],
            [144.994296244927, -35.917866150456675],
            [144.994296244927, -35.99488241815954],
            [145.0271644315049, -36.09279445870258],
            [144.88666704677922, -36.101373991234766],
            [144.7932522007169, -36.15586619392973],
            [144.6946476409862, -36.12093964740133],
            [144.40748348457248, -35.95867717514656],
            [144.34174711141816, -35.82413834305917],
            [144.23968274257254, -35.76239834657678],
            [144.11685951904553, -35.676724944757694],
            [143.89370183123106, -35.52199926843737],
            [143.71206185277634, -35.44734290379043],
            [143.5753993927961, -35.40082373210227],
            [143.5442611107764, -35.34722293200877],
            [143.5106026654273, -35.27583490814902],
            [143.3860495373437, -35.22639067174533],
            [143.30301411862285, -35.10901671567496],
            [143.27879545482807, -34.99714208343728],
            [143.32031316418932, -34.82549294904756],
            [143.2078693680038, -34.788562845450926],
            [142.91551549792018, -34.69616522329192],
            [142.76847361059998, -34.62644335221003],
            [142.71311666478556, -34.750194842522404],
            [142.67678866909426, -34.808450342134215],
            [142.54185611367234, -34.80134821548068],
            [142.44671136305334, -34.73882312036825],
            [142.4519010767233, -34.679095903869424],
            [142.4017338445788, -34.58657595306343],
            [142.33945728053772, -34.528164166739394],
            [142.30831899851637, -34.42406122855364],
            [142.32215823496966, -34.36410755685256],
            [142.2322031980259, -34.31697098312758],
            [142.1214893063954, -34.23549089319331],
            [142.0194249375499, -34.20115986891468],
            [141.8723830502313, -34.15822642125757],
            [141.713231831013, -34.13961514383837],
            [141.61289736672404, -34.22261839851633],
            [141.52294232977653, -34.25122126996579],
            [141.4312573882708, -34.19829831881202],
            [141.2651865508276, -34.14820547372924],
            [141.1389035181872, -34.1095421119491],
            [141.00916067643362, -34.07802556040453],
            [140.934774780496, -34.057963464239705],
            [140.9520563325026, -28.98106883500717],
          ],
        ],
        type: "Polygon",
      },
    },
  ],
};
