import {
  IconButton,
  LeftChevron,
  Pause,
  Play,
  RightChevron,
} from "@app/design-system";
import { SECOND } from "@kablamo/kerosene";
import React, { useState } from "react";
import styled from "styled-components";
import { useInterval } from "usehooks-ts";
import makeTestId from "../../../utils/makeTestId";
import HoursSlider, { MAX_SLIDER_VALUE, MIN_SLIDER_VALUE } from "./HoursSlider";

const StyledHoursControls = styled.div`
  display: flex;
  gap: 1.25rem;
`;

const StyledSubControls = styled.div`
  display: flex;
  gap: 0.5rem;
`;

interface HoursControlsProps {
  disabled?: boolean;
  hours: number;
  onHoursChange: (hours: number) => void;
  startDate: Date;
  "data-testid"?: string;
}

const HoursControls = ({
  disabled,
  hours,
  onHoursChange,
  startDate,
  "data-testid": dataTestId,
}: HoursControlsProps) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const increaseValueAndTime = () => {
    if (hours < MAX_SLIDER_VALUE) {
      onHoursChange(hours + 1);
    } else {
      onHoursChange(MIN_SLIDER_VALUE);
    }
  };

  const decreaseValueAndTime = () => {
    if (hours > MIN_SLIDER_VALUE) {
      onHoursChange(hours - 1);
    } else {
      onHoursChange(MAX_SLIDER_VALUE);
    }
  };

  useInterval(
    () => {
      increaseValueAndTime();
    },
    isPlaying ? SECOND : null,
  );

  if (disabled && isPlaying) {
    setIsPlaying(false);
  }

  return (
    <StyledHoursControls>
      <StyledSubControls>
        <IconButton
          icon={isPlaying ? Pause : Play}
          label={isPlaying ? "Pause" : "Play"}
          size="sm"
          variant="secondary"
          onClick={() => setIsPlaying(!isPlaying)}
          data-testid={makeTestId(dataTestId, "loop")}
        />
        <IconButton
          icon={LeftChevron}
          label="Back"
          size="sm"
          variant="secondary"
          onClick={() => decreaseValueAndTime()}
          data-testid={makeTestId(dataTestId, "decrease")}
        />
      </StyledSubControls>
      <HoursSlider
        onChange={onHoursChange}
        startDate={startDate}
        value={hours}
        data-testid={makeTestId(dataTestId, "slider")}
      />
      <IconButton
        icon={RightChevron}
        label="Forward"
        size="sm"
        variant="secondary"
        onClick={() => increaseValueAndTime()}
        data-testid={makeTestId(dataTestId, "increase")}
      />
    </StyledHoursControls>
  );
};

export default HoursControls;
