import { communicationTowersLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import CommunicationTowersLayer from "./CommunicationTowersLayer";

const CommunicationTowers = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(communicationTowersLayer.id)) {
    return null;
  }

  const state = getLayerState(communicationTowersLayer.id);

  return <CommunicationTowersLayer opacity={state?.opacity} />;
};

export default CommunicationTowers;
