import { fieldObsIconUnder1HrLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FieldObsIconUnder1HrLayer from "./FieldObsIconUnder1HrLayer";

const FieldObsIconUnder1Hr = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(fieldObsIconUnder1HrLayer.id)) {
    return null;
  }

  const state = getLayerState(fieldObsIconUnder1HrLayer.id);

  return <FieldObsIconUnder1HrLayer opacity={state?.opacity} />;
};

export default FieldObsIconUnder1Hr;
