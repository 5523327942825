import {
  Button,
  Image,
  ImageCollection,
  StaticIconWrapper,
  Twitter,
} from "@app/design-system";
import Link from "next/link";
import styled, { css } from "styled-components";
import type { SocialTwitter } from "../../../../.rest-hooks/types";
import QueryParams from "../../../config/queryParams";
import type { TweetInteractionState } from "../../map/SocialMediaClusters/interactions";
import { getSocialHashtagMatch } from "../../map/SocialMediaClusters/utils";
import SpatialPopup from "../SpatialPopup/SpatialPopup";

const StyledSocialMediaPopup = styled.div`
  display: grid;
  grid-template-areas:
    "a a a"
    "b c c"
    "d e e"
    "f f f";
  grid-template-columns: 1.5rem 1fr 1fr;
  gap: 0.25rem;
  align-items: center;
`;

const StyledValidationRow = styled.div`
  grid-area: a;
  padding: 0.5rem 0;
`;

const StyledMediaIcon = styled.div`
  grid-area: b;
`;

interface StyledHashtagsProps {
  hasMedia: boolean;
}

const hasMediaStyles = css`
  grid-area: c;
`;

const noMediaStyles = css`
  grid-column-start: b;
  grid-column-end: c;
  grid-row: c;
`;

const StyledHashtags = styled.div<StyledHashtagsProps>`
  ${(p) => (p.hasMedia ? hasMediaStyles : noMediaStyles)}
  ${(p) => p.theme.typography.variants.subtitleMd}
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TwitterIcon = styled.div`
  grid-area: d;
`;

const TwitterHandle = styled.div`
  grid-area: e;
  ${(p) => p.theme.typography.variants.bodyDefault}
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LinkButton = styled.div`
  grid-area: f;
`;

interface SocialMediaPopupProps {
  ctaLabel: string;
  ctaUrl: string;
  onClose: () => void;
  state: TweetInteractionState;
  tweet: SocialTwitter;
}

const ValidationChip = styled.div<{ validated: boolean }>`
  display: inline;
  text-align: center;
  padding: 0.25rem 0.75rem;
  border: ${(p) => `2px ${p.validated ? "solid" : "dashed"} #000`};
  border-radius: 20px;
  ${(p) => p.theme.typography.variants.bodyDefaultStrong};
`;

const SocialMediaPopup = ({
  ctaLabel,
  ctaUrl,
  onClose,
  tweet,
  state,
}: SocialMediaPopupProps) => {
  const {
    id,
    attributes: { validated, media, username, tags },
  } = tweet;
  const hashtag = getSocialHashtagMatch(tags);

  const hasMedia = !!media?.length;

  return (
    <SpatialPopup
      popupConfig={{
        id: state.id,
        offset: 16,
        type: "click",
        fullWidth: true,
        size: "lg",
      }}
      isOpen={state.isActive}
      onClose={onClose}
      lngLat={state.properties?.lngLat}
    >
      <StyledSocialMediaPopup>
        <StyledValidationRow>
          <ValidationChip validated={!!validated}>
            {validated ? "Validated" : "Unvalidated"}
          </ValidationChip>
        </StyledValidationRow>
        <StyledMediaIcon>
          {hasMedia && (
            <StaticIconWrapper
              size="md"
              icon={media?.length > 1 ? ImageCollection : Image}
            />
          )}
        </StyledMediaIcon>
        {hashtag && (
          <StyledHashtags hasMedia={hasMedia}>#${hashtag}</StyledHashtags>
        )}
        <TwitterIcon>
          <StaticIconWrapper size="md" icon={Twitter} />
        </TwitterIcon>
        <TwitterHandle>@{username}</TwitterHandle>
        <LinkButton>
          <Link
            as={ctaUrl}
            passHref
            href={{
              pathname: ctaUrl,
              query: { [QueryParams.TWEET_ID]: id },
            }}
          >
            <Button fullWidth variant="primary" size="sm">
              {ctaLabel}
            </Button>
          </Link>
        </LinkButton>
      </StyledSocialMediaPopup>
    </SpatialPopup>
  );
};

export default SocialMediaPopup;
