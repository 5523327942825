import isInteger from "lodash/isInteger";
import FieldObsSitRepPopup from "../../../popup/FieldObsSitRepPopup/FieldObsSitRepPopup";
import { FIELD_OBS_SIT_REP_MAP_FIELDS } from "../../../popup/FieldObsSitRepPopup/constants";
import useLayerInteractions from "../../MapInteractions/useLayerInteractions";
import type { QueryOptions } from "../../hooks/useMapServerQueryData/useMapServerQueryData";
import { getPropertiesFromFeature } from "./interactions";
import useFieldObsSitRepLayer from "./useFieldObsSitRepLayer";

const FIELD_OBS_WEATHER_LAYER_PATH =
  "/arcgis/rest/services/FieldObs/SitRepPresentation/FeatureServer";
const QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: "GlobalID, Incident, OBJECTID, Threats",
  },
};

interface FieldObsSitRepLayerProps {
  mapLayerId: string;
  opacity?: number;
  popupTitle: string;
  sourceLayerId: number;
}

const FieldObsSitRepLayer = ({
  mapLayerId,
  opacity,
  popupTitle,
  sourceLayerId,
}: FieldObsSitRepLayerProps) => {
  if (!isInteger(sourceLayerId)) {
    throw new Error(
      `source layer ID must be an integer, got: ${sourceLayerId}`,
    );
  }

  const sourceLayerUrl = `${FIELD_OBS_WEATHER_LAYER_PATH}/${sourceLayerId}`;

  useFieldObsSitRepLayer({
    id: mapLayerId,
    opacity,
    queryOptions: QUERY_OPTIONS,
    url: sourceLayerUrl,
  });

  const { clickedState, deactivateClickState } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: mapLayerId,
  });

  return (
    <FieldObsSitRepPopup
      fields={FIELD_OBS_SIT_REP_MAP_FIELDS}
      onClose={deactivateClickState}
      state={clickedState}
      title={popupTitle}
      url={sourceLayerUrl}
    />
  );
};

export default FieldObsSitRepLayer;
