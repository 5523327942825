import {
  continuousHaines,
  fireDangerRating,
  mixingHeight,
  relativeHumidity,
  seventyFivePercentChancePrecipitation,
  temperature,
  twentyFivePercentChancePrecipitation,
  windChangeDangerFlag,
  windChangeDangerIndex,
  windDirection,
  windDirection1500MElevation,
  windGustKmH,
  windSpeed1500MElevationKmH,
  windSpeedKmH,
} from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FireConditionsLayer from "./FireConditionsLayer.tsx";
import { FIRE_CONDITIONS_LAYERS } from "./fireConditionsUrls";

const FireConditionsLayers = () => {
  const { isLayerActive, getLayerState } = useActiveLayersContext();

  return (
    <>
      {isLayerActive(continuousHaines.id) && (
        <FireConditionsLayer
          layerName={FIRE_CONDITIONS_LAYERS.continuousHaines}
          opacity={getLayerState(continuousHaines.id)?.opacity}
        />
      )}
      {isLayerActive(windChangeDangerIndex.id) && (
        <FireConditionsLayer
          layerName={FIRE_CONDITIONS_LAYERS.windChangeDangerIndex}
          opacity={getLayerState(windChangeDangerIndex.id)?.opacity}
        />
      )}
      {isLayerActive(windChangeDangerFlag.id) && (
        <FireConditionsLayer
          layerName={FIRE_CONDITIONS_LAYERS.windChangeDangerFlag}
          opacity={getLayerState(windChangeDangerFlag.id)?.opacity}
        />
      )}
      {isLayerActive(temperature.id) && (
        <FireConditionsLayer
          layerName={FIRE_CONDITIONS_LAYERS.temperature}
          opacity={getLayerState(temperature.id)?.opacity}
        />
      )}
      {isLayerActive(mixingHeight.id) && (
        <FireConditionsLayer
          layerName={FIRE_CONDITIONS_LAYERS.mixingHeight}
          opacity={getLayerState(mixingHeight.id)?.opacity}
        />
      )}
      {isLayerActive(twentyFivePercentChancePrecipitation.id) && (
        <FireConditionsLayer
          layerName={
            FIRE_CONDITIONS_LAYERS.twentyFivePercentChancePrecipitation
          }
          opacity={
            getLayerState(twentyFivePercentChancePrecipitation.id)?.opacity
          }
        />
      )}
      {isLayerActive(seventyFivePercentChancePrecipitation.id) && (
        <FireConditionsLayer
          layerName={
            FIRE_CONDITIONS_LAYERS.seventyFivePercentChancePrecipitation
          }
          opacity={
            getLayerState(seventyFivePercentChancePrecipitation.id)?.opacity
          }
        />
      )}
      {isLayerActive(relativeHumidity.id) && (
        <FireConditionsLayer
          layerName={FIRE_CONDITIONS_LAYERS.relativeHumidity}
          opacity={getLayerState(relativeHumidity.id)?.opacity}
        />
      )}
      {isLayerActive(windDirection.id) && (
        <FireConditionsLayer
          layerName={FIRE_CONDITIONS_LAYERS.windDirection}
          opacity={getLayerState(windDirection.id)?.opacity}
        />
      )}
      {isLayerActive(windSpeedKmH.id) && (
        <FireConditionsLayer
          layerName={FIRE_CONDITIONS_LAYERS.windSpeedKmH}
          opacity={getLayerState(windSpeedKmH.id)?.opacity}
        />
      )}
      {isLayerActive(windGustKmH.id) && (
        <FireConditionsLayer
          layerName={FIRE_CONDITIONS_LAYERS.windGustKmH}
          opacity={getLayerState(windGustKmH.id)?.opacity}
        />
      )}
      {isLayerActive(windDirection1500MElevation.id) && (
        <FireConditionsLayer
          layerName={FIRE_CONDITIONS_LAYERS.windDirection1500MElevation}
          opacity={getLayerState(windDirection1500MElevation.id)?.opacity}
        />
      )}
      {isLayerActive(windSpeed1500MElevationKmH.id) && (
        <FireConditionsLayer
          layerName={FIRE_CONDITIONS_LAYERS.windSpeed1500MElevationKmH}
          opacity={getLayerState(windSpeed1500MElevationKmH.id)?.opacity}
        />
      )}
      {isLayerActive(fireDangerRating.id) && (
        <FireConditionsLayer
          layerName={FIRE_CONDITIONS_LAYERS.fireDangerRating}
          opacity={getLayerState(fireDangerRating.id)?.opacity}
        />
      )}
    </>
  );
};

export default FireConditionsLayers;
