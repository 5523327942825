import styled from "styled-components";
import type { SocialTwitterAttributesTagsItem } from "../../../../.rest-hooks/types/social-twitter.yml";
// import {
//   SocialFeedQuery,
//   SocialFeedSearchEventHandler,
// } from "../../ui/SocialFeed/socialFeedQuery";
// import TweetKeywordButton from "./TweetKeywordButton";
// import TweetTagButton from "./TweetTagButton";

// APP-663 TEMPORARILY ROLLED BACK

const StyledTweetMatches = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  ${(p) => p.theme.typography.variants.bodyDefaultStrong}
`;

const StyledTag = styled.div`
  color: ${(p) => p.theme.colors.social.link};
`;

interface TweetMatchesProps {
  // keywords: string[] | undefined;
  tags: SocialTwitterAttributesTagsItem[] | undefined;
  // onSearch: SocialFeedSearchEventHandler;
  // socialFeedQuery: SocialFeedQuery;
}

const TweetMatches = ({
  // keywords,
  // onSearch,
  // socialFeedQuery,
  tags,
}: TweetMatchesProps) => {
  // let keyword: React.ReactNode;
  // if (!keywords?.length) {
  //   return null;
  // } else {
  //   keyword = keywords.map((keyword, index) => (
  //     <TweetKeywordButton
  //       key={index}
  //       keyword={keyword}
  //       onSearch={onSearch}
  //       socialFeedQuery={socialFeedQuery}
  //     />
  //   ));
  // }

  if (!tags) {
    return null;
  }

  const matchedTweetTags = tags
    .filter((tag) => tag.matched)
    .map((tag) => (
      <StyledTag key={tag.tag}>#{tag.tag}</StyledTag>
      // <TweetTagButton
      //   key={index}
      //   tag={tag}
      //   onSearch={onSearch}
      //   socialFeedQuery={socialFeedQuery}
      // />
    ));

  if (!matchedTweetTags.length) {
    return null;
  }

  return (
    <StyledTweetMatches>
      {matchedTweetTags}
      {/* {keyword} */}
    </StyledTweetMatches>
  );
};

export default TweetMatches;
