import { useTheme } from "@app/design-system";
import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";

interface StyledMapRailDrawerListItemProps {
  gridColumn: number;
}

const StyledMapRailDrawerListItem = styled.div<StyledMapRailDrawerListItemProps>`
  display: none;

  &.enter {
    opacity: 0;
    transform: scale(0.98);
  }

  &.enter-active {
    opacity: 1;
    transform: scale(1);
    transition:
      opacity ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
      transform ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  }

  &.exit {
    opacity: 1;
    transform: scale(1);
  }

  &.exit-active {
    opacity: 0;
    transform: scale(0.98);
    transition:
      opacity ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
      transform ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  }

  &[data-in="true"],
  &.exit,
  &.exit-active {
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

  grid-row: 1;

  ${(p) =>
    p.gridColumn &&
    css`
      grid-column: ${p.gridColumn};
    `}
`;

const ONE_BASED_GRID_COLUMN_OFFSET = 1;
const ONE_MORE_THAN_LAST_PINNED_ITEM_OFFSET = 1;
const FIRST_GRID_COLUMN = 1;

interface GetGridColumnParams {
  selectedIndex: number;
  lastPinnedIndex: number;
}

/**
 * Get the grid column that this drawer component should occupy. Unfortunately
 * we cannot rely on normal document flow to handle this, because entering
 * cells need to occupy the same cell as the cell they are replacing even as
 * they exit, in order to get a clean transition effect.
 */
export const getGridColumn = ({
  lastPinnedIndex,
  selectedIndex,
}: GetGridColumnParams): number => {
  // If this is a currently selected item, it should occupy the grid cell
  // that matches its own position in the `selectedItems` array (while
  // accounting for the fact that `grid-column` is one-based and the array
  // index is zero-based)
  if (selectedIndex !== -1) {
    return selectedIndex + ONE_BASED_GRID_COLUMN_OFFSET;
  }

  // If this is item is not currently selected, assume it is exiting from one
  // column following the last pinned item
  if (lastPinnedIndex !== -1) {
    return (
      lastPinnedIndex +
      ONE_BASED_GRID_COLUMN_OFFSET +
      ONE_MORE_THAN_LAST_PINNED_ITEM_OFFSET
    );
  }

  // Otherwise, default to the first column
  return FIRST_GRID_COLUMN;
};

interface MapRailDrawerListItemProps {
  children?: React.ReactNode;
  gridColumn: number;
  in?: boolean;
}

const MapRailDrawerListItem = ({
  children,
  gridColumn,
  ...props
}: MapRailDrawerListItemProps) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      in={props.in}
      nodeRef={ref}
      timeout={{
        enter: theme.anim.duration.sm,
        exit: theme.anim.duration.sm,
      }}
    >
      <StyledMapRailDrawerListItem
        data-in={props.in}
        gridColumn={gridColumn}
        ref={ref}
      >
        {children}
      </StyledMapRailDrawerListItem>
    </CSSTransition>
  );
};

export default MapRailDrawerListItem;
