import { fieldObsSitRepUnder1HrLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FieldObsSitRepLayer from "./components/FieldObsSitRepLayer";

const FIELD_OBS_SIT_REP_UNDER_1_HR_ID = "fieldObsSitRepUnder1Hr";
const SOURCE_LAYER_ID = 0;

const FieldObsSitRepUnder1Hr = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(fieldObsSitRepUnder1HrLayer.id)) {
    return null;
  }

  const state = getLayerState(fieldObsSitRepUnder1HrLayer.id);

  return (
    <FieldObsSitRepLayer
      mapLayerId={FIELD_OBS_SIT_REP_UNDER_1_HR_ID}
      opacity={state?.opacity}
      popupTitle="UNDER 1 HR"
      sourceLayerId={SOURCE_LAYER_ID}
    />
  );
};

export default FieldObsSitRepUnder1Hr;
