import {
  BulletList,
  FieldGrid,
  Skeleton,
  StatusMessage,
  Text,
} from "@app/design-system";
import styled from "styled-components";
import type { FieldObsWeatherInteractionProperties } from "../../map/FieldObsWeather/components/interactions";
import AsyncView from "../../util/AsyncView/AsyncView";
import MapPopupView from "../SpatialPopup/MapPopupView";
import type { FieldObsWeatherField } from "./constants";

export const StyledHeader = styled.div`
  margin: auto 0;
  ${(p) => p.theme.typography.variants.eyebrowDefault};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

export const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const StyledBulletList = styled(BulletList)`
  white-space: nowrap;
  overflow: hidden;
`;

const StyledList = styled.li`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledAttachment = styled.a`
  text-decoration: underline;
  ${(p) => p.theme.typography.variants.bodyDefault}
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

export interface FieldObsWeatherPopupViewProps {
  attachments?: FieldObsWeatherAttachment[];
  fields: Record<FieldObsWeatherField, string>;
  isAttachmentsError: boolean;
  onClose: () => void;
  onDownloadAttachment: (attachment: FieldObsWeatherAttachment) => void;
  properties: FieldObsWeatherInteractionProperties | null;
  title: string;
}

export interface FieldObsWeatherAttachment {
  id: string;
  name: string;
}

const FieldObsWeatherPopupView = ({
  attachments,
  fields,
  isAttachmentsError,
  onClose,
  onDownloadAttachment,
  properties,
  title,
}: FieldObsWeatherPopupViewProps) => {
  if (!properties) {
    return null;
  }

  const formatValue = (value?: number | string) => {
    if (
      value === undefined ||
      (typeof value === "string" && value.trim() === "")
    ) {
      return "—";
    }

    return value;
  };

  return (
    <MapPopupView
      header={<StyledHeader>{title}</StyledHeader>}
      onClose={onClose}
    >
      <StyledBody>
        <Text size="subtitleMd">Weather Observation</Text>
        <FieldGrid>
          {Object.entries(fields).map(([key, value]) => {
            return (
              <FieldGrid.Item key={key} label={`${value}:`}>
                {formatValue(properties.data[key as keyof typeof fields])}
              </FieldGrid.Item>
            );
          })}
          <FieldGrid.Item label="Attachments:">
            <AsyncView
              data={attachments}
              errorState={
                <StatusMessage variant="error">
                  Unable to load attachments
                </StatusMessage>
              }
              isError={isAttachmentsError}
              loadingState={<Skeleton width={120} />}
            >
              {(data) =>
                data.length ? (
                  <StyledBulletList>
                    {data.map((attachment) => {
                      return (
                        <StyledList key={attachment.id}>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <StyledAttachment
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              onDownloadAttachment(attachment);
                            }}
                          >
                            {attachment.name}
                          </StyledAttachment>
                        </StyledList>
                      );
                    })}
                  </StyledBulletList>
                ) : (
                  "—"
                )
              }
            </AsyncView>
          </FieldGrid.Item>
        </FieldGrid>
      </StyledBody>
    </MapPopupView>
  );
};

export default FieldObsWeatherPopupView;
