import { frnswJurisdictionalBoundariesLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FRNSWJurisdictionalBoundariesLayer from "./FRNSWJurisdictionalBoundariesLayer";

const NSWFRBoundaries = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(frnswJurisdictionalBoundariesLayer.id)) {
    return null;
  }

  const state = getLayerState(frnswJurisdictionalBoundariesLayer.id);

  return <FRNSWJurisdictionalBoundariesLayer opacity={state?.opacity} />;
};

export default NSWFRBoundaries;
