import { FieldGrid, Text, useIsMinWidth } from "@app/design-system";
import getConfig from "next/config";
import styled from "styled-components";
import type { Incident } from "../../../../.rest-hooks/types";
import { getAlertLevelFromIncident } from "../../../config/alertLevel";
import { formatIncidentStatus } from "../../../config/general";
import { useHasPrivilege } from "../../../hooks/useHasPrivilege";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import getIconUrl from "../../../utils/getIconUrl";
import type { IncidentCtaProps } from "../../drawers/IncidentsDrawerCell/IncidentCard";
import AlertLevelIcon from "../../ui/AlertLevelIcon/AlertLevelIcon";
import IncidentPriorityIndicator from "../../ui/IncidentPriorityIndicator/IncidentPriorityIndicator";
import PreferredPredictionValidIndicator, {
  type PreferredPredictionAttribute,
} from "../../ui/PreferredPredictionValidIndicator/PreferredPredictionValidIndicator";
import MapPopupView from "../SpatialPopup/MapPopupView";
import FlightRisk from "./FlightRisk";

export const StyledHeader = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  align-self: center;
`;

const StyledIndicators = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledIncidentPopupContent = styled.div`
  display: grid;
  gap: 1.25rem;
`;

export const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export const StyledHeading = styled.div``;

export const StyledTitle = styled.div`
  ${(p) => p.theme.typography.variants.subtitleMd};
`;

export const StyledSubtitle = styled.div`
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  ${(p) => p.theme.typography.variants.bodyDefault};
`;

const { publicRuntimeConfig } = getConfig();
const { AVIATION_SAFETY_ENABLED } = publicRuntimeConfig;

interface IncidentPopupViewProps {
  cta: React.ComponentType<IncidentCtaProps>;
  incident: Incident;
  isCopView?: boolean;
  onClose: () => void;
  predictionAttribute: PreferredPredictionAttribute;
}

const IncidentPopupView = ({
  cta: Cta,
  incident,
  isCopView = false,
  onClose,
  predictionAttribute,
}: IncidentPopupViewProps) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  const { hasPrivilege: hasAviationPrivilege } = useHasPrivilege({
    requestedPermissions: ["aviation-safety:read"],
  });

  return (
    <MapPopupView
      hasClose
      header={
        <StyledHeader>
          {isTabletLandscapeAndAbove ? (
            <Text size="eyebrowDefault" variant="weak">
              {formatIncidentStatus(incident.attributes.status)}
            </Text>
          ) : (
            <>
              <AlertLevelIcon
                size="xl"
                alertLevel={getAlertLevelFromIncident(incident)}
              />
              <StyledIndicators>
                <IncidentPriorityIndicator
                  errorVariant="tooltip"
                  incident={incident}
                />
              </StyledIndicators>
            </>
          )}
        </StyledHeader>
      }
      onClose={onClose}
    >
      <StyledIncidentPopupContent>
        <StyledBody>
          <StyledHeading>
            <StyledTitle>
              {incident.attributes.name || <>&mdash;</>}
            </StyledTitle>
            <StyledSubtitle>
              {incident.attributes.lga || <>LGA &mdash;</>}
            </StyledSubtitle>
          </StyledHeading>
          {!isCopView && (
            <PreferredPredictionValidIndicator
              attribute={predictionAttribute}
              predictionId={incident.attributes.preferredPrediction}
            />
          )}
          <FieldGrid>
            {!isTabletLandscapeAndAbove && (
              <FieldGrid.Item label="Status:">
                {formatIncidentStatus(incident.attributes.status) || (
                  <>&mdash;</>
                )}
              </FieldGrid.Item>
            )}
            <FieldGrid.Item label="Assigned to:">
              {incident.attributes.leadAgency || <>&mdash;</>}
            </FieldGrid.Item>
            <FieldGrid.Item label="Type:">
              {incident.attributes.types.length ? (
                incident.attributes.types.join(", ")
              ) : (
                <>&mdash;</>
              )}
            </FieldGrid.Item>
            <FieldGrid.Item label="Size:">
              {incident.attributes.burntArea ? (
                `${incident.attributes.burntArea} ha`
              ) : (
                <>&mdash;</>
              )}
            </FieldGrid.Item>
            {!isCopView && AVIATION_SAFETY_ENABLED && hasAviationPrivilege && (
              <FieldGrid.Item label="Flight risk:">
                <FlightRisk incidentId={incident.id} />
              </FieldGrid.Item>
            )}
            <FieldGrid.Item label="Last updated:">
              {getFormattedDateAndTime(incident.attributes.updatedAt * 1000)}
            </FieldGrid.Item>
            <FieldGrid.Item label="ICON:">
              <div>
                <FieldGrid.Anchor
                  href={getIconUrl(incident.attributes.iconId)}
                  external
                  rel="noreferrer"
                  target="_blank"
                  title="View in ICON"
                >
                  {incident.attributes.iconId}
                </FieldGrid.Anchor>
              </div>
            </FieldGrid.Item>
          </FieldGrid>
        </StyledBody>
        {!isCopView && (
          <div>
            <Cta fullWidth incidentId={incident.id} size="sm" />
          </div>
        )}
      </StyledIncidentPopupContent>
    </MapPopupView>
  );
};

export default IncidentPopupView;
