import { Text, linkReset } from "@app/design-system";
import "linkify-plugin-hashtag";
import "linkify-plugin-mention";
import Linkify from "linkify-react";
import styled from "styled-components";
import TweetUrl from "../TweetUrl/TweetUrl";

const StyledLink = styled.a`
  ${linkReset}
  &&& {
    color: ${(p) => p.theme.colors.social.link};
    text-decoration: none;
  }

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &&&:hover {
    text-decoration: underline;
  }
`;

interface RenderProps {
  attributes: Record<string, string>;
  content: string;
}

const renderHashtag = ({ attributes, content }: RenderProps) => {
  const { href, ...props } = attributes;
  const hashtag = `https://twitter.com/hashtag/${href.substring(1)}`;
  return (
    <StyledLink
      aria-label="Twitter hashtag"
      href={hashtag}
      target="_blank"
      {...props}
    >
      {content}
    </StyledLink>
  );
};

const renderMention = ({ attributes, content }: RenderProps) => {
  const { href, ...props } = attributes;
  const mention = `https://twitter.com${href}`;
  return (
    <StyledLink
      aria-label="Twitter mention"
      href={mention}
      target="_blank"
      {...props}
    >
      {content}
    </StyledLink>
  );
};

const renderMailTo = ({ attributes, content }: RenderProps) => {
  const { href, ...props } = attributes;
  return (
    <StyledLink
      aria-label="Twitter mail to"
      href={href}
      target="_blank"
      {...props}
    >
      {content}
    </StyledLink>
  );
};

const renderLink = ({ attributes, content }: RenderProps) => {
  const { href, ...props } = attributes;
  const formattedContent = content.replaceAll(
    /(?:https?|ftp):\/\/twitter[\n\S]+/gi,
    "",
  );

  return (
    <StyledLink
      aria-label="Twitter link"
      href={href}
      target="_blank"
      {...props}
    >
      <TweetUrl url={formattedContent} length={38} />
    </StyledLink>
  );
};

const options = {
  render: {
    hashtag: renderHashtag,
    mention: renderMention,
    url: renderLink,
    email: renderMailTo,
  },
};

export type TweetPostSize = "sm" | "md";

interface TweetPostLinkProps {
  content: string | undefined;
  size?: TweetPostSize;
}

const TweetPostLink = ({ content, size = "md" }: TweetPostLinkProps) => {
  return (
    <Linkify options={options}>
      <Text size={size === "sm" ? "bodyDefault" : "bodyLg"}>{content}</Text>
    </Linkify>
  );
};

export default TweetPostLink;
