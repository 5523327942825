import { totalFuelLoadLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import TotalFuelLoadLayer from "./TotalFuelLoadLayer";

const TotalFuelLoad = () => {
  const { isLayerActive, getLayerState } = useActiveLayersContext();

  if (!isLayerActive(totalFuelLoadLayer.id)) {
    return null;
  }

  const state = getLayerState(totalFuelLoadLayer.id);

  return <TotalFuelLoadLayer opacity={state?.opacity} />;
};

export default TotalFuelLoad;
