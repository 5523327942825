import type { Resource } from "../../../../.rest-hooks/types";
import AircraftDetailModal from "../AircraftDetailModal/AircraftDetailModal";
import ApplianceDetailModal from "../ApplianceDetailModal/ApplianceDetailModal";
import HeavyPlantDetailModal from "../HeavyPlantDetailModal/HeavyPlantDetailModal";

interface ResourceDetailModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  resource: Resource;
}

const ResourceDetailModal = ({
  isOpen,
  onDismiss,
  resource,
}: ResourceDetailModalProps) => {
  switch (resource.type) {
    case "aircraft":
      return (
        <AircraftDetailModal
          aircraft={resource}
          isOpen={isOpen}
          onDismiss={onDismiss}
        />
      );
    case "appliance":
      return (
        <ApplianceDetailModal
          appliance={resource}
          isOpen={isOpen}
          onDismiss={onDismiss}
        />
      );
    case "heavy-plant":
      return (
        <HeavyPlantDetailModal
          heavyPlant={resource}
          isOpen={isOpen}
          onDismiss={onDismiss}
        />
      );
    default:
      throw new Error(
        `unsupported resource type: ${(resource as Resource).type}`,
      );
  }
};

export default ResourceDetailModal;
