import {
  BulletList,
  FieldGrid,
  Skeleton,
  StatusMessage,
  Text,
  showToast,
} from "@app/design-system";
import styled from "styled-components";
import useAttachmentsQuery from "../../../hooks/useAttachmentsQuery";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import { downloadFile } from "../../../utils/downloadFile";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import type { FieldObsSitRepInteractionProperties } from "../../map/FieldObsSitRep/components/interactions";
import AsyncView from "../../util/AsyncView/AsyncView";
import type { FieldObsSitRepFields } from "./constants";

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
`;

export const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const StyledBulletList = styled(BulletList)`
  white-space: nowrap;
  overflow: hidden;
`;

const StyledList = styled.li`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledAttachment = styled.a`
  text-decoration: underline;
  ${(p) => p.theme.typography.variants.bodyDefault}
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

interface FieldObsSitRepPopupViewProps {
  fields: Record<FieldObsSitRepFields, string>;
  properties: FieldObsSitRepInteractionProperties | null;
  url: string;
}

const FieldObsSitRepPopupView = ({
  fields,
  properties,
  url,
}: FieldObsSitRepPopupViewProps) => {
  const accessToken = useAuthAccessToken();
  const attachmentsResult = useAttachmentsQuery({
    objectId: properties?.data.OBJECTID,
    urlPath: url,
  });

  if (!properties) {
    return null;
  }

  const formatValue = (value: string) => {
    if (!value || value.trim() === "") {
      return "-";
    }

    return value;
  };

  const downloadAttachment = (id: string, name: string) => {
    downloadFile({
      accessToken,
      filename: name,
      url: `${getMapServerProxyBasepath()}${url}/${
        properties.data.OBJECTID
      }/attachments/${id}`,
    }).catch(() => {
      showToast({
        id: "download-error",
        title: "Unable to download attachment",
        variant: "error",
      });
    });
  };

  return (
    <StyledBody>
      <Text size="subtitleMd">Situation Report</Text>
      <FieldGrid>
        {Object.entries(fields).map(([key, value]) => {
          return (
            <FieldGrid.Item key={key} label={`${value}:`}>
              {formatValue(properties.data[key as keyof typeof fields])}
            </FieldGrid.Item>
          );
        })}
        <FieldGrid.Item label="Attachments:">
          <AsyncView
            {...attachmentsResult}
            errorState={
              <StatusMessage variant="error">
                Unable to load attachments
              </StatusMessage>
            }
            loadingState={<Skeleton width={120} />}
          >
            {(data) =>
              data.attachmentInfos.length ? (
                <StyledBulletList>
                  {data.attachmentInfos.map(({ id, name }) => {
                    return (
                      <StyledList key={id}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <StyledAttachment
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            downloadAttachment(id, name);
                          }}
                        >
                          {name}
                        </StyledAttachment>
                      </StyledList>
                    );
                  })}
                </StyledBulletList>
              ) : (
                "-"
              )
            }
          </AsyncView>
        </FieldGrid.Item>
      </FieldGrid>
    </StyledBody>
  );
};

export default FieldObsSitRepPopupView;
