import type { NavSubheaderVariant } from "@app/design-system";
import { keepPreviousData } from "@tanstack/react-query";
import { useGetAggregatesIncidents } from "../../../../.rest-hooks/incidents";
import { REFETCH_INTERVAL_PERIODIC } from "../../../config/refetch";
import { useIncidentsScope } from "../../incidents/IncidentsScopeProvider/IncidentsScopeProvider";
import useMapBbox from "../../map/hooks/useMapBbox/useMapBbox";
import AppNavSubheader from "../AppNavSubheader/AppNavSubheader";
import IncidentsNavBanner from "./IncidentsNavBanner";

interface IncidentsSubheaderProps {
  variant?: NavSubheaderVariant;
}

const IncidentsSubheader = ({ variant }: IncidentsSubheaderProps) => {
  const bbox = useMapBbox("cartesian", { debounced: true });
  const { params, scope } = useIncidentsScope();

  const { data: aggregateData, isPending } = useGetAggregatesIncidents(
    {
      ...bbox,
      ...params,
    },
    {
      query: {
        placeholderData: keepPreviousData,
        refetchInterval: REFETCH_INTERVAL_PERIODIC,
        select: (data) => data.data.meta,
      },
    },
  );

  return (
    <AppNavSubheader data-testid="incidents-subheader" variant={variant}>
      <IncidentsNavBanner
        aggregateData={aggregateData}
        isLoading={isPending}
        scope={scope}
      />
    </AppNavSubheader>
  );
};

export default IncidentsSubheader;
