import type { Geometry } from "geojson";
import type { LngLatLike } from "maplibre-gl";
import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isGeometryCollectionGeometry, isPointGeometry } from "../types";
import type {
  AusGeocode,
  InterstateIncidentFeature,
} from "./useInterstateIncidentsQuery";

export type InterstateIncidentInteractionProperties =
  FeatureInteractionProperties & {
    data: InterstateIncidentData;
  };

export interface InterstateIncidentData {
  category?: string;
  description: string;
  geocode: AusGeocode;
  title: string;
}

export type InterstateIncidentInteractionState =
  FeatureInteractionState<InterstateIncidentInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  InterstateIncidentInteractionProperties
> = (feature, event) => {
  const interstateIncidentFeature = feature as InterstateIncidentFeature;

  const lngLat =
    getLngLatFromGeometry(interstateIncidentFeature.geometry) || event.lngLat;

  const geocodes = JSON.parse(interstateIncidentFeature.properties.geocodes);

  return {
    data: {
      category: interstateIncidentFeature.properties.category ?? undefined,
      description: interstateIncidentFeature.properties.description,
      geocode: geocodes && geocodes["ISO3166-2"],
      title: interstateIncidentFeature.properties.title,
    },
    // This is a strange layer where a polygon and a point layer can come through
    // as different features but with the same title. To try and handle this
    // we disambiguate as much as we can by putting the geometry type in the
    // feature's ID.
    featureId:
      interstateIncidentFeature.properties.title +
      interstateIncidentFeature.geometry.type,
    lngLat,
  };
};

const getLngLatFromGeometry = (geometry: Geometry): LngLatLike | null => {
  if (isPointGeometry(geometry)) {
    return geometry.coordinates as LngLatLike;
  }

  if (isGeometryCollectionGeometry(geometry)) {
    for (const g of geometry.geometries) {
      const lngLat = getLngLatFromGeometry(g);
      if (lngLat) {
        return lngLat;
      }
    }
  }

  return null;
};
