import { useTheme } from "@app/design-system";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import MapRailMenuButton from "./MapRailMenuButton";
import { useMapRailContext } from "./MapRailProvider";

const StyledMapRailMenu = styled.div`
  display: flex;
  gap: 1rem;
`;

const MapRailMenu = () => {
  const theme = useTheme();
  const { items, selectedItems, remove, replace, statuses } =
    useMapRailContext();

  return (
    <TransitionGroup component={StyledMapRailMenu}>
      {[...items].map(([id, item]) => {
        const isSelected = selectedItems.has(id);

        const selectedItem = selectedItems.get(id);
        const status = statuses.get(id);

        return (
          <CSSTransition
            key={id}
            timeout={{
              enter: theme.anim.duration.sm,
              exit: theme.anim.duration.sm,
            }}
          >
            <MapRailMenuButton
              icon={isSelected ? item.iconOn : item.icon}
              isPinned={selectedItem?.isPinned}
              isSelected={isSelected}
              label={item.label}
              onClick={() => (isSelected ? remove({ id }) : replace({ id }))}
              status={status}
            />
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};

export default MapRailMenu;
