import getConfig from "next/config";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import useMapContext from "../Map/useMapContext";
import { loadImage } from "../utils/loadImage";

const { publicRuntimeConfig } = getConfig();

const LIVE_TRAFFIC_ICONS = {
  accident: "/icons/live-traffic-accident.png",
  congestion: "/icons/live-traffic-congestion.png",
  construction: "/icons/live-traffic-construction.png",
  disabled_vehicle: "/icons/live-traffic-disabled-vehicle.png",
  lane_restriction: "/icons/live-traffic-lane-restriction.png",
  mass_transit: "/icons/live-traffic-mass-transit.png",
  misc: "/icons/live-traffic-unknown.png",
  planned_event: "/icons/live-traffic-planned-event.png",
  road_closure: "/icons/live-traffic-road-closure.png",
  road_hazard: "/icons/live-traffic-road-hazard.png",
  other: "/icons/live-traffic-other-news.png",
  other_news: "/icons/live-traffic-other-news.png",
  weather: "/icons/live-traffic-weather-event.png",
};

const HERE_TRAFFIC_URL =
  "https://1.traffic.maps.ls.hereapi.com/maptile/2.1/flowtile/newest/terrain.day/{z}/{x}/{y}/512/png?apiKey=";

const HERE_INCIDENTS_URL =
  "https://traffic.vector.hereapi.com/v2/traffictiles/incident/mc/{z}/{x}/{y}/omv?apiKey=";

const HERE_TRAFFIC_ID = "traffic-flow";

const HERE_INCIDENTS_ID = "traffic_incidents";
const HERE_INCIDENTS_ICON_ID = "incident_icons";
const HERE_INCIDENTS_IMAGE_ID = "incidents_image";

interface LiveTrafficLayerProps {
  opacity?: number;
}

const LiveTrafficLayer = ({ opacity = 1 }: LiveTrafficLayerProps) => {
  const map = useMapContext();

  const { NEXT_HERE_API_KEY } = publicRuntimeConfig;

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(HERE_TRAFFIC_ID, {
      type: "raster",
      tiles: [`${HERE_TRAFFIC_URL}${NEXT_HERE_API_KEY}&ppi=320`],
      tileSize: 512,
    });
    map.addLayer(
      {
        id: HERE_TRAFFIC_ID,
        type: "raster",
        source: HERE_TRAFFIC_ID,
        minzoom: 0,
        maxzoom: 22,
      },
      MapLevel.FS_BACKGROUND,
    );

    map.addSource(HERE_INCIDENTS_ID, {
      type: "vector",
      tiles: [`${HERE_INCIDENTS_URL}${NEXT_HERE_API_KEY}&features=premium`],
      maxzoom: 16,
    });

    map.addLayer(
      {
        id: HERE_INCIDENTS_ID,
        type: "line",
        source: HERE_INCIDENTS_ID,
        "source-layer": HERE_INCIDENTS_ID,
        layout: { "line-join": "round", "line-cap": "round" },
        paint: {
          "line-color": "#f40000",
          "line-dasharray": [5, 3],
          "line-width": 5,
        },
      },
      MapLevel.FS_BACKGROUND,
    );

    Promise.all(
      Object.entries(LIVE_TRAFFIC_ICONS).map(([imageId, src]) =>
        loadImage({ imageId, map, src, signal: controller.signal }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: HERE_INCIDENTS_ICON_ID,
          type: "symbol",
          source: HERE_INCIDENTS_ID,
          "source-layer": HERE_INCIDENTS_ICON_ID,
          layout: {
            "icon-image": ["get", "kind"],
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
          minzoom: 8,
        },
        MapLevel.FS_SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      if (map.getLayer(HERE_TRAFFIC_ID)) {
        map.removeLayer(HERE_TRAFFIC_ID);
      }
      if (map.getSource(HERE_TRAFFIC_ID)) {
        map.removeSource(HERE_TRAFFIC_ID);
      }

      if (map.getLayer(HERE_INCIDENTS_ID)) {
        map.removeLayer(HERE_INCIDENTS_ID);
      }
      if (map.getLayer(HERE_INCIDENTS_ICON_ID)) {
        map.removeLayer(HERE_INCIDENTS_ICON_ID);
      }
      if (map.hasImage(HERE_INCIDENTS_IMAGE_ID)) {
        map.removeImage(HERE_INCIDENTS_IMAGE_ID);
      }
      if (map.getSource(HERE_INCIDENTS_ID)) {
        map.removeSource(HERE_INCIDENTS_ID);
      }

      Object.keys(LIVE_TRAFFIC_ICONS).forEach((id) => {
        if (map.hasImage(id)) {
          map.removeImage(id);
        }
      });
    };
  }, [NEXT_HERE_API_KEY, map]);

  useEffect(() => {
    map.setPaintProperty(HERE_TRAFFIC_ID, "raster-opacity", opacity);
    map.setPaintProperty(HERE_INCIDENTS_ICON_ID, "icon-opacity", opacity);
    map.setPaintProperty(HERE_INCIDENTS_ID, "line-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default LiveTrafficLayer;
