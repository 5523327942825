import { Button, FieldGrid, Text } from "@app/design-system";
import Link from "next/link";
import styled from "styled-components";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import type { AircraftPositionsInteractionProperties } from "../../map/AircraftPositions/interactions";
import AircraftAssignmentIncident from "../../resources/AircraftDetailModal/AircraftAssignmentIncident";
import { formatReportAge } from "./AircraftPositionsPreview";

const StyledActions = styled.div`
  display: grid;
  gap: 0.5rem;
  padding-top: 0.5rem;
`;

export interface AircraftPositionPopupSummaryProps {
  isCopView: boolean;
  properties: AircraftPositionsInteractionProperties;
}

const AircraftPositionPopupSummary = ({
  isCopView,
  properties,
}: AircraftPositionPopupSummaryProps) => {
  return (
    <>
      <Text size="subtitleMd">
        {properties.callSign || <FallbackElement />}
      </Text>
      <FieldGrid>
        <FieldGrid.Item label="Report age">
          {formatReportAge(properties.reportAge)}
        </FieldGrid.Item>
        <FieldGrid.Item label="Speed">
          {properties.speed} Km/hour
        </FieldGrid.Item>
        <FieldGrid.Item label="Assigned to:">
          {properties.assignment ? (
            <AircraftAssignmentIncident assignment={properties.assignment} />
          ) : (
            <FallbackElement />
          )}
        </FieldGrid.Item>
      </FieldGrid>
      {!isCopView && (
        <StyledActions>
          <Link
            as={`/resources/${properties.id}`}
            passHref
            href={{
              pathname: `/resources/${properties.id}`,
            }}
          >
            <Button fullWidth size="sm">
              View resource details
            </Button>
          </Link>
          <Link
            as="/resources"
            passHref
            href={{
              pathname: "/resources",
            }}
          >
            <Button fullWidth size="sm" variant="secondary">
              View all resources
            </Button>
          </Link>
        </StyledActions>
      )}
    </>
  );
};

export default AircraftPositionPopupSummary;
