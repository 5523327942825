import {
  IconButton,
  type IconButtonSize,
  Visibility,
} from "@app/design-system";
import getExternalTweetUrl from "../../../utils/getExternalTweetUrl/getExternalTweetUrl";

interface ViewPostProps {
  externalId: string;
  size?: IconButtonSize;
  username: string;
}

const ViewExternalPost = ({
  externalId,
  size = "md",
  username,
}: ViewPostProps) => {
  const externalSocialUrl = getExternalTweetUrl({ externalId, username });

  return (
    <a href={externalSocialUrl} rel="noreferrer" target="_blank">
      <IconButton
        icon={Visibility}
        label="View post"
        size={size}
        tabIndex={-1}
        variant="ghost"
      />
    </a>
  );
};

export default ViewExternalPost;
