import { useEffect, useState } from "react";
import useUnsafeMapContext from "../../Map/useUnsafeMapContext";

const getMapZoomLevel = (
  map: mapboxgl.Map | maplibregl.Map | null,
): number | null => {
  return map?.getZoom() ?? null;
};

const useMapZoomLevel = () => {
  const { map } = useUnsafeMapContext();
  const [zoomLevel, setZoomLevel] = useState(() => getMapZoomLevel(map));

  useEffect(() => {
    if (!map) return;

    function updateZoomLevel() {
      setZoomLevel(getMapZoomLevel(map));
    }

    updateZoomLevel();

    map.on("zoomend", updateZoomLevel);

    return () => {
      map.off("zoomend", updateZoomLevel);
    };
  }, [map]);

  return zoomLevel;
};

export default useMapZoomLevel;
