import { Field } from "@app/design-system";
import styled from "styled-components";
import type { Legend } from "../../../config/layers/layers";
import BurnProbabilityLegend from "../BurnProbabilityLegend/BurnProbabilityLegend";
import EmberDensityLegend from "../EmberDensityLegend/EmberDensityLegend";
import FlameHeightLegend from "../FlameHeightLegend/FlameHeightLegend";
import { ManualPredictionLegend } from "../Legends";

const StyledPredictionLegend = styled.div`
  display: grid;
  gap: 1rem;
`;

const PredictionLegend = () => {
  return (
    <StyledPredictionLegend>
      <BurnProbabilityLegend />
      <FlameHeightLegend />
      <EmberDensityLegend />
      <Field label="Manual prediction">
        <ManualPredictionLegend />
      </Field>
    </StyledPredictionLegend>
  );
};

export const predictionLegend: Legend = {
  component: PredictionLegend,
  id: "prediction",
  label: "Predictions",
};

export default PredictionLegend;
