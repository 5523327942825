import FourToSevenYears from "./FourToSevenYears";
import OneToThreeYears from "./OneToThreeYears";
import SevenPlusYears from "./SevenPlusYears";

export const WILDFIRE_COLOR_SCALE = [
  "200102",
  "#006000",
  "200203",
  "#277600",
  "200304",
  "#4d8c00",
  "200405",
  "#6ea300",
  "200506",
  "#97bd00",
  "200607",
  "#bdd600",
  "200708",
  "#e8f100",
  "200809",
  "#fff300",
  "200910",
  "#ffd100",
  "201011",
  "#ffb400",
  "201112",
  "#ff9000",
  "201213",
  "#ff7200",
  "201314",
  "#ff5100",
  "201415",
  "#ff3700",
  "201516",
  "#ff1f00",
  "201617",
  "#a80000",
  "201718",
  "#742400",
  "201819",
  "#740000",
  "black",
] as const;

const WildfireHistory = () => {
  return (
    <>
      <OneToThreeYears />
      <FourToSevenYears />
      <SevenPlusYears />
    </>
  );
};

export default WildfireHistory;
