import { useEffect } from "react";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import useMapContext from "../Map/useMapContext";

const MIN_ZOOM_FOR_LG_PITCH = 14;
const MAX_PITCH_LG = 60;
const MAX_PITCH_SM = 45;
const ANIMATION_DURATION = 200;

const PitchLimiter = () => {
  const map = useMapContext();
  const { is3DEnabled } = useVisualiser();

  useEffect(() => {
    const handler = () => {
      const pitch = map.getPitch();

      if (!is3DEnabled && pitch !== 0) {
        map.setMaxPitch(0);
        return;
      }

      const zoom = map.getZoom();

      if (zoom > MIN_ZOOM_FOR_LG_PITCH) {
        // only update max pitch if it's not already at the LG value
        if (map.getMaxPitch() !== MAX_PITCH_LG) {
          map.setMaxPitch(MAX_PITCH_LG);
        }
        return;
      }

      if (pitch > MAX_PITCH_SM) {
        // Using flyTo to make a smooth 200ms animation to the new pitch
        map.flyTo({
          center: map.getCenter(),
          pitch: MAX_PITCH_SM,
          duration: ANIMATION_DURATION,
        });
        // let animation finish before setting max pitch
        setTimeout(() => {
          map.setMaxPitch(MAX_PITCH_SM);
        }, ANIMATION_DURATION);
      } else if (map.getMaxPitch() !== MAX_PITCH_SM) {
        // only update max pitch if it's not already at the SM value
        map.setMaxPitch(MAX_PITCH_SM);
      }
    };
    handler();
    map.on("moveend", handler);
    return () => {
      map.off("moveend", handler);
    };
  }, [map, is3DEnabled]);

  return null;
};

export default PitchLimiter;
