import addDays from "date-fns/addDays";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import { redMapLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";

export const today = new Date();
export const tomorrow = addDays(today, 1);

export const useFromDay = () => {
  const { getLayerStateFromConfig } = useActiveLayersContext();
  const state = getLayerStateFromConfig(redMapLayer);

  const isRedMapTomorrowLayerActive = !!state?.redMapTomorrow?.isActive;

  const isRedMapTodayLayerActive = !!state?.redMapToday?.isActive;

  const startOfToday = Math.floor(startOfDay(today).getTime() / 1000);
  const startOfTomorrow = Math.floor(startOfDay(tomorrow).getTime() / 1000);

  let fromDay: number;

  if (isRedMapTomorrowLayerActive && !isRedMapTodayLayerActive) {
    fromDay = startOfTomorrow;
  } else {
    fromDay = startOfToday;
  }

  return fromDay;
};

export const useToDay = () => {
  const { getLayerStateFromConfig } = useActiveLayersContext();

  const state = getLayerStateFromConfig(redMapLayer);

  const isRedMapTomorrowLayerActive = state?.redMapTomorrow?.isActive;

  const endOfToday = Math.floor(endOfDay(today).getTime() / 1000);
  const endOfTomorrow = Math.floor(endOfDay(tomorrow).getTime() / 1000);

  let toDay: number;

  if (isRedMapTomorrowLayerActive) {
    toDay = endOfTomorrow;
  } else {
    toDay = endOfToday;
  }

  return toDay;
};
