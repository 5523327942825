import { buttonReset, focusStyles, useTheme } from "@app/design-system";
import sortBy from "lodash/sortBy";
import { useCollapse } from "react-collapsed";
import styled, { css } from "styled-components";
import LinkedIncident from "./LinkedIncident";

export type LinkedIncidentsSize = "sm" | "md";

export const StyledLinkedIncidents = styled.div``;

interface StyledPrimaryIncidentProps {
  hasCollapsedIncidents: boolean;
}

const StyledPrimaryIncident = styled.div<StyledPrimaryIncidentProps>`
  padding-bottom: ${(p) => (p.hasCollapsedIncidents ? 4 : 0)}px;
`;

interface StyledOtherIncidentsProps {
  hasPrimaryIncident: boolean;
}

const StyledOtherIncidents = styled.div<StyledOtherIncidentsProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: ${(p) => (p.hasPrimaryIncident ? 4 : 8)}px;
`;

const linkedIncidentsTriggerSizeStyles: Record<
  LinkedIncidentsSize,
  ReturnType<typeof css>
> = {
  sm: css`
    ${(p) => p.theme.typography.variants.footnote}
  `,
  md: css`
    ${(p) => p.theme.typography.variants.bodyDefault}
  `,
};

interface StyledTriggerProps {
  hasPrimaryIncident: boolean;
  size: LinkedIncidentsSize;
}

const StyledTrigger = styled.button<StyledTriggerProps>`
  ${buttonReset}
  display: block;
  margin-top: ${(p) => (p.hasPrimaryIncident ? 4 : 0)}px;
  align-self: flex-start;
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  text-decoration: underline;
  text-underline-offset: 3px;
  border-radius: ${(p) => p.theme.borderRadiuses.sm}px;

  ${(p) => linkedIncidentsTriggerSizeStyles[p.size]}

  &:hover {
    color: ${(p) => p.theme.colors.neutrals.text};
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

type LinkedIncidentsProps = {
  linkedIncidents: string[];
  primaryIncidentId?: string;
  showPrimaryIncident?: boolean;
  size?: LinkedIncidentsSize;
  tweetId: string | undefined;
};

const LinkedIncidents = ({
  linkedIncidents,
  primaryIncidentId: _primaryIncidentId,
  showPrimaryIncident = false,
  size = "md",
  tweetId,
}: LinkedIncidentsProps) => {
  const theme = useTheme();

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: theme.anim.duration.sm,
    easing: theme.anim.curve,
  });

  const primaryIncidentId =
    linkedIncidents.find((incidentId) => incidentId === _primaryIncidentId) ??
    linkedIncidents[0];

  const collapsedIncidentIds = linkedIncidents.filter(
    (id) => !showPrimaryIncident || id !== primaryIncidentId,
  );

  const collapsedIncidentsCount = collapsedIncidentIds.length;

  const showMoreLabel = showPrimaryIncident
    ? `View more linked incidents (${collapsedIncidentsCount})`
    : `View linked incidents (${collapsedIncidentsCount})`;

  const hasCollapsedIncidents = !!collapsedIncidentIds.length;

  let primaryIncident: React.ReactNode;
  let collapsedIncidents: React.ReactNode;

  if (showPrimaryIncident) {
    primaryIncident = (
      <StyledPrimaryIncident hasCollapsedIncidents={hasCollapsedIncidents}>
        <LinkedIncident incidentId={primaryIncidentId} tweetId={tweetId} />
      </StyledPrimaryIncident>
    );
  }

  if (collapsedIncidentsCount) {
    // Bring the primary incident, if not already filtered out, to the top of
    // the list
    const sortedCollapsedIncidentIds = sortBy(
      collapsedIncidentIds,
      (incidentId) => incidentId !== primaryIncidentId,
    );
    collapsedIncidents = (
      <>
        <div {...getCollapseProps()}>
          <StyledOtherIncidents hasPrimaryIncident={showPrimaryIncident}>
            {sortedCollapsedIncidentIds.map((collapsedIncidentId) => (
              <LinkedIncident
                key={collapsedIncidentId}
                incidentId={collapsedIncidentId}
                tweetId={tweetId}
              />
            ))}
          </StyledOtherIncidents>
        </div>
        <StyledTrigger
          {...getToggleProps()}
          hasPrimaryIncident={showPrimaryIncident}
          size={size}
        >
          {isExpanded ? "Hide linked incidents" : showMoreLabel}
        </StyledTrigger>
      </>
    );
  }

  return (
    <StyledLinkedIncidents>
      {primaryIncident}
      {collapsedIncidents}
    </StyledLinkedIncidents>
  );
};

export default LinkedIncidents;
