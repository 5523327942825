import centerOfMass from "@turf/center-of-mass";
import type { Feature, Geometry } from "geojson";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";

const SOVI_ID = "soviAggregation";
const SOVI_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/SocialVulnerability/MapServer`;
const SOVI_QUERY_URL = `${SOVI_URL}/0/query`;
const QUERY_OPTIONS: QueryOptions = {
  minZoom: 8,
  queryParams: {
    outFields: "MB_Categor",
  },
};
const SOVI_RASTER_QUERY_STRING = buildMapboxBBoxQuery({
  dpi: "96",
  format: "png32",
  layers: "show:0",
  transparent: "true",
  bboxSR: "3857",
  imageSR: "3857",
  size: "512,512",
  f: "image",
});
const SOVI_RASTER_URL = `${SOVI_URL}/export?${SOVI_RASTER_QUERY_STRING}`;
const LAND_CATEGORY_MIN_ZOOM = 9;

const transformPolygonsToPoints = (features: Feature<Geometry>[]) => {
  return features.map((feature) => {
    const centerPoint = centerOfMass(feature);
    return {
      ...centerPoint,
      properties: feature.properties,
    };
  });
};

interface SocialVulnerabilityAggregationLayerProps {
  opacity?: number;
}

const SocialVulnerabilityAggregationLayer = ({
  opacity = 1,
}: SocialVulnerabilityAggregationLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(SOVI_QUERY_URL, QUERY_OPTIONS);

  useEffect(() => {
    map.addSource(SOVI_ID, {
      type: "raster",
      tileSize: 512,
      tiles: [SOVI_RASTER_URL],
    });

    map.addSource(`${SOVI_ID}-geojson`, {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
    });

    map.addLayer(
      {
        id: SOVI_ID,
        type: "raster",
        source: SOVI_ID,
      },
      MapLevel.BACKGROUND,
    );

    map.addLayer(
      {
        id: `${SOVI_ID}-text`,
        type: "symbol",
        source: `${SOVI_ID}-geojson`,
        layout: {
          "text-field": ["get", "MB_Categor"],
          "symbol-placement": "point",
        },
        minzoom: LAND_CATEGORY_MIN_ZOOM,
      },
      MapLevel.BACKGROUND,
    );

    return () => {
      if (map.getLayer(SOVI_ID)) {
        map.removeLayer(SOVI_ID);
      }
      if (map.getSource(SOVI_ID)) {
        map.removeSource(SOVI_ID);
      }
      if (map.getLayer(`${SOVI_ID}-text`)) {
        map.removeLayer(`${SOVI_ID}-text`);
      }
      if (map.getSource(`${SOVI_ID}-geojson`)) {
        map.removeSource(`${SOVI_ID}-geojson`);
      }
    };
  }, [map]);

  useEffect(() => {
    if (data) {
      const source = map.getSource(`${SOVI_ID}-geojson`);
      if (isGeoJsonSource(source)) {
        const features = transformPolygonsToPoints(data.features);
        source.setData({
          type: "FeatureCollection",
          features,
        });
      }
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(SOVI_ID, "raster-opacity", opacity);
    map.setPaintProperty(`${SOVI_ID}-text`, "text-opacity", opacity);
  }, [map, opacity]);

  return null;
};
export default SocialVulnerabilityAggregationLayer;
