import React from "react";
import { TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import MapRailDrawerListItem, { getGridColumn } from "./MapRailDrawerListItem";
import { useMapRailContext } from "./MapRailProvider";
import { modalComponents, type MapRailModalComponentProps } from "./useMapRail";

const StyledMapRailDrawerList = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, 20.5rem);
  grid-auto-flow: column;
  flex: 1;
  gap: 0.5rem;
  min-height: 0;
`;

const MapRailDrawerList = () => {
  const { items, removeModal, selectedItems, selectedModalItems } =
    useMapRailContext();

  return (
    <StyledMapRailDrawerList>
      {[...items].map(([id, item]) => {
        const { component: Component, props } = item;

        const isSelected = selectedItems.has(item.id);

        const selectedIndex = [...selectedItems].findIndex(
          ([selectedId]) => selectedId === item.id,
        );

        const lastPinnedIndex = [...selectedItems].findLastIndex(
          ([, selectedItem]) => selectedItem.isPinned,
        );

        return (
          <MapRailDrawerListItem
            gridColumn={getGridColumn({ lastPinnedIndex, selectedIndex })}
            in={isSelected}
            key={id}
          >
            <Component {...props} />
          </MapRailDrawerListItem>
        );
      })}
      <TransitionGroup component={null}>
        {[...selectedModalItems].map(([id, selectedItem], index) => {
          // Type casting is necessary here as Typescript can't know that the
          // `selectedItem.props` object is definitely going to match the shape
          // of the props accepted by the component configured for the given
          // `selectedItem.id`. As far as Typescript knows, this is just a
          // component that accepts at least an `onClose` prop which we are
          // going to provide.
          const Component = modalComponents[
            selectedItem.id
          ] as React.ComponentType<MapRailModalComponentProps>;

          return (
            <MapRailDrawerListItem
              key={id}
              gridColumn={selectedItems.size + index + 1}
            >
              <Component
                // Spread the `props` provided by the consumer when they
                // originally pushed this modal up. At that point, Typescript
                // would have forced the props shape to match the shape expected
                // by the modal component corresponding to the `id` the consumer
                // selected.
                {...selectedItem.props}
                onClose={() => removeModal({ id })}
              />
            </MapRailDrawerListItem>
          );
        })}
      </TransitionGroup>
    </StyledMapRailDrawerList>
  );
};

export default MapRailDrawerList;
