import { AlertModal } from "@app/design-system";
import type { ResourceAircraft } from "../../../../.rest-hooks/types/resources.yml/resourceAircraft";
import CopyLinkToResourceButton from "../CopyLinkToResourceButton/CopyLinkToResourceButton";
import AircraftDetail from "./AircraftDetail";

interface AircraftDetailModalProps {
  aircraft: ResourceAircraft;
  isOpen: boolean;
  onDismiss: () => void;
}

const AircraftDetailModal = ({
  aircraft,
  isOpen,
  onDismiss,
}: AircraftDetailModalProps) => {
  return (
    <AlertModal
      actions={
        <CopyLinkToResourceButton
          resourceId={aircraft.id}
          size="md"
          variant="ghost"
        />
      }
      dismissLabel="Close"
      isOpen={isOpen}
      onDismiss={onDismiss}
      size="lg"
      title={aircraft.attributes.callSign || "Unknown aircraft"}
    >
      <AircraftDetail aircraft={aircraft} />
    </AlertModal>
  );
};

export default AircraftDetailModal;
