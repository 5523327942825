import {
  Skeleton,
  Info,
  StatusIcon,
  Tooltip,
  showToast,
  theme,
} from "@app/design-system";
import { useEffect } from "react";
import styled from "styled-components";
import { useGetIncidentsIdRiskAssessments } from "../../../../.rest-hooks/risk-assessments";
import AsyncView from "../../util/AsyncView/AsyncView";
import { getMaxRiskRating } from "./utils";

const StyledFlightRisk = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledTextError = styled.span`
  color: ${(p) => p.theme.colors.error.text};
  ${(p) => p.theme.typography.variants.bodyDefault};
`;

interface FlightRiskProps {
  incidentId: string;
}

const FlightRisk = ({ incidentId }: FlightRiskProps) => {
  const { data, error, isError } = useGetIncidentsIdRiskAssessments(incidentId);

  useEffect(() => {
    if (error) {
      showToast({
        title: "Unable to load Fireground flight risk",
        variant: "error",
      });
    }
  }, [error]);

  const riskRating = data?.data.data.assessments?.length
    ? getMaxRiskRating(data?.data.data.assessments)
    : "";

  return (
    <StyledFlightRisk>
      <AsyncView
        data={riskRating}
        isError={isError}
        errorState={
          <>
            <StatusIcon size="xs" status="error" />
            <StyledTextError> Failed</StyledTextError>
          </>
        }
        loadingState={<Skeleton data-testid="FlightRisk-loading" flex />}
      >
        {(risk) => (
          <>
            {risk}{" "}
            <Tooltip message="Aircraft risk ratings are based on forecasted incident fireground conditions.">
              <div>
                <Info
                  width="16"
                  color={theme.colors.neutrals.textWeak}
                  data-testid="risk-rating-tooltip"
                />
              </div>
            </Tooltip>
          </>
        )}
      </AsyncView>
    </StyledFlightRisk>
  );
};

export default FlightRisk;
