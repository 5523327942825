import { airportsLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import AirportsLayer from "./AirportsLayer";

const Airports = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(airportsLayer.id)) {
    return null;
  }

  const state = getLayerState(airportsLayer.id);

  return <AirportsLayer opacity={state?.opacity} />;
};

export default Airports;
