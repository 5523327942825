import { useMemo } from "react";
import styled from "styled-components";
import formatDate, {
  getFormattedTime,
} from "../../../utils/formatDate/formatDate";

const StyledDatePill = styled.div`
  display: flex;
  gap: 0.5rem;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
  color: ${(p) => p.theme.colors.neutrals.textInverse};
  padding: 0.375rem 1rem;
  min-width: fit-content;
  font-variant-numeric: tabular-nums;
  ${(p) => p.theme.typography.variants.eyebrowLg}
`;

interface DateTimePillProps {
  date: Date;
  "data-testid"?: string;
}

const DateTimePill = ({
  date,
  "data-testid": dataTestId,
}: DateTimePillProps) => {
  const formattedDate = useMemo(() => {
    return formatDate(date, "dateOnly");
  }, [date]);

  const formattedTime = useMemo(() => {
    return getFormattedTime(date);
  }, [date]);

  return (
    <StyledDatePill data-testid={dataTestId}>
      <div>{formattedTime}</div>
      <div>&bull;</div>
      <div>{formattedDate}</div>
    </StyledDatePill>
  );
};

export default DateTimePill;
