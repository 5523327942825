import { buttonReset, focusStyles, weakStyles } from "@app/design-system";
import styled from "styled-components";
import { useGetIncidentsId } from "../../../../.rest-hooks/incidents";
import { getAlertLevelFromIncident } from "../../../config/alertLevel";
import QueryParams from "../../../config/queryParams";
import AlertLevelIcon from "../../ui/AlertLevelIcon/AlertLevelIcon";
import TaskLink from "../../util/TaskLink/TaskLink";
import SkeletonLinkedIncident from "./SkeletonLinkedIncident";

export const StyledLinkedIncident = styled.a`
  ${buttonReset}
  display: grid;
  align-items: start;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;

  &:hover:not(:disabled) {
    ${weakStyles.background.hover}
  }

  &:active:not(:disabled) {
    ${weakStyles.background.active}
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

export const StyledDetail = styled.div``;

export const StyledTitle = styled.div`
  ${(p) => p.theme.typography.variants.subtitleSm}
`;

export const StyledSubtitle = styled.div`
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  ${(p) => p.theme.typography.variants.footnote}
`;

interface LinkedIncidentProps {
  incidentId: string;
  tweetId: string | undefined;
}

const LinkedIncident = ({ incidentId, tweetId }: LinkedIncidentProps) => {
  const { data } = useGetIncidentsId(incidentId);
  const incidentData = data?.data.data;

  if (!incidentData || !tweetId) {
    return <SkeletonLinkedIncident />;
  }

  return (
    <TaskLink
      as={`/incidents/${incidentId}/social`}
      href={{
        pathname: "/incidents/[id]/social",
        query: {
          [QueryParams.INCIDENT_ID]: incidentId,
          [QueryParams.TWEET_ID]: tweetId,
        },
      }}
      legacyBehavior
    >
      <StyledLinkedIncident>
        <AlertLevelIcon
          size="sm"
          alertLevel={getAlertLevelFromIncident(incidentData)}
        />
        <StyledDetail>
          <StyledTitle>{incidentData.attributes.name}</StyledTitle>
          <StyledSubtitle>{incidentData.attributes.lga}</StyledSubtitle>
        </StyledDetail>
      </StyledLinkedIncident>
    </TaskLink>
  );
};

export default LinkedIncident;
