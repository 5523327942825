import {
  Combobox,
  Search,
  type ComboboxOptionFilter,
  type ComboboxProps,
} from "@app/design-system";
import { forwardRef, type ForwardRefRenderFunction } from "react";
import { useGetIncidents } from "../../../../.rest-hooks/incidents";
import type { Incident } from "../../../../.rest-hooks/types";
import { ACTIVE_STATUSES } from "../../../config/incidentStatus";
import { MAX_INCIDENTS } from "../../incidents/StateView/StateView";
import IncidentDropdownOption from "./IncidentDropdownOption";
import { getIncidentMap, getIncidentOptions } from "./utils";

export const makeIncidentOptionFilter =
  (incidents: Map<string, Incident>): ComboboxOptionFilter =>
  (options, inputValue) => {
    return options.filter((option) => {
      const incident = incidents.get(option.value);

      if (!incident) return false;

      const {
        attributes: { name, lga },
      } = incident;

      return (
        name.toLowerCase().includes(inputValue) ||
        lga.toLowerCase().includes(inputValue)
      );
    });
  };

type IncidentComboboxProps = Omit<ComboboxProps, "options">;

const IncidentCombobox: ForwardRefRenderFunction<
  HTMLInputElement,
  IncidentComboboxProps
> = (props: IncidentComboboxProps, ref) => {
  const { data, isPending } = useGetIncidents({
    page: 1,
    perPage: MAX_INCIDENTS,
    statuses: ACTIVE_STATUSES.join(","),
  });

  const incidentData = data?.data.data;

  const incidentsById = getIncidentMap(incidentData);
  const options = getIncidentOptions(incidentData);

  const filterOptions = makeIncidentOptionFilter(incidentsById);

  return (
    <Combobox
      {...props}
      filterOptions={filterOptions}
      icon={Search}
      isLoading={isPending}
      options={options}
      placeholder="Search name or LGA..."
      ref={ref}
      renderOption={({
        disabled,
        focused,
        hasSelection,
        selected,
        itemProps,
        option,
      }) => {
        const incident = incidentsById.get(option.value);
        return (
          <IncidentDropdownOption
            {...itemProps}
            alertLevel={incident?.attributes.alertLevel}
            disabled={disabled}
            focused={focused}
            hasSelection={hasSelection}
            lga={incident?.attributes.lga}
            name={option.label}
            selected={selected}
          />
        );
      }}
    />
  );
};

export default forwardRef(IncidentCombobox);
