import { SocialVerified } from "@app/design-system";
import styled from "styled-components";

export const StyledTweetVerifiedIcon = styled(SocialVerified)`
  height: ${(p) => p.theme.typography.variants.subtitleMd["line-height"]};
  width: ${(p) => p.theme.typography.variants.subtitleMd["line-height"]};
`;

interface TweetVerifiedIconProps {
  verified: boolean | undefined;
}

const TweetVerifiedIcon = ({ verified }: TweetVerifiedIconProps) => {
  if (!verified) return null;

  return <StyledTweetVerifiedIcon />;
};

export default TweetVerifiedIcon;
