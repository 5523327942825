import { Fragment } from "react";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import AerialImageryPopupLayer from "./AerialImageryPopup/AerialImageryPopupLayer";
import AerialImageryRasterLayer from "./AerialImageryRasterLayer";
import FESM from "./FESM/FESM";
import { aerialImageryLayers } from "./utils";

const AerialImagery = () => {
  const { isLayerActive, getLayerState } = useActiveLayersContext();

  return (
    <>
      {/* TODO: FESM isn't actually an aerial image layer, should likely be moved */}
      <FESM />
      {Object.entries(aerialImageryLayers).map(
        ([layerId, aerialImageryLayer]) => {
          if (!isLayerActive(layerId)) {
            return null;
          }

          const state = getLayerState(layerId);

          return (
            <Fragment key={layerId}>
              <AerialImageryRasterLayer
                aerialImageryLayer={aerialImageryLayer}
                layerId={layerId}
                opacity={state?.opacity}
              />
              <AerialImageryPopupLayer
                aerialImageryLayer={aerialImageryLayer}
                layerId={layerId}
              />
            </Fragment>
          );
        },
      )}
    </>
  );
};

export default AerialImagery;
