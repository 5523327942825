import type { IconButtonSize } from "@app/design-system";
import styled from "styled-components";
import type { SocialTwitter } from "../../../../.rest-hooks/types";
import CopyLinkToTweetButton from "../CopyLinkToTweetButton/CopyLinkToTweetButton";
import type { PostLink } from "../SocialFeed/socialFeedQuery";
import ViewExternalPost from "../ViewExternalPost/ViewExternalPost";
import ViewTweetOnMapButton from "../ViewTweetOnMapButton/ViewTweetOnMapButton";
import TweetOverflowMenu from "./TweetOverflowMenu";

interface TweetHeaderActionsProps {
  children?: React.ReactNode;
  link: PostLink;
  size?: IconButtonSize;
  tweet: SocialTwitter;
  viewLocation?: boolean;
  viewPost?: boolean;
}

const StyledActions = styled.div`
  display: flex;
  margin: -0.25rem -0.25rem 0 auto;
  gap: 0.25rem;
`;

const TweetHeaderActions = ({
  children,
  link,
  size = "sm",
  tweet,
  viewLocation = true,
  viewPost = true,
}: TweetHeaderActionsProps) => {
  let actions: React.ReactNode;

  const { externalId, location, username } = tweet.attributes;

  const hasViewLocation = !!location && viewLocation;

  if (!hasViewLocation && !viewPost) {
    actions = (
      <CopyLinkToTweetButton link={link} size={size} tweetId={tweet.id} />
    );
  } else if (hasViewLocation && !viewPost) {
    actions = (
      <>
        <ViewTweetOnMapButton
          link={link}
          location={location}
          size={size}
          tweetId={tweet.id}
        />
        <CopyLinkToTweetButton link={link} size={size} tweetId={tweet.id} />
      </>
    );
  } else if (!hasViewLocation && viewPost) {
    actions = (
      <>
        <CopyLinkToTweetButton link={link} size={size} tweetId={tweet.id} />
        <ViewExternalPost
          externalId={externalId}
          size={size}
          username={username}
        />
      </>
    );
  } else {
    actions = (
      <TweetOverflowMenu
        externalId={externalId}
        link={link}
        size={size}
        tweetId={tweet.id}
        username={username}
      />
    );
  }

  return (
    <StyledActions>
      {actions}
      {children}
    </StyledActions>
  );
};

export default TweetHeaderActions;
