import { topographicLayer } from "../../../config/layers/baseLayers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import TopographicLayer from "./TopographicLayer";

const Topographic = () => {
  const { isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(topographicLayer.id)) {
    return null;
  }

  return <TopographicLayer />;
};

export default Topographic;
