import { rfsDistricts } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import RFSDistrictsLayer from "./RFSDistrictsLayer";

const RFSDistricts = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(rfsDistricts.id)) {
    return null;
  }

  const state = getLayerState(rfsDistricts.id);

  return <RFSDistrictsLayer opacity={state?.opacity} />;
};

export default RFSDistricts;
