/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";
import type { GetLightningDownloadParams } from "./types";

/**
 * Returns a CSV containing lightning data
 * @summary Get lightning data CSV
 */
export const getLightningDownload = (
  params: GetLightningDownloadParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<string>> => {
  return axios.get(`/lightning-download`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetLightningDownloadQueryKey = (
  params: GetLightningDownloadParams,
) => {
  return [`/lightning-download`, ...(params ? [params] : [])] as const;
};

export const getGetLightningDownloadQueryOptions = <
  TData = Awaited<ReturnType<typeof getLightningDownload>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params: GetLightningDownloadParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLightningDownload>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLightningDownloadQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLightningDownload>>
  > = ({ signal }) => getLightningDownload(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLightningDownload>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLightningDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLightningDownload>>
>;
export type GetLightningDownloadQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get lightning data CSV
 */
export const useGetLightningDownload = <
  TData = Awaited<ReturnType<typeof getLightningDownload>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params: GetLightningDownloadParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getLightningDownload>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLightningDownloadQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
