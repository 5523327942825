import styled from "styled-components";
import type { FieldObsSitRepInteractionState } from "../../map/FieldObsSitRep/components/interactions";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import FieldObsSitRepPopupView from "./FieldObsSitRepPopupView";
import type { FieldObsSitRepFields } from "./constants";

const StyledHeader = styled.div`
  ${(p) => p.theme.typography.variants.eyebrowDefault};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  margin: auto 0;
`;

interface FieldObsSitRepPopupProps {
  "data-testid"?: string;
  fields: Record<FieldObsSitRepFields, string>;
  onClose: () => void;
  state: FieldObsSitRepInteractionState;
  title: string;
  url: string;
}

const FieldObsSitRepPopup = ({
  "data-testid": dataTestId,
  fields,
  onClose,
  state,
  title,
  url,
}: FieldObsSitRepPopupProps) => {
  const { id, isActive, properties } = state;

  return (
    <SpatialPopup
      isOpen={isActive}
      onClose={onClose}
      popupConfig={{
        id,
        offset: 32,
        type: "click",
      }}
      lngLat={properties?.lngLat}
    >
      {state.properties && (
        <MapPopupView
          data-testid={dataTestId}
          header={<StyledHeader>{title}</StyledHeader>}
          onClose={onClose}
        >
          <FieldObsSitRepPopupView
            fields={fields}
            properties={properties}
            url={url}
          />
        </MapPopupView>
      )}
    </SpatialPopup>
  );
};

export default FieldObsSitRepPopup;
