import { himawariSurfaceTemperature } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import HimawariSurfaceTemperatureLayer from "./HimawariSurfaceTemperatureLayer";

const HimawariSurfaceTemperature = () => {
  const { isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(himawariSurfaceTemperature.id)) {
    return null;
  }

  return <HimawariSurfaceTemperatureLayer />;
};

export default HimawariSurfaceTemperature;
