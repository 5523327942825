import TelephoneExchangePopup from "../../popup/TelephoneExchangePopup/TelephoneExchangePopup";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import { getPropertiesFromFeature } from "./interactions";
import useTelephoneExchangeMapLayer from "./useTelephoneExchangeMapLayer";

const TELEPHONE_EXCHANGE_ID = "telephoneExchange";

interface TelephoneExchangeLayerProps {
  opacity?: number;
}

const TelephoneExchangeLayer = ({ opacity }: TelephoneExchangeLayerProps) => {
  useTelephoneExchangeMapLayer({
    layerId: TELEPHONE_EXCHANGE_ID,
    opacity,
  });

  const { clickedState, deactivateClickState } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: TELEPHONE_EXCHANGE_ID,
  });

  return (
    <TelephoneExchangePopup
      onClose={deactivateClickState}
      state={clickedState}
    />
  );
};
export default TelephoneExchangeLayer;
