import { Button } from "@app/design-system";
import React from "react";
import { useBoolean } from "usehooks-ts";
import type { Resource } from "../../../../.rest-hooks/types/resources.yml/resource";
import ResourceDetailModal from "../../resources/ViewResourceDetailButton/ResourceDetailModal";

interface AVLPopupViewResourceDetailButtonProps {
  resource: Resource;
}

const AVLPopupViewResourceDetailButton = ({
  resource,
}: AVLPopupViewResourceDetailButtonProps) => {
  const { value: isOpen, setTrue: open, setFalse: close } = useBoolean();
  return (
    <>
      <Button fullWidth onClick={open} size="sm">
        View resource details
      </Button>
      <ResourceDetailModal
        isOpen={isOpen}
        onDismiss={close}
        resource={resource}
      />
    </>
  );
};

export default AVLPopupViewResourceDetailButton;
