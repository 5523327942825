import {
  AssetDefendable,
  AssetEmpty,
  AssetFauna,
  AssetFlora,
  AssetHazmat,
  AssetHistoricSite,
  AssetIndigenousSite,
  AssetNotDefendable,
  AssetPotentiallyDefendable,
  AssetThreatened,
  FireBurntArea,
  FireEmergencyArea,
  FirePreviouslyBurntArea,
  FireProposedBurnArea,
  SymbolAccessPoint,
  SymbolAirbase,
  SymbolAmbulanceLocation,
  SymbolAssemblyArea,
  SymbolBaseCamp,
  SymbolCommunications,
  SymbolControlCentre,
  SymbolControlPoint,
  SymbolDivisionalCommandPoint,
  SymbolEvacuationArea,
  SymbolEvacuationCentre,
  SymbolEscapeRoute,
  SymbolHelibase,
  SymbolHelipad,
  SymbolLivestock,
  SymbolMedical,
  SymbolPoliceVehicle,
  SymbolPropertyDamaged,
  SymbolRaftCrew,
  SymbolRefugeArea,
  SymbolRoadClosure,
  SymbolSectorCommandPoint,
  SymbolSESVehicle,
  SymbolSmokerOnGround,
  SymbolSmokerStanding,
  SymbolStagingArea,
  SymbolTreeDown,
  SymbolWaterPointHelicopter,
  SymbolWaterPointVehicle,
  SymbolWaterPoint,
  Draw,
  TextBox,
  Location,
  Machine,
  Measure,
  AddPicture,
  FlagOutlined,
  HydrantPoint,
  MobileWeatherStation,
  AerialIgnitionPlanned,
  DivisionalBoundary,
  FireDirection,
  FireOriginUnconfirmed,
  SectorBoundary,
  SpotFireConfirmed,
  WindDirection,
} from "@app/design-system";
import type { DrawingModes } from "../state/mapAnnotationVariables";
import type { ButtonConfig } from "./buttons";

export interface Tool {
  mode: DrawingModes;
  button: ButtonConfig;
  type: "tool";
  value: string;
}

export interface Toolset {
  label: string;
  tools: Tool[];
  type: "toolset";
  value: string;
}

type ToolInput = Omit<Tool, "type">;

type ToolsetInput = Omit<Toolset, "type">;

const createTool = ({ mode, button, value }: ToolInput): Tool => ({
  mode,
  button,
  type: "tool",
  value,
});

const createToolset = ({ label, tools, value }: ToolsetInput): Toolset => ({
  label,
  tools,
  type: "toolset",
  value,
});

/* Annotate ----------------------------------------------------------------- */

export const annotateDrawPolygon = createTool({
  mode: { id: "place-text", selection: null },
  button: { icon: Draw, label: "Draw Polygon", disabled: true },
  value: "drawPolygon",
} as const);

export const annotateMarkPoint = createTool({
  mode: { id: "place-text", selection: null },
  button: { icon: Location, label: "Mark Point", disabled: true },
  value: "markPoint",
} as const);

export const annotateMeasure = createTool({
  mode: { id: "place-text", selection: null },
  button: { icon: Measure, label: "Measure", disabled: true },
  value: "measure",
} as const);

export const annotate = createToolset({
  label: "Annotate",
  tools: [annotateDrawPolygon, annotateMarkPoint, annotateMeasure],
  value: "annotate",
});

/* Fire mapping ------------------------------------------------------------- */

export const burntArea = createTool({
  mode: { id: "draw-polygon", selection: "firemap-fire-burnt-area" },
  button: { icon: FireBurntArea, label: "Burnt Area" },
  value: "burntArea",
});

export const previouslyBurntArea = createTool({
  mode: {
    id: "draw-polygon",
    selection: "firemap-fire-previously-burnt-area",
  },
  button: { icon: FirePreviouslyBurntArea, label: "Previously Burnt Area" },
  value: "previouslyBurntArea",
});

export const proposedBurnArea = createTool({
  mode: { id: "draw-polygon", selection: "firemap-fire-proposed-burn-area" },
  button: { icon: FireProposedBurnArea, label: "Proposed Burn Area" },
  value: "proposedBurnArea",
});

export const emergencyAlert = createTool({
  mode: { id: "draw-polygon", selection: "firemap-fire-emergency-alert" },
  button: { icon: FireEmergencyArea, label: "Emergency Alert" },
  value: "emergencyAlert",
});

export const fireMapping = createToolset({
  label: "Fire mapping",
  tools: [burntArea, previouslyBurntArea, proposedBurnArea, emergencyAlert],
  value: "fireMapping",
});

/* Place general symbols ---------------------------------------------------- */

export const symbolAccessPoint = createTool({
  mode: { id: "place-symbols", selection: "symbol-access-point" },
  button: { icon: SymbolAccessPoint, label: "Access Point" },
  value: "accessPoint",
});

export const symbolAerialIgnition = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-aerial-ignition" },
  button: { icon: AerialIgnitionPlanned, label: "Aerial Ignition" },
  value: "aerialIgnition",
});

export const symbolAirbase = createTool({
  mode: { id: "place-symbols", selection: "symbol-airbase" },
  button: { icon: SymbolAirbase, label: "Airbase" },
  value: "airbase",
});

export const symbolAmbulance = createTool({
  mode: {
    id: "place-symbols",
    selection: "symbol-ambulance-location",
  },
  button: { icon: SymbolAmbulanceLocation, label: "Ambulance Location" },
  value: "ambulanceLocation",
});

export const symbolAssemblyArea = createTool({
  mode: { id: "place-symbols", selection: "symbol-assembly-area" },
  button: { icon: SymbolAssemblyArea, label: "Assembly Area" },
  value: "assemblyArea",
});

export const symbolBaseCamp = createTool({
  mode: { id: "place-symbols", selection: "symbol-base-camp" },
  button: { icon: SymbolBaseCamp, label: "Base Camp" },
  value: "baseCamp",
});

export const symbolPortableCommunication = createTool({
  mode: { id: "place-symbols", selection: "symbol-communications" },
  button: { icon: SymbolCommunications, label: "Portable Communications" },
  value: "portableCommunications",
});

export const symbolControlCentre = createTool({
  mode: { id: "place-symbols", selection: "symbol-control-centre" },
  button: { icon: SymbolControlCentre, label: "Control Centre" },
  value: "controlCentre",
});

export const symbolControlPoint = createTool({
  mode: { id: "place-symbols", selection: "symbol-control-point" },
  button: { icon: SymbolControlPoint, label: "Control Point" },
  value: "controlPoint",
});

export const symbolDivisionalBoundary = createTool({
  mode: {
    id: "place-symbols",
    selection: "firemap-symbol-divisional-boundary",
  },
  button: {
    icon: DivisionalBoundary,
    label: "Divisional Boundary",
  },
  value: "divisionalBoundary",
});

export const symbolDivisionalCommandPoint = createTool({
  mode: {
    id: "place-symbols",
    selection: "firemap-symbol-divisional-command-point",
  },
  button: {
    icon: SymbolDivisionalCommandPoint,
    label: "Divisional Command Point",
  },
  value: "divisionalCommandPoint",
});

export const symbolEvacuationArea = createTool({
  mode: { id: "place-symbols", selection: "symbol-evacuation-area" },
  button: { icon: SymbolEvacuationArea, label: "Evacuation Area" },
  value: "evacuationArea",
});

export const symbolEvacuationCentre = createTool({
  mode: {
    id: "place-symbols",
    selection: "symbol-evacuation-centre",
  },
  button: { icon: SymbolEvacuationCentre, label: "Evacuation Centre" },
  value: "evacuationCentre",
});

export const symbolEscapeRoute = createTool({
  mode: { id: "place-symbols", selection: "symbol-escape-route" },
  button: { icon: SymbolEscapeRoute, label: "Escape Route" },
  value: "escapeRoute",
});

export const symbolFireDirection = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-fire-direction" },
  button: { icon: FireDirection, label: "Fire Direction" },
  value: "fireDirection",
});

export const symbolFireOrigin = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-fire-origin" },
  button: { icon: FireOriginUnconfirmed, label: "Fire Origin (Unconfirmed)" },
  value: "fireOrigin",
});

export const symbolFireVehicle = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-fire-vehicle" },
  button: { icon: SymbolEscapeRoute, label: "Fire Vehicle" },
  value: "fireVehicle",
});

export const symbolHelibase = createTool({
  mode: { id: "place-symbols", selection: "symbol-helibase" },
  button: { icon: SymbolHelibase, label: "Helibase" },
  value: "helibase",
});

export const symbolHelipad = createTool({
  mode: { id: "place-symbols", selection: "symbol-helipad" },
  button: { icon: SymbolHelipad, label: "Helipad" },
  value: "helipad",
});

export const symbolHydrantPoint = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-hydrant-point" },
  button: { icon: HydrantPoint, label: "Hydrant Point" },
  value: "hydrantPoint",
});

export const symbolLivestock = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-livestock" },
  button: { icon: SymbolLivestock, label: "Livestock" },
  value: "livestock",
});

export const symbolMedical = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-medical" },
  button: { icon: SymbolMedical, label: "Medical" },
  value: "medical",
});

export const symbolMobileWeatherStation = createTool({
  mode: {
    id: "place-symbols",
    selection: "firemap-symbol-mobile-weather-station",
  },
  button: { icon: MobileWeatherStation, label: "Mobile Weather Station" },
  value: "mobileWeatherStation",
});

export const symbolPlant = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-plant" },
  button: { icon: Machine, label: "Plant" },
  value: "plant",
});

export const symbolPoliceVehicle = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-police-vehicle" },
  button: { icon: SymbolPoliceVehicle, label: "Police Vehicle" },
  value: "policeVehicle",
});

export const symbolPropertyDamaged = createTool({
  mode: { id: "place-symbols", selection: "symbol-property-damaged" },
  button: { icon: SymbolPropertyDamaged, label: "Property Damaged" },
  value: "propertyDamaged",
});

export const symbolRaftCrew = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-raft-crew" },
  button: { icon: SymbolRaftCrew, label: "Raft Crew" },
  value: "raftCrew",
});

export const symbolRefugeArea = createTool({
  mode: { id: "place-symbols", selection: "symbol-refuge-area" },
  button: { icon: SymbolRefugeArea, label: "Refuge Area" },
  value: "refugeArea",
});

export const symbolRoadClosure = createTool({
  mode: { id: "place-symbols", selection: "symbol-road-closure" },
  button: { icon: SymbolRoadClosure, label: "Road Closure" },
  value: "roadClosure",
});

export const symbolSectorBoundary = createTool({
  mode: {
    id: "place-symbols",
    selection: "firemap-symbol-sector-boundary",
  },
  button: { icon: SectorBoundary, label: "Sector Boundary" },
  value: "sectorBoundary",
});

export const symbolSectorCommandPoint = createTool({
  mode: {
    id: "place-symbols",
    selection: "firemap-symbol-sector-command-point",
  },
  button: { icon: SymbolSectorCommandPoint, label: "Sector Command Point" },
  value: "sectorCommandPoint",
});

export const symbolSESVehicle = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-ses-vehicle" },
  button: { icon: SymbolSESVehicle, label: "SES Vehicle" },
  value: "sesVehicle",
});

export const symbolSmokerOnGround = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-smoker-on-ground" },
  button: { icon: SymbolSmokerOnGround, label: "Smoker On Ground" },
  value: "smokerOnGround",
});

export const symbolSmokerStanding = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-smoker-standing" },
  button: { icon: SymbolSmokerStanding, label: "Smoker Standing" },
  value: "smokerStanding",
});

export const symbolSpotFire = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-spot-fire" },
  button: { icon: SpotFireConfirmed, label: "Spot Fire" },
  value: "spotFire",
});

export const symbolStagingArea = createTool({
  mode: { id: "place-symbols", selection: "symbol-staging-area" },
  button: { icon: SymbolStagingArea, label: "Staging Area" },
  value: "stagingArea",
});

export const symbolTreeDown = createTool({
  mode: { id: "place-symbols", selection: "symbol-tree-down" },
  button: { icon: SymbolTreeDown, label: "Tree Down" },
  value: "treeDown",
});

export const symbolWaterPoint = createTool({
  mode: { id: "place-symbols", selection: "firemap-symbol-water-point" },
  button: { icon: SymbolWaterPoint, label: "Water Point" },
  value: "waterPoint",
});

export const symbolWaterPointVehicle = createTool({
  mode: {
    id: "place-symbols",
    selection: "firemap-symbol-water-point-vehicle",
  },
  button: { icon: SymbolWaterPointVehicle, label: "Water Point Vehicle" },
  value: "waterPointVehicle",
});

export const symbolWaterPointHelicopter = createTool({
  mode: {
    id: "place-symbols",
    selection: "firemap-symbol-water-point-helicopter",
  },
  button: { icon: SymbolWaterPointHelicopter, label: "Water Point Helicopter" },
  value: "waterPointHelicopter",
});

export const symbolWindDirection = createTool({
  mode: {
    id: "place-symbols",
    selection: "firemap-symbol-wind-direction",
  },
  button: { icon: WindDirection, label: "Wind Direction" },
  value: "windDirection",
});

export const placeGeneralSymbols = createToolset({
  label: "General mapping",
  tools: [
    symbolAccessPoint,
    symbolAerialIgnition,
    symbolAirbase,
    symbolAmbulance,
    symbolAssemblyArea,
    symbolBaseCamp,
    symbolControlCentre,
    symbolControlPoint,
    symbolDivisionalBoundary,
    symbolDivisionalCommandPoint,
    symbolEscapeRoute,
    symbolEvacuationArea,
    symbolEvacuationCentre,
    symbolFireDirection,
    symbolFireOrigin,
    symbolFireVehicle,
    symbolHelibase,
    symbolHelipad,
    symbolHydrantPoint,
    symbolLivestock,
    symbolMedical,
    symbolMobileWeatherStation,
    symbolPlant,
    symbolPoliceVehicle,
    symbolPortableCommunication,
    symbolPropertyDamaged,
    symbolRaftCrew,
    symbolRefugeArea,
    symbolRoadClosure,
    symbolSectorBoundary,
    symbolSectorCommandPoint,
    symbolSESVehicle,
    symbolSmokerOnGround,
    symbolSmokerStanding,
    symbolSpotFire,
    symbolStagingArea,
    symbolTreeDown,
    symbolWaterPoint,
    symbolWaterPointVehicle,
    symbolWaterPointHelicopter,
    symbolWindDirection,
  ],
  value: "generalMappingSymbols",
});

/* Place assets ------------------------------------------------------------- */

export const assetEmpty = createTool({
  mode: { id: "place-symbols", selection: "firemap-asset-empty" },
  button: { icon: AssetEmpty, label: "Empty" },
  value: "empty",
});

export const assetFauna = createTool({
  mode: { id: "place-symbols", selection: "firemap-asset-fauna" },
  button: { icon: AssetFauna, label: "Fauna" },
  value: "fauna",
});

export const assetFlora = createTool({
  mode: { id: "place-symbols", selection: "firemap-asset-flora" },
  button: { icon: AssetFlora, label: "Flora" },
  value: "flora",
});

export const assetHistoricSite = createTool({
  mode: { id: "place-symbols", selection: "firemap-asset-historic-site" },
  button: { icon: AssetHistoricSite, label: "Historic Site" },
  value: "historicSite",
});

export const assetIndigenousSite = createTool({
  mode: { id: "place-symbols", selection: "firemap-asset-indigenous-site" },
  button: { icon: AssetIndigenousSite, label: "Indigenous Site" },
  value: "indigenousSite",
});

export const assetThreatened = createTool({
  mode: { id: "place-symbols", selection: "firemap-asset-threatened" },
  button: { icon: AssetThreatened, label: "Threatened" },
  value: "threatened",
});

export const assetHazmat = createTool({
  mode: { id: "place-symbols", selection: "firemap-asset-hazmat" },
  button: { icon: AssetHazmat, label: "Hazmat" },
  value: "hazmat",
});

export const assetDefendable = createTool({
  mode: { id: "place-symbols", selection: "firemap-asset-defendable" },
  button: { icon: AssetDefendable, label: "Defendable" },
  value: "defendable",
});

export const assetNotDefendable = createTool({
  mode: { id: "place-symbols", selection: "firemap-asset-not-defendable" },
  button: { icon: AssetNotDefendable, label: "Not Defendable" },
  value: "notDefendable",
});

export const assetPotentiallyDefendable = createTool({
  mode: {
    id: "place-symbols",
    selection: "firemap-asset-potentially-defendable",
  },
  button: { icon: AssetPotentiallyDefendable, label: "Potentially Defendable" },
  value: "potentiallyDefendable",
});

export const placeAssets = createToolset({
  label: "Assets",
  tools: [
    assetEmpty,
    assetFauna,
    assetFlora,
    assetHistoricSite,
    assetIndigenousSite,
    assetThreatened,
    assetHazmat,
    assetDefendable,
    assetNotDefendable,
    assetPotentiallyDefendable,
  ],
  value: "assetSymbols",
});

/* All markup tools --------------------------------------------------------- */

export const markupToolsets: Toolset[] = [
  annotate,
  fireMapping,
  placeGeneralSymbols,
  placeAssets,
];

/* Annotation markup tools -------------------------------------------------- */

export const lineAnnotation = createTool({
  mode: { id: "draw-line", selection: null },
  button: { icon: Draw, label: "Draw Line" },
  value: "drawLine",
});

export const textAnnotation = createTool({
  mode: { id: "place-text", selection: null },
  button: { icon: TextBox, label: "Place Text" },
  value: "placeText",
});

export const annotationsTools = createToolset({
  label: "Annotations",
  tools: [lineAnnotation, textAnnotation],
  value: "annotationTools",
});

/* Measurement tools -------------------------------------------------------- */

export const areaMeasurement = createTool({
  mode: { id: "draw-polygon", selection: null },
  button: { icon: AssetEmpty, label: "Measure area" },
  value: "measureArea",
});

export const distanceMeasurement = createTool({
  mode: { id: "draw-line", selection: null },
  button: { icon: Draw, label: "Measure distance" },
  value: "measureDistance",
});

export const measurementTools = createToolset({
  label: "Measurements",
  tools: [distanceMeasurement, areaMeasurement],
  value: "measurementTools",
});

/* Other tools -------------------------------------------------------------- */

export const uploadMedia = createTool({
  button: { icon: AddPicture, label: "Upload Media" },
  mode: { id: "upload-media", selection: null },
  value: "uploadMedia",
});

export const flagData = createTool({
  button: { icon: FlagOutlined, label: "Flag Data" },
  mode: { id: "flag-data", selection: null },
  value: "flagData",
});

export const otherTools = createToolset({
  label: "Other",
  tools: [uploadMedia, flagData],
  value: "otherTools",
});

export const incidentMapToolsets: Toolset[] = [
  annotationsTools,
  placeAssets,
  fireMapping,
  placeGeneralSymbols,
  otherTools,
];
