import { ExpandLess, ExpandMore, IconButton, Text } from "@app/design-system";
import React from "react";
import styled from "styled-components";
import type { Incident } from "../../../../.rest-hooks/types";
import { getAlertLevelFromIncident } from "../../../config/alertLevel";
import makeTestId from "../../../utils/makeTestId";
import AlertLevelIcon from "../../ui/AlertLevelIcon/AlertLevelIcon";
import IncidentNotificationToggleButton from "../../ui/IncidentNotificationToggleButton/IncidentNotificationToggleButton";

const StyledCompactIncidentCardHeader = styled.div`
  display: flex;
  gap: 0.75rem;
  padding: 0.625rem 0.625rem 0.625rem 0.75rem;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
`;

const StyledIcon = styled.div`
  padding-top: 0.125rem;
`;

const StyledHeading = styled.div`
  display: grid;
  flex: 1;
`;

const StyledActions = styled.div`
  display: flex;
  gap: 0.125rem;
`;

interface CompactIncidentCardHeaderProps {
  "data-testid"?: string;
  incident: Incident;
  isCopView: boolean;
  isExpanded: boolean;
  title: React.ReactNode;
  toggleProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

const CompactIncidentCardHeader = ({
  incident,
  isCopView,
  isExpanded,
  title,
  toggleProps,
  "data-testid": dataTestId,
}: CompactIncidentCardHeaderProps) => {
  return (
    <StyledCompactIncidentCardHeader>
      <StyledIcon>
        <AlertLevelIcon
          size="sm"
          alertLevel={getAlertLevelFromIncident(incident)}
        />
      </StyledIcon>
      <StyledHeading>
        <Text size="subtitleSm">{title}</Text>
        <Text size="bodyDefault" variant="weak">
          {incident.attributes.lga || <>LGA &mdash;</>}
        </Text>
      </StyledHeading>
      <StyledActions>
        {!isCopView && (
          <IncidentNotificationToggleButton incidentId={incident.id} />
        )}
        <IconButton
          {...toggleProps}
          icon={isExpanded ? ExpandLess : ExpandMore}
          label={isExpanded ? "Collapse" : "Expand"}
          size="sm"
          variant="ghost"
          data-testid={makeTestId(dataTestId, "trigger")}
        />
      </StyledActions>
    </StyledCompactIncidentCardHeader>
  );
};

export default CompactIncidentCardHeader;
