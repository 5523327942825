import type { FieldObsWeatherData } from "../../map/FieldObsWeather/types";

export type FieldObsWeatherField = keyof FieldObsWeatherData;

export const FIELD_OBS_WEATHER_FIELD_NAMES: Record<
  FieldObsWeatherField,
  string
> = {
  temperatureCelsius: "Temp °C",
  humidityPercent: "Humidity %",
  windSpeedKmh: "Wind speed (@ 2m)",
  windDirection: "Wind direction",
  windTrend: "Wind trend",
};
