import { Button } from "@app/design-system";
import QueryParams from "../../../config/queryParams";
import TaskLink from "../../util/TaskLink/TaskLink";

interface ViewDistrictButtonProps {
  districtId: string | undefined;
}

const ViewDistrictButton = ({ districtId }: ViewDistrictButtonProps) => {
  return (
    <TaskLink
      as={`/districts/${districtId}/brigades`}
      href={{
        pathname: "/districts/[districtId]/brigades",
        query: { [QueryParams.DISTRICT_ID]: districtId },
      }}
    >
      <Button disabled={!districtId} fullWidth size="sm">
        View district
      </Button>
    </TaskLink>
  );
};

export default ViewDistrictButton;
