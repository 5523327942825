import { media, useIsMinWidth } from "@app/design-system";
import React from "react";
import styled from "styled-components";

const StyledMapRailOverlay = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.5rem;
  gap: 0.625rem;
  min-height: 0;
  pointer-events: none;
  overflow: clip;
`;

const StyledMenu = styled.div`
  flex-shrink: 0;

  @media ${media.lg} {
    position: relative;
  }
`;

const StyledPills = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.75rem;

  > * {
    pointer-events: all;
  }

  @media ${media.lg} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0.125rem;
  }
`;

const StyledMinimap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

interface MapRailOverlayProps {
  children?: React.ReactNode;
  menu?: React.ReactNode;
  pills?: React.ReactNode;
  slider?: React.ReactNode;
  minimap?: React.ReactNode;
}

const MapRailOverlay = ({
  children,
  menu,
  pills,
  slider,
  minimap,
}: MapRailOverlayProps) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("lg");
  if (!isTabletLandscapeAndAbove) {
    return (
      <StyledMapRailOverlay>
        <StyledMenu>
          <StyledPills>{pills}</StyledPills>
        </StyledMenu>
      </StyledMapRailOverlay>
    );
  }
  return (
    <StyledMapRailOverlay>
      <StyledMenu>
        {menu}
        {pills && <StyledPills>{pills}</StyledPills>}
        <StyledMinimap>{minimap}</StyledMinimap>
      </StyledMenu>
      {children}
      {slider}
    </StyledMapRailOverlay>
  );
};

export default MapRailOverlay;
