import { FieldGrid, Text } from "@app/design-system";
import type { ResourceAircraftAssignment } from "../../../../.rest-hooks/types/resources.yml/resourceAircraftAssignment";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import AircraftAssignmentIncident from "../../resources/AircraftDetailModal/AircraftAssignmentIncident";

export const formatReportAge = (reportAge: number | undefined) => {
  if (!reportAge) {
    return <FallbackElement />;
  }

  return getFormattedDateAndTime(reportAge * 1000);
};

export interface AircraftPositionsPreviewProps {
  assignment?: ResourceAircraftAssignment;
  callSign: string | undefined;
  reportAge: number | undefined;
  speed?: number;
}

const AircraftPositionsPreview = ({
  assignment,
  callSign,
  reportAge,
  speed,
}: AircraftPositionsPreviewProps) => {
  return (
    <>
      <Text size="subtitleLg">{callSign || <FallbackElement />}</Text>
      <FieldGrid>
        <FieldGrid.Item label="Report age">
          {formatReportAge(reportAge)}
        </FieldGrid.Item>
        {speed !== undefined && (
          <FieldGrid.Item label="Speed">{speed} Km/hour</FieldGrid.Item>
        )}
        {assignment && (
          <FieldGrid.Item label="Assigned to:">
            <AircraftAssignmentIncident assignment={assignment} />
          </FieldGrid.Item>
        )}
      </FieldGrid>
    </>
  );
};

export default AircraftPositionsPreview;
