import type React from "react";
import { useCallback, useReducer } from "react";
import { TypedMap } from "../../../lib/typedMap";
import SocialDrawerCell from "../../drawers/SocialDrawerCell/SocialDrawerCell";
import LegendDrawer from "./LegendDrawer/LegendDrawer";
import type { MapRailMenuButtonProps } from "./MapRailMenuButton";

type HasRequiredProps<T> = Partial<T> extends T ? false : true;

// eslint-disable-next-line @typescript-eslint/ban-types
export type MapRailItem<P = {}> = {
  component: React.ComponentType<P>;
  id: string;
  icon: ReactSVGComponent;
  iconOn: ReactSVGComponent;
  label: string;
  triggerComponent?: React.ComponentType<MapRailMenuButtonProps>;
  type: "menu";
} & (HasRequiredProps<P> extends true ? { props: P } : { props?: P });

export interface SelectedMapRailItem {
  id: string;
  isPinned: boolean;
}

export interface MapRailStatus {
  id: string;
  disabled?: boolean;
  count?: number;
}

/**
 * Map rail modals must be registered here to be triggered by consumers
 */
export const modalComponents = {
  legend: LegendDrawer,
  social: SocialDrawerCell,
} as const;

type ModalMapRailItemType = keyof typeof modalComponents;

type MapRailModalComponentMap = {
  [K in keyof typeof modalComponents]: ModalMapRailItem<K>;
};

/**
 * Props provided by map rail library code
 */
export type MapRailModalComponentProps = {
  onClose: () => void;
};

/**
 * Props that are provided by the user rather than the library.
 * e.g. `onClose` prop is injected by internal map rail hooks in order to
 * connect it to the state automatically.
 */
type CustomMapRailModalComponentProps<C extends ModalMapRailItemType> = Omit<
  React.ComponentPropsWithoutRef<(typeof modalComponents)[C]>,
  keyof MapRailModalComponentProps
>;

export type ModalMapRailItem<
  C extends ModalMapRailItemType = ModalMapRailItemType,
> = {
  id: C;
  type: "modal";
} & (HasRequiredProps<CustomMapRailModalComponentProps<C>> extends true
  ? {
      props: CustomMapRailModalComponentProps<C>;
    }
  : {
      props?: CustomMapRailModalComponentProps<C>;
    });

interface PushModalAction<
  C extends ModalMapRailItemType = ModalMapRailItemType,
> {
  type: "pushModal";
  payload: {
    item: MapRailModalComponentMap[C];
  };
}

interface RemoveAction {
  type: "remove";
  payload: { id: string };
}

interface RemoveModalAction {
  type: "removeModal";
  payload: { id: string };
}

interface ReplaceAction {
  type: "replace";
  payload: { id: string };
}

interface SetStatusAction {
  type: "setStatus";
  payload: MapRailStatus;
}

interface SetItemsAction {
  type: "setItems";
  payload: {
    items: Map<string, MapRailItem>;
  };
}

interface TogglePinnedAction {
  type: "togglePinned";
  payload: { id: string };
}

type MapRailAction =
  | PushModalAction
  | RemoveModalAction
  | RemoveAction
  | ReplaceAction
  | SetStatusAction
  | SetItemsAction
  | TogglePinnedAction;

interface MapRailState {
  /**
   * Important note: this is controlled state and should be referentially stable
   * i.e. use useMemo or declare it outside of the render function if it doesn't
   * depend on props or state.
   */
  items: Map<string, MapRailItem>;
  selectedModalItems: TypedMap<MapRailModalComponentMap>;
  selectedItems: Map<string, SelectedMapRailItem>;
  statuses: Map<string, MapRailStatus>;
}

const mapRailReducer = (
  state: MapRailState,
  action: MapRailAction,
): MapRailState => {
  switch (action.type) {
    case "pushModal": {
      const nextSelectedModalItems = new TypedMap<MapRailModalComponentMap>(
        state.selectedModalItems,
      );
      nextSelectedModalItems.set(action.payload.item.id, action.payload.item);
      return {
        ...state,
        selectedModalItems: nextSelectedModalItems,
      };
    }
    case "remove": {
      const nextSelectedItems = new Map(
        [...state.selectedItems].filter(
          ([, item]) => item.id !== action.payload.id,
        ),
      );
      return { ...state, selectedItems: nextSelectedItems };
    }
    case "removeModal": {
      const nextSelectedModalItems = new TypedMap<MapRailModalComponentMap>(
        [...state.selectedModalItems].filter(
          ([id]) => id !== action.payload.id,
        ),
      );
      return { ...state, selectedModalItems: nextSelectedModalItems };
    }
    case "replace": {
      const nextSelectedItems = new Map(
        [...state.selectedItems].filter(([, item]) => item.isPinned),
      );
      nextSelectedItems.set(action.payload.id, {
        id: action.payload.id,
        isPinned: false,
      });
      return { ...state, selectedItems: nextSelectedItems };
    }
    case "setStatus": {
      const nextStatus = new Map(state.statuses);
      if (!action.payload.count && !action.payload.disabled) {
        nextStatus.delete(action.payload.id);
      } else {
        nextStatus.set(action.payload.id, action.payload);
      }
      return { ...state, statuses: nextStatus };
    }
    case "setItems": {
      return { ...state, items: action.payload.items };
    }
    case "togglePinned": {
      const nextSelectedItems = new Map(state.selectedItems);
      const itemToToggle = nextSelectedItems.get(action.payload.id);
      if (itemToToggle) {
        nextSelectedItems.set(action.payload.id, {
          ...itemToToggle,
          isPinned: !itemToToggle.isPinned,
        });
      }
      return { ...state, selectedItems: nextSelectedItems };
    }

    default:
      return state;
  }
};

export const createMapRailItem = <P>(
  config: Omit<MapRailItem<P>, "type">,
): [string, MapRailItem] => {
  return [config.id, { ...config, type: "menu" }] as [string, MapRailItem];
};

type UseMapRailParams = {
  defaultSelectedItems?: Map<string, SelectedMapRailItem>;
  defaultStatuses?: Map<string, MapRailStatus>;
  items: Map<string, MapRailItem>;
};

export type UseMapRailReturn = MapRailState & {
  pushModal: <C extends ModalMapRailItemType = ModalMapRailItemType>(
    payload: PushModalAction<C>["payload"],
  ) => void;
  remove: (payload: RemoveAction["payload"]) => void;
  removeModal: (payload: RemoveModalAction["payload"]) => void;
  replace: (payload: ReplaceAction["payload"]) => void;
  togglePinned: (payload: TogglePinnedAction["payload"]) => void;
  setStatus: (payload: SetStatusAction["payload"]) => void;
};

export const useMapRail = ({
  defaultSelectedItems,
  defaultStatuses,
  items,
}: UseMapRailParams): UseMapRailReturn => {
  const [state, dispatch] = useReducer(mapRailReducer, {}, (): MapRailState => {
    return {
      items,
      selectedItems: defaultSelectedItems ?? new Map(),
      selectedModalItems: new Map(),
      statuses: defaultStatuses ?? new Map(),
    };
  });

  const pushModal = useCallback<UseMapRailReturn["pushModal"]>(
    <C extends ModalMapRailItemType>(payload: PushModalAction<C>["payload"]) =>
      dispatch({ type: "pushModal", payload }),
    [dispatch],
  );
  const remove = useCallback<UseMapRailReturn["remove"]>(
    (payload) => dispatch({ type: "remove", payload }),
    [dispatch],
  );
  const removeModal = useCallback<UseMapRailReturn["removeModal"]>(
    (payload) => dispatch({ type: "removeModal", payload }),
    [dispatch],
  );
  const replace = useCallback<UseMapRailReturn["replace"]>(
    (payload) => dispatch({ type: "replace", payload }),
    [dispatch],
  );
  const setStatus = useCallback<UseMapRailReturn["setStatus"]>(
    (payload) => dispatch({ type: "setStatus", payload }),
    [dispatch],
  );
  const togglePinned = useCallback<UseMapRailReturn["togglePinned"]>(
    (payload) => dispatch({ type: "togglePinned", payload }),
    [dispatch],
  );

  if (items !== state.items) {
    dispatch({ type: "setItems", payload: { items } });
  }

  return {
    items: state.items,
    selectedItems: state.selectedItems,
    selectedModalItems: state.selectedModalItems,
    statuses: state.statuses,
    pushModal,
    remove,
    removeModal,
    replace,
    setStatus,
    togglePinned,
  } as const;
};
