import {
  fesmFourToSevenYearsLayer,
  fesmOneToThreeYearsLayer,
  fesmSevenPlusYearsLayer,
} from "../../../../config/layers/layers";
import { useActiveLayersContext } from "../../../util/ActiveLayersProvider/ActiveLayersProvider";
import FESMLayer from "./FESMLayer";

const FESM = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  const isOneToThreeYearsActive = isLayerActive(fesmOneToThreeYearsLayer.id);
  const isFourToSevenYearsActive = isLayerActive(fesmFourToSevenYearsLayer.id);
  const isSevenPlusYearsActive = isLayerActive(fesmSevenPlusYearsLayer.id);

  return (
    <>
      {isOneToThreeYearsActive && (
        <FESMLayer
          opacity={getLayerState(fesmOneToThreeYearsLayer.id)?.opacity}
          timeSpan="fesm-1-3"
        />
      )}
      {isFourToSevenYearsActive && (
        <FESMLayer
          opacity={getLayerState(fesmFourToSevenYearsLayer.id)?.opacity}
          timeSpan="fesm-4-7"
        />
      )}
      {isSevenPlusYearsActive && (
        <FESMLayer
          opacity={getLayerState(fesmSevenPlusYearsLayer.id)?.opacity}
          timeSpan="fesm-7+"
        />
      )}
    </>
  );
};

export default FESM;
