import { fieldObsIconUnder3HrsLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FieldObsIconUnder3HrsLayer from "./FieldObsIconUnder3HrsLayer";

const FieldObsIconUnder3Hrs = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(fieldObsIconUnder3HrsLayer.id)) {
    return null;
  }

  const state = getLayerState(fieldObsIconUnder3HrsLayer.id);

  return <FieldObsIconUnder3HrsLayer opacity={state?.opacity} />;
};

export default FieldObsIconUnder3Hrs;
