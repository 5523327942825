import {
  keepPreviousData,
  useInfiniteQuery,
  type InfiniteData,
} from "@tanstack/react-query";
import {
  type GetIncidentsQueryError,
  type GetIncidentsQueryResult,
  getGetIncidentsQueryKey,
  getIncidents,
} from "../../../../../.rest-hooks/incidents";
import type { GetIncidentsParams } from "../../../../../.rest-hooks/types";
import type { IncludeIncidentCategoriesParameter } from "../../../../../.rest-hooks/types/incidents.yml";
import { REFETCH_INTERVAL_PERIODIC } from "../../../../config/refetch";
import { INCIDENTS_DRAWER_LIST_PER_PAGE } from "../../../drawers/IncidentsDrawerCell/IncidentCardList";
import { useIncidentsScope } from "../../../incidents/IncidentsScopeProvider/IncidentsScopeProvider";
import { getNextPageParam } from "../../../ui/SearchResults/pageQuery";
import useMapBbox from "../useMapBbox/useMapBbox";

interface UseIncidentsByBboxParams {
  enabled?: boolean;
  term?: string;
  categories?: IncludeIncidentCategoriesParameter;
  statuses?: string;
  useBbox?: boolean;
}

const useIncidentsByBbox = ({
  enabled = true,
  useBbox = true,
  term,
  categories,
  statuses,
}: UseIncidentsByBboxParams = {}) => {
  const bbox = useMapBbox("cartesian", { debounced: true });
  const { params: scopeParams } = useIncidentsScope();

  const params: GetIncidentsParams = {
    ...scopeParams,
    ...(useBbox && bbox),
    ...(term && { term }),
    ...(categories && { categories }),
    ...(statuses && { statuses }),
    perPage: INCIDENTS_DRAWER_LIST_PER_PAGE,
    sort: "priorityDesc",
  };

  const query = useInfiniteQuery<
    GetIncidentsQueryResult,
    GetIncidentsQueryError,
    InfiniteData<GetIncidentsQueryResult>,
    ReturnType<typeof getGetIncidentsQueryKey>,
    number
  >({
    enabled: !!bbox && enabled,
    getNextPageParam,
    placeholderData: keepPreviousData,
    initialPageParam: 1,
    queryFn: ({ pageParam }) =>
      getIncidents({
        ...params,
        page: pageParam,
      }),
    queryKey: getGetIncidentsQueryKey(params),
    refetchInterval: REFETCH_INTERVAL_PERIODIC,
  });

  return query;
};

export default useIncidentsByBbox;
