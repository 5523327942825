import { featureCollection } from "@turf/helpers";
import type { Feature, GeoJsonProperties, Geometry } from "geojson";
import type { RiskAssessments } from "../../../../.rest-hooks/types/weather.yml";

export const getIconId = (risk: string) => {
  switch (risk) {
    case "severe":
      return "FIRE_GROUND_FLIGHT_RISK_SEVERE";
    case "moderate":
      return "FIRE_GROUND_FLIGHT_RISK_MODERATE";
    case "low":
      return "FIRE_GROUND_FLIGHT_RISK_LOW";
    default:
      return "";
  }
};

export const updateFeaturesWithIcon = (
  features: readonly Feature[],
  riskAssessment: readonly RiskAssessments[],
) => {
  const combinedData = {} as {
    [key: string]: Feature<Geometry, GeoJsonProperties>;
  };

  features.forEach((feature) => {
    combinedData[feature.properties?.incidentId] = { ...feature };
  });
  riskAssessment.forEach(({ incidentId, risk }) => {
    if (!combinedData[incidentId]) {
      return;
    }
    combinedData[incidentId] = {
      ...combinedData[incidentId],
      properties: {
        ...combinedData[incidentId].properties,
        icon: getIconId(risk as string),
      },
    };
  });
  const filteredFeatures = Object.values(combinedData).filter(
    (feature) => !!feature.properties?.icon,
  );

  return featureCollection(filteredFeatures);
};
