import type mapboxgl from "mapbox-gl";
import { useEffect } from "react";
import type { IncidentPointFeatureCollection } from "../IncidentPoint/utils";
import useMapContext from "../Map/useMapContext";
import { isGeoJsonSource } from "../types";
import { loadIcon, loadIcons } from "../utils/loadImage";
import {
  INCIDENT_POINT_ACTIVE_SUFFIX,
  INCIDENT_POINT_COLLECTION_SOURCE_ID,
  INCIDENT_POINT_HOVERED_SUFFIX,
} from "./constants";

interface LoadIncidentPointCollectionIconsParams {
  map: mapboxgl.Map | maplibregl.Map;
  geojson: IncidentPointFeatureCollection;
}

const loadIncidentPointCollectionIcons = async ({
  geojson,
  map,
}: LoadIncidentPointCollectionIconsParams) => {
  await loadIcons({
    geojson,
    getImageId: (properties) => properties.iconId,
    map,
    pixelRatio: 4,
  });
  await loadIcons({
    geojson,
    getImageId: (properties) =>
      `${properties.iconId}${INCIDENT_POINT_HOVERED_SUFFIX}`,
    map,
    pixelRatio: 4,
  });
  await loadIcons({
    geojson,
    getImageId: (properties) =>
      `${properties.iconId}${INCIDENT_POINT_ACTIVE_SUFFIX}`,
    map,
    pixelRatio: 4,
  });
  await loadIcon({
    imageId: "label-bg",
    map,
    pixelRatio: 4,
    stretchX: [[28, 36]],
    stretchY: [[24, 32]],
    content: [20, 16, 44, 40],
  });
};

interface UseIncidentPointCollectionMapDataParams {
  geojson: IncidentPointFeatureCollection;
}

const useIncidentPointCollectionMapData = ({
  geojson,
}: UseIncidentPointCollectionMapDataParams) => {
  const map = useMapContext();

  useEffect(() => {
    const controller = new AbortController();

    const loadIconsAndSetData = async () => {
      await loadIncidentPointCollectionIcons({ geojson, map });

      if (controller.signal.aborted) return;

      const source = map.getSource(INCIDENT_POINT_COLLECTION_SOURCE_ID);
      if (isGeoJsonSource(source)) {
        source.setData(geojson);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadIconsAndSetData();

    return () => controller.abort();
  }, [geojson, map]);
};

export default useIncidentPointCollectionMapData;
