import {
  DebouncedTextInput,
  ExpandLess,
  ExpandMore,
  FieldGroup,
  FieldRow,
  IconButton,
  MenuSection,
  Search,
  Select,
  Switch,
  Text,
  Tooltip,
  media,
  useTheme,
  type FormControlOption,
} from "@app/design-system";
import { useLocalStorage } from "@kablamo/kerosene-ui";
import { keepPreviousData } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { useCollapse } from "react-collapsed";
import styled from "styled-components";
import { useGetAggregatesIncidents } from "../../../../../.rest-hooks/incidents";
import type {
  Incident,
  IncidentCategory,
} from "../../../../../.rest-hooks/types";
import { REFETCH_INTERVAL_PERIODIC } from "../../../../config/refetch";
import { LocalStorageKeys } from "../../../../config/storage";
import DrawerContent from "../../../drawers/DrawerOverlay/DrawerContent";
import type {
  IncidentCardLayout,
  IncidentCtaProps,
} from "../../../drawers/IncidentsDrawerCell/IncidentCard";
import IncidentCardList from "../../../drawers/IncidentsDrawerCell/IncidentCardList";
import {
  categoryOptions,
  incidentCategoryDefault,
  incidentCategoryLabels,
  incidentStatusDefault,
  statusOptions,
} from "../../../drawers/IncidentsDrawerCell/constants";
import {
  useIncidentsScope,
  type IncidentStatusOption,
} from "../../../incidents/IncidentsScopeProvider/IncidentsScopeProvider";
import { INCIDENT_POINT_COLLECTION_LAYER_ID } from "../../../map/IncidentPointCollection/constants";
import type { IncidentInteractionProperties } from "../../../map/IncidentPointCollection/interactions";
import useUnsafeMapContext from "../../../map/Map/useUnsafeMapContext";
import useLayerInteractionState from "../../../map/MapInteractions/useLayerInteractionState";
import useIncidentsByBbox from "../../../map/hooks/useIncidentsByBbox/useIncidentsByBbox";
import useMapBbox from "../../../map/hooks/useMapBbox/useMapBbox";
import type { PreferredPredictionAttribute } from "../../PreferredPredictionValidIndicator/PreferredPredictionValidIndicator";
import MapRailDrawerCell from "../MapRailDrawerCell";
import { useMapRailContext } from "../MapRailProvider";
import { incidentsDrawerSchema, isIncidentsDrawerSettings } from "./settings";

const StyledFilters = styled.div`
  display: grid;
  padding: 0.75rem;
  margin: 0 0 0.5rem;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};

  @media ${media.lg} {
    margin: 0 0 0.1875rem;
  }
`;

const StyledSearch = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: -0.125rem;
`;

const StyledInput = styled.div`
  flex: 1;
`;

const StyledCollapse = styled.div``;

const StyledCollapseInner = styled.div`
  display: grid;
  gap: 0.75rem;
  padding-top: 1rem;
`;

const incidentCardLayoutOptions: FormControlOption<IncidentCardLayout>[] = [
  { label: "Card", value: "card" },
  { label: "List", value: "list" },
];

interface IncidentsDrawerProps {
  cta: React.ComponentType<IncidentCtaProps>;
  isCopView?: boolean;
  predictionAttribute: PreferredPredictionAttribute;
}

const IncidentsDrawer = ({
  cta,
  isCopView,
  predictionAttribute,
}: IncidentsDrawerProps) => {
  const id = "incidents";

  const theme = useTheme();

  const [term, setTerm] = useState("");

  const defaultIncidentsDrawerSettings = incidentsDrawerSchema.cast({
    categoryFilter: incidentCategoryDefault(!!isCopView),
    statusFilter: incidentStatusDefault,
  });

  const [settings, setSettings] = useLocalStorage(
    isCopView
      ? LocalStorageKeys.INCIDENTS_DRAWER_SETTINGS_COP
      : LocalStorageKeys.INCIDENTS_DRAWER_SETTINGS,
    defaultIncidentsDrawerSettings,
    isIncidentsDrawerSettings,
  );

  const { remove, selectedItems, setStatus } = useMapRailContext();

  const isSelected = selectedItems.has(id);

  const getIncidentsQuery = useIncidentsByBbox({
    enabled: isSelected,
    term,
    useBbox: settings.isSearchWithinViewEnabled,
  });

  const bbox = useMapBbox("cartesian", { debounced: true });
  const { params, scope, onScopeChange } = useIncidentsScope();

  useEffect(() => {
    onScopeChange({
      ...scope,
      status: settings.statusFilter as IncidentStatusOption,
      category: settings.categoryFilter as IncidentCategory,
    });
  }, []);

  useEffect(() => {
    setSettings({
      ...settings,
      statusFilter: scope.status,
      categoryFilter: scope.category,
    });
  }, [scope]);

  const { data: aggregateData } = useGetAggregatesIncidents(
    {
      ...(settings.isSearchWithinViewEnabled && bbox),
      ...params,
      term,
    },
    {
      query: {
        placeholderData: keepPreviousData,
        refetchInterval: REFETCH_INTERVAL_PERIODIC,
        select: (data) => data.data.meta,
      },
    },
  );

  const count = aggregateData?.total;

  useEffect(() => {
    setStatus({ id: "incidents", count });
  }, [count, setStatus]);

  const { isExpanded, getToggleProps, getCollapseProps } = useCollapse({
    duration: theme.anim.duration.sm,
    easing: theme.anim.curve,
  });

  const { map } = useUnsafeMapContext();

  const { activateClickState, clickedState } =
    useLayerInteractionState<IncidentInteractionProperties>({
      layerId: INCIDENT_POINT_COLLECTION_LAYER_ID,
    });

  // We apply padding to center the popup in the middle of the screen, rather
  // than the incident point in the middle of the screen (so that the popup
  // doesn't get cut off by being too high) when we `easeTo` the incident.
  // By tracking whether we've done so we can remove the padding when the user
  // next closes the popup.
  const didEaseTo = useRef(false);

  const onLocateClick = (incident: Incident) => {
    if (!map) return;

    const [lng, lat] = incident.attributes.location.coordinates;

    map?.easeTo({
      center: {
        lat,
        lng,
      },
      padding: {
        top: isCopView ? 192 : 288,
        bottom: 0,
        left: 0,
        right: 0,
      },
      zoom: 12,
      duration: theme.anim.duration.lg,
    });

    didEaseTo.current = true;

    activateClickState({
      incidentId: incident.id,
      lngLat: {
        lng,
        lat,
      },
      name: incident.attributes.name,
      featureId: incident.id,
    });
  };

  useEffect(() => {
    if (!clickedState.isActive && didEaseTo.current) {
      map?.easeTo({
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      });
      didEaseTo.current = false;
    }
  }, [clickedState, map]);

  return (
    <MapRailDrawerCell
      actions={
        <FieldRow
          htmlFor="withinViewSwitch"
          id="withinViewLabel"
          label={
            <Tooltip
              delay
              message="Display only those incidents which are visible within the current map view"
            >
              <Text variant="weak">Within view</Text>
            </Tooltip>
          }
        >
          <Switch
            aria-labelledby="withinViewLabel"
            id="withinViewSwitch"
            isSelected={settings.isSearchWithinViewEnabled}
            onChange={(value) =>
              setSettings({
                ...settings,
                isSearchWithinViewEnabled: value,
              })
            }
            size="sm"
          />
        </FieldRow>
      }
      onClose={() => remove({ id })}
      label={isCopView ? incidentCategoryLabels[scope.category] : "Incidents"}
      data-testid="incidents-drawer"
    >
      <DrawerContent>
        <StyledFilters>
          <StyledSearch>
            <StyledInput>
              <DebouncedTextInput
                iconStart={Search}
                isClearable
                onChange={(value) => setTerm(value)}
                placeholder="Search name or LGA..."
                size="sm"
                value={term}
              />
            </StyledInput>
            <IconButton
              {...getToggleProps()}
              icon={isExpanded ? ExpandLess : ExpandMore}
              label={isExpanded ? "Less" : "More"}
              variant="secondary"
              size="sm"
            />
          </StyledSearch>
          <StyledCollapse {...getCollapseProps()}>
            <StyledCollapseInner>
              <MenuSection label="Filter">
                <FieldGroup>
                  {/* Need to add functionality to these */}
                  <FieldRow label="Category">
                    <Select
                      options={categoryOptions}
                      size="xs"
                      onChange={(value) =>
                        value &&
                        onScopeChange({
                          ...scope,
                          category: value.value,
                        })
                      }
                      value={categoryOptions.find(
                        (c) => c.value === settings.categoryFilter,
                      )}
                    />
                  </FieldRow>
                  <FieldRow label="Status">
                    <Select
                      options={statusOptions}
                      size="xs"
                      onChange={(value) =>
                        value &&
                        onScopeChange({
                          ...scope,
                          status: value.value,
                        })
                      }
                      value={statusOptions.find(
                        (s) => s.value === settings.statusFilter,
                      )}
                    />
                  </FieldRow>
                  <FieldRow label="Sort by">
                    <Select
                      options={[
                        { label: "Risk rating", value: "riskRating" },
                        { label: "Alert level", value: "alertLevel" },
                      ]}
                      size="xs"
                      value={{ label: "Risk rating", value: "riskRating" }}
                    />
                  </FieldRow>
                </FieldGroup>
              </MenuSection>
              <MenuSection label="View">
                <FieldGroup>
                  <FieldRow label="Layout">
                    <Select
                      onChange={(value) =>
                        value &&
                        setSettings({
                          ...settings,
                          layout: value.value,
                        })
                      }
                      options={incidentCardLayoutOptions}
                      size="xs"
                      value={incidentCardLayoutOptions.find(
                        (option) => option.value === settings.layout,
                      )}
                    />
                  </FieldRow>
                  <FieldRow
                    hint="Automatically expands the first incident in the list"
                    id="spotlightFirstResult"
                    label="Spotlight first result"
                  >
                    <Switch
                      aria-labelledby="spotlightFirstResult"
                      onChange={(value) => {
                        setSettings({
                          ...settings,
                          isSpotlightFirstResultEnabled: value,
                        });
                      }}
                      isSelected={settings.isSpotlightFirstResultEnabled}
                      size="sm"
                    />
                  </FieldRow>
                </FieldGroup>
              </MenuSection>
            </StyledCollapseInner>
          </StyledCollapse>
        </StyledFilters>
        <IncidentCardList
          cta={cta}
          data-testid="incidents-list"
          getIncidentsQuery={getIncidentsQuery}
          isCopView={isCopView}
          isSpotlightFirstResultEnabled={settings.isSpotlightFirstResultEnabled}
          layout={settings.layout}
          predictionAttribute={predictionAttribute}
          onLocateClick={onLocateClick}
          term={term}
        />
      </DrawerContent>
    </MapRailDrawerCell>
  );
};

export default IncidentsDrawer;
