import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";

const TOTAL_FIRE_BAN_AREAS_QUERY = buildMapboxBBoxQuery({
  dpi: "96",
  format: "png32",
  transparent: "true",
  bboxSR: "3857",
  imageSR: "3857",
  size: "512,512",
  f: "image",
});

const TOTAL_FIRE_BAN_AREAS_ID = "totalFireBanAreas";
const TOTAL_FIRE_BAN_AREAS_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/TobanDays/MapServer/export?${TOTAL_FIRE_BAN_AREAS_QUERY}`;

interface TotalFireBanAreasLayerProps {
  opacity?: number;
}

const TotalFireBanAreasLayer = ({
  opacity = 1,
}: TotalFireBanAreasLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(TOTAL_FIRE_BAN_AREAS_ID, {
      type: "raster",
      tileSize: 512,
      tiles: [TOTAL_FIRE_BAN_AREAS_URL],
    });

    map.addLayer(
      {
        id: TOTAL_FIRE_BAN_AREAS_ID,
        type: "raster",
        source: TOTAL_FIRE_BAN_AREAS_ID,
      },
      MapLevel.BACKGROUND,
    );

    return () => {
      if (map.getLayer(TOTAL_FIRE_BAN_AREAS_ID)) {
        map.removeLayer(TOTAL_FIRE_BAN_AREAS_ID);
      }
      if (map.getSource(TOTAL_FIRE_BAN_AREAS_ID)) {
        map.removeSource(TOTAL_FIRE_BAN_AREAS_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    map.setPaintProperty(TOTAL_FIRE_BAN_AREAS_ID, "raster-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default TotalFireBanAreasLayer;
