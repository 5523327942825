interface GetTweetUrlParams {
  externalId: string;
  username: string;
}

const getExternalTweetUrl = ({ externalId, username }: GetTweetUrlParams) => {
  return `https://twitter.com/${username}/status/${externalId}`;
};

export default getExternalTweetUrl;
