import { useGetPredictionsRedmaps } from "../../../../.rest-hooks/predictions";
import { redMapLayer } from "../../../config/layers/layers";
import { isRedMapPredictionAttributes } from "../../../config/predictionProduct";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import RedMapByFileUrl from "./RedMapByPrediction";
import { useFromDay, useToDay } from "./useRedMapTimes";
import useRedMapVariant from "./useRedMapVariant";

const RedMap = () => {
  const { isLayerActive } = useActiveLayersContext();

  const isRedMapLayerActive = isLayerActive(redMapLayer.id);
  const variant = useRedMapVariant();
  const fromDay = useFromDay();
  const toDay = useToDay();

  const { data } = useGetPredictionsRedmaps(
    {
      fromDay,
      toDay,
    },
    {
      query: {
        enabled: isRedMapLayerActive,
      },
    },
  );

  if (!isRedMapLayerActive) return null;

  const predictions = data?.data.data ?? [];

  return (
    <>
      {predictions.map((prediction) => {
        if (
          !isRedMapPredictionAttributes(prediction.attributes) ||
          !prediction.attributes.result
        ) {
          return null;
        }
        return (
          <RedMapByFileUrl
            key={prediction.id}
            id={prediction.id}
            potentialEmbersFileUrl={
              prediction.attributes.result.potentialEmbersFileUrl
            }
            potentialSpreadFileUrl={
              prediction.attributes.result.potentialSpreadFileUrl
            }
            variant={variant}
          />
        );
      })}
    </>
  );
};

export default RedMap;
