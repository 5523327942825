import type mapboxgl from "mapbox-gl";
import type { InteractionStateType } from "../MapInteractions/types";

interface SetInteractionFeatureStateParams {
  map: mapboxgl.Map | maplibregl.Map;
  featureId: string | number;
  isActive: boolean;
  sourceId: string;
  type: InteractionStateType;
}

const setSourceFeatureInteractionState = ({
  map,
  featureId,
  isActive,
  sourceId,
  type,
}: SetInteractionFeatureStateParams) => {
  if (!map.getSource(sourceId)) return;

  map.setFeatureState(
    {
      source: sourceId,
      id: featureId,
    },
    { [type]: isActive },
  );
};

export default setSourceFeatureInteractionState;
