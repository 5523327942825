import { Layers, LayersFilled } from "@app/design-system";
import { useEffect } from "react";
import ResetLayersButton from "../../../drawers/LayersDrawerCell/ResetLayersButton";
import { useActiveLayersContext } from "../../../util/ActiveLayersProvider/ActiveLayersProvider";
import MapRailDrawerCell from "../MapRailDrawerCell";
import { useMapRailContext } from "../MapRailProvider";
import { createMapRailItem } from "../useMapRail";
import LayersDrawerView from "./LayersDrawerView";

const LayersDrawer = () => {
  const { remove, setStatus } = useMapRailContext();
  const { getLayersBySource } = useActiveLayersContext();

  const layers = getLayersBySource("layers-drawer");
  const count = layers.size;

  useEffect(() => {
    setStatus({ id: "layers", count });
  }, [count, setStatus]);

  return (
    <MapRailDrawerCell
      data-testid="layers-drawer"
      footer={count > 0 && <ResetLayersButton />}
      label="Layers"
      onClose={() => remove({ id: "layers" })}
    >
      <LayersDrawerView isMapRailEnabled />
    </MapRailDrawerCell>
  );
};

export const layersMapRailItem = createMapRailItem({
  component: LayersDrawer,
  icon: Layers,
  iconOn: LayersFilled,
  label: "Layers",
  id: "layers",
});

export default LayersDrawer;
