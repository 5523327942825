import { redMapLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import type { RedMapLayerVariant } from "./RedMapLayer";

const useRedMapVariant = () => {
  const { getLayerStateFromConfig } = useActiveLayersContext();
  const state = getLayerStateFromConfig(redMapLayer);

  const isRedMapTomorrowLayerActive = !!state?.redMapTomorrow?.isActive;

  const isRedMapTodayLayerActive = !!state?.redMapToday?.isActive;

  let variant: RedMapLayerVariant;

  if (isRedMapTomorrowLayerActive && !isRedMapTodayLayerActive) {
    variant = "weak";
  } else {
    variant = "default";
  }

  return variant;
};

export default useRedMapVariant;
