import groupBy from "lodash/groupBy";
import type { AircraftRiskAssessment } from "../../../../.rest-hooks/types/weather.yml";

export const getMaxRiskRating = (
  riskRatings: readonly AircraftRiskAssessment[],
) => {
  const gropedByRiskRating = groupBy(riskRatings, "riskRating");

  if (gropedByRiskRating.SEV) {
    return "Severe";
  }
  if (gropedByRiskRating.MOD) {
    return "Moderate";
  }

  return "Low";
};
