import { useEffect } from "react";
import { MapLevel } from "../../../../config/layers/layers";
import catchAbortError from "../../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../../utils/getMapServerProxyBasepath";
import useMapContext from "../../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../../types";
import { loadImage } from "../../utils/loadImage";

interface UseFieldObsSitRepLayer {
  id: string;
  opacity?: number;
  queryOptions: QueryOptions;
  url: string;
}

const useFieldObsSitRepLayer = ({
  id,
  opacity = 1,
  queryOptions,
  url,
}: UseFieldObsSitRepLayer) => {
  const map = useMapContext();

  const queryUrl = `${getMapServerProxyBasepath()}${url}/query`;

  const { data } = useMapServerQueryData(queryUrl, queryOptions);

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(id, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    loadImage({
      imageId: id,
      map,
      src: "/icons/field-obs-sitrep.png",
      signal: controller.signal,
    }).then(() => {
      map.addLayer(
        {
          id,
          type: "symbol",
          source: id,
          layout: {
            "icon-image": id,
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
      if (map.hasImage(id)) {
        map.removeImage(id);
      }
      controller.abort();
    };
  }, [id, map]);

  useEffect(() => {
    const source = map.getSource(id);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [id, data, map]);

  useEffect(() => {
    map.setPaintProperty(id, "icon-opacity", opacity);
  }, [id, map, opacity]);

  return null;
};

export default useFieldObsSitRepLayer;
