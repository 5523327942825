import styled from "styled-components";
import type { SocialTwitterAttributesMediaItem } from "../../../../.rest-hooks/types/social-twitter.yml";

const StyledTweetMedia = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
  overflow-x: auto;
`;

const StyledFigure = styled.div`
  flex-basis: 0;
  display: grid;
  gap: 4px;
`;

const StyledSource = styled.img`
  display: block;
  max-height: 256px;
  width: auto;
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
`;

const StyledTweetMediaLink = styled.a`
  display: block;
`;

interface TweetMediaProps {
  media: SocialTwitterAttributesMediaItem[];
}

const TweetMedia = ({ media }: TweetMediaProps) => {
  return (
    <StyledTweetMedia>
      {media.map(({ type, url }) => {
        let mediaContent: React.ReactNode;
        switch (type) {
          case "photo": {
            mediaContent = (
              <StyledSource height="256" src={url} alt="tweet-image" />
            );
            break;
          }
          case "video": {
            // Key is important so that video loads new source when URL changes
            // @see https://stackoverflow.com/a/47382850
            mediaContent = (
              <StyledSource as="video" controls>
                <source src={url} type="video/mp4" />
              </StyledSource>
            );
            break;
          }
          default:
          // empty
        }
        return (
          <StyledFigure key={url}>
            <StyledTweetMediaLink href={url} target="_blank" rel="noreferrer">
              {mediaContent}
            </StyledTweetMediaLink>
          </StyledFigure>
        );
      })}
    </StyledTweetMedia>
  );
};

export default TweetMedia;
