import type { Feature, GeoJsonProperties, LineString, Polygon } from "geojson";

export type MeasurementUnit =
  | {
      type: "area";
      isActive: boolean;
      value: Feature<Polygon, GeoJsonProperties> | undefined;
    }
  | {
      type: "distance";
      isActive: boolean;
      value: Feature<LineString, GeoJsonProperties> | undefined;
    };

export type MeasurementTool = {
  type: MeasurementUnit["type"];
  mode: MapboxDraw.DrawMode;
};

export const measurementToolArea = {
  type: "area",
  mode: "draw_polygon",
} as const satisfies MeasurementTool;

export const measurementToolDistance = {
  type: "distance",
  mode: "draw_line_string",
} as const satisfies MeasurementTool;
