import { fuelTypeLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FuelTypeLayer from "./FuelTypeLayer";

const FuelType = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(fuelTypeLayer.id)) {
    return null;
  }

  const state = getLayerState(fuelTypeLayer.id);

  return <FuelTypeLayer opacity={state?.opacity} />;
};

export default FuelType;
