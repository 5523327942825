import { fireGroundFlightRiskLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FireGroundFlightRiskLayer from "./FireGroundFlightRiskLayer";

const FireGroundFlightRisk = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(fireGroundFlightRiskLayer.id)) {
    return null;
  }

  const state = getLayerState(fireGroundFlightRiskLayer.id);

  return <FireGroundFlightRiskLayer opacity={state?.opacity} />;
};

export default FireGroundFlightRisk;
