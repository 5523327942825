import AVLPopup from "../../popup/AVLPopup/AVLPopup";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import type { QueryOptions } from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { getPropertiesFromFeature } from "./interactions";
import useAVLPositionsMapData from "./useAVLPositionsMapData";
import useAVLPositionsMapLayers from "./useAVLPositionsMapLayers";
import type { AVLType } from "./utils";

interface AVLPositionsLayerProps {
  accessToken: string;
  avlType: AVLType;
  isCopView: boolean;
  layerId: string;
  opacity?: number;
  queryOptions: QueryOptions;
  queryUrl: string;
  sourceId: string;
  showLabels?: boolean;
  symbologyOverrides?: Partial<Record<string, string>>;
  symbologyUrl: string;
}

const AVLPositionsLayer = ({
  accessToken,
  avlType,
  isCopView,
  layerId,
  opacity,
  queryOptions,
  queryUrl,
  showLabels,
  sourceId,
  symbologyOverrides,
  symbologyUrl,
}: AVLPositionsLayerProps) => {
  useAVLPositionsMapLayers({
    avlType,
    layerId,
    opacity,
    showLabels,
    sourceId,
  });
  useAVLPositionsMapData({
    accessToken,
    avlType,
    layerId,
    queryOptions,
    queryUrl,
    sourceId,
    symbologyOverrides,
    symbologyUrl,
  });

  const {
    clickedState,
    deactivateClickState,
    deactivateHoverState,
    hoveredState,
  } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId,
  });

  return (
    <>
      <AVLPopup
        avlType={avlType}
        isCopView={isCopView}
        onClose={deactivateHoverState}
        state={hoveredState}
        type="hover"
      />
      <AVLPopup
        avlType={avlType}
        isCopView={isCopView}
        onClose={deactivateClickState}
        state={clickedState}
        type="click"
      />
    </>
  );
};

export default AVLPositionsLayer;
