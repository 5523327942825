import { Button, Search, useTheme } from "@app/design-system";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { avlDataLayer } from "../../../../config/layers/layers";
import type { AVLPositionsLayerState } from "../../../map/AVLPositions/AVLPositions";
import { AVL_POSITIONS_OPTION_APPLIANCES_LAYER_ID } from "../../../map/AVLPositions/constants";
import type { AVLInteractionProperties } from "../../../map/AVLPositions/interactions";
import useUnsafeMapContext from "../../../map/Map/useUnsafeMapContext";
import useLayerInteractionState from "../../../map/MapInteractions/useLayerInteractionState";
import { useActiveLayersContext } from "../../../util/ActiveLayersProvider/ActiveLayersProvider";
import MapRailDrawerCell from "../MapRailDrawerCell";
import { useMapRailContext } from "../MapRailProvider";
import { createMapRailItem } from "../useMapRail";
import {
  getDefaultSearchDrawerFormValues,
  type SearchDrawerFormError,
  type SearchDrawerFormValues,
  type SearchDrawerResult,
} from "./SearchDrawerForm";
import SearchDrawerView from "./SearchDrawerView";
import { useSearchMarker } from "./useSearchMarker";

const mapRailId = "search";

const SearchDrawer = () => {
  const theme = useTheme();

  const [formError, setFormError] = useState<SearchDrawerFormError | null>(
    null,
  );

  const defaultValues = getDefaultSearchDrawerFormValues();

  const form = useForm<SearchDrawerFormValues>({
    defaultValues,
  });

  const {
    formState: { isSubmitting },
    watch,
  } = form;

  const { remove, selectedItems } = useMapRailContext();
  const { map } = useUnsafeMapContext();

  const { addMarker, removeMarker } = useSearchMarker();

  const searchMode = watch("searchMode");

  // Reset error and search marker when search mode changes
  useEffect(() => {
    removeMarker();

    setFormError(null);
  }, [removeMarker, searchMode]);

  const formValues = {
    locationCoordinates: watch("locationCoordinates"),
    bfNumber: watch("bfNumber"),
  } satisfies Record<keyof Omit<SearchDrawerFormValues, "searchMode">, unknown>;

  // Reset error and search marker if the user clears their search
  useEffect(() => {
    if (searchMode !== "locationName" && !formValues[searchMode]) {
      removeMarker();

      setFormError(null);
    }
  }, [formValues, removeMarker, searchMode]);

  // Remove the search marker when the search form isn't active
  useEffect(() => {
    if (!selectedItems.get(mapRailId)) {
      removeMarker();
    }
  }, [removeMarker, selectedItems]);

  const onError = (error: SearchDrawerFormError | null) => {
    setFormError(error);
  };

  const { activateLayer } = useActiveLayersContext();

  const { activateClickState } =
    useLayerInteractionState<AVLInteractionProperties>({
      layerId: AVL_POSITIONS_OPTION_APPLIANCES_LAYER_ID,
    });

  const onComplete = (result: SearchDrawerResult) => {
    switch (result.type) {
      case "bounds": {
        addMarker(result.value.center);

        map?.fitBounds(result.value.bounds, {
          maxZoom: 12,
          duration: theme.anim.duration.lg,
        });

        break;
      }
      case "coordinates": {
        addMarker(result.value);

        map?.flyTo({
          center: result.value,
          zoom: 12,
          duration: theme.anim.duration.lg,
        });

        break;
      }
      case "resource": {
        activateLayer<AVLPositionsLayerState>({
          id: avlDataLayer.id,
          source: "layers-drawer",
          state: {
            avlAppliancesOption: {
              isActive: true,
              opacity: 1,
            },
            avlPortableOption: {
              isActive: true,
              opacity: 1,
            },
          },
        });

        map?.flyTo({
          center: result.value.coordinates,
          zoom: 12,
          duration: theme.anim.duration.lg,
        });

        activateClickState({
          data: {
            BfNumber: result.value.resource.attributes.BfNumber,
            Brigade: result.value.resource.attributes.Brigade,
            CallSign: result.value.resource.attributes.CallSign,
            Category: result.value.resource.attributes.Category,
            Description: result.value.resource.attributes.Description,
            DeviceType: result.value.resource.attributes.DeviceType,
            Emergency: result.value.resource.attributes.Emergency,
            GpsStatus: result.value.resource.attributes.GpsStatus,
            LicenseNo: result.value.resource.attributes.LicenseNo,
            RadioId: result.value.resource.attributes.RadioId,
            ReportAge: result.value.resource.attributes.ReportAge,
            Status: result.value.resource.attributes.Status,
            TalkGroupId: result.value.resource.attributes.TalkGroupId,
          },
          lngLat: result.value.coordinates,
          featureId: `${result.value.resource.attributes.RadioId}`,
        });
      }
    }
  };

  return (
    <MapRailDrawerCell
      footer={
        searchMode !== "locationName" && (
          <Button
            disabled={isSubmitting}
            fullWidth
            form="searchDrawerForm"
            isLoading={isSubmitting}
            type="submit"
          >
            Search
          </Button>
        )
      }
      label="Search"
      onClose={() => remove({ id: mapRailId })}
    >
      <FormProvider {...form}>
        <SearchDrawerView
          formError={formError}
          onComplete={onComplete}
          onError={onError}
        />
      </FormProvider>
    </MapRailDrawerCell>
  );
};

export const searchMapRailItem = createMapRailItem({
  component: SearchDrawer,
  icon: Search,
  iconOn: Search,
  label: "Search",
  id: "search",
});

export default SearchDrawer;
