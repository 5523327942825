import { Close, IconButton, Switch } from "@app/design-system";
import { useEffect } from "react";
import styled, { css } from "styled-components";
import { useBoolean } from "usehooks-ts";
import PredictionController from "../../ui/PredictionController/PredictionController";
import { useTimeline } from "../../util/TimelineProvider/TimelineProvider";
import DrawerCellBase from "../DrawerOverlay/DrawerCellBase";
import { StyledDrawerHandle } from "../DrawerOverlay/DrawerHandle";
import { DrawerOverlayGridArea } from "../DrawerOverlay/DrawerOverlayGrid";
import PredictionControlDrawer from "./PredictionControlDrawer";

const disabledStyles = css`
  color: ${(p) => p.theme.colors.neutrals.textDisabled};
  background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  cursor: not-allowed;
`;

interface StyledPredictionControlDrawerHandleProps {
  $disabled?: boolean;
}

const StyledPredictionControlDrawerHandle = styled(
  StyledDrawerHandle,
)<StyledPredictionControlDrawerHandleProps>`
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  gap: 1rem;
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
  transition:
    color ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
    background-color
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  &:disabled {
    ${disabledStyles}
  }

  ${(p) => p.$disabled && disabledStyles}
`;

interface PredictionControlDrawerCellProps {
  defaultExpanded?: boolean;
  disabled?: boolean;
  isHidden?: boolean;
}

export const PredictionControlDrawerCell = ({
  defaultExpanded,
  disabled,
  isHidden,
}: PredictionControlDrawerCellProps) => {
  const {
    setFalse: close,
    setTrue: open,
    toggle,
    value: isExpanded,
  } = useBoolean(defaultExpanded);

  useEffect(() => {
    if (!disabled) {
      open();
    }
  }, [disabled, open]);

  return (
    <DrawerCellBase
      gridArea={DrawerOverlayGridArea.CONTROLS}
      handle={
        <StyledPredictionControlDrawerHandle
          disabled={disabled}
          onClick={toggle}
          data-testid="prediction-control-drawer-handle"
        >
          Predictions
        </StyledPredictionControlDrawerHandle>
      }
      isExpanded={isExpanded && !disabled}
      isHidden={isHidden}
    >
      <PredictionControlDrawer
        label={
          <>
            Predictions
            <IconButton
              icon={Close}
              label="Close"
              onClick={close}
              size="sm"
              variant="ghost"
              data-testid="prediction-control-drawer-close"
            />
          </>
        }
        data-testid="prediction-control-drawer"
      >
        <PredictionController data-testid="prediction-control" />
      </PredictionControlDrawer>
    </DrawerCellBase>
  );
};

interface SwitchPredictionControlDrawerCellProps {
  disabled?: boolean;
  isHidden?: boolean;
  isSelected: boolean;
  onChange: (isSelected: boolean) => void;
}

export const SwitchPredictionControlDrawerCell = ({
  disabled,
  isHidden,
  isSelected,
  onChange,
}: SwitchPredictionControlDrawerCellProps) => {
  const { timelineDispatch } = useTimeline();

  useEffect(() => {
    timelineDispatch({
      payload: {
        hours: 0,
      },
      type: "reset",
    });
  }, [isSelected, timelineDispatch]);

  return (
    <DrawerCellBase
      gridArea={DrawerOverlayGridArea.CONTROLS}
      handle={
        <StyledPredictionControlDrawerHandle
          as="div"
          $disabled={disabled}
          data-testid="prediction-control-drawer-handle"
        >
          Predictions
          <Switch
            aria-label="Activate prediction"
            isDisabled={disabled}
            isSelected={isSelected}
            onChange={onChange}
            data-testid="prediction-control-drawer-switch"
          />
        </StyledPredictionControlDrawerHandle>
      }
      isExpanded={isSelected}
      isHidden={isHidden}
    >
      <PredictionControlDrawer
        label={
          <>
            Predictions
            <Switch
              aria-label="Activate prediction"
              isDisabled={disabled}
              isSelected={isSelected}
              onChange={onChange}
              data-testid="prediction-control-drawer-switch"
            />
          </>
        }
        data-testid="prediction-control-drawer"
      >
        <PredictionController
          data-testid="prediction-control"
          disabled={!isSelected}
        />
      </PredictionControlDrawer>
    </DrawerCellBase>
  );
};
