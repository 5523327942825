import styled from "styled-components";
import type { SocialTwitter } from "../../../../.rest-hooks/types";
import formatCoordinates from "../../../config/formatCoordinates";
import { getLongFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import makeTestId from "../../../utils/makeTestId";
import TweetMatches from "../TweetMatches/TweetMatches";
import TweetMedia from "../TweetMedia/TweetMedia";
import TweetMetrics from "../TweetMetrics/TweetMetrics";
import TweetUser from "../TweetUser/TweetUser";
import TweetPostLink, { type TweetPostSize } from "./TweetPostLink";
// import {
//   getQueryFromSocialFeedQuery,
//   pluckSocialFeedQueryFromQuery,
//   SocialFeedQuery,
// } from "../../ui/SocialFeed/socialFeedQuery";
// import { useRouter } from "next/router";

const StyledTweetPost = styled.div`
  display: grid;
  gap: 12px;
`;

const StyledMain = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledMeta = styled.div`
  display: grid;
  gap: 2px;
  ${(p) => p.theme.typography.variants.footnote}
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

const StyledContent = styled.div`
  display: grid;
  gap: 8px;
  ${(p) => p.theme.typography.variants.bodyDefault}
  /* This handles the case where there are super long links that stretch the
  container if they aren't allowed to break */
  word-break: break-word;
`;

interface TweetPostProps {
  fullWidth?: boolean;
  size?: TweetPostSize;
  tweet: SocialTwitter;
}

const TweetPost = ({ fullWidth, size = "md", tweet }: TweetPostProps) => {
  // APP-663 TEMPORARILY ROLLED BACK

  // const router = useRouter();

  // const onSearch = async (socialFeedQuery: SocialFeedQuery) => {
  //   const query = getQueryFromSocialFeedQuery(socialFeedQuery);
  //   await router.push({
  //     pathname: "/social/feed",
  //     query: {
  //       ...query,
  //     },
  //   });
  // };

  // const socialFeedQuery = pluckSocialFeedQueryFromQuery(router.query);

  return (
    <StyledTweetPost data-testid="tweet-post">
      <StyledMain>
        <TweetUser
          data-testid={makeTestId("tweet-post", "user")}
          name={tweet.attributes.name}
          username={tweet.attributes.username}
          verified={tweet.attributes.verified}
        />
        <StyledContent>
          <TweetPostLink content={tweet.attributes.postText} size={size} />
          <TweetMatches
            // keywords={tweet.attributes.keywords}
            // onSearch={onSearch}
            // socialFeedQuery={socialFeedQuery}
            tags={tweet.attributes.tags}
          />
          <StyledMeta>
            <div>
              {getLongFormattedDateAndTime(tweet.attributes.postedAt * 1000)}
            </div>
            {tweet.attributes.location?.coordinates && (
              <div>
                {formatCoordinates(tweet.attributes.location.coordinates)}
              </div>
            )}
          </StyledMeta>
        </StyledContent>
      </StyledMain>
      <TweetMetrics
        fullWidth={fullWidth}
        likeCount={tweet.attributes.likeCount}
        replyCount={tweet.attributes.replyCount}
        retweetCount={tweet.attributes.retweetCount}
      />
      {tweet.attributes.media && <TweetMedia media={tweet.attributes.media} />}
    </StyledTweetPost>
  );
};

export default TweetPost;
