import { Button, type ButtonProps } from "@app/design-system";
import { useBoolean } from "usehooks-ts";
import type { SocialTwitter } from "../../../../.rest-hooks/types";
import ValidateTweetModalForm from "../../social/ModalTweetDetail/ValidateTweetModalForm";
import type { PostLink } from "../../social/SocialFeed/socialFeedQuery";

type ValidateTweetButtonProps = Pick<ButtonProps, "fullWidth" | "size"> & {
  link: PostLink;
  tweet: SocialTwitter;
};

const ValidateTweetButton = ({
  fullWidth,
  link,
  size,
  tweet,
}: ValidateTweetButtonProps) => {
  const { setFalse: close, setTrue: open, value: isOpen } = useBoolean();

  const isValidated = !!tweet.attributes.validated;

  return (
    <>
      <Button
        disabled={isValidated}
        fullWidth={fullWidth}
        onClick={open}
        size={size}
      >
        Validate
      </Button>
      <ValidateTweetModalForm
        isOpen={isOpen}
        link={link}
        onClose={close}
        tweet={tweet}
      />
    </>
  );
};

export default ValidateTweetButton;
