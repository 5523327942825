import { useStableIdentity } from "@kablamo/kerosene-ui";
import type { ExpressionSpecification } from "maplibre-gl";
import { useEffect, useRef } from "react";
import { MapLevel } from "../../../config/layers/layers";
import { EMDASH } from "../../../lib/strings";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import InterstateIncidentPopup from "../../popup/InterstateIncidentPopup/InterstateIncidentPopup";
import PreviewSpatialPopup from "../../popup/PreviewSpatialPopup/PreviewSpatialPopup";
import useMapContext from "../Map/useMapContext";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";
import {
  INTERSTATE_INCIDENTS_ICON_ID,
  INTERSTATE_INCIDENTS_LAYER_ID,
  INTERSTATE_INCIDENTS_SOURCE_ID,
} from "./constants";
import { getPropertiesFromFeature } from "./interactions";
import useInterstateIncidentsQuery, {
  type AusGeocode,
} from "./useInterstateIncidentsQuery";

const geocodeFilter = (geocodes: AusGeocode[]): ExpressionSpecification => {
  return [
    "in",
    ["get", "ISO3166-2", ["get", "geocodes"]],
    ["literal", geocodes],
  ];
};

export interface InterstateIncidentsLayerProps {
  opacity?: number;
  sourceUrl: string;
  showGeocodes: AusGeocode[];
}

const InterstateIncidentsLayer = ({
  opacity = 1,
  sourceUrl,
  showGeocodes: nextShowGeocodes,
}: InterstateIncidentsLayerProps) => {
  const showGeocodes = useStableIdentity(nextShowGeocodes);

  // Capture the initial value to be used on the first render. Required because the map layer is
  // added when the load image promise resolves which often occurs after all effects complete.
  const initialGeocodesRef = useRef(showGeocodes);

  const map = useMapContext();
  const { data } = useInterstateIncidentsQuery({ url: sourceUrl });

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(INTERSTATE_INCIDENTS_SOURCE_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    loadImage({
      imageId: INTERSTATE_INCIDENTS_ICON_ID,
      map,
      pixelRatio: 4,
      signal: controller.signal,
      src: `/icons/${INTERSTATE_INCIDENTS_ICON_ID}.png`,
    })
      .then(() => {
        map.addLayer(
          {
            id: INTERSTATE_INCIDENTS_LAYER_ID,
            type: "symbol",
            source: INTERSTATE_INCIDENTS_SOURCE_ID,
            layout: {
              "icon-image": INTERSTATE_INCIDENTS_ICON_ID,
              "icon-allow-overlap": true,
              "icon-optional": false,
            },
            filter: geocodeFilter(initialGeocodesRef.current),
          },
          MapLevel.SYMBOLS,
        );
      })
      .catch(catchAbortError);

    return () => {
      controller.abort();

      if (map.getLayer(INTERSTATE_INCIDENTS_LAYER_ID)) {
        map.removeLayer(INTERSTATE_INCIDENTS_LAYER_ID);
      }
      if (map.getSource(INTERSTATE_INCIDENTS_SOURCE_ID)) {
        map.removeSource(INTERSTATE_INCIDENTS_SOURCE_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    const source = map.getSource(INTERSTATE_INCIDENTS_SOURCE_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    const layer = map.getLayer(INTERSTATE_INCIDENTS_LAYER_ID);
    if (layer) {
      map.setFilter(layer.id, geocodeFilter(showGeocodes));
    }
  }, [map, showGeocodes]);

  useEffect(() => {
    map.setPaintProperty(
      INTERSTATE_INCIDENTS_LAYER_ID,
      "icon-opacity",
      opacity,
    );
  }, [map, opacity]);

  const {
    clickedState,
    deactivateClickState,
    hoveredState,
    deactivateHoverState,
  } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: INTERSTATE_INCIDENTS_LAYER_ID,
  });

  return (
    <>
      {hoveredState.properties?.data && (
        <PreviewSpatialPopup
          offset="lg"
          onClose={deactivateHoverState}
          state={hoveredState}
          type="hover"
        >
          {hoveredState.properties.data.title || EMDASH}
        </PreviewSpatialPopup>
      )}
      {clickedState.properties?.data && (
        <InterstateIncidentPopup
          title={clickedState.properties.data.title || EMDASH}
          descriptionRawHtml={
            clickedState.properties.data.description || EMDASH
          }
          onClose={deactivateClickState}
          state={clickedState}
          type="click"
        />
      )}
    </>
  );
};

export default InterstateIncidentsLayer;
