import { FieldGrid, Text } from "@app/design-system";
import type { AVLInteractionProperties } from "../../map/AVLPositions/interactions";

export interface AVLPopupSummaryProps {
  properties: AVLInteractionProperties;
}

const formatValue = (value: string | number | undefined): string => {
  if (typeof value === "number") {
    return String(value);
  }

  return value || "—";
};

const AVLPopupSummary = ({ properties }: AVLPopupSummaryProps) => {
  return (
    <>
      <Text size="subtitleMd">{properties.data.CallSign}</Text>
      <FieldGrid>
        <FieldGrid.Item label="Report age:">
          {formatValue(properties.data.ReportAge)}
        </FieldGrid.Item>
      </FieldGrid>
    </>
  );
};

export default AVLPopupSummary;
