import {
  DEFAULT_ERROR_MESSAGE,
  PlaceholderCard,
  weakStyles,
} from "@app/design-system";
import { keepPreviousData } from "@tanstack/react-query";
import styled from "styled-components";
import { useGetSocialTweetsId } from "../../../../.rest-hooks/social-twitter";
import useQueryAsyncValue from "../../../hooks/useQueryAsyncValue";
import { getPostLink } from "../../social/SocialFeed/socialFeedQuery";
import SkeletonSocialPost from "../../social/SocialPost/SkeletonSocialPost";
import SocialPost from "../../social/SocialPost/SocialPost";
import MapRailDrawerCell from "../../ui/MapRail/MapRailDrawerCell";
import AsyncView from "../../util/AsyncView/AsyncView";
import DrawerContent from "../DrawerOverlay/DrawerContent";
import SocialDrawerFooter from "./SocialDrawerFooter";

const StyledCard = styled.div`
  ${weakStyles.border.base}
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
`;

interface SocialDrawerCellProps {
  incidentId?: string;
  onClose: () => void;
  tweetId: string;
}

const SocialDrawerCell = ({
  incidentId,
  onClose,
  tweetId,
}: SocialDrawerCellProps) => {
  const tweetQuery = useGetSocialTweetsId(tweetId, {
    query: {
      enabled: !!tweetId,
      select: (data) => data.data.data,
      placeholderData: keepPreviousData,
    },
  });

  const tweetResult = useQueryAsyncValue({ query: tweetQuery });

  return (
    <MapRailDrawerCell
      footer={
        <AsyncView {...tweetResult} errorState={null} loadingState={null}>
          {(tweet) => {
            const link = getPostLink({ incidentId, tweet });

            return <SocialDrawerFooter link={link} tweet={tweet} />;
          }}
        </AsyncView>
      }
      label="Social post"
      onClose={onClose}
    >
      <DrawerContent>
        <AsyncView
          {...tweetResult}
          errorState={
            <PlaceholderCard
              size="sm"
              title="Unable to load post"
              subtitle={DEFAULT_ERROR_MESSAGE}
              status="error"
              variant="border"
            />
          }
          loadingState={<SkeletonSocialPost />}
        >
          {(tweet) => {
            const link = getPostLink({ incidentId, tweet });

            return (
              <StyledCard>
                <SocialPost
                  link={link}
                  primaryIncidentId={incidentId}
                  showPrimaryIncident
                  size="sm"
                  tweet={tweet}
                  data-testid="social-drawer"
                />
              </StyledCard>
            );
          }}
        </AsyncView>
      </DrawerContent>
    </MapRailDrawerCell>
  );
};

export default SocialDrawerCell;
