import { powerLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import { PowerInfrastructureLayer } from "./PowerInfrastructureLayer";

const PowerInfrastructure = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(powerLayer.id)) {
    return null;
  }

  const state = getLayerState(powerLayer.id);

  return <PowerInfrastructureLayer opacity={state?.opacity} />;
};

export default PowerInfrastructure;
