import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";

const TOTAL_FUEL_LOAD_QUERY = buildMapboxBBoxQuery({
  SERVICE: "WMS",
  REQUEST: "GetMap",
  FORMAT: "image/png",
  TRANSPARENT: "TRUE",
  STYLES: "",
  VERSION: "1.3.0",
  LAYERS: "1",
  WIDTH: "512",
  HEIGHT: "512",
  CRS: "EPSG:3857",
});

const TOTAL_FUEL_LOAD_ID = "totalFuelLoad";

interface TotalFuelLoadLayerProps {
  opacity?: number;
}

const TotalFuelLoadLayer = ({ opacity }: TotalFuelLoadLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(TOTAL_FUEL_LOAD_ID, {
      type: "raster",
      tileSize: 512,
      tiles: [
        `${getMapServerProxyBasepath()}/arcgis/services/Reference/PredictedFuelLoad/MapServer/WMSServer?${TOTAL_FUEL_LOAD_QUERY}`,
      ],
    });

    map.addLayer(
      {
        id: TOTAL_FUEL_LOAD_ID,
        type: "raster",
        source: TOTAL_FUEL_LOAD_ID,
        minzoom: 8,
        paint: {
          "raster-opacity": 0.8,
        },
      },
      MapLevel.BACKGROUND,
    );

    return () => {
      if (map.getLayer(TOTAL_FUEL_LOAD_ID)) {
        map.removeLayer(TOTAL_FUEL_LOAD_ID);
      }
      if (map.getSource(TOTAL_FUEL_LOAD_ID)) {
        map.removeSource(TOTAL_FUEL_LOAD_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    map?.setPaintProperty(TOTAL_FUEL_LOAD_ID, "raster-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default TotalFuelLoadLayer;
