import { FieldGrid, Text } from "@app/design-system";
import styled from "styled-components";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import type { SiteInformationInteractionProperties } from "../../map/SiteInformation/interactions";

const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export interface SiteInformationPopupDetailProps {
  properties: SiteInformationInteractionProperties;
}

const SiteInformationPopupDetail = ({
  properties,
}: SiteInformationPopupDetailProps) => {
  return (
    <StyledBody>
      <Text size="subtitleMd">Asbestos</Text>
      <FieldGrid>
        <FieldGrid.Item label="Address:">
          {properties.data.foundAddress || <FallbackElement />}
        </FieldGrid.Item>
        <FieldGrid.Item label="Less than 0.01 fibres/ml:">
          {properties.data.BelowPoint01FibresPerML || <FallbackElement />}
        </FieldGrid.Item>
        <FieldGrid.Item label="Chrysotile:">
          {properties.data.Chrysotile || <FallbackElement />}
        </FieldGrid.Item>
        <FieldGrid.Item label="Date tested:">
          {properties.data.TestDate ? (
            getFormattedDateAndTime(parseInt(properties.data.TestDate, 10))
          ) : (
            <FallbackElement />
          )}
        </FieldGrid.Item>
      </FieldGrid>
    </StyledBody>
  );
};

export default SiteInformationPopupDetail;
