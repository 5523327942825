import { Text } from "@app/design-system";
import styled from "styled-components";
import makeTestId from "../../../utils/makeTestId";
import TweetVerifiedIcon from "../TweetVerifiedIcon/TweetVerifiedIcon";

const StyledUsername = styled.div`
  display: flex;
  align-items: center;
`;

const StyledUser = styled.div`
  display: grid;
`;

interface TweetUserProps {
  name: string | undefined;
  username: string | undefined;
  verified: boolean | undefined;
  "data-testid"?: string;
}

const TweetUser = ({
  name,
  username,
  verified,
  "data-testid": dataTestId,
}: TweetUserProps) => {
  const mdash = <>&mdash;</>;

  return (
    <StyledUser>
      <Text data-testid={makeTestId(dataTestId, "name")} size="subtitleMd">
        {name || mdash}
      </Text>
      <StyledUsername>
        <Text
          data-testid={makeTestId(dataTestId, "username")}
          size="bodyDefault"
        >
          {(username && `@${username}`) || mdash}
        </Text>
        {verified && <TweetVerifiedIcon verified={verified} />}
      </StyledUsername>
    </StyledUser>
  );
};

export default TweetUser;
