import { FieldGrid, FieldGroup } from "@app/design-system";
import type { Prediction } from "../../../../.rest-hooks/types";
import { useTimeline } from "../../util/TimelineProvider/TimelineProvider";
import ImpactProbabilityIndicator from "./ImpactProbabilityIndicator";

interface ImpactProbabilitiesSummaryProps {
  prediction: Prediction;
}

const ImpactProbabilitiesSummary = ({
  prediction,
}: ImpactProbabilitiesSummaryProps) => {
  const {
    timelineState: { hours },
  } = useTimeline();

  return (
    <FieldGroup size="sm" title={`Impact probabilities +${hours} hrs`}>
      <FieldGrid layout="compact">
        <ImpactProbabilityIndicator
          burnProbabilityType="high"
          prediction={prediction}
        />
        <ImpactProbabilityIndicator
          burnProbabilityType="med"
          prediction={prediction}
        />
        <ImpactProbabilityIndicator
          burnProbabilityType="low"
          prediction={prediction}
        />
      </FieldGrid>
    </FieldGroup>
  );
};

export default ImpactProbabilitiesSummary;
