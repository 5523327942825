import { governmentRadioNetworkLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import GovernmentRadioNetworkLayer from "./GovernmentRadioNetworkLayer";

const GovernmentRadioNetwork = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(governmentRadioNetworkLayer.id)) {
    return null;
  }

  const state = getLayerState(governmentRadioNetworkLayer.id);

  return <GovernmentRadioNetworkLayer opacity={state?.opacity} />;
};

export default GovernmentRadioNetwork;
