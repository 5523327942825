import { useIsMinWidth } from "@app/design-system";
import styled from "styled-components";
import FixedLayout from "../FixedLayout/FixedLayout";

const StyledMaps = styled.div`
  position: absolute;
  inset: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 0.25rem;
  background-color: ${(p) => p.theme.colors.neutrals.background};
`;

interface MapLayoutProps {
  bottomTabs?: React.ReactNode;
  children?: React.ReactNode;
  drawerOverlay?: React.ReactNode;
  subheader?: React.ReactNode;
}

const MapLayout = ({
  bottomTabs,
  children,
  drawerOverlay,
  subheader,
}: MapLayoutProps) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  return (
    <FixedLayout>
      <StyledMaps>{children}</StyledMaps>
      {subheader}
      {drawerOverlay}
      {!isTabletLandscapeAndAbove && bottomTabs}
    </FixedLayout>
  );
};

export default MapLayout;
