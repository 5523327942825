import type { FeatureCollection } from "geojson";
import { useEffect } from "react";
import { useGetResources } from "../../../../.rest-hooks/resources";
import type { ResourceAircraft } from "../../../../.rest-hooks/types/resources.yml/resourceAircraft";
import getAircraftResourceCategoryStatus from "../../../config/getAircraftResourceCategoryStatus";
import { REFETCH_INTERVAL_FREQUENT } from "../../../config/refetch";
import useMapContext from "../Map/useMapContext";
import { isGeoJsonSource } from "../types";
import {
  AIRCRAFT_POSITIONS_SOURCE_ID,
  MAX_AIRCRAFT_PER_PAGE,
} from "./constants";

const useAircraftPositionsMapData = () => {
  const map = useMapContext();

  const { data: resourceData } = useGetResources(
    {
      type: "aircraft",
      page: 1,
      perPage: MAX_AIRCRAFT_PER_PAGE,
    },
    {
      query: {
        refetchInterval: REFETCH_INTERVAL_FREQUENT,
      },
    },
  );

  const resources = resourceData?.data.data as ResourceAircraft[];

  useEffect(() => {
    if (!resources) {
      return;
    }

    const data: FeatureCollection = {
      type: "FeatureCollection",
      features: resources.map((resource) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates:
              resource.attributes.currentLocation?.location?.coordinates ?? [],
          },
          properties: {
            assignment: resource.attributes.assignment,
            callSign: resource.attributes.callSign,
            categoryStatus: getAircraftResourceCategoryStatus(
              resource.attributes.category,
              resource.attributes.status,
            ),
            id: resource.id,
            model: resource.attributes.model,
            reportAge: resource.attributes.currentLocation?.transmittedAt,
            speed: resource.attributes.currentLocation?.speed,
            status: resource.attributes.status,
          },
        };
      }),
    };

    const source = map.getSource(AIRCRAFT_POSITIONS_SOURCE_ID);

    if (isGeoJsonSource(source)) {
      source.setData(data);
    }
  }, [map, resources]);
};

export default useAircraftPositionsMapData;
