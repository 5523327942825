import { neighbourhoodSaferPlacesLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import NeighbourhoodSaferPlacesLayer from "./NeighbourhoodSaferPlacesLayer";

const NeighbourhoodSaferPlaces = () => {
  const { isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(neighbourhoodSaferPlacesLayer.id)) {
    return null;
  }

  return <NeighbourhoodSaferPlacesLayer />;
};

export default NeighbourhoodSaferPlaces;
