import type { Feature } from "geojson";
import { useEffect } from "react";
import { MapLevel } from "../../../../config/layers/layers";
import SpatialPopup from "../../../popup/SpatialPopup/SpatialPopup";
import useMapContext from "../../Map/useMapContext";
import useLayerInteractions from "../../MapInteractions/useLayerInteractions";
import { isGeoJsonSource } from "../../types";
import { useGetAerialImageryMeta } from "../useGetAerialImageryMeta";
import type { AerialImageryFeature, AerialImageryLayer } from "../utils";
import AerialImageryPopupView from "./AerialImageryPopupView";

interface AerialImageryPointCollectionProps {
  aerialImageryLayer: AerialImageryLayer;
  layerId: string;
}

// TODO: May need custom matching between polygon and points here, but so far it's never called
const getPropertiesFromFeature = (
  featureBase: Feature,
  event: mapboxgl.MapMouseEvent | maplibregl.MapLayerMouseEvent,
) => {
  // We can't guarantee the types here, so do a quick cast and verify
  const feature = featureBase as Partial<AerialImageryFeature>;

  return feature?.properties?.media_id
    ? {
        featureId: feature?.properties?.media_id.toString(),
        lngLat: event.lngLat,
        feature: feature as AerialImageryFeature,
      }
    : null;
};

const AerialImageryPopupLayer = ({
  aerialImageryLayer,
  ...props
}: AerialImageryPointCollectionProps) => {
  const map = useMapContext();
  const layerId = `${props.layerId}-interactive`;

  const { clickedState, deactivateClickState } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId,
  });

  const { data, isPending } = useGetAerialImageryMeta({
    enabled: clickedState.isActive,
    aerialImageryLayer,
  });

  useEffect(() => {
    const controller = new AbortController();
    controller.signal.addEventListener("abort", () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }

      if (map.getSource(layerId)) {
        map.removeSource(layerId);
      }
    });

    if (!map.getSource(layerId)) {
      map.addSource(layerId, {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
    }

    if (!map.getLayer(layerId)) {
      map.addLayer(
        {
          id: layerId,
          type: "fill",
          source: layerId,
          paint: { "fill-color": "#AAA", "fill-opacity": 0 },
        },
        MapLevel.FS_BACKGROUND,
      );
    }

    return () => controller.abort();
  }, [layerId, map]);

  const raw = data?.raw;

  useEffect(() => {
    const source = map?.getSource(layerId);

    if (isGeoJsonSource(source) && raw) {
      source.setData(raw);
    }
  }, [layerId, map, raw]);

  return (
    <>
      {clickedState.properties && (
        <SpatialPopup
          isOpen={clickedState.isActive}
          lngLat={clickedState.properties.lngLat}
          popupConfig={{
            id: clickedState.id,
            offset: 0,
            size: "lg",
            type: "click",
          }}
          onClose={deactivateClickState}
        >
          <AerialImageryPopupView
            feature={clickedState.properties.feature}
            isPending={isPending}
            onClose={deactivateClickState}
            title={aerialImageryLayer.label}
          />
        </SpatialPopup>
      )}
    </>
  );
};

export default AerialImageryPopupLayer;
