import { AnchorButton } from "@app/design-system";
import QueryParams from "../../../config/queryParams";
import type { IncidentCtaProps } from "../../drawers/IncidentsDrawerCell/IncidentCard";
import TaskLink from "../../util/TaskLink/TaskLink";

const ViewIncidentButton = ({
  fullWidth,
  incidentId,
  size,
}: IncidentCtaProps) => {
  return (
    <TaskLink
      as={`/incidents/${incidentId}`}
      href={{
        pathname: "/incidents/[id]",
        query: { [QueryParams.INCIDENT_ID]: incidentId },
      }}
      legacyBehavior
    >
      <AnchorButton fullWidth={fullWidth} size={size}>
        View incident
      </AnchorButton>
    </TaskLink>
  );
};

export default ViewIncidentButton;
