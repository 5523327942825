import { IconButton, Pause, Play } from "@app/design-system";
import { SECOND } from "@kablamo/kerosene";
import React, { useState } from "react";
import styled from "styled-components";
import { useInterval } from "usehooks-ts";
import makeTestId from "../../../utils/makeTestId";
import HoursSlider, {
  MAX_SLIDER_VALUE,
  MIN_SLIDER_VALUE,
} from "../HoursControls/HoursSlider";

const StyledCompactHoursControls = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 16px 24px;
`;

const StyledSliderContainer = styled.div`
  flex: 1;
`;

interface CompactHoursControlsProps {
  hours: number;
  onHoursChange: (hours: number) => void;
  startDate: Date;
  "data-testid"?: string;
}

const CompactHoursControls = ({
  hours,
  onHoursChange,
  startDate,
  "data-testid": dataTestId,
}: CompactHoursControlsProps) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const incrementHours = () => {
    if (hours < MAX_SLIDER_VALUE) {
      onHoursChange(hours + 1);
    } else {
      onHoursChange(MIN_SLIDER_VALUE);
    }
  };

  useInterval(
    () => {
      incrementHours();
    },
    isPlaying ? SECOND : null,
  );

  return (
    <StyledCompactHoursControls>
      <IconButton
        icon={isPlaying ? Pause : Play}
        label="play-pause"
        size="sm"
        variant="secondary"
        onClick={() => setIsPlaying(!isPlaying)}
        data-testid={makeTestId(dataTestId, "loop")}
      />
      <StyledSliderContainer>
        <HoursSlider
          onChange={onHoursChange}
          startDate={startDate}
          value={hours}
          data-testid={makeTestId(dataTestId, "slider")}
        />
      </StyledSliderContainer>
    </StyledCompactHoursControls>
  );
};

export default CompactHoursControls;
