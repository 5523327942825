import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";

const GAS_COLOUR = "#ddc08a";
const OIL_COLOUR = "#915624";
const TEXT_COLOUR = "#000";

const GAS_PIPELINETYPE = 2;
const OIL_PIPELINETYPE = 3;

const OIL_AND_GAS_ID = "oil-and-gas-pipelines";
const OIL_AND_GAS_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/Athena/MapServer/1/query`;

const QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: "PIPELINETYPE, GENERALNAME",
  },
};

interface OilAndGasPipelinesLayerProps {
  opacity?: number;
}

const OilAndGasPipelinesLayer = ({
  opacity = 1,
}: OilAndGasPipelinesLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(OIL_AND_GAS_URL, QUERY_OPTIONS);

  useEffect(() => {
    map.addSource(OIL_AND_GAS_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: `${OIL_AND_GAS_ID}-gas`,
        type: "line",
        source: OIL_AND_GAS_ID,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": GAS_COLOUR,
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
        filter: ["==", "PIPELINETYPE", GAS_PIPELINETYPE],
      },
      MapLevel.LINES,
    );

    map.addLayer(
      {
        id: `${OIL_AND_GAS_ID}-oil`,
        type: "line",
        source: OIL_AND_GAS_ID,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": OIL_COLOUR,
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
        filter: ["==", "PIPELINETYPE", OIL_PIPELINETYPE],
      },
      MapLevel.LINES,
    );

    map.addLayer(
      {
        id: `${OIL_AND_GAS_ID}-text`,
        type: "symbol",
        source: OIL_AND_GAS_ID,
        layout: {
          "text-field": ["get", "GENERALNAME"],
          "text-size": 12,
          "text-offset": [0, -1],
          "text-anchor": "center",
          "symbol-placement": "line",
          "symbol-spacing": 400,
        },
        paint: {
          "text-color": TEXT_COLOUR,
        },
      },
      MapLevel.LINES,
    );

    return () => {
      if (map.getLayer(`${OIL_AND_GAS_ID}-gas`)) {
        map.removeLayer(`${OIL_AND_GAS_ID}-gas`);
      }
      if (map.getLayer(`${OIL_AND_GAS_ID}-oil`)) {
        map.removeLayer(`${OIL_AND_GAS_ID}-oil`);
      }
      if (map.getLayer(`${OIL_AND_GAS_ID}-text`)) {
        map.removeLayer(`${OIL_AND_GAS_ID}-text`);
      }
      if (map.getSource(OIL_AND_GAS_ID)) {
        map.removeSource(OIL_AND_GAS_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    const source = map?.getSource(OIL_AND_GAS_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(`${OIL_AND_GAS_ID}-gas`, "line-opacity", opacity);
    map.setPaintProperty(`${OIL_AND_GAS_ID}-oil`, "line-opacity", opacity);
    map.setPaintProperty(`${OIL_AND_GAS_ID}-text`, "text-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default OilAndGasPipelinesLayer;
