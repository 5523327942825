import mapboxgl from "mapbox-gl";
import getConfig from "next/config";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import useUnsafeMapContext from "../../../map/Map/useUnsafeMapContext";
import { isGeoJsonSource } from "../../../map/types";

const StyledBox = styled.div`
  background-color: ${(p) => p.theme.colors.neutrals.background};
  padding: 0.125rem;
  width: 8rem;
  aspect-ratio: 4 / 3;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  box-shadow: ${(p) => p.theme.boxShadows.md};
`;

const StyledInner = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceDivider};
  overflow: hidden;
  border-radius: calc(${(p) => p.theme.borderRadiuses.lg}px - 0.125rem);

  .mapboxgl-ctrl-logo {
    display: none;
  }
`;

const lineColor = "#408BFF";
const lineWidth = 1;
const lineOpacity = 1;
const fillColor = "#004ABC";
const fillOpacity = 0.2;

const BASE_MAP_TILES_URL = [
  "https://1.aerial.maps.ls.hereapi.com/maptile/2.1/basetile/newest/terrain.day/{z}/{x}/{y}/256/png8?apiKey=",
  "https://2.aerial.maps.ls.hereapi.com/maptile/2.1/basetile/newest/terrain.day/{z}/{x}/{y}/256/png8?apiKey=",
  "https://3.aerial.maps.ls.hereapi.com/maptile/2.1/basetile/newest/terrain.day/{z}/{x}/{y}/256/png8?apiKey=",
  "https://4.aerial.maps.ls.hereapi.com/maptile/2.1/basetile/newest/terrain.day/{z}/{x}/{y}/256/png8?apiKey=",
];

const { publicRuntimeConfig } = getConfig();
const { NEXT_HERE_API_KEY, NEXT_MAPBOX_ACCESS_TOKEN } = publicRuntimeConfig;

const calcBoundPoints = (
  bounds: mapboxgl.LngLatBounds | maplibregl.LngLatBounds,
) => {
  const ne = bounds._ne;
  const sw = bounds._sw;
  return [
    [
      [ne.lng, ne.lat],
      [sw.lng, ne.lat],
      [sw.lng, sw.lat],
      [ne.lng, sw.lat],
      [ne.lng, ne.lat],
    ],
  ];
};

const Minimap = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { map } = useUnsafeMapContext();

  useEffect(() => {
    if (!containerRef.current || !map) {
      return;
    }
    const miniMap = new mapboxgl.Map({
      accessToken: NEXT_MAPBOX_ACCESS_TOKEN,
      attributionControl: false,
      container: containerRef.current,
      bounds: [140.803699, -37.59526, 153.745594, -28.050849],
      style: {
        version: 8,
        sources: {
          "basemap-raster-tiles": {
            type: "raster",
            tiles: BASE_MAP_TILES_URL.map(
              (url) => `${url}${NEXT_HERE_API_KEY}`,
            ),
            tileSize: 256,
          },
        },
        layers: [
          {
            id: "base-map-tiles",
            type: "raster",
            source: "basemap-raster-tiles",
          },
        ],
      },
    });

    miniMap.on("load", () => {
      miniMap.dragPan.disable();
      miniMap.scrollZoom.disable();
      miniMap.boxZoom.disable();
      miniMap.dragRotate.disable();
      miniMap.keyboard.disable();
      miniMap.doubleClickZoom.disable();
      miniMap.touchZoomRotate.disable();

      miniMap.addSource("trackingRect", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {
            name: "trackingRect",
          },
          geometry: {
            type: "Polygon",
            coordinates: calcBoundPoints(
              map?.getBounds() ?? miniMap.getBounds(),
            ),
          },
        },
      });

      miniMap.addLayer({
        id: "trackingRectOutline",
        type: "line",
        source: "trackingRect",
        layout: {},
        paint: {
          "line-color": lineColor,
          "line-width": lineWidth,
          "line-opacity": lineOpacity,
        },
      });

      miniMap.addLayer({
        id: "trackingRectFill",
        type: "fill",
        source: "trackingRect",
        layout: {},
        paint: {
          "fill-color": fillColor,
          "fill-opacity": fillOpacity,
        },
      });
    });

    const mapMove = () => {
      const source = miniMap.getSource("trackingRect");
      if (isGeoJsonSource(source) && map) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error _data does exist, it's just not on the TS type definition.
        const data = source._data;
        data.geometry.coordinates = calcBoundPoints(map.getBounds());
        source.setData(data);
      }
    };

    map?.on("move", mapMove);

    let dragging = false;
    const mouseMove = (e: mapboxgl.MapMouseEvent) => {
      if (dragging) {
        map?.jumpTo({ center: [e.lngLat.lng, e.lngLat.lat] });
      }
    };
    const mouseDown = (e: mapboxgl.MapMouseEvent) => {
      dragging = true;
      map?.jumpTo({ center: [e.lngLat.lng, e.lngLat.lat] });
    };
    const mouseUp = () => {
      dragging = false;
    };

    miniMap.on("mousemove", mouseMove);
    miniMap.on("mousedown", mouseDown);
    miniMap.on("mouseup", mouseUp);
    miniMap.on("touchmove", mouseMove);
    miniMap.on("touchstart", mouseDown);
    miniMap.on("touchend", mouseUp);

    return () => {
      map?.off("move", mapMove);
      miniMap.off("mousemove", mouseMove);
      miniMap.off("mousedown", mouseDown);
      miniMap.off("mouseup", mouseUp);
      miniMap.off("touchmove", mouseMove);
      miniMap.off("touchstart", mouseDown);
      miniMap.off("touchend", mouseUp);
    };
  }, [map]);

  return (
    <StyledBox>
      <StyledInner className="mapboxgl-ctrl" ref={containerRef} />
    </StyledBox>
  );
};

export default Minimap;
