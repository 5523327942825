import {
  IconButton,
  PopoverDialogTrigger,
  Tune,
  TuneAdjusted,
} from "@app/design-system";
import isEqual from "lodash/isEqual";
import {
  DEFAULT_GLOBAL_PREDICTIONS_STATE,
  usePredictions,
} from "../PredictionsProvider/PredictionsProvider";
import GlobalPredictionControlForm, {
  type GlobalPredictionControlFormChangeHandler,
} from "./GlobalPredictionControlForm";

interface GlobalPredictionControlPopoverTriggerProps {
  "data-testid"?: string;
}

const GlobalPredictionControlPopoverTrigger = ({
  "data-testid": dataTestId,
}: GlobalPredictionControlPopoverTriggerProps) => {
  const {
    predictionsState: { globalState },
    setGlobalPredictionsState,
  } = usePredictions();

  const isAdjusted = !isEqual(DEFAULT_GLOBAL_PREDICTIONS_STATE, globalState);

  const onChange: GlobalPredictionControlFormChangeHandler = (value) => {
    setGlobalPredictionsState({ globalState: { ...value } });
  };

  const onReset = () => {
    setGlobalPredictionsState({
      globalState: { ...DEFAULT_GLOBAL_PREDICTIONS_STATE },
    });
  };

  return (
    <PopoverDialogTrigger
      renderTrigger={(props) => (
        <IconButton
          {...props}
          icon={isAdjusted ? TuneAdjusted : Tune}
          label="Settings"
          size="sm"
          variant="secondary"
        />
      )}
      title="Prediction settings"
      data-testid={dataTestId}
    >
      <GlobalPredictionControlForm
        onChange={onChange}
        onReset={onReset}
        value={globalState}
      />
    </PopoverDialogTrigger>
  );
};

export default GlobalPredictionControlPopoverTrigger;
