import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isMultiPolygonFeature, isPolygonFeature } from "../types";
import type { BurntAreaCurrentFireSeasonFeature } from "./gis";

export interface BurntAreaCurrentFireSeasonInteractionProperties
  extends FeatureInteractionProperties {
  data: BurntAreaCurrentFireSeasonData;
  objectId: string;
}

export interface BurntAreaCurrentFireSeasonData {
  burntArea?: number;
  date?: number;
  incidentName?: string;
}

export type BurntAreaCurrentFireSeasonInteractionState =
  FeatureInteractionState<BurntAreaCurrentFireSeasonInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  BurntAreaCurrentFireSeasonInteractionProperties
> = (feature, event) => {
  if (!isMultiPolygonFeature(feature) && !isPolygonFeature(feature)) {
    return null;
  }

  const { lngLat } = event;
  const burntAreaFeature = feature as BurntAreaCurrentFireSeasonFeature;

  return {
    data: {
      burntArea: burntAreaFeature.properties.BurntArea ?? undefined,
      date: burntAreaFeature.properties.CAPTUREDDATETIME ?? undefined,
      incidentName: burntAreaFeature.properties.IncidentName ?? undefined,
    },
    featureId: burntAreaFeature.properties.OBJECTID,
    objectId: burntAreaFeature.properties.OBJECTID,
    lngLat,
  };
};
