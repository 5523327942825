import {
  AircraftAvailable,
  AircraftDeployed,
  AircraftNotAvailable,
  AircraftNotKnown,
  AircraftPlannedDispatch,
  StaticIconWrapper,
} from "@app/design-system";
import styled from "styled-components";
import type { ResourceAircraftStatus } from "../../../../.rest-hooks/types/resources.yml/resourceAircraftStatus";

const StyledHeader = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  ${(p) => p.theme.typography.variants.eyebrowDefault};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  line-height: 2rem;
`;

const AIRCRAFT_STATUS: Record<ResourceAircraftStatus, ReactSVGComponent> = {
  available: AircraftAvailable,
  limited: AircraftAvailable,
  deployed: AircraftDeployed,
  "planned-dispatch": AircraftPlannedDispatch,
  standby: AircraftPlannedDispatch,
  "standby-amended": AircraftPlannedDispatch,
  "standby-temp-asset": AircraftPlannedDispatch,
  unavailable: AircraftNotAvailable,
  "unserviceable-airdesk": AircraftNotAvailable,
  "unserviceable-restricted": AircraftNotAvailable,
  "unserviceable-standby": AircraftNotAvailable,
  "unserviceable-stood-down": AircraftNotAvailable,
  unserviceable: AircraftNotAvailable,
  "not-known": AircraftNotKnown,
};

const getAircraftStatusIcon = (status: ResourceAircraftStatus | undefined) => {
  if (!status) return AIRCRAFT_STATUS["not-known"];

  if (Object.keys(AIRCRAFT_STATUS).includes(status)) {
    return AIRCRAFT_STATUS[status];
  }

  return AIRCRAFT_STATUS["not-known"];
};

interface AircraftPositionsPopupHeaderProps {
  status: ResourceAircraftStatus | undefined;
}

const AircraftPositionsPopupHeader = ({
  status,
}: AircraftPositionsPopupHeaderProps) => {
  let formattedStatus: string;

  if (status) {
    formattedStatus = status.replaceAll("-", " ").toUpperCase();
  } else {
    formattedStatus = "Not known";
  }
  return (
    <StyledHeader>
      <StaticIconWrapper icon={getAircraftStatusIcon(status)} size="sm" />
      {formattedStatus}
    </StyledHeader>
  );
};

export default AircraftPositionsPopupHeader;
