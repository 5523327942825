import { Button, TabFooter } from "@app/design-system";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled, { useTheme } from "styled-components";
import { avlDataLayer } from "../../../config/layers/layers";
import type { AVLPositionsLayerState } from "../../map/AVLPositions/AVLPositions";
import useUnsafeMapContext from "../../map/Map/useUnsafeMapContext";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import {
  getDefaultSearchDrawerFormValues,
  type SearchDrawerFormError,
  type SearchDrawerFormValues,
  type SearchDrawerResult,
} from "../MapRail/SearchDrawer/SearchDrawerForm";
import SearchDrawerView from "../MapRail/SearchDrawer/SearchDrawerView";
import type { UseSearchMarkerResult } from "../MapRail/SearchDrawer/useSearchMarker";

const StyledSearchBottomTab = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  padding: 0.5rem;
`;

type SearchBottomTabProps = {
  onAddMarker: UseSearchMarkerResult["addMarker"];
  onClose: () => void;
  onRemoveMarker: UseSearchMarkerResult["removeMarker"];
};

const SearchBottomTab = ({
  onAddMarker,
  onClose,
  onRemoveMarker,
}: SearchBottomTabProps) => {
  const theme = useTheme();

  const { map } = useUnsafeMapContext();

  const [formError, setFormError] = useState<SearchDrawerFormError | null>(
    null,
  );

  const defaultValues = getDefaultSearchDrawerFormValues();

  const form = useForm<SearchDrawerFormValues>({
    defaultValues,
  });

  const {
    formState: { isSubmitting },
    watch,
  } = form;

  const searchMode = watch("searchMode");

  // Reset error and search marker when search mode changes
  useEffect(() => {
    onRemoveMarker();

    setFormError(null);
  }, [onRemoveMarker, searchMode]);

  const formValues = {
    locationCoordinates: watch("locationCoordinates"),
    bfNumber: watch("bfNumber"),
  } satisfies Record<keyof Omit<SearchDrawerFormValues, "searchMode">, unknown>;

  // Reset error and search marker if the user clears their search
  useEffect(() => {
    if (searchMode !== "locationName" && !formValues[searchMode]) {
      onRemoveMarker();

      setFormError(null);
    }
  }, [formValues, onRemoveMarker, searchMode]);

  const onError = (error: SearchDrawerFormError | null) => {
    setFormError(error);
  };

  const { activateLayer } = useActiveLayersContext();

  const onComplete = (result: SearchDrawerResult) => {
    switch (result.type) {
      case "bounds": {
        onAddMarker(result.value.center);

        map?.fitBounds(result.value.bounds, {
          maxZoom: 12,
          duration: theme.anim.duration.lg,
        });

        break;
      }
      case "coordinates": {
        onAddMarker(result.value);

        map?.flyTo({
          center: result.value,
          zoom: 12,
          duration: theme.anim.duration.lg,
        });

        break;
      }
      case "resource": {
        map?.flyTo({
          center: result.value.coordinates,
          zoom: 12,
          duration: theme.anim.duration.lg,
        });

        activateLayer<AVLPositionsLayerState>({
          id: avlDataLayer.id,
          source: "layers-drawer",
          state: {
            avlAppliancesOption: {
              isActive: true,
              opacity: 1,
            },
            avlPortableOption: {
              isActive: true,
              opacity: 1,
            },
          },
        });

        break;
      }
    }

    onClose?.();
  };

  return (
    <StyledSearchBottomTab>
      <FormProvider {...form}>
        <SearchDrawerView
          formError={formError}
          onComplete={onComplete}
          onError={onError}
        />
      </FormProvider>
      <TabFooter>
        <Button
          disabled={isSubmitting}
          fullWidth
          form="searchDrawerForm"
          isLoading={isSubmitting}
          type="submit"
        >
          Search
        </Button>
      </TabFooter>
    </StyledSearchBottomTab>
  );
};

export default SearchBottomTab;
