import { Close, IconButton } from "@app/design-system";
import styled from "styled-components";

const StyledCancelActiveToolButton = styled.div`
  display: flex;
  justify-content: end;
  padding: 16px;
`;

interface CancelActiveToolButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const CancelActiveToolButton = ({ onClick }: CancelActiveToolButtonProps) => {
  return (
    <StyledCancelActiveToolButton>
      <IconButton
        icon={Close}
        label="Cancel tool"
        onClick={onClick}
        type="button"
        variant="secondary"
      />
    </StyledCancelActiveToolButton>
  );
};

export default CancelActiveToolButton;
