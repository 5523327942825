import { FieldGroup, RadioButtonGroup, TextButton } from "@app/design-system";
import styled from "styled-components";
import { IncidentCategory } from "../../../../.rest-hooks/types/incidents.yml";
import {
  IncidentStatusOption,
  type IncidentsScope,
} from "../../incidents/IncidentsScopeProvider/IncidentsScopeProvider";
import { incidentCategoryLabels } from "./constants";

const StyledFilterIncidentsForm = styled.div`
  display: grid;
  gap: 1rem;
`;

interface FilterIncidentsFormProps {
  onChange: (value: Partial<IncidentsScope>) => void;
  onReset: () => void;
  value: IncidentsScope;
}

const FilterIncidentsForm = ({
  onChange,
  onReset,
  value,
}: FilterIncidentsFormProps) => {
  return (
    <StyledFilterIncidentsForm>
      <FieldGroup size="sm">
        <RadioButtonGroup
          label="Type"
          name="type"
          onChange={(newValue) => {
            // Check whether the selected category is valid, throw if not
            const category = Object.keys(IncidentCategory).includes(newValue)
              ? (newValue as IncidentCategory)
              : undefined;

            if (!category) {
              throw new Error("Unsupported incident category");
            }

            onChange({ category });
          }}
          value={value.category}
        >
          {Object.entries(incidentCategoryLabels).map(([key, label]) => (
            <RadioButtonGroup.Item key={key} label={label} value={key} />
          ))}
        </RadioButtonGroup>
        <RadioButtonGroup
          label="Status"
          name="status"
          onChange={(status) => {
            switch (status) {
              case IncidentStatusOption.all: {
                onChange({ status: "all" });
                break;
              }
              case IncidentStatusOption.uncontained: {
                onChange({ status: "uncontained" });
                break;
              }
              default: {
                throw new Error("Unsupported incident status");
              }
            }
          }}
          value={value.status}
        >
          <RadioButtonGroup.Item label="All" value="all" />
          <RadioButtonGroup.Item
            hint="Uncontained incidents are incidents with a status of going or being controlled."
            label="Uncontained"
            value="uncontained"
          />
        </RadioButtonGroup>
      </FieldGroup>
      <div>
        <TextButton onClick={onReset}>Reset to default</TextButton>
      </div>
    </StyledFilterIncidentsForm>
  );
};

export default FilterIncidentsForm;
