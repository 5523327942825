/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetSourcesActAvl200,
  GetSourcesLivetrafficIncidents200,
} from "./types";

/**
 * Get ACT AVL data.

AVL data is ingested from: https://www.esacop.act.gov.au/rfs/DATA/ACT_VEHICLE.js

 * @summary Get ACT AVL data
 */
export const getSourcesActAvl = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetSourcesActAvl200>> => {
  return axios.get(`/sources/act-avl`, options);
};

export const getGetSourcesActAvlQueryKey = () => {
  return [`/sources/act-avl`] as const;
};

export const getGetSourcesActAvlQueryOptions = <
  TData = Awaited<ReturnType<typeof getSourcesActAvl>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSourcesActAvl>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSourcesActAvlQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSourcesActAvl>>
  > = ({ signal }) => getSourcesActAvl({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSourcesActAvl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSourcesActAvlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSourcesActAvl>>
>;
export type GetSourcesActAvlQueryError = AxiosError<unknown>;

/**
 * @summary Get ACT AVL data
 */
export const useGetSourcesActAvl = <
  TData = Awaited<ReturnType<typeof getSourcesActAvl>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSourcesActAvl>>, TError, TData>
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSourcesActAvlQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get live traffic incidents.

Incident data is ingested from: https://www.livetraffic.com/traffic/hazards/incident.json

 * @summary Get traffic incidents
 */
export const getSourcesLivetrafficIncidents = (
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetSourcesLivetrafficIncidents200>> => {
  return axios.get(`/sources/livetraffic-incidents`, options);
};

export const getGetSourcesLivetrafficIncidentsQueryKey = () => {
  return [`/sources/livetraffic-incidents`] as const;
};

export const getGetSourcesLivetrafficIncidentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSourcesLivetrafficIncidentsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>
  > = ({ signal }) =>
    getSourcesLivetrafficIncidents({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSourcesLivetrafficIncidentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>
>;
export type GetSourcesLivetrafficIncidentsQueryError = AxiosError<unknown>;

/**
 * @summary Get traffic incidents
 */
export const useGetSourcesLivetrafficIncidents = <
  TData = Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
  TError = AxiosError<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getSourcesLivetrafficIncidents>>,
      TError,
      TData
    >
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSourcesLivetrafficIncidentsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
