import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useEffect, useMemo } from "react";
import { addMapboxDrawControlToMap } from "../../../../utils/addMapboxDrawControlToMap/addMapboxDrawControlToMap";
import useUnsafeMapContext from "../../../map/Map/useUnsafeMapContext";
import type { MeasurementTool, MeasurementUnit } from "./utils";

interface MeasurementToolLayerProps {
  measurementTool: MeasurementTool;
  measurementUnit: MeasurementUnit;
  setMeasurementUnit: (unit: MeasurementUnit) => void;
}

const MeasurementToolLayer = ({
  measurementTool,
  measurementUnit,
  setMeasurementUnit,
}: MeasurementToolLayerProps) => {
  const { lib, map } = useUnsafeMapContext();

  const drawControl = useMemo(
    () =>
      new MapboxDraw({
        displayControlsDefault: false,
        defaultMode: measurementTool.mode,
        keybindings: false,
      }),
    [measurementTool.mode],
  );

  useEffect(() => {
    if (!measurementUnit?.isActive) {
      drawControl.changeMode("simple_select");
    }
  }, [drawControl, measurementUnit?.isActive]);

  useEffect(() => {
    if (!map) {
      return;
    }

    // NOTE: This is a mutation function! Adds classes and the control to the map, and returns the cleanup function
    const removeDrawControl = addMapboxDrawControlToMap(drawControl, lib, map);

    const unitUpdate = (complete: boolean) => {
      try {
        const controls = drawControl.getAll();
        const feature =
          controls?.features?.length > 0 ? controls.features[0] : null;

        if (feature) {
          setMeasurementUnit({
            type: measurementTool.type,
            isActive: !complete,
            // NOTE: never is just a quick shortcut around typing the different features
            value: feature as never,
          });
        }
      } catch (e) {
        // Intentionally left blank, if the schema validation fails we can just ignore. Not worth logging errors as the coordinates are updated with every mouse movement
      }
    };

    const onDraw = () => unitUpdate(false);
    const onDrawComplete = () => unitUpdate(true);

    map.on("draw.render", onDraw);
    map.on("draw.create", onDrawComplete);

    return () => {
      map.off("draw.render", unitUpdate);
      map.off("draw.create", onDrawComplete);
      removeDrawControl();
    };
  }, [drawControl, lib, map, measurementTool.type, setMeasurementUnit]);

  return null;
};

export default MeasurementToolLayer;
