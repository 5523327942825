import DrawerContent from "../../../drawers/DrawerOverlay/DrawerContent";
import LayersList from "../../../drawers/LayersDrawerCell/LayersList";

interface LayersDrawerProps {
  isMapRailEnabled?: boolean;
}

const LayersDrawer = ({ isMapRailEnabled }: LayersDrawerProps) => {
  return (
    <DrawerContent>
      <LayersList
        isMapRailEnabled={isMapRailEnabled}
        data-testid="layers-list"
      />
    </DrawerContent>
  );
};

export default LayersDrawer;
