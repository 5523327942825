import { emergencyAlertsLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import EmergencyAlertsLayer from "./EmergencyAlertsLayer";

const EmergencyAlerts = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(emergencyAlertsLayer.id)) {
    return null;
  }

  const state = getLayerState(emergencyAlertsLayer.id);

  return <EmergencyAlertsLayer opacity={state?.opacity} />;
};

export default EmergencyAlerts;
