import { Text } from "@app/design-system";
import type { BurntAreaCurrentFireSeasonInteractionState } from "../../map/BurntAreaCurrentFireSeason/interactions";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import BurntAreaCurrentFireSeasonPopupDetail from "./BurntAreaCurrentFireSeasonPopupDetail";

export interface BurntAreaCurrentFireSeasonPopupProps {
  "data-testid"?: string;
  onClose: () => void;
  state: BurntAreaCurrentFireSeasonInteractionState;
}

const BurntAreaCurrentFireSeasonPopup = ({
  "data-testid": dataTestId,
  onClose,
  state: { id, isActive, properties },
}: BurntAreaCurrentFireSeasonPopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      lngLat={properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        id,
        offset: 16,
        size: "xl",
        type: "click",
      }}
    >
      {properties && (
        <MapPopupView
          data-testid={dataTestId}
          header={
            <Text size="eyebrowDefault" variant="weak">
              Burnt area curr. season
            </Text>
          }
          onClose={onClose}
        >
          <BurntAreaCurrentFireSeasonPopupDetail properties={properties} />
        </MapPopupView>
      )}
    </SpatialPopup>
  );
};

export default BurntAreaCurrentFireSeasonPopup;
