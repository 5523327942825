import {
  Field,
  FieldGroup,
  FieldRow,
  MenuSection,
  RadioButton,
  Switch,
  TextButton,
} from "@app/design-system";
import styled from "styled-components";
import OpacitySlider from "../OpacitySlider/OpacitySlider";
import type { PredictionOutput } from "../PredictionsProvider/PredictionsProvider";

const StyledGlobalPredictionControlForm = styled.div`
  display: grid;
  gap: 0.75rem;
  padding-bottom: 0.125rem;
`;

interface GlobalPredictionsControlPanelValue {
  isEmberDensityActive: boolean;
  opacity: number;
  output: PredictionOutput;
}

export type GlobalPredictionControlFormChangeHandler = (
  value: Partial<GlobalPredictionsControlPanelValue>,
) => void;

interface GlobalPredictionsControlPanelProps {
  onReset: () => void;
  onChange: GlobalPredictionControlFormChangeHandler;
  value: GlobalPredictionsControlPanelValue;
}

const GlobalPredictionControlForm = ({
  onReset,
  onChange,
  value,
}: GlobalPredictionsControlPanelProps) => {
  const onOpacityChange = (opacity: number) => {
    onChange({
      opacity,
    });
  };

  const onOutputChange = (output: PredictionOutput) => () => {
    onChange({
      output,
    });
  };

  const onEmberDensityChange = (isSelected: boolean) => {
    onChange({
      isEmberDensityActive: isSelected,
    });
  };

  return (
    <StyledGlobalPredictionControlForm>
      <FieldGroup>
        <MenuSection label="All predictions">
          <Field label="Opacity">
            <OpacitySlider onChange={onOpacityChange} value={value.opacity} />
          </Field>
        </MenuSection>
        <MenuSection label="Rapid appreciation">
          <FieldGroup>
            <Field label="Primary output">
              <RadioButton
                checked={value.output === "burnProbability"}
                label="Burn probability"
                name="output"
                onChange={onOutputChange("burnProbability")}
              />
              <RadioButton
                checked={value.output === "flameHeight"}
                label="Average flame height"
                name="output"
                onChange={onOutputChange("flameHeight")}
              />
            </Field>
            <FieldRow
              hint="Ember density is only supported on Phoenix predictions"
              label="Show ember density"
            >
              <Switch
                aria-label="Ember density"
                isSelected={value.isEmberDensityActive}
                onChange={onEmberDensityChange}
                size="sm"
              />
            </FieldRow>
          </FieldGroup>
        </MenuSection>
      </FieldGroup>
      <div>
        <TextButton onClick={onReset}>Reset to default</TextButton>
      </div>
    </StyledGlobalPredictionControlForm>
  );
};

export default GlobalPredictionControlForm;
