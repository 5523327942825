import styled from "styled-components";
import MarkupToolsetsList from "./MarkupToolsetsList";

const StyledMarkupToolsetsBottomTab = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  padding: 0.25rem;
`;

const MarkupToolsetsBottomTab = () => {
  return (
    <StyledMarkupToolsetsBottomTab>
      <MarkupToolsetsList />
    </StyledMarkupToolsetsBottomTab>
  );
};

export default MarkupToolsetsBottomTab;
