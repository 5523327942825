import type { TweetFiltersParameter } from "../../.rest-hooks/types/social-twitter.yml";
import { useActiveLayersContext } from "../components/util/ActiveLayersProvider/ActiveLayersProvider";
import type { LayerConfigState } from "../components/util/ActiveLayersProvider/useActiveLayers";
import { socialMedia } from "../config/layers/layers";

export const useSocialMediaFilters = () => {
  const { getLayerState } = useActiveLayersContext();
  const state = getLayerState<LayerConfigState<typeof socialMedia>>(
    socialMedia.id,
  );

  const isLinkedToIncidentLayerActive =
    !!state?.socialMediaLinkedToIncident?.isActive;
  const isNearDEAHotspotLayerActive = !!state?.socialMediaNearHotspot?.isActive;
  const filters: TweetFiltersParameter = [];
  if (isLinkedToIncidentLayerActive) {
    filters.push("linked_to_incident");
  }
  if (isNearDEAHotspotLayerActive) {
    filters.push("near_hotspot");
  }
  return filters;
};
