import {
  Checkbox,
  CheckboxGroup,
  Field,
  FieldGroup,
  TextArea,
  type FormControlOption,
} from "@app/design-system";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import type { SocialTwitter } from "../../../../.rest-hooks/types/social-twitter.yml";
import { validationReasonsOptions } from "../../drawers/SocialDrawerCell/constants";
import IncidentMultiCombobox from "../../ui/IncidentMultiCombobox/IncidentMultiCombobox";
import LinkedIncidents from "../LinkedIncidents/LinkedIncidents";

const StyledValidateTweetForm = styled.div`
  display: grid;
  gap: 1rem;
`;

export interface ValidateTweetFormValues {
  confirmation: boolean;
  incidentIds: FormControlOption<string>[];
  note: string;
  validationReasons: string[];
}

export const getDefaultValidateSocialPostFormValues =
  (): ValidateTweetFormValues => {
    return {
      confirmation: false,
      incidentIds: [],
      note: "",
      validationReasons: [],
    };
  };

interface ValidateTweetFormProps {
  incidentIds: string[] | undefined;
  tweet: SocialTwitter;
}

const ValidateTweetForm = ({ incidentIds, tweet }: ValidateTweetFormProps) => {
  const {
    control,
    formState: { errors },
    register,
    reset,
  } = useFormContext<ValidateTweetFormValues>();

  useEffect(() => {
    reset(getDefaultValidateSocialPostFormValues());
  }, [reset]);

  return (
    <StyledValidateTweetForm>
      <FieldGroup title="Linked incident(s)">
        {incidentIds?.length ? (
          <LinkedIncidents linkedIncidents={incidentIds} tweetId={tweet.id} />
        ) : (
          <Controller
            control={control}
            name="incidentIds"
            render={({ field, fieldState: { error } }) => {
              return (
                <IncidentMultiCombobox
                  {...field}
                  error={error}
                  label="Incidents"
                />
              );
            }}
            rules={{
              validate: (value) =>
                !!value.length || "Please select at least one linked incident",
            }}
          />
        )}
      </FieldGroup>
      <FieldGroup title="Validation reasons">
        <Controller
          control={control}
          name="validationReasons"
          render={({ field, fieldState: { error } }) => {
            return (
              <CheckboxGroup
                {...field}
                error={error}
                label="Select reasons for validation"
              >
                {validationReasonsOptions.map(({ label, value }) => (
                  <CheckboxGroup.Item key={value} label={label} value={value} />
                ))}
              </CheckboxGroup>
            );
          }}
          rules={{
            validate: (value) =>
              !!value.length ||
              "Please select at least one reason for validation",
          }}
        />
        <Field
          error={errors.note}
          htmlFor="note"
          label="Additional notes, sources and details"
        >
          <TextArea
            id="note"
            placeholder="Add information about your note here"
            {...register("note", { required: "Please enter a note" })}
          />
        </Field>
        <Controller
          control={control}
          name="confirmation"
          rules={{
            validate: (value) => value === true || "Please confirm this action",
          }}
          render={({
            field: { onChange, value, ...field },
            fieldState: { error },
          }) => {
            return (
              <Checkbox
                {...field}
                checked={value}
                error={error}
                label="I confirm that I cannot undo this action"
                onChange={(event) => {
                  onChange(event.currentTarget.checked);
                }}
              />
            );
          }}
        />
      </FieldGroup>
    </StyledValidateTweetForm>
  );
};

export default ValidateTweetForm;
