import LngLat from "../LngLat/LngLat";
import useUnsafeMapContext from "../Map/useUnsafeMapContext";
import NavigationControls from "../NavigationControls/NavigationControls";
import PitchLimiter from "../PitchLimiter/PitchLimiter";
import Sky from "../Sky/Sky";
import Terrain from "../Terrain/Terrain";

interface MapControlsAndBaseLayersProps {
  disableControls?: boolean;
  /**
   * This should be moved down into the individual components via the
   * useDeveloperOptions context hook rather than being passed as a prop,
   * eventually. The issue right now is that even when the map rail layout is
   * enabled in the developer options, not every page has it set up. So we only
   * pass this through on the state/cop visualisers.
   */
  isMapRailEnabled?: boolean;
  isPredictionsActive?: boolean;
}

const MapControlsAndBaseLayers = ({
  disableControls,
  isMapRailEnabled,
  isPredictionsActive,
}: MapControlsAndBaseLayersProps) => {
  const { lib } = useUnsafeMapContext();

  return (
    <>
      {!disableControls && (
        <>
          <NavigationControls
            isMapRailEnabled={isMapRailEnabled}
            isPredictionsActive={isPredictionsActive}
          />
          {!isMapRailEnabled && <LngLat />}
        </>
      )}
      {lib === "mapbox" && <Sky />}
      {lib === "mapbox" && <Terrain />}
      <PitchLimiter />
    </>
  );
};

export default MapControlsAndBaseLayers;
