import styled from "styled-components";
import DrawerContent from "../../../drawers/DrawerOverlay/DrawerContent";
import MapRailDrawerCell from "../MapRailDrawerCell";

const StyledLegend = styled.div`
  padding: 0.75rem;
  background-color: ${(p) => p.theme.colors.neutrals.surface};
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
  border-radius: ${(p) => p.theme.borderRadiuses.md}px;
`;

interface MapRailLegendDrawerProps {
  component: React.ComponentType<{ id?: string }>;
  id: string;
  label: string;
  onClose: () => void;
}

const LegendDrawer = ({
  component: Component,
  id,
  label,
  onClose,
}: MapRailLegendDrawerProps) => {
  return (
    <MapRailDrawerCell
      data-testid="legend-drawer"
      label={label}
      onClose={onClose}
    >
      <DrawerContent>
        <StyledLegend>{Component && <Component id={id} />}</StyledLegend>
      </DrawerContent>
    </MapRailDrawerCell>
  );
};

export default LegendDrawer;
