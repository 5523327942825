import {
  Filter,
  FilterAdjusted,
  IconButton,
  PopoverDialogTrigger,
} from "@app/design-system";
import { useIncidentsScope } from "../../incidents/IncidentsScopeProvider/IncidentsScopeProvider";
import FilterIncidentsForm from "./FilterIncidentsForm";

interface FilterIncidentsPopoverTriggerProps {
  "data-testid"?: string;
}

const FilterIncidentsPopoverTrigger = ({
  "data-testid": dataTestId,
}: FilterIncidentsPopoverTriggerProps) => {
  const { defaultScope, isAdjusted, scope, onScopeChange } =
    useIncidentsScope();

  return (
    <PopoverDialogTrigger
      data-testid={dataTestId}
      placement="right top"
      renderTrigger={(props) => (
        <IconButton
          {...props}
          icon={isAdjusted ? FilterAdjusted : Filter}
          label="Filters"
          size="sm"
          variant="secondary"
        />
      )}
      title="Filter incidents"
    >
      <FilterIncidentsForm
        onChange={(value) => {
          onScopeChange({ ...scope, ...value });
        }}
        onReset={() => {
          onScopeChange({ ...defaultScope });
        }}
        value={scope}
      />
    </PopoverDialogTrigger>
  );
};

export default FilterIncidentsPopoverTrigger;
