import { healthFacilitiesLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import HealthFacilitiesLayer from "./HealthFacilitiesLayer";

const HealthFacilities = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(healthFacilitiesLayer.id)) {
    return null;
  }

  const state = getLayerState(healthFacilitiesLayer.id);

  return <HealthFacilitiesLayer opacity={state?.opacity} />;
};

export default HealthFacilities;
