import {
  DropdownOptionItem,
  StaticIconWrapper,
  Text,
} from "@app/design-system";
import React, { forwardRef, type ForwardRefRenderFunction } from "react";
import styled from "styled-components";
import type { IncidentAlertLevel } from "../../../../.rest-hooks/types/incidents.yml";
import { getAlertLevelIcon } from "../../../config/alertLevel";

const StyledIncidentDropdownOption = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 0.75rem;
  align-items: start;
`;

const StyledAlertLevel = styled.div`
  width: fit-content;
`;

interface IncidentDropdownOptionProps
  extends React.LiHTMLAttributes<HTMLLIElement> {
  alertLevel?: IncidentAlertLevel;
  disabled: boolean;
  focused: boolean;
  hasSelection: boolean;
  lga?: string;
  name: string;
  selected: boolean;
}

const IncidentDropdownOption: ForwardRefRenderFunction<
  HTMLLIElement,
  IncidentDropdownOptionProps
> = (
  {
    alertLevel,
    disabled,
    focused,
    hasSelection,
    lga,
    name,
    selected,
    ...props
  }: IncidentDropdownOptionProps,
  ref,
) => {
  const icon = getAlertLevelIcon(alertLevel || "Other");
  return (
    <DropdownOptionItem
      disabled={disabled}
      focused={focused}
      hasSelection={hasSelection}
      selected={selected}
      ref={ref}
      {...props}
    >
      <StyledIncidentDropdownOption>
        <StyledAlertLevel>
          <StaticIconWrapper icon={icon} size="sm" />
        </StyledAlertLevel>
        <Text size="bodyDefault">
          <strong>{name}</strong>
          {lga && <>, {lga}</>}
        </Text>
      </StyledIncidentDropdownOption>
    </DropdownOptionItem>
  );
};

export default forwardRef(IncidentDropdownOption);
