import { captureException } from "@sentry/react";

interface DownloadFileParams {
  accessToken?: string;
  filename: string;
  url: string;
}

export const downloadFile = async ({
  accessToken,
  filename,
  url,
}: DownloadFileParams) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      ...(accessToken && {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    });
    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    captureException(error);
  }
};
