import { Skeleton, StatusMessage } from "@app/design-system";
import styled from "styled-components";
import { useGetPredictionsId } from "../../../../.rest-hooks/predictions";
import useQueryAsyncValue from "../../../hooks/useQueryAsyncValue";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import AsyncView from "../../util/AsyncView/AsyncView";

export const StyledPreferredPredictionValidIndicator = styled.div`
  ${(p) => p.theme.typography.variants.bodyDefault}
`;

export type PreferredPredictionAttribute = "validFrom" | "validTo";

const attributeLabelMap: Record<PreferredPredictionAttribute, string> = {
  validFrom: "valid from",
  validTo: "valid to",
};

interface PreferredPredictionValidIndicatorProps {
  attribute: PreferredPredictionAttribute;
  enabled?: boolean;
  predictionId: string | undefined;
}

const PreferredPredictionValidIndicator = ({
  attribute,
  enabled = true,
  predictionId,
}: PreferredPredictionValidIndicatorProps) => {
  const predictionQuery = useGetPredictionsId(predictionId ?? "", {
    query: {
      enabled: !!predictionId && enabled,
      select: (data) => data.data.data,
    },
  });

  const predictionResult = useQueryAsyncValue({ query: predictionQuery });

  if (!predictionId) {
    return (
      <StatusMessage
        tooltip="Prediction may be in progress or failed"
        variant="info"
      >
        No preferred prediction
      </StatusMessage>
    );
  }

  return (
    <AsyncView
      {...predictionResult}
      errorState={
        <StatusMessage variant="error">Error loading prediction</StatusMessage>
      }
      loadingState={
        <StyledPreferredPredictionValidIndicator>
          <Skeleton />
        </StyledPreferredPredictionValidIndicator>
      }
    >
      {(prediction) => (
        <StyledPreferredPredictionValidIndicator>
          Prediction {attributeLabelMap[attribute]}:{" "}
          {getFormattedDateAndTime(prediction.attributes[attribute] * 1000)}
        </StyledPreferredPredictionValidIndicator>
      )}
    </AsyncView>
  );
};

export default PreferredPredictionValidIndicator;
