import { useEffect, useState } from "react";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import FireConditionsRasterViewer from "./FireConditionsRasterViewer";
import getFireConditionsTimestamp from "./getFireConditionsTimestamp";

interface FireConditionsLayerProps {
  layerName: string;
  opacity?: number;
}

const FireConditionsLayer = ({
  layerName,
  opacity,
}: FireConditionsLayerProps) => {
  // dimIssueTime is most recent time in which the data has been published or issued and
  // thus we update our layer based on this.
  // i.e. it is Monday and the data was published on Monday at X time, we use that rather than Sunday at X time.
  // This is necessary because the time below can be accounted for on multiple days or "issues" of the data.
  // i.e. on Friday the data for the time of Monday at 12:00am is issued and then again on Sunday.
  const [dimIssueTime, setDimIssueTime] = useState<string | null>(null);

  // time is simply the the time closest to the current users time, in turn showing the most relevant data.
  // i.e. it is 11:23am we return the wind direction for 11:00am
  const [time, setTime] = useState<string | null>(null);

  const accessToken = useAuthAccessToken();

  useEffect(() => {
    const controller = new AbortController();

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getFireConditionsTimestamp({
      accessToken,
      layerName,
      param: "ISSUE_TIME",
    }).then((dimTime) => {
      if (controller.signal.aborted) return;
      setDimIssueTime(dimTime);
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getFireConditionsTimestamp({
      accessToken,
      layerName,
      param: "time",
    }).then((currentTime) => {
      if (controller.signal.aborted) return;
      setTime(currentTime);
    });

    return () => controller.abort();
  }, [accessToken, layerName]);

  return (
    <FireConditionsRasterViewer
      dimIssueTime={dimIssueTime}
      opacity={opacity}
      layer={layerName}
      time={time}
    />
  );
};

export default FireConditionsLayer;
