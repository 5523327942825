import { Badge } from "@app/design-system";

interface TweetValidationBadgeProps {
  validated: boolean | undefined;
}

const TweetValidationBadge = ({ validated }: TweetValidationBadgeProps) => {
  return (
    <Badge variant={validated ? "validated" : "unvalidated"}>
      {validated ? "Validated" : "Unvalidated"}
    </Badge>
  );
};

export default TweetValidationBadge;
