import getConfig from "next/config";
import { stateBorder } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import StateBorderLayer from "./StateBorderLayer";

const { publicRuntimeConfig } = getConfig();
const { COP_PHASE_TWO_ENABLED } = publicRuntimeConfig;

const StateBorder = () => {
  const { isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(stateBorder.id) || !COP_PHASE_TWO_ENABLED) {
    return null;
  }

  return <StateBorderLayer />;
};

export default StateBorder;
