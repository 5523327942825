import { FieldGrid } from "@app/design-system";
import capitalize from "lodash/capitalize";
import styled from "styled-components";
import type { Prediction } from "../../../../.rest-hooks/types";
import type {
  PredictionImpactSummaryHourlyCountByBurnProbability,
  PredictionImpacts,
} from "../../../../.rest-hooks/types/predictions.yml";
import getImpactCount from "../../../utils/getImpactCount/getImpactCount";
import {
  getHoursOffset,
  useTimeline,
} from "../../util/TimelineProvider/TimelineProvider";

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const StyledItemDetail = styled.div`
  flex: 1;
`;

const StyledBullet = styled.div`
  color: ${(p) => p.theme.colors.neutrals.textDisabled};
  ${(p) => p.theme.typography.variants.bodyDefault}
  line-height: 20px;
`;

const Bullet = () => {
  return <StyledBullet>&bull;</StyledBullet>;
};

interface GetHourlyImpactCountParams {
  burnProbabilityType: keyof PredictionImpactSummaryHourlyCountByBurnProbability;
  hoursOffset: number;
  impactType: keyof PredictionImpacts;
  impacts: PredictionImpacts | undefined;
}

const getHourlyImpactCount = ({
  burnProbabilityType,
  hoursOffset,
  impactType,
  impacts,
}: GetHourlyImpactCountParams) => {
  if (!impacts) return;

  return getImpactCount({
    hourlyCount:
      impacts[impactType].hourlyCountByBurnProbability[burnProbabilityType],
    hoursFrom: hoursOffset,
    hoursTo: hoursOffset,
  });
};

interface ImpactProbabilityIndicatorProps {
  burnProbabilityType: keyof PredictionImpactSummaryHourlyCountByBurnProbability;
  prediction: Prediction;
}

const ImpactProbabilityIndicator = ({
  burnProbabilityType,
  prediction,
}: ImpactProbabilityIndicatorProps) => {
  const {
    timelineState: { hours, startDate },
  } = useTimeline();

  const { impacts, validFrom } = prediction.attributes;

  const validFromMs = validFrom ? validFrom * 1000 : undefined;
  const hoursOffset = getHoursOffset(startDate, validFromMs) + hours;

  const params = { hoursOffset, impacts, burnProbabilityType };

  const criticalInfrastructureCount = getHourlyImpactCount({
    ...params,
    impactType: "criticalInfrastructure",
  });

  const habitablePropertiesCount = getHourlyImpactCount({
    ...params,
    impactType: "habitableProperties",
  });

  let tooltip = "";
  switch (burnProbabilityType) {
    case "high":
      tooltip = "High impact = 67% - 100%";
      break;
    case "med":
      tooltip = "Medium impact = 34% - 66%";
      break;
    case "low":
      tooltip = "Low impact = 0% - 33%";
      break;
  }

  return (
    <FieldGrid.Item label={capitalize(burnProbabilityType)} tooltip={tooltip}>
      <StyledItem>
        <StyledItemDetail>
          HP: {habitablePropertiesCount || "—"}
        </StyledItemDetail>
        <Bullet />
        <StyledItemDetail>
          CI: {criticalInfrastructureCount || "—"}
        </StyledItemDetail>
      </StyledItem>
    </FieldGrid.Item>
  );
};

export default ImpactProbabilityIndicator;
