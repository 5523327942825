import { Close, IconButton } from "@app/design-system";
import styled from "styled-components";
import makeTestId from "../../../utils/makeTestId";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../../map/MapInteractions/types";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import MapPreview from "./MapPreview";

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

type PreviewSpatialPopupOffsetSize = "sm" | "md" | "lg";

const offsetSizes: Record<PreviewSpatialPopupOffsetSize, number> = {
  sm: 8,
  md: 16,
  lg: 28,
};

interface PreviewSpatialPopupProps {
  "data-testid"?: string;
  children: React.ReactNode;
  offset?: PreviewSpatialPopupOffsetSize;
  onClose: () => void;
  state: FeatureInteractionState<FeatureInteractionProperties>;
  type?: "click" | "hover";
}

const PreviewSpatialPopup = ({
  "data-testid": dataTestId,
  children,
  offset = "sm",
  onClose,
  state,
  type = "hover",
}: PreviewSpatialPopupProps) => {
  const { id, isActive, properties } = state;
  return (
    <SpatialPopup
      isOpen={isActive}
      lngLat={properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        id,
        offset: offsetSizes[offset],
        size: "lg",
        fullWidth: false,
        type,
      }}
    >
      <MapPreview data-testid={dataTestId}>
        <StyledContent>
          {children}
          {type === "click" && (
            <IconButton
              data-testid={makeTestId(dataTestId, "close-button")}
              icon={Close}
              label="Close"
              onClick={onClose}
              size="xs"
              variant="ghost"
            />
          )}
        </StyledContent>
      </MapPreview>
    </SpatialPopup>
  );
};

export default PreviewSpatialPopup;
