import React from "react";
import styled from "styled-components";
import Drawer from "../../drawers/DrawerOverlay/Drawer";

const StyledMapRailDrawerCell = styled.div`
  min-height: 0;
  z-index: ${(p) => p.theme.zIndexes.overlayDrawer};

  & > * {
    pointer-events: all;
  }
`;

interface MapRailDrawerCellProps {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  fullHeight?: boolean;
  label: React.ReactNode;
  onClose: () => void;
  "data-testid"?: string;
}

const MapRailDrawerCell = ({
  actions,
  children,
  footer,
  fullHeight,
  label,
  onClose,
  "data-testid": dataTestId,
}: MapRailDrawerCellProps) => {
  return (
    <StyledMapRailDrawerCell>
      <Drawer
        actions={actions}
        label={label}
        footer={footer}
        fullHeight={fullHeight}
        onClose={onClose}
        data-testid={dataTestId}
      >
        {children}
      </Drawer>
    </StyledMapRailDrawerCell>
  );
};

export default MapRailDrawerCell;
