import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useMapContext from "../Map/useMapContext";
import { InteractionStateType } from "../MapInteractions/types";
import { isMaplibre } from "../utils/isMaplibre";
import {
  INCIDENT_POINT_ACTIVE_SUFFIX,
  INCIDENT_POINT_COLLECTION_ACTIVE_LAYER_ID,
  INCIDENT_POINT_COLLECTION_HOVERED_LAYER_ID,
  INCIDENT_POINT_COLLECTION_LAYER_ID,
  INCIDENT_POINT_COLLECTION_SOURCE_ID,
  INCIDENT_POINT_HOVERED_SUFFIX,
} from "./constants";

type IconSize = "sm" | "md";

const iconSize: Record<IconSize, number> = {
  sm: 0.6,
  md: 0.9,
};

interface UseIncidentPointCollectionMapLayersProps {
  size?: IconSize;
}

const useIncidentPointCollectionMapLayers = ({
  size = "md",
}: Partial<UseIncidentPointCollectionMapLayersProps> = {}) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(INCIDENT_POINT_COLLECTION_SOURCE_ID, {
      type: "geojson",
      data: {
        features: [],
        type: "FeatureCollection",
      },
      promoteId: "incidentId",
    });

    map.addLayer(
      {
        id: INCIDENT_POINT_COLLECTION_LAYER_ID,
        type: "symbol",
        source: INCIDENT_POINT_COLLECTION_SOURCE_ID,
        layout: {
          "icon-size": iconSize[size],
          "icon-image": ["get", "iconId"],
          "icon-allow-overlap": true,
          "icon-optional": false,
          "symbol-sort-key": ["get", "alertLevelPriority"],
        },
      },
      MapLevel.FS_SYMBOLS_PRIORITY,
    );

    map.addLayer(
      {
        id: `${INCIDENT_POINT_COLLECTION_LAYER_ID}-text`,
        type: "symbol",
        source: INCIDENT_POINT_COLLECTION_SOURCE_ID,
        minzoom: 7,
        layout: {
          "text-font": isMaplibre(map) ? ["Noto Sans Bold"] : ["DIN Pro Bold"],
          "text-field": ["get", "name"],
          "text-offset": [0, -2.6],
          "text-max-width": 40,
          "text-size": 14,
        },
        paint: {
          "text-color": "#000",
          "text-halo-color": "#FFF",
          "text-halo-width": 1.25,
          "text-halo-blur": 0,
          "text-opacity": [
            "case",
            [
              "all",
              [
                "!",
                [
                  "boolean",
                  ["feature-state", InteractionStateType.HOVERED],
                  false,
                ],
              ],
              [
                "!",
                [
                  "boolean",
                  ["feature-state", InteractionStateType.CLICKED],
                  false,
                ],
              ],
            ],
            1,
            0,
          ],
        },
      },
      MapLevel.FS_SYMBOLS_PRIORITY,
    );

    map.addLayer(
      {
        id: INCIDENT_POINT_COLLECTION_HOVERED_LAYER_ID,
        type: "symbol",
        source: INCIDENT_POINT_COLLECTION_SOURCE_ID,
        layout: {
          "icon-size": iconSize[size],
          "icon-image": [
            "concat",
            ["get", "iconId"],
            INCIDENT_POINT_HOVERED_SUFFIX,
          ],
          "icon-allow-overlap": true,
          "icon-optional": false,
        },
        paint: {
          "icon-opacity": [
            "case",
            [
              "all",
              [
                "boolean",
                ["feature-state", InteractionStateType.HOVERED],
                false,
              ],
              [
                "!",
                [
                  "boolean",
                  ["feature-state", InteractionStateType.CLICKED],
                  false,
                ],
              ],
            ],
            1,
            0,
          ],
        },
      },
      MapLevel.FS_SYMBOLS_PRIORITY,
    );

    map.addLayer(
      {
        id: INCIDENT_POINT_COLLECTION_ACTIVE_LAYER_ID,
        type: "symbol",
        source: INCIDENT_POINT_COLLECTION_SOURCE_ID,
        layout: {
          "icon-size": iconSize[size],
          "icon-image": [
            "concat",
            ["get", "iconId"],
            INCIDENT_POINT_ACTIVE_SUFFIX,
          ],
          "icon-allow-overlap": true,
          "icon-optional": false,
        },
        paint: {
          "icon-opacity": [
            "case",
            ["boolean", ["feature-state", InteractionStateType.CLICKED], false],
            1,
            0,
          ],
        },
      },
      MapLevel.FS_SYMBOLS_PRIORITY,
    );

    return () => {
      if (map.getLayer(INCIDENT_POINT_COLLECTION_LAYER_ID)) {
        map.removeLayer(INCIDENT_POINT_COLLECTION_LAYER_ID);
      }
      if (map.getLayer(`${INCIDENT_POINT_COLLECTION_LAYER_ID}-text`)) {
        map.removeLayer(`${INCIDENT_POINT_COLLECTION_LAYER_ID}-text`);
      }
      if (map.getLayer(INCIDENT_POINT_COLLECTION_HOVERED_LAYER_ID)) {
        map.removeLayer(INCIDENT_POINT_COLLECTION_HOVERED_LAYER_ID);
      }
      if (map.getLayer(INCIDENT_POINT_COLLECTION_ACTIVE_LAYER_ID)) {
        map.removeLayer(INCIDENT_POINT_COLLECTION_ACTIVE_LAYER_ID);
      }
      if (map.getSource(INCIDENT_POINT_COLLECTION_SOURCE_ID)) {
        map.removeSource(INCIDENT_POINT_COLLECTION_SOURCE_ID);
      }
    };
  }, [map, size]);
};

export default useIncidentPointCollectionMapLayers;
