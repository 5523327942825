import type { Feature } from "geojson";
import type { LngLatLike } from "mapbox-gl";
import type { ResourceAircraftAssignment } from "../../../../.rest-hooks/types/resources.yml/resourceAircraftAssignment";
import type { ResourceAircraftStatus } from "../../../../.rest-hooks/types/resources.yml/resourceAircraftStatus";
import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isPointFeature } from "../types";

export interface AircraftPositionsInteractionProperties
  extends FeatureInteractionProperties {
  assignment: ResourceAircraftAssignment;
  callSign: string;
  id: string;
  model: string;
  reportAge: number;
  speed: number;
  status: ResourceAircraftStatus;
}

export type AircraftPositionsInteractionState =
  FeatureInteractionState<AircraftPositionsInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  AircraftPositionsInteractionProperties
> = (feature: Feature) => {
  if (!isPointFeature(feature)) {
    return null;
  }
  const lngLat = feature.geometry.coordinates as LngLatLike;
  const { assignment, callSign, id, model, reportAge, speed, status } =
    feature.properties;

  return {
    assignment,
    callSign,
    featureId: id,
    id,
    lngLat,
    model,
    reportAge,
    speed,
    status,
  };
};
