import { FieldGrid, Text } from "@app/design-system";
import styled from "styled-components";
import type { InterstateIncidentInteractionState } from "../../map/InterstateIncidents/interactions";
import RawHtml from "../../util/RawHtml/RawHtml";
import type { MapPopupType } from "../SpatialPopup/MapPopup";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";

const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const StyledContent = styled.div`
  white-space: pre-line;
`;

export interface InterstateIncidentPopupProps {
  descriptionRawHtml: string;
  onClose: () => void;
  state: InterstateIncidentInteractionState;
  title: string;
  type: MapPopupType;
}

const InterstateIncidentPopup = ({
  descriptionRawHtml,
  onClose,
  state: { id, isActive, properties },
  title,
  type,
}: InterstateIncidentPopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      onClose={onClose}
      popupConfig={{
        id,
        fullWidth: true,
        offset: 32,
        size: "xl",
        type,
      }}
      lngLat={properties?.lngLat}
    >
      <MapPopupView
        header={
          <Text size="eyebrowDefault" variant="weak">
            {properties?.data.geocode}
          </Text>
        }
        onClose={onClose}
      >
        <StyledBody>
          <Text size="subtitleMd">{title}</Text>
          <FieldGrid>
            <FieldGrid.Item label="Description:" fullWidth>
              <StyledContent>
                <RawHtml allowLinks={false} html={descriptionRawHtml} />
              </StyledContent>
            </FieldGrid.Item>
          </FieldGrid>
        </StyledBody>
      </MapPopupView>
    </SpatialPopup>
  );
};

export default InterstateIncidentPopup;
