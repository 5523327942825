import {
  interstateIncidentsACTOption,
  interstateIncidentsQLDOption,
  interstateIncidentsSAOption,
  interstateIncidentsVICOption,
} from "../../../config/layers/layers";
import type { AusGeocode } from "./useInterstateIncidentsQuery";

export const INTERSTATE_INCIDENTS_SOURCE_URL =
  "https://prod.dataportal.rfs.nsw.gov.au/majorIncidents.json";

export const INTERSTATE_INCIDENTS_SOURCE_ID = "interstateIncidents";
export const INTERSTATE_INCIDENTS_LAYER_ID = "interstateIncidents";
export const INTERSTATE_INCIDENTS_ICON_ID = "alert-level-default";

export const INTERSTATE_INCIDENTS_LAYER_OPTIONS = [
  interstateIncidentsACTOption,
  interstateIncidentsQLDOption,
  interstateIncidentsSAOption,
  interstateIncidentsVICOption,
] as const;

export const INTERSTATE_INCIDENTS_LAYER_OPTION_ID_TO_GEOCODE: Record<
  (typeof INTERSTATE_INCIDENTS_LAYER_OPTIONS)[number]["id"],
  AusGeocode
> = {
  interstateIncidentsACT: "AU-ACT",
  interstateIncidentsQLD: "AU-QLD",
  interstateIncidentsSA: "AU-SA",
  interstateIncidentsVIC: "AU-VIC",
};
