import styled from "styled-components";
import { useTimeline } from "../../util/TimelineProvider/TimelineProvider";
import CompactHoursControls from "./CompactHoursControls";

const StyledCompactPredictionControls = styled.div`
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-top: 1px solid ${(p) => p.theme.colors.neutrals.borderDisabled};
`;

interface CompactPredictionControlsProps {
  "data-testid"?: string;
}

const CompactPredictionControls = ({
  "data-testid": dataTestId,
}: CompactPredictionControlsProps) => {
  const {
    timelineState: { hours, startDate },
    timelineDispatch,
  } = useTimeline();

  const onHoursChange = (newHours: number) => {
    timelineDispatch({
      payload: { hours: newHours },
      type: "setHours",
    });
  };

  return (
    <StyledCompactPredictionControls>
      <CompactHoursControls
        hours={hours}
        onHoursChange={onHoursChange}
        startDate={startDate}
        data-testid={dataTestId}
      />
    </StyledCompactPredictionControls>
  );
};

export default CompactPredictionControls;
