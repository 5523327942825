import { fourToSevenYearsLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FourToSevenYearsLayer from "./FourToSevenYearsLayer";

const FourToSevenYears = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(fourToSevenYearsLayer.id)) {
    return null;
  }

  const state = getLayerState(fourToSevenYearsLayer.id);

  return <FourToSevenYearsLayer opacity={state?.opacity} />;
};

export default FourToSevenYears;
