import noop from "lodash/noop";
import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import { useMapServerQueryData } from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";

const StateBorderLayer = () => {
  const layerId = "stateBorder";
  const map = useMapContext();
  const accessToken = useAuthAccessToken();
  const layerUrl = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/Athena/MapServer/5/query`;
  const { data } = useMapServerQueryData(layerUrl);

  useEffect(() => {
    if (!accessToken) return noop;

    const controller = new AbortController();
    controller.signal.addEventListener("abort", () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }

      if (map.getSource(layerId)) {
        map.removeSource(layerId);
      }
    });

    if (!map.getSource(layerId)) {
      map.addSource(layerId, {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
    }

    if (!map.getLayer(layerId)) {
      map.addLayer(
        {
          id: layerId,
          type: "line",
          source: layerId,
          layout: { "line-join": "round", "line-cap": "round" },
          paint: {
            "line-color": "#000000",
            "line-width": 2,
            "line-opacity": 0.6,
          },
          maxzoom: 12,
        },
        MapLevel.LINES,
      );
    }

    return () => controller.abort();
  }, [accessToken, layerId, layerUrl, map]);

  useEffect(() => {
    const source = map.getSource(layerId);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, layerId, map]);

  return null;
};

export default StateBorderLayer;
