import { sanitize } from "dompurify";
import { useEffect, useState } from "react";

export interface RawHtmlProps {
  allowLinks?: boolean;
  html: string;
}

const RawHtml = ({ allowLinks = false, html }: RawHtmlProps) => {
  // The way we use dompurify relies on browser-only features, so sanitisation must be done via an
  // effect.
  //
  // In future, if we want to run this on the server, see: https://www.npmjs.com/package/dompurify#running-dompurify-on-the-server
  const [sanitisedHtml, setSanitisedHtml] = useState("");

  useEffect(() => {
    const clean = sanitize(html, {
      USE_PROFILES: { html: true },
      ALLOWED_URI_REGEXP: allowLinks ? undefined : /^$/,
    });

    setSanitisedHtml(clean);
  }, [allowLinks, html]);

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: sanitisedHtml }}
    />
  );
};

export default RawHtml;
