import styled from "styled-components";
import type { SocialTwitter } from "../../../../.rest-hooks/types";
import type { PostLink } from "../SocialFeed/socialFeedQuery";
import TweetHeaderActions from "../TweetHeader/TweetHeaderActions";
import TweetLinkedIncidents from "../TweetLinkedIncidents/TweetLinkedIncidents";
import TweetPost from "../TweetPost/TweetPost";
import type { TweetPostSize } from "../TweetPost/TweetPostLink";
import TweetValidationBadge from "../TweetValidationBadge/TweetValidationBadge";
import DrawerTweetValidationReasons from "../TweetValidationReasons/DrawerTweetValidationReasons";

export const StyledSocialPost = styled.div`
  display: grid;
  padding: 0.75rem;
  gap: 0.75rem;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
`;

export const StyledHeader = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export const StyledActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type SocialPostProps = {
  link: PostLink;
  primaryIncidentId?: string;
  showOverflowMenu?: boolean;
  showPrimaryIncident?: boolean;
  size?: TweetPostSize;
  tweet: SocialTwitter;
};

const SocialPost = ({
  link,
  primaryIncidentId,
  showOverflowMenu = true,
  showPrimaryIncident,
  size = "md",
  tweet,
}: SocialPostProps) => {
  return (
    <StyledSocialPost>
      <StyledHeader>
        <StyledActions>
          <TweetValidationBadge validated={tweet.attributes.validated} />
          {showOverflowMenu && (
            <TweetHeaderActions
              link={link}
              size="sm"
              tweet={tweet}
              viewLocation={false}
              viewPost={false}
            />
          )}
        </StyledActions>
        <TweetLinkedIncidents
          primaryIncidentId={primaryIncidentId}
          showPrimaryIncident={showPrimaryIncident}
          tweet={tweet}
        />
      </StyledHeader>
      <TweetPost tweet={tweet} fullWidth size={size} />
      {tweet.attributes.validated && (
        <DrawerTweetValidationReasons
          validatedAt={tweet.attributes.validatedAt}
          validatedBy={tweet.attributes.validatedUserEmail}
          validationNotes={tweet.attributes.validationNotes}
          validationReasons={tweet.attributes.validationReasons}
        />
      )}
    </StyledSocialPost>
  );
};

export default SocialPost;
