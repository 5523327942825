import { totalFireBanAreasLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import TotalFireBanAreasLayer from "./TotalFireBanAreasLayer";

const TotalFireBanAreas = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(totalFireBanAreasLayer.id)) {
    return null;
  }

  const state = getLayerState(totalFireBanAreasLayer.id);

  return <TotalFireBanAreasLayer opacity={state?.opacity} />;
};

export default TotalFireBanAreas;
