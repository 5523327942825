import styled from "styled-components";
import useLngLat from "./useLngLat";

export const StyledLngLat = styled.div`
  display: none;
  position: absolute;
  margin: auto;
  inset: auto 0.5rem 0 auto;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  padding: 0.125rem 0.75rem;
  ${(p) => p.theme.typography.variants.footnote}
  color: ${(p) => p.theme.colors.neutrals.text};
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-top-left-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  border-top-right-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  box-shadow: ${(p) => p.theme.boxShadows.md};
  font-family: monospace;
  font-variant-numeric: tabular-nums;
`;

const LngLat: React.FC = () => {
  const lngLat = useLngLat();

  return (
    <StyledLngLat data-testid="lng-lat-viewer">
      <div>Lng: {lngLat.lng}</div>
      <div>|</div>
      <div>Lat: {lngLat.lat}</div>
    </StyledLngLat>
  );
};

export default LngLat;
