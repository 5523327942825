import { useRouter } from "next/router";
import { useEffect } from "react";
import getActiveLayersFromQuery from "../../../config/getActiveLayersFromQuery";
import { rfsBrigades } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import RFSBrigadesLayer from "./RFSBrigadesLayer";

const RFSBrigades = () => {
  const { query } = useRouter();
  const { getLayerState, isLayerActive, activateLayer } =
    useActiveLayersContext();

  useEffect(() => {
    // TODO: Refactor this so that we have first-class support for
    // pre-activating layers from query params rather than needing to be
    // colocated with the layer component.
    const activeLayers = getActiveLayersFromQuery(query);

    if (activeLayers?.includes("rfsBrigades")) {
      activateLayer({
        id: rfsBrigades.id,
        source: "layers-drawer",
      });
    }
  }, [query, activateLayer]);

  if (isLayerActive(rfsBrigades.id)) {
    return (
      <RFSBrigadesLayer opacity={getLayerState(rfsBrigades.id)?.opacity} />
    );
  }

  return null;
};

export default RFSBrigades;
