import styled from "styled-components";
import makeTestId from "../../../utils/makeTestId";
import { useTimeline } from "../../util/TimelineProvider/TimelineProvider";
import HoursControls from "../HoursControls/HoursControls";
import GlobalPredictionControlPopoverTrigger from "../PredictionControlPopoverTrigger/GlobalPredictionControlPopoverTrigger";
import DateTimePill from "./DateTimePill";
import PredictionLegendToggleButton from "./PredictionLegendToggleButton";

const StyledPredictionController = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

interface PredictionControllerProps {
  disabled?: boolean;
  "data-testid"?: string;
}

const PredictionController = ({
  disabled,
  "data-testid": dataTestId,
}: PredictionControllerProps) => {
  const {
    timelineState: { date, hours, startDate },
    timelineDispatch,
  } = useTimeline();

  const onHoursChange = (newHours: number) => {
    timelineDispatch({
      payload: { hours: newHours },
      type: "setHours",
    });
  };

  return (
    <StyledPredictionController>
      <HoursControls
        disabled={disabled}
        hours={hours}
        onHoursChange={onHoursChange}
        startDate={startDate}
        data-testid={dataTestId}
      />
      <DateTimePill date={date} data-testid={makeTestId(dataTestId, "date")} />
      <StyledActions>
        <PredictionLegendToggleButton />
        <GlobalPredictionControlPopoverTrigger data-testid="global-prediction-controls" />
      </StyledActions>
    </StyledPredictionController>
  );
};

export default PredictionController;
