import type { Feature, MultiPolygon, Polygon } from "geojson";

export type BurntAreaCurrentFireSeasonFeature = Feature<
  Polygon | MultiPolygon,
  BurntAreaCurrentFireSeasonFeatureProps
>;

// Determined using:
//  - https://shared-athena.dev.apps.rfs.nsw.gov.au/arcgis/rest/services/Reference/WildfireHistory/MapServer/3?f=pjson
//  - https://desktop.arcgis.com/en/arcmap/latest/manage-data/geodatabases/arcgis-field-data-types.htm
interface BurntAreaCurrentFireSeasonFeatureProps {
  OBJECTID: string;
  BurntArea: number | null;
  CAPTUREDDATETIME: number;
  IncidentName: string | null;
}

export const BURNT_AREA_CURRENT_FIRE_SEASON_FIELD_NAMES = [
  "OBJECTID",
  "BurntArea",
  "CAPTUREDDATETIME",
  "IncidentName",
] as const;
