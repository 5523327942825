import { FieldGroup, PointScan, Table, Text } from "@app/design-system";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMouseLocation } from "../../../../state/MouseProvider";
import {
  convertLngLatToGdaProjection,
  getDdCoordinates,
  getDdmCoordinates,
  getDmsCoordinates,
  type EastingNorthingDisplay,
  type LngLatDisplay,
} from "../../../../utils/coordinateConversion";
import useUnsafeMapContext from "../../../map/Map/useUnsafeMapContext";
import MapRailDrawerCell from "../MapRailDrawerCell";
import { useMapRailContext } from "../MapRailProvider";
import { createMapRailItem } from "../useMapRail";
import PointDetailsView from "./PointDetailsView";

const railTabId = "coordinates";
const railTabLabel = "Coordinates";

const StyledDrawCellContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1875rem;
`;

const StyledCard = styled.div`
  display: grid;
  gap: 0.25rem;
  padding: 0.5rem;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};
  background: ${(p) => p.theme.colors.neutrals.background};
`;

const StyledLocationCard = styled(StyledCard)`
  padding: 0.75rem;
`;

const StyledTable = styled(Table)`
  padding: 0;
  border: none;
`;

const StyledHintText = styled(Text)`
  padding: 0 0.25rem;
`;

const CoordinateConversionDrawer = () => {
  const { selectedItems, remove } = useMapRailContext();
  const { mouseLocation } = useMouseLocation();
  const { map } = useUnsafeMapContext();
  const [savedCoordinates, setSavedCoordinates] = useState<LngLatObject | null>(
    null,
  );

  const geographicCoordinates = {
    DMS: getDmsCoordinates(mouseLocation),
    "DDM\n(Aviation)": getDdmCoordinates(mouseLocation),
    DD: getDdCoordinates(mouseLocation),
  } satisfies Record<string, LngLatDisplay>;

  const gda2020 = convertLngLatToGdaProjection(mouseLocation, "gda2020");

  // Add extra colours from COP (highlights the 6 figure map reference) and formatting for the table
  const formatGdaCoords = (coordinate: string) => {
    // Separate out the actual value at the start for highlighting. We highlight 3 of the last 5 digits to achieve this, eg "63 '390' 81" or "317848932784 '283' 33"
    const value = `${parseInt(coordinate, 10)}`;
    const content = coordinate.slice(value.length);

    return (
      <>
        {value.substring(0, value.length - 5)}
        <Text as="span" variant="manual" fontWeight="bold">
          {value.substring(value.length - 5, value.length - 2)}
        </Text>
        {value.substring(value.length - 2)}
        {content.split(" Zone ").join("\nZone ")}
      </>
    );
  };

  const gridCoordinates = {
    MGA: {
      easting: gda2020?.easting ? formatGdaCoords(gda2020.easting) : "Error",
      northing: gda2020?.northing ? formatGdaCoords(gda2020.northing) : "Error",
    },
  } satisfies Record<
    string,
    Record<keyof EastingNorthingDisplay, React.ReactNode>
  >;

  useEffect(() => {
    const updateLgaMapsheet = () => {
      if (!selectedItems.has(railTabId)) return;
      setSavedCoordinates(mouseLocation);
    };

    map?.on("click", updateLgaMapsheet);

    return () => {
      map?.off("click", updateLgaMapsheet);
    };
  });

  return (
    <MapRailDrawerCell
      label={railTabLabel}
      onClose={() => remove({ id: railTabId })}
    >
      <StyledDrawCellContent>
        <StyledLocationCard>
          <FieldGroup size="sm" title="Location">
            <PointDetailsView coordinates={savedCoordinates} />
          </FieldGroup>
          <Text size="footnote" variant="weak">
            Click to update location
          </Text>
        </StyledLocationCard>
        <StyledCard>
          <StyledTable
            data-testid="geographic-coordinate-conversion-table"
            columns={3}
            size="sm"
          >
            <thead>
              <Table.Row>
                <Table.HeaderCell style={{ width: "25%" }}>
                  Format
                </Table.HeaderCell>
                <Table.HeaderCell>Latitude</Table.HeaderCell>
                <Table.HeaderCell>Longitude</Table.HeaderCell>
              </Table.Row>
            </thead>
            <tbody>
              {Object.entries(geographicCoordinates).map(
                ([label, { lng, lat }]) => (
                  <Table.Row key={label}>
                    <Table.Cell>{label}</Table.Cell>
                    <Table.Cell>{lat}</Table.Cell>
                    <Table.Cell>{lng}</Table.Cell>
                  </Table.Row>
                ),
              )}
            </tbody>
          </StyledTable>
        </StyledCard>

        <StyledCard>
          <StyledTable
            data-testid="grid-coordinate-conversion-table"
            columns={3}
            size="sm"
          >
            <thead>
              <Table.Row>
                <Table.HeaderCell style={{ width: "27%" }}>
                  Grid
                </Table.HeaderCell>
                <Table.HeaderCell>Northing</Table.HeaderCell>
                <Table.HeaderCell>Easting</Table.HeaderCell>
              </Table.Row>
            </thead>
            <tbody>
              {Object.entries(gridCoordinates).map(
                ([label, { easting, northing }]) => (
                  <Table.Row key={label}>
                    <Table.Cell>{label}</Table.Cell>
                    <Table.Cell>{northing}</Table.Cell>
                    <Table.Cell>{easting}</Table.Cell>
                  </Table.Row>
                ),
              )}
            </tbody>
          </StyledTable>

          <StyledHintText size="footnote" variant="weak">
            {`GDA94 and GDA2020 are equivalent at Athena's level of precision`}
          </StyledHintText>
        </StyledCard>
      </StyledDrawCellContent>
    </MapRailDrawerCell>
  );
};

export const coordinateConversionMapRailItem = createMapRailItem({
  component: CoordinateConversionDrawer,
  icon: PointScan,
  iconOn: PointScan,
  label: "Coordinates",
  id: railTabId,
});

export default CoordinateConversionDrawer;
