import type { FeatureCollection } from "geojson";
import { useEffect } from "react";
import { MapLevel } from "../../../../config/layers/layers";
import catchAbortError from "../../../../utils/catchAbortError/catchAbortError";
import useMapContext from "../../Map/useMapContext";
import { isGeoJsonSource } from "../../types";
import { loadImage } from "../../utils/loadImage";

export interface UseFieldObsWeatherMapLayerParams {
  data: FeatureCollection | null;
  mapLayerId: string;
  opacity?: number;
}

export const useFieldObsWeatherMapLayer = ({
  data,
  mapLayerId,
  opacity = 1,
}: UseFieldObsWeatherMapLayerParams) => {
  const map = useMapContext();

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(mapLayerId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    loadImage({
      imageId: mapLayerId,
      map,
      src: "/icons/field-obs-weather.png",
      signal: controller.signal,
    }).then(() => {
      map.addLayer(
        {
          id: mapLayerId,
          type: "symbol",
          source: mapLayerId,
          layout: {
            "icon-image": mapLayerId,
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      if (map.getLayer(mapLayerId)) {
        map.removeLayer(mapLayerId);
      }
      if (map.getSource(mapLayerId)) {
        map.removeSource(mapLayerId);
      }
      if (map.hasImage(mapLayerId)) {
        map.removeImage(mapLayerId);
      }
      controller.abort();
    };
  }, [mapLayerId, map]);

  useEffect(() => {
    const source = map.getSource(mapLayerId);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [mapLayerId, data, map]);

  useEffect(() => {
    map.setPaintProperty(mapLayerId, "icon-opacity", opacity);
  }, [map, mapLayerId, opacity]);
};
