import { satelliteLayer } from "../../../config/layers/baseLayers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import SatelliteLayer from "./SatelliteLayer";

const Satellite = () => {
  const { isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(satelliteLayer.id)) {
    return null;
  }

  return <SatelliteLayer />;
};

export default Satellite;
