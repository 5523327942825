import { Field, FieldGrid, Skeleton, SkeletonButton } from "@app/design-system";
import styled from "styled-components";
import { StyledPreferredPredictionValidIndicator } from "../../ui/PreferredPredictionValidIndicator/PreferredPredictionValidIndicator";
import {
  StyledBody,
  StyledContent,
  StyledContentInner,
  StyledFields,
  StyledHeader,
  StyledHeading,
  StyledIncidentCard,
  StyledOverline,
  StyledSubtitle,
  StyledTitle,
} from "./styled";

const StyledSkeletonOverline = styled(StyledOverline)`
  padding: 4px 0;
`;

const SkeletonHeader = () => {
  return (
    <StyledHeader>
      <StyledSkeletonOverline>
        <Skeleton width={24} height={24} />
        <div>
          <Skeleton width={24} height={24} />
        </div>
      </StyledSkeletonOverline>
      <StyledHeading>
        <StyledTitle>
          <Skeleton width={232} />
        </StyledTitle>
        <StyledSubtitle>
          <Skeleton width={120} />
        </StyledSubtitle>
      </StyledHeading>
    </StyledHeader>
  );
};

export const SkeletonCollapsedIncidentCard = () => {
  return (
    <StyledIncidentCard>
      <SkeletonHeader />
    </StyledIncidentCard>
  );
};

export const SkeletonImpactPropbabilitiesSummary = () => {
  return (
    <Field label={<Skeleton width={160} />}>
      <FieldGrid>
        <FieldGrid.Item label={<Skeleton />}>
          <Skeleton />
        </FieldGrid.Item>
        <FieldGrid.Item label={<Skeleton />}>
          <Skeleton />
        </FieldGrid.Item>
        <FieldGrid.Item label={<Skeleton />}>
          <Skeleton />
        </FieldGrid.Item>
      </FieldGrid>
    </Field>
  );
};

const SkeletonIncidentCard = () => {
  return (
    <StyledIncidentCard>
      <SkeletonHeader />
      <StyledContent>
        <StyledContentInner>
          <StyledBody>
            <StyledFields>
              <StyledPreferredPredictionValidIndicator>
                <Skeleton />
              </StyledPreferredPredictionValidIndicator>
              <FieldGrid>
                <FieldGrid.Item label={<Skeleton />}>
                  <Skeleton />
                </FieldGrid.Item>
                <FieldGrid.Item label={<Skeleton />}>
                  <Skeleton />
                </FieldGrid.Item>
                <FieldGrid.Item label={<Skeleton />}>
                  <Skeleton />
                </FieldGrid.Item>
                <FieldGrid.Item label={<Skeleton />}>
                  <Skeleton />
                </FieldGrid.Item>
                <FieldGrid.Item label={<Skeleton />}>
                  <Skeleton />
                </FieldGrid.Item>
                <FieldGrid.Item label={<Skeleton />}>
                  <Skeleton />
                </FieldGrid.Item>
                <FieldGrid.Item label={<Skeleton />}>
                  <Skeleton />
                </FieldGrid.Item>
              </FieldGrid>
            </StyledFields>
            <SkeletonImpactPropbabilitiesSummary />
          </StyledBody>
          <SkeletonButton width={122} />
        </StyledContentInner>
      </StyledContent>
    </StyledIncidentCard>
  );
};

export default SkeletonIncidentCard;
