import { landUseLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import LandUseLayer from "./LandUseLayer";

const LandUse = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(landUseLayer.id)) {
    return null;
  }

  const state = getLayerState(landUseLayer.id);

  return <LandUseLayer opacity={state?.opacity} />;
};

export default LandUse;
