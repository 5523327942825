import IconNSW from "./IconNSW";
import Satellite from "./Satellite";
import Topographic from "./Topographic";

const BaseMaps = () => {
  return (
    <>
      <IconNSW />
      <Satellite />
      <Topographic />
    </>
  );
};

export default BaseMaps;
