import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";

const TELEPHONE_EXCHANGE_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/63/query`;
const QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: "exchgename, staddrtag, locality, srcagency, objectid",
  },
};

interface UseTelephoneExchangeMapLayerParams {
  layerId: string;
  opacity?: number;
}

const useTelephoneExchangeMapLayer = ({
  layerId,
  opacity = 1,
}: UseTelephoneExchangeMapLayerParams) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(TELEPHONE_EXCHANGE_URL, QUERY_OPTIONS);

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(layerId, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    loadImage({
      imageId: layerId,
      map,
      src: "/icons/telephone-exchange.png",
      signal: controller.signal,
    }).then(() => {
      map.addLayer(
        {
          id: layerId,
          type: "symbol",
          source: layerId,
          layout: {
            "icon-image": layerId,
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
      if (map.getSource(layerId)) {
        map.removeSource(layerId);
      }
      if (map.hasImage(layerId)) {
        map.removeImage(layerId);
      }
      controller.abort();
    };
  }, [layerId, map]);

  useEffect(() => {
    const source = map.getSource(layerId);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, layerId, map]);

  useEffect(() => {
    map.setPaintProperty(layerId, "icon-opacity", opacity);
  }, [layerId, map, opacity]);

  return null;
};

export default useTelephoneExchangeMapLayer;
