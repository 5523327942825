import { IconButton, type IconButtonSize, Location } from "@app/design-system";
import type { GeoJSONPoint } from "../../../../.rest-hooks/types/geojson.yml";
import QueryParams from "../../../config/queryParams";
import TaskLink from "../../util/TaskLink/TaskLink";
import type { PostLink } from "../SocialFeed/socialFeedQuery";

interface ViewTweetOnMapButtonProps {
  link: PostLink;
  location: GeoJSONPoint | undefined;
  size?: IconButtonSize;
  tweetId: string;
}

const ViewTweetOnMapButton = ({
  link,
  location,
  size = "md",
  tweetId,
}: ViewTweetOnMapButtonProps) => {
  const iconButton = (
    <IconButton
      icon={Location}
      label="View on map"
      size={size}
      tabIndex={-1}
      variant="ghost"
    />
  );

  const iconButtonDisabled = (
    <IconButton
      disabled
      icon={Location}
      label="View on map"
      size={size}
      variant="ghost"
    />
  );

  if (!location) {
    return iconButtonDisabled;
  }

  if (link.linkedTo === "linked_to_incident") {
    return (
      <TaskLink
        as={`/incidents/${link.incidentId}/social?${QueryParams.TWEET_ID}=${tweetId}&${QueryParams.INCIDENT_ID}=${link.incidentId}`}
        href={{
          pathname: "/incidents/[id]/social",
          query: {
            [QueryParams.INCIDENT_ID]: link.incidentId,
            [QueryParams.TWEET_ID]: tweetId,
          },
        }}
        legacyBehavior
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a rel="noreferrer" target="_blank">
          {iconButton}
        </a>
      </TaskLink>
    );
  }

  return (
    <TaskLink
      as={`/social?${QueryParams.TWEET_ID}=${tweetId}`}
      href={{
        pathname: `/social`,
        query: { [QueryParams.TWEET_ID]: tweetId },
      }}
      legacyBehavior
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a rel="noreferrer" target="_blank">
        {iconButton}
      </a>
    </TaskLink>
  );
};
export default ViewTweetOnMapButton;
