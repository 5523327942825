import { Tooltip, TypographyStatusIcon, buttonReset } from "@app/design-system";
import type { StatusVariant } from "@app/design-system/src/types/design-system";
import styled, { css } from "styled-components";

const statusStyles: Record<StatusVariant, ReturnType<typeof css>> = {
  warning: css`
    background-color: ${(p) => p.theme.colors.warning.backgroundStrong};

    &:hover {
      background-color: ${(p) => p.theme.colors.warning.backgroundStrongHover};
    }

    &:active {
      background-color: ${(p) => p.theme.colors.warning.backgroundStrongActive};
    }
  `,
  danger: css`
    background-color: ${(p) => p.theme.colors.error.backgroundStrong};

    &:hover {
      background-color: ${(p) => p.theme.colors.error.backgroundStrongHover};
    }

    &:active {
      background-color: ${(p) => p.theme.colors.error.backgroundStrongActive};
    }
  `,
  error: css`
    background-color: ${(p) => p.theme.colors.error.backgroundStrong};

    &:hover {
      background-color: ${(p) => p.theme.colors.error.backgroundStrongHover};
    }

    &:active {
      background-color: ${(p) => p.theme.colors.error.backgroundStrongActive};
    }
  `,
  info: css`
    background-color: ${(p) => p.theme.colors.informative.backgroundStrong};

    &:hover {
      background-color: ${(p) =>
        p.theme.colors.informative.backgroundStrongHover};
    }

    &:active {
      background-color: ${(p) =>
        p.theme.colors.informative.backgroundStrongActive};
    }
  `,
  success: css`
    background-color: ${(p) => p.theme.colors.success.backgroundStrong};

    &:hover {
      background-color: ${(p) => p.theme.colors.success.backgroundStrongHover};
    }

    &:active {
      background-color: ${(p) => p.theme.colors.success.backgroundStrongActive};
    }
  `,
  progress: css`
    background-color: ${(p) => p.theme.colors.informative.backgroundStrong};

    &:hover {
      background-color: ${(p) =>
        p.theme.colors.informative.backgroundStrongHover};
    }

    &:active {
      background-color: ${(p) =>
        p.theme.colors.informative.backgroundStrongActive};
    }
  `,
};

const StyledLabel = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.375rem;
`;

interface StyledMapRailButtonProps {
  status: StatusVariant;
}

const StyledMapRailButton = styled.button<StyledMapRailButtonProps>`
  ${buttonReset}
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
  box-shadow: ${(p) => p.theme.boxShadows.md};
  color: ${(p) => p.theme.colors.neutrals.textInverse};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${(p) => p.theme.typography.variants.buttonDefault}
  transition:
    background-color
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
    color ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};

  ${(p) => statusStyles[p.status]}
`;

const StyledAction = styled.div`
  ${(p) => p.theme.typography.variants.eyebrowDefault}
`;

interface MapRailPillProps {
  action?: string;
  children?: string;
  isDisabled?: boolean;
  onClick?: () => void;
  status: StatusVariant;
  tooltip?: string;
}

const MapRailPill = ({
  action,
  children,
  isDisabled,
  onClick,
  status,
  tooltip,
}: MapRailPillProps) => {
  return (
    <Tooltip delay message={tooltip} placement="bottom">
      <StyledMapRailButton
        data-disabled={isDisabled}
        onClick={onClick}
        status={status}
      >
        <StyledLabel>
          {!action && (
            <TypographyStatusIcon
              variant="inverse"
              size="buttonDefault"
              status={status}
            />
          )}
          {children}
        </StyledLabel>
        {action && <StyledAction>{action}</StyledAction>}
      </StyledMapRailButton>
    </Tooltip>
  );
};

export default MapRailPill;
