const FIELD_OBS_SIT_REP_FIELDS = ["Incident", "Threats"] as const;

export type FieldObsSitRepFields = (typeof FIELD_OBS_SIT_REP_FIELDS)[number];

export const FIELD_OBS_SIT_REP_MAP_FIELDS: Record<
  FieldObsSitRepFields,
  string
> = {
  Incident: "Type",
  Threats: "Threats",
};
