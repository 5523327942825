import { Text } from "@app/design-system";
import type mapboxgl from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { useBoolean } from "usehooks-ts";
import useMapContext from "../../../map/Map/useMapContext";
import MapPopupView from "../../../popup/SpatialPopup/MapPopupView";
import SpatialPopup from "../../../popup/SpatialPopup/SpatialPopup";
import PointDetailsView from "./PointDetailsView";

const PointDetailsContextPopup = () => {
  const map = useMapContext();

  const { value: isOpen, setTrue: open, setFalse: close } = useBoolean();

  const [coordinates, setCoordinates] = useState<LngLatObject | null>(null);

  useEffect(() => {
    const onContextMenu = (
      event: mapboxgl.MapMouseEvent | maplibregl.MapMouseEvent,
    ) => {
      setCoordinates({
        lat: event.lngLat.lat,
        lng: event.lngLat.lng,
      });

      open();
    };
    map.on("contextmenu", onContextMenu);

    return () => {
      map.off("contextmenu", onContextMenu);
    };
  }, [map, open]);

  const longTouchTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    let handleTouchMove: (
      moveEvent: mapboxgl.MapTouchEvent | maplibregl.MapTouchEvent,
    ) => void;

    const handleTouchStart = (event: mapboxgl.MapTouchEvent) => {
      // Clear any existing timeout
      clearTimeout(longTouchTimeoutRef.current);

      // Set a new timeout to trigger the long touch event
      longTouchTimeoutRef.current = setTimeout(() => {
        setCoordinates({
          lat: event.lngLat.lat,
          lng: event.lngLat.lng,
        });

        open();
      }, 700); // Adjust the timeout duration (in milliseconds) as needed

      // Store initial touch coordinates
      const initialTouchX = event.originalEvent.touches[0].clientX;
      const initialTouchY = event.originalEvent.touches[0].clientY;

      handleTouchMove = (
        moveEvent: mapboxgl.MapTouchEvent | maplibregl.MapTouchEvent,
      ) => {
        const currentTouchX = moveEvent.originalEvent.touches[0].clientX;
        const currentTouchY = moveEvent.originalEvent.touches[0].clientY;

        // Calculate the distance moved
        const deltaX = Math.abs(currentTouchX - initialTouchX);
        const deltaY = Math.abs(currentTouchY - initialTouchY);

        // Define a movement threshold (adjust as needed)
        const movementThreshold = 10; // Allow 10 pixels of movement

        if (deltaX > movementThreshold || deltaY > movementThreshold) {
          // Finger moved too much, cancel the long touch
          clearTimeout(longTouchTimeoutRef.current);

          map.off("touchmove", handleTouchMove);
        }
      };

      // Add touchmove listener
      map.on("touchmove", handleTouchMove);
    };

    const handleTouchEnd = () => {
      // Clear the timeout if the touch ends before the long touch duration
      map.off("touchmove", handleTouchMove);

      clearTimeout(longTouchTimeoutRef.current);
    };

    map.on("touchstart", handleTouchStart);
    map.on("touchend", handleTouchEnd);

    return () => {
      // Clean up event listeners when the component unmounts
      map.off("touchstart", handleTouchStart);
      map.off("touchend", handleTouchEnd);
      map.off("touchmove", handleTouchMove);
    };
  }, [map, open]);

  return (
    <SpatialPopup
      // Currently experiencing bug where interacting with dropdown menu closes
      // the modal on mobile. Prevent dismissing with click outside in this
      // instance in the meantime to prevent this behaviour.
      isDismissable={false}
      isOpen={isOpen}
      lngLat={coordinates ?? undefined}
      onClose={close}
      popupConfig={{
        id: `point${coordinates?.lat}${coordinates?.lng}`.replaceAll(".", ""),
        type: "click",
        size: "lg",
        offset: 8,
      }}
    >
      <MapPopupView
        disablePagination
        header={
          <Text size="eyebrowDefault" variant="weak">
            Location
          </Text>
        }
        onClose={close}
      >
        <PointDetailsView coordinates={coordinates} />
      </MapPopupView>
    </SpatialPopup>
  );
};

export default PointDetailsContextPopup;
