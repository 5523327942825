import { useEffect } from "react";
import { useInterval } from "usehooks-ts";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { TEN_SECONDS } from "./constants";
import useLoadAVLSymbology from "./useLoadAVLSymbology";
import type { AVLType } from "./utils";

interface UseAVLPositionsMapDataParams {
  accessToken: string;
  avlType: AVLType;
  layerId: string;
  queryOptions: QueryOptions;
  queryUrl: string;
  sourceId: string;
  symbologyOverrides?: Partial<Record<string, string>>;
  symbologyUrl: string;
}

const useAVLPositionsMapData = ({
  accessToken,
  avlType,
  layerId,
  queryOptions,
  queryUrl,
  sourceId,
  symbologyOverrides,
  symbologyUrl,
}: UseAVLPositionsMapDataParams) => {
  const map = useMapContext();

  const { data, refetch } = useMapServerQueryData(queryUrl, queryOptions);

  useInterval(() => {
    refetch();
  }, TEN_SECONDS);

  useLoadAVLSymbology({
    accessToken,
    avlType,
    layerId,
    map,
    symbologyOverrides,
    symbologyUrl,
  });

  useEffect(() => {
    const source = map.getSource(sourceId);
    if (isGeoJsonSource(source) && data) {
      source.setData({
        ...data,
        features: data.features.map((feature) => ({
          ...feature,
          // NOTE: We add the avlType property to handle cases where appliance and portable icons have overlapping StatusCategory values
          properties: { ...feature.properties, avlType },
        })),
      });
    }
  }, [avlType, data, map, sourceId]);
};

export default useAVLPositionsMapData;
