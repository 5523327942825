import type { Feature } from "geojson";
import type { LngLatLike } from "mapbox-gl";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isPointFeature } from "../types";

export interface AVLData {
  BfNumber: string;
  Brigade: string;
  CallSign: string;
  Category: string;
  Description: string;
  DeviceType: string;
  Emergency: string;
  GpsStatus: string;
  LicenseNo: string;
  RadioId: number;
  ReportAge: string;
  Status: string;
  TalkGroupId: string;
}

const defaultEmpty = (value: string | undefined): string => {
  if (!value || value === "N/A") return "";
  return value;
};

export function getPropertiesFromFeature(
  feature: Feature,
): AVLInteractionProperties | null {
  if (!isPointFeature(feature)) {
    return null;
  }

  const lngLat = feature.geometry.coordinates as LngLatLike;

  return {
    data: {
      BfNumber: defaultEmpty(feature.properties?.BfNumber),
      Brigade: feature.properties?.Brigade ?? "",
      CallSign: feature.properties?.CallSign ?? "",
      Category: feature.properties?.Category ?? "",
      Description: feature.properties?.Description ?? "",
      DeviceType: feature.properties?.DeviceType ?? "",
      Emergency: feature.properties?.Emergency ?? "",
      GpsStatus: feature.properties?.GpsStatus ?? "",
      LicenseNo: feature.properties?.LicenseNo ?? "",
      RadioId: feature.properties?.RadioId ?? 0,
      ReportAge: feature.properties?.ReportAge ?? "",
      Status: feature.properties?.Status ?? "",
      TalkGroupId: feature.properties?.TalkGroupId ?? "",
    },
    featureId: feature.properties?.RadioId,
    lngLat,
  };
}

export interface AVLInteractionProperties extends FeatureInteractionProperties {
  data: AVLData;
}

export type AVLInteractionState =
  FeatureInteractionState<AVLInteractionProperties>;
