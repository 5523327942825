import { incidentIcons } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import IncidentPointCollectionLayer, {
  type IncidentPopupRenderProps,
} from "./IncidentPointCollectionLayer";
import useGetIncidentPointCollection from "./useGetIncidentPointCollection";

interface IncidentPointCollectionProps {
  renderPopup: (props: IncidentPopupRenderProps) => React.ReactNode;
}

const IncidentPointCollection = ({
  renderPopup,
}: IncidentPointCollectionProps) => {
  const incidentPointCollection = useGetIncidentPointCollection();
  const { isLayerActive } = useActiveLayersContext();

  if (!incidentPointCollection || !isLayerActive(incidentIcons.id)) return null;

  return (
    <IncidentPointCollectionLayer
      geojson={incidentPointCollection}
      renderPopup={renderPopup}
    />
  );
};

export default IncidentPointCollection;
