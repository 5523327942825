import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";

const AIRPORTS_ID = "airportsAndHelipads";

// CLASSSUBTYPE 1 = AIRPORTS & 3 = HELIPADS
const AIRPORTS_AND_HELIPADS = {
  1: "/icons/airport-airport.png",
  3: "/icons/airport-helipad.png",
};

const AIRPORTS_QUERY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/59/query`;
const QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: "CLASSSUBTYPE",
    where: "CLASSSUBTYPE=1 OR CLASSSUBTYPE=3",
  },
};

interface AirportsLayerProps {
  opacity?: number;
}

const AirportsLayer = ({ opacity = 1 }: AirportsLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(AIRPORTS_QUERY_URL, QUERY_OPTIONS);

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(AIRPORTS_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    Promise.all(
      Object.entries(AIRPORTS_AND_HELIPADS).map(([imageId, src]) =>
        loadImage({ imageId, map, src, signal: controller.signal }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: AIRPORTS_ID,
          type: "symbol",
          source: AIRPORTS_ID,
          layout: {
            "icon-image": ["get", "CLASSSUBTYPE"],
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      if (map.getLayer(AIRPORTS_ID)) {
        map.removeLayer(AIRPORTS_ID);
      }
      if (map.getSource(AIRPORTS_ID)) {
        map.removeSource(AIRPORTS_ID);
      }
      Object.keys(AIRPORTS_AND_HELIPADS).forEach((id) => {
        if (map.hasImage(id)) {
          map.removeImage(id);
        }
      });
    };
  }, [map]);

  useEffect(() => {
    const source = map?.getSource(AIRPORTS_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(AIRPORTS_ID, "icon-opacity", opacity);
  }, [map, opacity]);

  return null;
};

export default AirportsLayer;
