import { Skeleton } from "@app/design-system";
import styled from "styled-components";

const StyledSocialAttributes = styled.div`
  display: flex;
  padding: 0 16px 16px;
  flex-direction: column;
  gap: 8px;
`;

const StyledTitle = styled.div`
  ${(p) => p.theme.typography.variants.subtitleMd}
`;

const StyledMeta = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledMetaAttribute = styled.div`
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  ${(p) => p.theme.typography.variants.footnote}
`;

const StyledBody = styled.div`
  ${(p) => p.theme.typography.variants.bodyDefault};
  overflow-wrap: break-word;
`;

const StyledHashtags = styled(StyledBody)`
  ${(p) => p.theme.typography.variants.bodyDefault};
  overflow-wrap: break-word;
  color: ${(p) => p.theme.colors.social.link};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SkeletonSocialAttributes = () => {
  return (
    <StyledSocialAttributes>
      <div>
        <StyledTitle>
          <Skeleton width={180} />
        </StyledTitle>
        <div>
          <Skeleton width={120} />
        </div>
      </div>
      <StyledMeta>
        <StyledMetaAttribute>
          <Skeleton width={140} />
        </StyledMetaAttribute>
        <StyledMetaAttribute>
          <Skeleton width={200} />
        </StyledMetaAttribute>
      </StyledMeta>
      <div>
        <StyledBody>
          <Skeleton />
          <Skeleton />
        </StyledBody>
        <div>
          <StyledHashtags>
            <Skeleton />
          </StyledHashtags>
        </div>
      </div>
    </StyledSocialAttributes>
  );
};

export default SkeletonSocialAttributes;
