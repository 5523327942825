import { TabFooter } from "@app/design-system";
import styled from "styled-components";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import LayersList from "./LayersList";
import ResetLayersButton from "./ResetLayersButton";

const StyledLayersBottomTab = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  padding: 0.5rem;
  gap: 0.5rem;
`;

const LayersBottomTab = () => {
  const { getLayersBySource } = useActiveLayersContext();

  const layers = getLayersBySource("layers-drawer");
  const showResetLayersButton = !!layers.size;

  return (
    <StyledLayersBottomTab>
      <LayersList isMapRailEnabled />
      {showResetLayersButton && (
        <TabFooter>
          <ResetLayersButton />
        </TabFooter>
      )}
    </StyledLayersBottomTab>
  );
};

export default LayersBottomTab;
