import { sevenPlusYearsLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import SevenPlusYearsLayer from "./SevenPlusYearsLayer";

const SevenPlusYears = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(sevenPlusYearsLayer.id)) {
    return null;
  }

  const state = getLayerState(sevenPlusYearsLayer.id);

  return <SevenPlusYearsLayer opacity={state?.opacity} />;
};

export default SevenPlusYears;
