import type { SocialTwitter } from "../../../../.rest-hooks/types";
import LinkTweetToIncidentButton from "../LinkTweetToIncidentButton/LinkTweetToIncidentButton";
import LinkedIncidents from "../LinkedIncidents/LinkedIncidents";
import type { PostLink } from "../SocialFeed/socialFeedQuery";

type TweetLinkedIncidentsProps = {
  link?: PostLink;
  primaryIncidentId?: string;
  showPrimaryIncident?: boolean;
  tweet: SocialTwitter;
};

const TweetLinkedIncidents = ({
  link,
  primaryIncidentId,
  showPrimaryIncident,
  tweet,
}: TweetLinkedIncidentsProps) => {
  const { incidentIds } = tweet.attributes;
  const hasLinkedIncidents = !!incidentIds?.length;

  if (!hasLinkedIncidents && link) {
    return <LinkTweetToIncidentButton link={link} tweet={tweet} />;
  }

  if (!incidentIds) {
    return null;
  }

  return (
    <LinkedIncidents
      linkedIncidents={incidentIds}
      primaryIncidentId={primaryIncidentId}
      showPrimaryIncident={showPrimaryIncident}
      tweetId={tweet.id}
    />
  );
};

export default TweetLinkedIncidents;
