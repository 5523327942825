import AVLPositionsLayer from "../AVLPositions/AVLPositionsLayer";
import {
  AVL_DATA_OPTION_APPLIANCES_QUERY_URL,
  AVL_DATA_OPTION_APPLIANCES_SYMBOLOGY_URL,
  AVL_DATA_OPTION_PORTABLE_QUERY_URL,
  AVL_DATA_OPTION_PORTABLE_SYMBOLOGY_URL,
  AVL_EMERGENCY_APPLIANCES_LAYER_ID,
  AVL_EMERGENCY_APPLIANCES_SOURCE_ID,
  AVL_EMERGENCY_PORTABLE_LAYER_ID,
  AVL_EMERGENCY_PORTABLE_SOURCE_ID,
  AVL_EMERGENCY_QUERY_OPTIONS,
  AVL_PORTABLE_SYMBOLOGY_OVERRIDES,
} from "../AVLPositions/constants";

interface AVLDataLayerProps {
  accessToken: string;
  isCopView: boolean;
}

const AVLEmergencyLayer = ({ accessToken, isCopView }: AVLDataLayerProps) => {
  return (
    <>
      <AVLPositionsLayer
        accessToken={accessToken}
        avlType="appliance"
        isCopView={isCopView}
        layerId={AVL_EMERGENCY_APPLIANCES_LAYER_ID}
        queryOptions={AVL_EMERGENCY_QUERY_OPTIONS}
        queryUrl={AVL_DATA_OPTION_APPLIANCES_QUERY_URL}
        sourceId={AVL_EMERGENCY_APPLIANCES_SOURCE_ID}
        symbologyUrl={AVL_DATA_OPTION_APPLIANCES_SYMBOLOGY_URL}
      />
      <AVLPositionsLayer
        accessToken={accessToken}
        avlType="portable"
        isCopView={isCopView}
        layerId={AVL_EMERGENCY_PORTABLE_LAYER_ID}
        queryOptions={AVL_EMERGENCY_QUERY_OPTIONS}
        queryUrl={AVL_DATA_OPTION_PORTABLE_QUERY_URL}
        sourceId={AVL_EMERGENCY_PORTABLE_SOURCE_ID}
        symbologyOverrides={AVL_PORTABLE_SYMBOLOGY_OVERRIDES}
        symbologyUrl={AVL_DATA_OPTION_PORTABLE_SYMBOLOGY_URL}
      />
    </>
  );
};

export default AVLEmergencyLayer;
