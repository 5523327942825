/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  DeleteSocialFeedPins200,
  DeleteSocialFeedPinsBody,
  GetSocialFeed200,
  GetSocialFeedParams,
  GetSocialFeedPins200,
  GetSocialFeedPinsParams,
  PutSocialFeedPins200,
  PutSocialFeedPinsBody,
} from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";

/**
 * Get the top-level sections for the social intelligence views.

Content (e.g. tweets) is grouped by incident. Content not linked to an incident will be in a
group with a `null` incident.

 * @summary Get social feed sections
 */
export const getSocialFeed = (
  params?: GetSocialFeedParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetSocialFeed200>> => {
  return axios.get(`/social/feed`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetSocialFeedQueryKey = (params?: GetSocialFeedParams) => {
  return [`/social/feed`, ...(params ? [params] : [])] as const;
};

export const getGetSocialFeedQueryOptions = <
  TData = Awaited<ReturnType<typeof getSocialFeed>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetSocialFeedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getSocialFeed>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSocialFeedQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSocialFeed>>> = ({
    signal,
  }) => getSocialFeed(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSocialFeed>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSocialFeedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSocialFeed>>
>;
export type GetSocialFeedQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get social feed sections
 */
export const useGetSocialFeed = <
  TData = Awaited<ReturnType<typeof getSocialFeed>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetSocialFeedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getSocialFeed>>, TError, TData>
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSocialFeedQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * List the sections in the social intelligence views that have been pinned by the current
user.

Sorted by the time each section was pinned, in ascending order.

 * @summary List pinned social feed sections
 */
export const getSocialFeedPins = (
  params?: GetSocialFeedPinsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetSocialFeedPins200>> => {
  return axios.get(`/social/feed/pins`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetSocialFeedPinsQueryKey = (
  params?: GetSocialFeedPinsParams,
) => {
  return [`/social/feed/pins`, ...(params ? [params] : [])] as const;
};

export const getGetSocialFeedPinsQueryOptions = <
  TData = Awaited<ReturnType<typeof getSocialFeedPins>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetSocialFeedPinsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSocialFeedPins>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSocialFeedPinsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSocialFeedPins>>
  > = ({ signal }) => getSocialFeedPins(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSocialFeedPins>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSocialFeedPinsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSocialFeedPins>>
>;
export type GetSocialFeedPinsQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary List pinned social feed sections
 */
export const useGetSocialFeedPins = <
  TData = Awaited<ReturnType<typeof getSocialFeedPins>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetSocialFeedPinsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSocialFeedPins>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSocialFeedPinsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Pin a section in the social intelligence views for the current user.
 * @summary Pin social feed section
 */
export const putSocialFeedPins = (
  putSocialFeedPinsBody: PutSocialFeedPinsBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PutSocialFeedPins200>> => {
  return axios.put(`/social/feed/pins`, putSocialFeedPinsBody, options);
};

export const getPutSocialFeedPinsMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSocialFeedPins>>,
    TError,
    { data: PutSocialFeedPinsBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSocialFeedPins>>,
  TError,
  { data: PutSocialFeedPinsBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSocialFeedPins>>,
    { data: PutSocialFeedPinsBody }
  > = (props) => {
    const { data } = props ?? {};

    return putSocialFeedPins(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSocialFeedPinsMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSocialFeedPins>>
>;
export type PutSocialFeedPinsMutationBody = PutSocialFeedPinsBody;
export type PutSocialFeedPinsMutationError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Pin social feed section
 */
export const usePutSocialFeedPins = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSocialFeedPins>>,
    TError,
    { data: PutSocialFeedPinsBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getPutSocialFeedPinsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Un-pin a section in the social intelligence views for the current user.
 * @summary Un-pin social feed section
 */
export const deleteSocialFeedPins = (
  deleteSocialFeedPinsBody: DeleteSocialFeedPinsBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DeleteSocialFeedPins200>> => {
  return axios.delete(`/social/feed/pins`, {
    data: deleteSocialFeedPinsBody,
    ...options,
  });
};

export const getDeleteSocialFeedPinsMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSocialFeedPins>>,
    TError,
    { data: DeleteSocialFeedPinsBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSocialFeedPins>>,
  TError,
  { data: DeleteSocialFeedPinsBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSocialFeedPins>>,
    { data: DeleteSocialFeedPinsBody }
  > = (props) => {
    const { data } = props ?? {};

    return deleteSocialFeedPins(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSocialFeedPinsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSocialFeedPins>>
>;
export type DeleteSocialFeedPinsMutationBody = DeleteSocialFeedPinsBody;
export type DeleteSocialFeedPinsMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Un-pin social feed section
 */
export const useDeleteSocialFeedPins = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSocialFeedPins>>,
    TError,
    { data: DeleteSocialFeedPinsBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getDeleteSocialFeedPinsMutationOptions(options);

  return useMutation(mutationOptions);
};
