import { SeverityLevel } from "../../.rest-hooks/types/common.yml";
import type { BrigadeCoverageSnapshotValid } from "../../.rest-hooks/types/coverage.yml";

interface GetCoverageGapSeverityProps {
  isFbiAvailable: boolean;
  snapshot: BrigadeCoverageSnapshotValid | "error";
}

const getCoverageGapSeverity = ({
  isFbiAvailable,
  snapshot,
}: GetCoverageGapSeverityProps) => {
  if (!isFbiAvailable || snapshot === "error") {
    return SeverityLevel.unknown;
  }

  if (snapshot.assessment) {
    return "assessed";
  }

  return snapshot.coverageImpactSeverity;
};

export default getCoverageGapSeverity;
