import styled from "styled-components";
import type { SiteInformationInteractionState } from "../../map/SiteInformation/interactions";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import SiteInformationPopupDetail from "./SiteInformationPopupDetail";

const StyledHeader = styled.div`
  ${(p) => p.theme.typography.variants.eyebrowDefault};
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  margin: auto 0;
`;

export interface SiteInformationPopupProps {
  "data-testid"?: string;
  onClose: () => void;
  state: SiteInformationInteractionState;
}

const SiteInformationPopup = ({
  "data-testid": dataTestId,
  onClose,
  state: { id, isActive, properties },
}: SiteInformationPopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      lngLat={properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        id,
        offset: 16,
        size: "lg",
        type: "click",
      }}
    >
      {properties?.data ? (
        <MapPopupView
          data-testid={dataTestId}
          header={<StyledHeader>{properties.data.Status}</StyledHeader>}
          onClose={onClose}
        >
          <SiteInformationPopupDetail properties={properties} />
        </MapPopupView>
      ) : (
        <MapPopupView
          data-testid={`${dataTestId}-error`}
          header={<StyledHeader>Failed to load site information</StyledHeader>}
          onClose={onClose}
        />
      )}
    </SpatialPopup>
  );
};

export default SiteInformationPopup;
