import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";

const SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID =
  "substationsAndTransmissionStations";
const POWERLINES = "/icons/power-transmission-lines.png";
const STATIONS = "/icons/power-transmission-stations-and-power-stations.png";

const SUBSTATIONS_TRANSMISSION_STATIONS_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/7/query`;
const SUBSTATIONS_TRANSMISSION_STATIONS_QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    where:
      "CLASSSUBTYPE=5 AND BUILDINGCOMPLEXTYPE=10 OR BUILDINGCOMPLEXTYPE=11",
  },
};
const TRANSMISSION_LINES_ID = "transmissionLines";
const TRANSMISSION_LINES_QUERY_OPTIONS: QueryOptions = {
  minZoom: 7,
};
const TRANSMISSION_LINES_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/NSWVectorsOverview/MapServer/21/query`;

interface PowerInfrastructureLayerProps {
  opacity?: number;
}

export const PowerInfrastructureLayer = ({
  opacity = 1,
}: PowerInfrastructureLayerProps) => {
  const map = useMapContext();

  const { data: subStationsData } = useMapServerQueryData(
    SUBSTATIONS_TRANSMISSION_STATIONS_URL,
    SUBSTATIONS_TRANSMISSION_STATIONS_QUERY_OPTIONS,
  );

  const { data: transmissionLinesData } = useMapServerQueryData(
    TRANSMISSION_LINES_URL,
    TRANSMISSION_LINES_QUERY_OPTIONS,
  );

  useEffect(() => {
    const controller = new AbortController();

    loadImage({ imageId: TRANSMISSION_LINES_ID, map, src: POWERLINES }).catch(
      catchAbortError,
    );
    loadImage({
      imageId: SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID,
      map,
      src: STATIONS,
    }).catch(catchAbortError);

    map.addSource(SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addSource(TRANSMISSION_LINES_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID,
        type: "symbol",
        source: SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID,
        layout: {
          "icon-image": SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID,
          "icon-allow-overlap": true,
        },
      },
      MapLevel.SYMBOLS,
    );

    map.addLayer(
      {
        id: TRANSMISSION_LINES_ID,
        type: "line",
        source: TRANSMISSION_LINES_ID,
        paint: {
          "line-color": "#000",
          "line-width": 10,
          "line-pattern": TRANSMISSION_LINES_ID,
        },
        minzoom: 8,
      },
      MapLevel.SYMBOLS,
    );

    return () => {
      controller.abort();
      if (map.getLayer(SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID)) {
        map.removeLayer(SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID);
      }
      if (map.getSource(SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID)) {
        map.removeSource(SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID);
      }
      if (map.getLayer(TRANSMISSION_LINES_ID)) {
        map.removeLayer(TRANSMISSION_LINES_ID);
      }
      if (map.getSource(TRANSMISSION_LINES_ID)) {
        map.removeSource(TRANSMISSION_LINES_ID);
      }
      if (map.hasImage(SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID)) {
        map.removeImage(SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID);
      }
      if (map.hasImage(TRANSMISSION_LINES_ID)) {
        map.removeImage(TRANSMISSION_LINES_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    if (subStationsData) {
      const source = map?.getSource(SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID);
      if (isGeoJsonSource(source)) {
        source.setData(subStationsData);
      }
    }
  }, [map, subStationsData]);

  useEffect(() => {
    if (transmissionLinesData) {
      const source = map?.getSource(TRANSMISSION_LINES_ID);
      if (isGeoJsonSource(source)) {
        source.setData(transmissionLinesData);
      }
    }
  }, [transmissionLinesData, map]);

  useEffect(() => {
    map.setPaintProperty(
      SUBSTATIONS_AND_TRANSMISSION_STATIONS_ID,
      "icon-opacity",
      opacity,
    );
    map.setPaintProperty(TRANSMISSION_LINES_ID, "line-opacity", opacity);
  }, [map, opacity]);

  return null;
};
