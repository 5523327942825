import {
  DropdownOptionItem,
  FieldGrid,
  Select,
  type FormControlOption,
} from "@app/design-system";
import { useLocalStorage } from "@kablamo/kerosene-ui";
import { useQuery } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import * as yup from "yup";
import { LocalStorageKeys } from "../../../../config/storage";
import useAuthAccessToken from "../../../../hooks/useAuthAccessToken";
import { EMDASH } from "../../../../lib/strings";
import {
  convertLngLatToGdaProjection,
  getDdCoordinates,
  getDdmCoordinates,
  getDmsCoordinates,
} from "../../../../utils/coordinateConversion";
import getMapServerProxyBasepath from "../../../../utils/getMapServerProxyBasepath";
import CopyableText from "./CopyableText";

const coordinateFormats = ["dms", "ddm", "dd", "mga"] as const;

type CoordinateFormat = (typeof coordinateFormats)[number];

const coordinateFormatLabels = {
  dd: "DD",
  ddm: "DDM",
  dms: "DMS",
  mga: "MGA",
} as const satisfies Record<CoordinateFormat, string>;

const coordinateFormatOptions = coordinateFormats.map<
  FormControlOption<CoordinateFormat>
>((format) => ({ label: coordinateFormatLabels[format], value: format }));

const isCoordinateFormat = (value: unknown): value is CoordinateFormat => {
  return (
    typeof value === "string" &&
    coordinateFormats.includes(value as CoordinateFormat)
  );
};

interface FormatCoordinatesParams {
  coordinateFormat: CoordinateFormat;
  coordinates: LngLatObject | null;
}

const formatCoordinates = ({
  coordinateFormat,
  coordinates,
}: FormatCoordinatesParams) => {
  if (!coordinates) {
    return EMDASH;
  }

  switch (coordinateFormat) {
    case "dms": {
      const coords = getDmsCoordinates(coordinates);
      return `${coords.lat}, ${coords.lng}`;
    }
    case "ddm": {
      const coords = getDdmCoordinates(coordinates);
      return `${coords.lat}, ${coords.lng}`;
    }
    case "dd": {
      const coords = getDdCoordinates(coordinates);
      return `${coords.lat}, ${coords.lng}`;
    }
    case "mga": {
      const coords = convertLngLatToGdaProjection(coordinates, "gda2020");

      return coords ? `${coords.northing}, ${coords.easting}` : EMDASH;
    }
  }
};

const lgaSchema = yup.object({
  features: yup.array(
    yup.object({ attributes: yup.object({ LGANAME: yup.string() }) }),
  ),
});

const mapsheetSchema = yup.object({
  features: yup.array(
    yup.object({ attributes: yup.object({ MAPTITLE: yup.string() }) }),
  ),
});

type GisPointType = "LGA" | "Mapsheet";

interface FetchGisDataParams {
  accessToken: string | undefined;
  coordinates: LngLatObject;
  type: GisPointType;
}

const fetchGisData = (params: FetchGisDataParams) => {
  const query = new URLSearchParams({
    f: "json",
    geometry: JSON.stringify({
      x: params.coordinates.lng,
      y: params.coordinates.lat,
      spatialReference: { wkid: 4326 },
    }),
    geometryType: "esriGeometryPoint",
    returnGeometry: "false",
    spatialRel: "esriSpatialRelIntersects",
    inSR: "4326",
    outFields: params.type === "Mapsheet" ? "MAPTITLE" : "LGANAME",
    outSR: "4326",
  }).toString();
  const url = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/${
    params.type === "Mapsheet" ? 19 : 20
  }/query?${query}`;

  return fetch(url, {
    headers: url.includes("shared-athena")
      ? { Authorization: `Bearer ${params.accessToken}` }
      : undefined,
  })
    .then((response) => response.json())
    .then((data) =>
      params.type === "Mapsheet"
        ? mapsheetSchema.validateSync(data)
        : lgaSchema.validateSync(data),
    );
};

interface GisPointLookupProps {
  coordinates: LngLatObject;
  type: GisPointType;
}

const GisPointLookup = ({ coordinates, type }: GisPointLookupProps) => {
  const accessToken = useAuthAccessToken();
  const {
    data: value,
    isPending,
    isError,
  } = useQuery({
    queryKey: ["point-lookup", type, coordinates.lat, coordinates.lng],
    queryFn: () =>
      fetchGisData({ accessToken, coordinates, type }).then((data) => {
        switch (type) {
          case "LGA": {
            return (
              (data as yup.InferType<typeof lgaSchema>).features?.[0]
                ?.attributes.LGANAME ?? ""
            );
          }
          case "Mapsheet": {
            return (
              (data as yup.InferType<typeof mapsheetSchema>).features?.[0]
                ?.attributes.MAPTITLE ?? ""
            );
          }
        }
      }),
  });

  if (isPending) {
    return <Skeleton />;
  }

  if (isError) {
    return "Error, try again";
  }

  return value ? (
    <CopyableText textToCopy={value}>{value}</CopyableText>
  ) : (
    EMDASH
  );
};

interface PointDetailsViewProps {
  coordinates: LngLatObject | null;
}

const PointDetailsView = ({ coordinates }: PointDetailsViewProps) => {
  const [coordinateFormat, setCoordinateFormat] = useLocalStorage(
    LocalStorageKeys.COORDINATE_FORMAT,
    "dd",
    isCoordinateFormat,
  );

  const formattedCoordinates = formatCoordinates({
    coordinateFormat,
    coordinates,
  });

  return (
    <FieldGrid layout="compact">
      <FieldGrid.Item
        labelAlign="center"
        labelJustify="start"
        label={
          <Select
            onChange={(format) => format && setCoordinateFormat(format.value)}
            options={coordinateFormatOptions}
            renderOption={({
              disabled,
              focused,
              hasSelection,
              itemProps,
              option,
              selected,
            }) => (
              <DropdownOptionItem
                {...itemProps}
                disabled={disabled}
                focused={focused}
                hasSelection={hasSelection}
                selected={selected}
              >
                {option.label}
                {option.value === "ddm" && <> (Aviation)</>}
              </DropdownOptionItem>
            )}
            size="xs"
            value={coordinateFormatOptions.find(
              ({ value }) => value === coordinateFormat,
            )}
          />
        }
      >
        {coordinates ? (
          <CopyableText textToCopy={formattedCoordinates}>
            {formattedCoordinates}
          </CopyableText>
        ) : (
          EMDASH
        )}
      </FieldGrid.Item>
      <FieldGrid.Item label="LGA:">
        {coordinates ? (
          <GisPointLookup coordinates={coordinates} type="LGA" />
        ) : (
          EMDASH
        )}
      </FieldGrid.Item>
      <FieldGrid.Item label="Mapsheet:">
        {coordinates ? (
          <GisPointLookup coordinates={coordinates} type="Mapsheet" />
        ) : (
          EMDASH
        )}
      </FieldGrid.Item>
    </FieldGrid>
  );
};

export default PointDetailsView;
