import truncate from "lodash/truncate";

interface TweetUrlProps {
  length?: number;
  url: string | undefined;
}
const TweetUrl = ({ length = 45, url }: TweetUrlProps) => {
  if (!url) {
    return null;
  }

  const formattedUrl = url.replaceAll(/(?:https?|ftp):\/\/+/gi, "");

  return <>{truncate(formattedUrl, { length })}</>;
};

export default TweetUrl;
