import isInteger from "lodash/isInteger";
import getMapServerProxyBasepath from "../../../../utils/getMapServerProxyBasepath";
import FieldObsWeatherPopup from "../../../popup/FieldObsWeatherPopup/FieldObsWeatherPopup";
import { FIELD_OBS_WEATHER_FIELD_NAMES } from "../../../popup/FieldObsWeatherPopup/constants";
import useLayerInteractions from "../../MapInteractions/useLayerInteractions";
import {
  useMapServerQueryData,
  type QueryOptions,
} from "../../hooks/useMapServerQueryData/useMapServerQueryData";
import { FIELD_OBS_WEATHER_GIS_FIELD_NAMES } from "./gis";
import { getPropertiesFromFeature } from "./interactions";
import { useFieldObsWeatherMapLayer } from "./useFieldObsWeatherMapLayer";

const FIELD_OBS_WEATHER_LAYER_PATH =
  "/arcgis/rest/services/FieldObs/WeatherObPresentation/FeatureServer";
const QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: FIELD_OBS_WEATHER_GIS_FIELD_NAMES.join(","),
  },
};

export interface FieldObsWeatherLayerProps {
  mapLayerId: string;
  opacity?: number;
  popupTitle: string;
  sourceLayerId: number;
}

const FieldObsWeatherLayer = ({
  mapLayerId,
  opacity,
  popupTitle,
  sourceLayerId,
}: FieldObsWeatherLayerProps) => {
  if (!isInteger(sourceLayerId)) {
    throw new Error(
      `source layer ID must be an integer, got: ${sourceLayerId}`,
    );
  }

  const sourceLayerUrlPath = `${FIELD_OBS_WEATHER_LAYER_PATH}/${sourceLayerId}`;
  const { data } = useMapServerQueryData(
    `${getMapServerProxyBasepath()}${sourceLayerUrlPath}/query`,
    QUERY_OPTIONS,
  );

  useFieldObsWeatherMapLayer({ data, mapLayerId, opacity });

  const { clickedState, deactivateClickState } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: mapLayerId,
  });

  return (
    <FieldObsWeatherPopup
      fields={FIELD_OBS_WEATHER_FIELD_NAMES}
      onClose={deactivateClickState}
      sourceLayerUrlPath={sourceLayerUrlPath}
      state={clickedState}
      title={popupTitle}
    />
  );
};

export default FieldObsWeatherLayer;
