import styled from "styled-components";

const StyledMapPreview = styled.div`
  ${(p) => p.theme.typography.variants.subtitleSm}
  text-align: center;
  overflow-wrap: break-word;
  overflow: hidden;
`;

interface MapPreviewProps {
  children: React.ReactNode;
  "data-testid"?: string;
}

const MapPreview = ({
  children,
  "data-testid": dataTestId,
}: MapPreviewProps) => {
  return (
    <StyledMapPreview data-testid={dataTestId}>{children}</StyledMapPreview>
  );
};

export default MapPreview;
