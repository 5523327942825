import { keepPreviousData } from "@tanstack/react-query";
import {
  useGetSocialTweets,
  useGetSocialTweetsValidated,
} from "../../../../.rest-hooks/social-twitter";
import type { SocialTwitter } from "../../../../.rest-hooks/types";
import { validatedSocialMedia } from "../../../config/layers/layers";
import { useSocialMediaFilters } from "../../../hooks/useSocialMediaFilters";
import SocialMediaPopup from "../../popup/SocialMediaPopup/SocialMediaPopup";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import SocialMediaClusters from "../SocialMediaClusters/SocialMediaClusters";
import { getTweetFeatureCollection } from "../SocialMediaClusters/utils";

const MAX_TWEETS = 5000;

const getActiveTweet = (
  tweets: SocialTwitter[],
  activeTweetId: string | undefined,
) => {
  if (!activeTweetId) return undefined;
  return tweets.find((tweet) => tweet.id === activeTweetId);
};

const SocialMediaClustersStateMap = ({ isCopView }: { isCopView: boolean }) => {
  const { isLayerActive } = useActiveLayersContext();
  const filters = useSocialMediaFilters();
  const isTweetsActive = !isCopView && !!filters.length;
  const { data: tweetsData } = useGetSocialTweets(
    {
      page: 1,
      perPage: MAX_TWEETS,
      filters,
    },
    {
      query: {
        enabled: isTweetsActive,
        select: (result) => {
          const tweets = result.data.data;
          return {
            tweets,
            geojson: getTweetFeatureCollection(tweets),
          };
        },
        placeholderData: keepPreviousData,
      },
    },
  );

  const isValidatedActive = isCopView && isLayerActive(validatedSocialMedia.id);
  const { data: validatedData } = useGetSocialTweetsValidated(
    {
      page: 1,
      perPage: MAX_TWEETS,
    },
    {
      query: {
        enabled: isValidatedActive,
        select: (result) => {
          const tweets = result.data.data;
          return {
            tweets,
            geojson: getTweetFeatureCollection(tweets),
          };
        },
        placeholderData: keepPreviousData,
      },
    },
  );

  const data = isCopView ? validatedData : tweetsData;
  const isActive = isCopView ? isValidatedActive : isTweetsActive;

  if (!data) return null;
  if (!isActive) return null;

  const { geojson, tweets } = data;

  return (
    <SocialMediaClusters
      geojson={geojson}
      renderPopup={({ state, onClose }) => {
        const tweet = getActiveTweet(tweets, state.properties?.tweetId);
        if (!tweet) return null;
        return (
          <SocialMediaPopup
            ctaLabel="View Social Intel map"
            ctaUrl="/social"
            state={state}
            tweet={tweet}
            onClose={onClose}
          />
        );
      }}
    />
  );
};

export default SocialMediaClustersStateMap;
