import { DebouncedTextInput, Fire, Search, media } from "@app/design-system";
import React, { useState } from "react";
import styled from "styled-components";
import { useIncidentsScope } from "../../incidents/IncidentsScopeProvider/IncidentsScopeProvider";
import useIncidentsByBbox from "../../map/hooks/useIncidentsByBbox/useIncidentsByBbox";
import LabelWithCount from "../../ui/LabelWithCount/LabelWithCount";
import type { PreferredPredictionAttribute } from "../../ui/PreferredPredictionValidIndicator/PreferredPredictionValidIndicator";
import DrawerCell from "../DrawerOverlay/DrawerCell";
import DrawerContent from "../DrawerOverlay/DrawerContent";
import { DrawerOverlayGridArea } from "../DrawerOverlay/DrawerOverlayGrid";
import FilterIncidentsPopoverTrigger from "./FilterIncidentsPopoverTrigger";
import type { IncidentCtaProps } from "./IncidentCard";
import IncidentCardList from "./IncidentCardList";
import { incidentCategoryLabels } from "./constants";

const StyledSearch = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.75rem;
  margin: 0 0 0.5rem;
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  border: 1px solid ${(p) => p.theme.colors.neutrals.borderWeak};

  @media ${media.lg} {
    padding: 0.5rem;
    margin: 0 0 0.1875rem;
  }
`;

const StyledInput = styled.div`
  flex: 1;
`;

const StyledFilter = styled.div`
  padding-right: 0.25rem;
`;
interface IncidentsDrawerCellProps {
  cta: React.ComponentType<IncidentCtaProps>;
  defaultExpanded?: boolean;
  isCopView?: boolean;
  predictionAttribute: PreferredPredictionAttribute;
}

const IncidentsDrawerCell = ({
  cta,
  defaultExpanded,
  isCopView,
  predictionAttribute,
}: IncidentsDrawerCellProps) => {
  const [term, setTerm] = useState("");

  const getIncidentsQuery = useIncidentsByBbox({ term });

  const { data, isFetching } = getIncidentsQuery;

  const firstPage = data?.pages[0];
  const count = firstPage?.data.meta.totalRecords;

  const { scope, isAdjusted } = useIncidentsScope();

  return (
    <DrawerCell
      defaultExpanded={defaultExpanded}
      fullHeight
      gridArea={DrawerOverlayGridArea.CONTENT}
      icon={Fire}
      isAdjusted={isAdjusted || !!term}
      label={
        <LabelWithCount count={count} isLoading={isFetching}>
          {isCopView ? incidentCategoryLabels[scope.category] : "Incidents"}
        </LabelWithCount>
      }
      data-testid="incidents-drawer"
    >
      <DrawerContent>
        <StyledSearch>
          <StyledInput>
            <DebouncedTextInput
              iconStart={Search}
              isClearable
              onChange={(value) => setTerm(value)}
              placeholder="Search name or LGA..."
              size="sm"
              value={term}
            />
          </StyledInput>
          {isCopView && (
            <StyledFilter>
              <FilterIncidentsPopoverTrigger />
            </StyledFilter>
          )}
        </StyledSearch>
        <IncidentCardList
          cta={cta}
          data-testid="incidents-list"
          getIncidentsQuery={getIncidentsQuery}
          isCopView={isCopView}
          layout="card"
          predictionAttribute={predictionAttribute}
          term={term}
        />
      </DrawerContent>
    </DrawerCell>
  );
};

export default IncidentsDrawerCell;
