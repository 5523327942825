import type { LngLatLike } from "mapbox-gl";
import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isTweetFeature } from "./types";

export type TweetInteractionProperties = FeatureInteractionProperties & {
  tweetId: string;
};

export type TweetInteractionState =
  FeatureInteractionState<TweetInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  TweetInteractionProperties
> = (feature) => {
  if (!isTweetFeature(feature)) {
    return null;
  }

  const lngLat = feature.geometry.coordinates as LngLatLike;
  const { tweetId } = feature.properties;

  return { featureId: feature.properties.tweetId, lngLat, tweetId };
};
