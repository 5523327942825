import type { Feature, GeoJsonProperties } from "geojson";
import type { LngLatLike } from "mapbox-gl";
import type { MapLayerMouseEvent } from "maplibre-gl";
import { useEffect } from "react";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import useUnsafeMapContext from "../Map/useUnsafeMapContext";
import { SOCIAL_MEDIA_CLUSTERS_CLUSTER_LAYER_ID } from "../SocialMediaClusters/constants";
import {
  isGeoJsonSource,
  isPointFeature,
  type MapboxMouseEvent,
} from "../types";
import type { FeatureInteractionProperties } from "./types";
import useLayerInteractions from "./useLayerInteractions";

type ClusterInteractionProperties = FeatureInteractionProperties &
  ClusterProperties;

interface ClusterProperties {
  cluster_id: number;
  cluster: boolean;
}

const getPropertiesFromFeature = (
  feature: Feature,
): ClusterInteractionProperties | null => {
  if (!isPointFeature(feature) || !isClusterProperties(feature.properties)) {
    return null;
  }

  return {
    featureId: feature.properties.cluster_id,
    cluster: feature.properties.cluster,
    cluster_id: feature.properties.cluster_id,
    lngLat: feature.geometry.coordinates as LngLatLike,
  };
};

const isClusterProperties = (
  properties: GeoJsonProperties,
): properties is ClusterProperties => {
  return properties?.cluster && typeof properties.cluster_id === "number";
};

interface UseExpandClusterOnClickParams {
  layerId: string;
  sourceId: string;
}

const useExpandClusterOnClick = ({
  layerId,
  sourceId,
}: UseExpandClusterOnClickParams) => {
  const { lib, map } = useUnsafeMapContext();
  if (!map) {
    throw new Error(
      "useExpandClusterOnClick must be used within a Map child component",
    );
  }

  // Hook the layer up to interaction state so that clicking it will not
  // deselect persistent elements like the Social Drawer
  useLayerInteractions({
    getPropertiesFromFeature,
    layerId,
  });

  const {
    visualiserState: { activeTool },
  } = useVisualiser();

  useEffect(() => {
    if (activeTool) return;

    const onClick = (event: MapboxMouseEvent | MapLayerMouseEvent) => {
      const [feature] = map.queryRenderedFeatures(
        // @ts-expect-error TS is getting confused when merging Mapbox and MapLibre overloads
        event.point,
        {
          layers: ["clusters"],
        },
      );

      if (!isPointFeature(feature) || !isClusterProperties(feature?.properties))
        return;

      const clusterId = feature.properties.cluster_id;

      const source = map.getSource(sourceId);

      if (!isGeoJsonSource(source)) return;

      source.getClusterExpansionZoom(clusterId, (error, zoom) => {
        if (error) return;

        map.easeTo({
          center: feature.geometry.coordinates as LngLatLike,
          zoom,
        });
      });
    };

    // Note: Code duplication between Mapbox and MapLibre implementations is intentional here. TypeScript is confused
    // when combining overloads and this becomes type-unsafe if we suppress errors.
    if (lib === "mapbox") {
      map.on("click", layerId, onClick);
    } else {
      map.on("click", layerId, onClick);
    }

    return () => {
      // Note: Code duplication between Mapbox and MapLibre implementations is intentional here. TypeScript is confused
      // when combining overloads and this becomes type-unsafe if we suppress errors.
      if (lib === "mapbox") {
        map.off("click", SOCIAL_MEDIA_CLUSTERS_CLUSTER_LAYER_ID, onClick);
      } else {
        map.off("click", SOCIAL_MEDIA_CLUSTERS_CLUSTER_LAYER_ID, onClick);
      }
    };
  }, [activeTool, layerId, lib, map, sourceId]);
};

export default useExpandClusterOnClick;
