import { FieldGrid, Skeleton, Text } from "@app/design-system";
import styled from "styled-components";
import { getFormattedDateAndTime } from "../../../../utils/formatDate/formatDate";
import MapPopupView from "../../../popup/SpatialPopup/MapPopupView";
import {
  aerialImageryFeatureLabels,
  type AerialImageryFeature,
} from "../utils";

const StyledContent = styled.div`
  display: grid;
  gap: 1.25rem;
`;

const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

interface AerialImageryPopupViewProps {
  feature: AerialImageryFeature;
  isPending: boolean;
  onClose: () => void;
  title: React.ReactNode;
}

const fields = [
  "flight_number",
  "call_sign",
  "media_id",
  "image_tag_date",
  "image_upload_date",
  "incident_name",
] as const satisfies readonly (keyof typeof aerialImageryFeatureLabels)[];

const AerialImageryPopupView = ({
  feature,
  isPending,
  onClose,
  title,
}: AerialImageryPopupViewProps) => (
  <MapPopupView
    hasClose
    header={
      <Text size="eyebrowDefault" variant="weak">
        {title}
      </Text>
    }
    onClose={onClose}
  >
    <StyledContent>
      <StyledBody>
        <FieldGrid>
          {fields.map((field) => (
            <FieldGrid.Item
              key={field}
              label={`${aerialImageryFeatureLabels[field]}:`}
            >
              {isPending ? (
                <Skeleton />
              ) : (
                (() => {
                  switch (field) {
                    case "image_tag_date":
                    case "image_upload_date":
                      return feature.properties[field] ? (
                        getFormattedDateAndTime(
                          new Date(feature.properties[field]),
                        )
                      ) : (
                        <>&mdash;</>
                      );
                    default:
                      return feature.properties[field] || <>&mdash;</>;
                  }
                })()
              )}
            </FieldGrid.Item>
          ))}
        </FieldGrid>
      </StyledBody>
    </StyledContent>
  </MapPopupView>
);

export default AerialImageryPopupView;
