import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isMultiPolygonFeature, isPolygonFeature } from "../types";

export interface StateForestPlantationInteractionProperties
  extends FeatureInteractionProperties {
  GazettedSFNo: string;
  OBJECTID: string;
}

export type StateForestPlantationInteractionState =
  FeatureInteractionState<StateForestPlantationInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  StateForestPlantationInteractionProperties
> = (feature, event) => {
  if (!isMultiPolygonFeature(feature) && !isPolygonFeature(feature)) {
    return null;
  }

  const { lngLat } = event;

  return {
    GazettedSFNo: feature.properties.GazettedSFNo,
    featureId: feature.properties.OBJECTID,
    OBJECTID: `${feature.properties.OBJECTID}`,
    lngLat,
  };
};
