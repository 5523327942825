import type { LngLatLike } from "mapbox-gl";
import { isIncidentPointFeature } from "../IncidentPoint/utils";
import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";

export interface IncidentInteractionProperties
  extends FeatureInteractionProperties {
  incidentId: string;
  name: string;
}

export type IncidentInteractionState =
  FeatureInteractionState<IncidentInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  IncidentInteractionProperties
> = (feature) => {
  if (!isIncidentPointFeature(feature)) {
    return null;
  }

  const lngLat = feature.geometry.coordinates as LngLatLike;

  const { incidentId, name } = feature.properties;

  return {
    featureId: incidentId,
    incidentId,
    lngLat,
    name,
  };
};
