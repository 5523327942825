import { AlertModal } from "@app/design-system";
import { useLastDefined } from "../../../hooks/useLastDefined";
import { useMapRailContext } from "../MapRail/MapRailProvider";

const ActiveLegendModal = () => {
  const { selectedModalItems, removeModal } = useMapRailContext();

  const activeLegend = selectedModalItems.get("legend");

  // This is intended to "hold onto" the last defined active legend so that the
  // title and component don't disappear from within the modal as it's
  // transitioning out. Instead of this, consider using a pattern where
  // `activeLegend` holds the legend _as well as_ a property called `isActive`.
  // When de-selecting a legend, `isActive` would be flipped to false, but
  // the legend properties would be retained.
  const cachedActiveLegend = useLastDefined(activeLegend);

  if (!cachedActiveLegend) {
    return null;
  }

  const {
    props: { component: Component, id, label },
  } = cachedActiveLegend;

  return (
    <AlertModal
      isOpen={!!activeLegend}
      title={label}
      onDismiss={() => removeModal({ id: "legend" })}
    >
      {Component && <Component id={id} />}
    </AlertModal>
  );
};

export default ActiveLegendModal;
