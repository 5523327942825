import { useUpdatingRef } from "@kablamo/kerosene-ui";
import { keepPreviousData } from "@tanstack/react-query";
import { useEffect } from "react";
import { useGetIncidentpredictionsEndorsed } from "../../../../../.rest-hooks/predictions";
import { REFETCH_INTERVAL_PERIODIC } from "../../../../config/refetch";
import { useIncidentsScope } from "../../../incidents/IncidentsScopeProvider/IncidentsScopeProvider";
import { MAX_INCIDENTS } from "../../../incidents/StateView/StateView";
import useMapBbox from "../useMapBbox/useMapBbox";

interface UseIncidentPredictionsEndorsedParams {
  enabled: boolean;
  onError: () => void;
}

const useIncidentPredictionsEndorsed = ({
  enabled,
  onError,
}: UseIncidentPredictionsEndorsedParams) => {
  const bbox = useMapBbox("cartesian", { debounced: true });
  const { params } = useIncidentsScope();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const query = useGetIncidentpredictionsEndorsed(
    {
      ...bbox,
      ...params,
      perPage: MAX_INCIDENTS,
      preferred: true,
    },
    {
      query: {
        enabled: !!bbox && enabled,
        placeholderData: keepPreviousData,
        refetchInterval: REFETCH_INTERVAL_PERIODIC,
        select: (data) => data.data.data,
      },
    },
  );

  const onErrorRef = useUpdatingRef(onError);
  useEffect(() => {
    if (query.error) {
      onErrorRef.current?.();
    }
  }, [onErrorRef, query.error]);

  return query;
};

export default useIncidentPredictionsEndorsed;
