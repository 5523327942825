import { aircraftLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import AircraftPositionsLayer from "./AircraftPositionsLayer";

interface AircraftPositionsProps {
  isCopView: boolean;
}

const AircraftPositions = ({ isCopView }: AircraftPositionsProps) => {
  const { isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(aircraftLayer.id)) {
    return null;
  }

  return <AircraftPositionsLayer isCopView={isCopView} />;
};

export default AircraftPositions;
