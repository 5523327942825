import type { LngLatLike } from "maplibre-gl";
import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isPointFeature } from "../types";

interface RtaIncidentArrangementAttachment {
  /**
   * Unused
   */
  displayName: string;
  /**
   * Name the document originally had when first uploaded to our internal tracking system.
   */
  fileName: string;
  /**
   * Code for the document type. At the time of writing only pdf is possible.
   */
  fileType: string;
  /**
   * Text of the hyperlink to the document
   */
  linkName: string;
  /**
   * Size of the document file in bytes
   */
  sizeInBytes: number;
  /**
   * Name of the document including an automatically generated suffix which ensures uniqueness amongst all attachments.
   */
  uniqueFileName: string;
}

interface RtaIncidentArrangementElement {
  /**
   * HTML content of the element
   */
  html: string;
  /**
   * The title of the element
   */
  title: string;
}

interface RtaIncidentEncodedPolyline {
  /**
   * The vertexes of a polyline accompanying this hazard in order from first to last. The polyline is encoded using the Google Maps algorithm for polyline encoding as presented in the following code:
   *
   * https://developers.google.com/maps/documentation/utilities/polylinealgorithm
   *
   * All Live Traffic polylines are encoded with `'forceEndpoints'` set to true.
   *
   * For those using the Google Maps V3 API, these polylines may be decoded using the decodePath method of the class `com.google.gwt.maps.client.geometrylib.EncodingUtils`.
   */
  coords: string;
  /**
   * Unused
   */
  levels: string;
}

interface RtaIncidentPeriod {
  /**
   * Symbolic constant representing the type of planned closure. Possible values are:
   *
   * - ROAD_CLOSURE
   * - LANE_CLOSURE
   */
  closureType: string;
  /**
   * Direction/s in which closure will occur
   */
  direction: string;
  /**
   * The time at which closure will end each day in this period
   */
  finishTime: string;
  /**
   * The final day of this period
   */
  fromDay: string;
  /**
   * The time at which closure will commence each day in this period
   */
  startTime: string;
  /**
   * The first day of this period
   */
  toDay: string;
}

interface RtaIncidentImpactedLane {
  /**
   * Direction/s of travel in which traffic is affected
   */
  affectedDirection: string;
  /**
   * Number of lanes closed
   */
  closedLanes: string;
  /**
   * Description of the closed lane/s e.g. the lane number
   */
  description: string;
  /**
   * Nature of the impact
   */
  extent: string;
  /**
   * Number of lanes in total, including `closedLanes`.
   */
  numberOfLanes: string;
  /**
   * Type of carriageway.
   */
  roadType: string;
}

interface RtaIncidentRoad {
  /**
   * Unused
   */
  conditionTendency: string;
  /**
   * Nearest street crossing this road
   */
  crossStreet: string;
  /**
   * Approximate magnitude of the delay to motorists on this road. Possible values are:
   *
   * - Minimal
   * - Significant
   * - 30 mins
   * - > 2 hrs
   */
  delay: string;
  /**
   * An array of `Impacted Lane` objects which captures the lane/s of the road influenced by this hazard. The ordering of elements in the array is undefined.
   */
  impactedLanes: readonly RtaIncidentImpactedLane[];
  /**
   * String separator that goes between the `mainStreet` and `crossStreet` when displayed. Describes the physical relationship between the two.
   */
  locationQualifier: string;
  /**
   * Name of this road
   */
  mainStreet: string;
  /**
   * Unused
   */
  quadrant: string;
  /**
   * Length of traffic queued along this road, in kilometres. A value of 0 or -1 indicates there is no queue.
   */
  queueLength: number;
  /**
   * The geographic region in which this road is located. Possible values are:
   * - `"region":"Sydney"`
   * - `"region":"Blue Mountains"`
   * - `"region":"Central Coast"`
   * - `"region":"Central NSW"`
   * - `"region":"Far West NSW"`
   * - `"region":"Hunter"`
   * - `"region":"New England North West"`
   * - `"region":"North Coast NSW"`
   * - `"region":"Riverina"`
   * - `"region":"Snowy Mountains"`
   * - `"region":"South Coast"`
   * - `"region":"Southern Highlands"`
   * - `"region":"The Murray"`
   */
  region: string;
  /**
   * Displayed after locationQualifier in the format `"and secondLocation"`
   */
  secondLocation: string;
  /**
   * Name of the suburb in which this road is located
   */
  suburb: string;
  /**
   * One word description of the traffic volume on this road. Possible values are:
   *
   * - Light
   * - Moderate
   * - Heavy
   */
  trafficVolume: string;
}

interface RtaWebLink {
  /**
   * The text of the hyperlink
   */
  linkTest: string;
  /**
   * The URL of the hyperlink
   */
  linkURL: string;
}

export interface RtaIncidentFeatureProperties {
  CategoryIcon?: string;
  /**
   * Circumstantial information that supplements what appears in e.g. `"Site cleared"`, `"Persons trapped"`
   */
  additionalInfo: readonly string[];
  /**
   * The first standard piece of advice to motorists. At the present time, the following values are possible:
   *
   * - Allow extra travel time
   * - Avoid the area
   * - Check signage
   * - Delay journey
   * - Exercise caution
   * - Expect delays
   * - Police directing traffic
   * - Reduce speed
   * - Reduced speed limit
   * - Snow chains required
   * - Stay away
   * - Turn around, go back
   * - Use alternative route
   * - Use diversions
   * - Use public transport
   * - Never drive through Floodwater
   * - Vehicle salvage in progress
   * - Plan your journey
   */
  adviceA: string;
  /**
   * The second standard piece of advice to motorists. The possible values for adviceB are the same as the possible values for adviceA, however adviceB and adviceA cannot have the same values.
   */
  adviceB: string;
  /**
   * The third standard piece of advice to motorists. The possible values for adviceC are the same as the possible values for adviceA and adviceB, however they cannot have the same values.
   */
  advice: string;
  /**
   * Hazards can have PDF documents associated with them, containing maps pertaining to the event. Each PDF document corresponds to an `Arrangement Attachment` object. The Live Traffic web site displays these attachments in the same order in which they appear in the `arrangementAttachments` array, in a section whose title is always `"Download maps"`. The `"Download maps"` titled section always appears after any titled sections specified by the arrangementElements property.
   */
  arrangementAttachments: readonly RtaIncidentArrangementAttachment[];
  /**
   * Hazards can have a large amount of descriptive information associated with them. To aid readability, this information can be broken down into titled sections. Each titled section corresponds to an `Arrangement Element` object. Example section titles include `"Road Closures"`, `"Special Event Clearways"` and `"Other Information"`. The Live Traffic web site displays these sections in the same order in which the elements appear in the arrangementElements array.
   */
  arrangementElements: readonly RtaIncidentArrangementElement[];
  /**
   * An array of strings, each one being the name of a group that is attending the scene of the traffic hazard. At the time of writing, the following values are possible:
   *
   * - Emergency Services
   * - Transport for NSW
   * - Tow Truck
   * - Motorway Crew
   * - Utility Company
   * - Local Council
   * - Rural Fire Service
   * - Helicopter
   * - Heavy Vehicle Inspectors
   * - Heavy Vehicle Tow Truck
   * - Crash Investigation Unit
   * - Mechanic
   *
   * There may be an additional, free-form values entered by our staff.
   */
  attendingGroups: readonly string[] | null;
  /**
   * Date and time at which a record of the hazard was created in our internal tracking system, as a number of milliseconds since January 1, 1970 00:00:00 GMT. This property is a counterpart to the ended property.
   */
  created: number;
  /**
   * Describes the particulars of a hazard. The description generally begins with a general hazard category in upper case, followed by details in lower case e.g. `"ACCIDENT Two cars"`, `"HAZARD Fallen tree"`. The displayName is incorporated into headings in both the Map View and Text View of the Live Traffic web site.
   */
  displayName: string;
  /**
   * Summary of any traffic diversions in place. The text may contain HTML markup.
   */
  diversions: string;
  /**
   * Planned duration of the hazard. This property is rarely used.
   */
  duration?: string | null;
  /**
   * A hazard may have zero or more polylines associated with it, representing stretches of road affected by the hazard. Rather than capture the polylines as a raw series of coordinates, that series is encoded into a string of printable characters. Each element of the array is an Encoded Polyline object associated with this hazard. The order of the polylines in the encodedPolylines array is undefined. The polylines needed not be contiguous.
   */
  encodedPolylines: readonly RtaIncidentEncodedPolyline[];
  /**
   * Date on which a planned hazard is scheduled to end, as a number of milliseconds since January 1, 1970 00:00:00 GMT. This property is a counterpart to the `start` property.
   */
  end?: number;
  /**
   * `true` if the hazard has ended, otherwise `false`. Once ended, the hazard’s record in our internal tracking system is closed and further modification becomes impossible unless the record is later re-opened. This property is a counterpart to the `created` property. When true, the `lastUpdated` property of the hazard will be the date/time when the hazard’s record in the tracking system was closed.
   */
  ended: boolean;
  /**
   * The value is a numeric representation of expected delay in minutes. A value of 0 or -1 indicates that there is no delay information available.
   */
  expectedDelay: number;
  /**
   * A one-line summary of the hazard, including its type, location and other high level details. The headline text of major hazards appears in the yellow "Major Incident Ticker" above the map in Map View.
   */
  headline: string;
  /**
   * True if the hazard is currently having some impact on traffic on the road network.
   */
  impactingNetwork: boolean;
  /**
   * Identifies the hazard as either Planned or Unplanned. The values for this property include:
   *
   * - Planned
   * - Unplanned
   *
   * A Planned hazard must have the properties: `start` and `end`. And optionally the  properties: `duration` and `periods`. An Unplanned hazard will not contain these properties.
   */
  incidentKind: string;
  /**
   * True if the hazard has been reported to us by an external source but not yet confirmed by staff or police.
   */
  isInitialReport?: boolean;
  /**
   * True if this hazard is regarded as having a particularly significant impact on the road network. The `headline` text of hazards with an `isMajor` value of `true` appear in the red "Major Hazard Ticker" above the map in Map View.
   */
  isMajor: boolean;
  /**
   * True if the hazard is new.
   */
  isNewIncident: boolean;
  /**
   * When the data for this hazard was last updated by our staff, as a number of milliseconds since January 1, 1970 00:00:00 GMT
   */
  lastUpdated: number;
  /**
   * The broad hazard category description assigned to the hazard by TMC Communications. Used internally by TMC Communications for reporting hazard statistics. Please note the values used by this property are subject to change and should not be relied upon.
   */
  mainCategory: string;
  /**
   * Unused
   */
  media: readonly Record<string, unknown>[];
  /**
   * Unused
   */
  name: string;
  /**
   * Free form text containing advice to motorists, to supplement the standard advice conveyed in the `adviceA` and `adviceB` properties. This text may contain HTML markup.
   */
  otherAdvice: string;
  /**
   * Array of `Period` objects, each being a planned period of operation within which the road network will potentially be impacted. Each `Period` represents a series of consecutive days over which the same operating times apply. The order of elements in the `periods` array is undefined.
   */
  periods: readonly RtaIncidentPeriod[];
  /**
   * Free form text containing information about the public transport impact of this hazard. This text may contain HTML markup.
   */
  publicTransport: string;
  queueLength: number;
  /**
   * Array of `Road` objects, each being a road that is impacted by this hazard.
   */
  roads: readonly RtaIncidentRoad[];
  /**
   * A special, restricted speed limit in km/h which is in force around this hazard. A value of 0 or -1 indicates that there is no such restriction in force. This field will only contain a value greater than 0 if adviceA, adviceB or adviceC equals "Reduced speed limit".
   */
  speedLimit: number;
  /**
   * The date on which a planned hazard is scheduled to begin, as a number of milliseconds since 1970-01-01 00:00:000 (YYYY-MM-DD hh:mm:ss:nnn). This property is a counterpart to the end property.
   */
  start: number;
  /**
   * An optional refining hazard description for the assigned `mainCategory`. Used internally by TMC for reporting hazard statistics. Please note the values used by this property are subject to change and should not be relied upon.
   */
  subCategoryA: string;
  /**
   * Unused
   */
  subCategoryB: string | null;
  /**
   * Unused
   */
  webLinkName: string | null;
  /**
   * Array of `WebLink` objects in the order in which they should appear, top to bottom. Each `WebLink` represents a hyperlink to an external web site that provides information pertaining to the hazard.
   */
  webLinks: readonly RtaWebLink[];
  /**
   * Unused
   */
  webLinkUrl: string | null;
  /**
   * Name of the council
   */
  orgName?: string | null;
  /**
   * Website of the council
   */
  orgWebsite?: string | null;
  /**
   * Contact number for the council
   */
  orgContact?: string | null;
}

export type RtaIncidentInteractionProperties = FeatureInteractionProperties & {
  data: RtaIncidentFeatureProperties;
};

export type RtaIncidentInteractionState =
  FeatureInteractionState<RtaIncidentInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  RtaIncidentInteractionProperties
> = (feature: GeoJSON.Feature): RtaIncidentInteractionProperties | null => {
  if (!isPointFeature(feature)) return null;

  const lngLat = feature.geometry.coordinates as LngLatLike;

  if (!feature.id) {
    throw new Error("Unable to identify RTA incident");
  }

  return {
    data: feature.properties,
    featureId: feature.id,
    lngLat,
  };
};
