import { deaHotspotsLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import DEAHotspotsLayer from "./DEAHotspotsLayer";

const DEAHotspots = () => {
  const { isLayerActive, getLayerState } = useActiveLayersContext();

  if (!isLayerActive(deaHotspotsLayer.id)) {
    return null;
  }

  const state = getLayerState(deaHotspotsLayer.id);

  return <DEAHotspotsLayer opacity={state?.opacity} />;
};

export default DEAHotspots;
