import { fadeInStyles, useTheme } from "@app/design-system";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import type { Tool } from "../../../config/tools";
import CompactPredictionControls from "../CompactPredictionControls/CompactPredictionControls";
import { useVisualiser } from "../Visualiser/VisualiserProvider";
import CancelActiveToolButton from "./CancelActiveToolButton";

const StyledAdornment = styled.div`
  ${fadeInStyles}
`;

interface VisualiserBottomTabsAdornmentProps {
  activeTool: Tool | null;
  showPredictionControls: boolean | undefined;
}

const VisualiserBottomTabsAdornment = ({
  activeTool,
  showPredictionControls,
}: VisualiserBottomTabsAdornmentProps) => {
  const theme = useTheme();
  const { onToolComplete } = useVisualiser();

  const activeToolButtonRef = useRef<HTMLDivElement>(null);
  const predictionControlsRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <CSSTransition
        appear
        in={!!activeTool}
        mountOnEnter
        nodeRef={activeToolButtonRef}
        timeout={{
          enter: theme.anim.duration.sm,
          exit: theme.anim.duration.sm,
        }}
        unmountOnExit
      >
        <StyledAdornment ref={activeToolButtonRef}>
          <CancelActiveToolButton onClick={onToolComplete} />
        </StyledAdornment>
      </CSSTransition>
      <CSSTransition
        appear
        in={!!showPredictionControls}
        mountOnEnter
        nodeRef={activeToolButtonRef}
        timeout={{
          enter: theme.anim.duration.sm,
          exit: theme.anim.duration.sm,
        }}
        unmountOnExit
      >
        <StyledAdornment ref={predictionControlsRef}>
          <CompactPredictionControls />
        </StyledAdornment>
      </CSSTransition>
    </>
  );
};

export default VisualiserBottomTabsAdornment;
