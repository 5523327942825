export const BRIGADES_SOURCE_ID = "RFSBrigades";
export const BRIGADES_LAYER_ID = "RFSBrigades";
export const BRIGADES_FILL_LAYER_ID = "RFSBrigades-fill";
export const BRIGADES_TEXT_LAYER_ID = "RFSBrigades-text";

export const SEVERITY_COLOR_SCALE = [
  "assessed",
  "#5d5d5d",
  "severe",
  "#EA1D2C",
  "moderate",
  "#F58C1D",
  "no-impact",
  "#0BA350",
  "unknown",
  "transparent",
  "transparent",
] as const;
