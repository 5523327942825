export const INCIDENT_POINT_COLLECTION_SOURCE_ID = "incident-point-collection";
export const INCIDENT_POINT_COLLECTION_LAYER_ID = "incident-point-collection";
export const INCIDENT_POINT_COLLECTION_HOVERED_LAYER_ID =
  "incident-point-collection-hovered-layer-name";
export const INCIDENT_POINT_COLLECTION_HOVERED_LABEL_LAYER_ID =
  "incident-point-collection-hovered-label-layer-name";
export const INCIDENT_POINT_COLLECTION_ACTIVE_LAYER_ID =
  "incident-point-collection-active-layer-name";

export const INCIDENT_POINT_HOVERED_SUFFIX = "-hover";
export const INCIDENT_POINT_ACTIVE_SUFFIX = "-active";
