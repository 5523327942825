import type MapboxDraw from "@mapbox/mapbox-gl-draw";

/** A wrapper for adding MapboxDraw controls to either mapbox or maplibre. Handles additional work needed for proper maplibre support. Returns a clean-up function for use in useEffect */
export const addMapboxDrawControlToMap = (
  drawControl: MapboxDraw,
  lib: "maplibre" | "mapbox",
  map: maplibregl.Map | mapboxgl.Map,
) => {
  // NOTE: We add some additional classes to maplibre maps for MapboxDraw to work, issue and the workaround we use in this thread: https://github.com/maplibre/maplibre-gl-js/issues/2601#issuecomment-1564747778
  // We use the messier class changing workaround rather than the constants change as we support both mapbox and maplibre in this codebase, and changing those constants would only work for maplibre
  if (lib === "maplibre") {
    // MapboxDraw requires the canvas's class order to have the class
    // "mapboxgl-canvas" first in the list for the key bindings to work
    map.getCanvas().classList.add("mapboxgl-canvas");
    map.getContainer().classList.add("mapboxgl-map");
    const canvasContainer = map.getCanvasContainer();
    canvasContainer.classList.add("mapboxgl-canvas-container");
    if (canvasContainer.classList.contains("maplibregl-interactive")) {
      canvasContainer.classList.add("mapboxgl-interactive");
    }

    // Also, when the draw controls are created, the MapLibre classes need to be added to what MapboxDraw creates:
    const originalOnAdd = drawControl.onAdd.bind(drawControl);
    // eslint-disable-next-line no-param-reassign
    drawControl.onAdd = (mapTarget) => {
      const controlContainer = originalOnAdd(mapTarget);
      controlContainer.classList.add(
        "maplibregl-ctrl",
        "maplibregl-ctrl-group",
      );
      return controlContainer;
    };
  }

  // @ts-expect-error The add control is consistent for both maps, but has type issues
  map.addControl(drawControl);

  // Return a clean-up function for use in useEffect
  // @ts-expect-error The remove control is consistent for both maps, but has type issues
  return () => map.removeControl(drawControl);
};
