import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";

const HEALTH_FACILITIES_ID = "healthFacilities";
const HEALTH_CENTRES_ID = "healthCentres";

export const HEALTH_FACILITIES_AND_CENTRES = {
  HEALTH_FACILITY_HOSPITAL_GENERAL:
    "/icons/health-facility-hospital-general.png",
  HEALTH_FACILITY_HOSPITAL_PRIVATE:
    "/icons/health-facility-hospital-private.png",
  "Indigenous Health Centre":
    "/icons/health-facility-indigenous-health-centre.png",
  "Disability Support Service":
    "/icons/health-facility-disability-support-service.png",
};

const HEALTH_FACILITIES_QUERY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/9/query`;
const FACILITIES_QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: "symbolname",
  },
};

const HEALTH_CENTRES_QUERY_URL = `https://services.ga.gov.au/gis/rest/services/Foundation_Facilities_Points/MapServer/1/query`;
const CENTRES_QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: "main_function",
  },
};

interface HealthFacilitiesLayerProps {
  opacity?: number;
}

const HealthFacilitiesLayer = ({ opacity = 1 }: HealthFacilitiesLayerProps) => {
  const map = useMapContext();
  const { data: healthFacilitiesData } = useMapServerQueryData(
    HEALTH_FACILITIES_QUERY_URL,
    FACILITIES_QUERY_OPTIONS,
  );

  const { data: healthCentresData } = useMapServerQueryData(
    HEALTH_CENTRES_QUERY_URL,
    CENTRES_QUERY_OPTIONS,
  );

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(HEALTH_FACILITIES_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addSource(HEALTH_CENTRES_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    Promise.all(
      Object.entries(HEALTH_FACILITIES_AND_CENTRES).map(([imageId, src]) =>
        loadImage({ imageId, map, src, signal: controller.signal }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: HEALTH_FACILITIES_ID,
          type: "symbol",
          source: HEALTH_FACILITIES_ID,
          layout: {
            "icon-image": ["get", "symbolname"],
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );

      map.addLayer(
        {
          id: HEALTH_CENTRES_ID,
          type: "symbol",
          source: HEALTH_CENTRES_ID,
          layout: {
            "icon-image": ["get", "main_function"],
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      if (map.getLayer(HEALTH_FACILITIES_ID)) {
        map.removeLayer(HEALTH_FACILITIES_ID);
      }
      if (map.getLayer(HEALTH_CENTRES_ID)) {
        map.removeLayer(HEALTH_CENTRES_ID);
      }
      if (map.getSource(HEALTH_FACILITIES_ID)) {
        map.removeSource(HEALTH_FACILITIES_ID);
      }
      if (map.getSource(HEALTH_CENTRES_ID)) {
        map.removeSource(HEALTH_CENTRES_ID);
      }
      Object.keys(HEALTH_FACILITIES_AND_CENTRES).forEach((id) => {
        if (map.hasImage(id)) {
          map.removeImage(id);
        }
      });
    };
  }, [map]);

  useEffect(() => {
    const source = map?.getSource(HEALTH_FACILITIES_ID);
    if (isGeoJsonSource(source) && healthFacilitiesData) {
      source.setData(healthFacilitiesData);
    }
  }, [healthFacilitiesData, map]);

  useEffect(() => {
    const source = map?.getSource(HEALTH_CENTRES_ID);
    if (isGeoJsonSource(source) && healthCentresData) {
      source.setData(healthCentresData);
    }
  }, [healthCentresData, map]);

  useEffect(() => {
    map.setPaintProperty(HEALTH_FACILITIES_ID, "icon-opacity", opacity);
    map.setPaintProperty(HEALTH_CENTRES_ID, "icon-opacity", opacity);
  }, [opacity, map]);

  return null;
};

export default HealthFacilitiesLayer;
