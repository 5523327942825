import styled from "styled-components";

const StyledPredictionControlDrawer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.5rem 1.5rem;
  background-color: ${(p) => p.theme.colors.neutrals.surface};
  box-shadow: ${(p) => p.theme.boxShadows.md};
  overflow: auto;
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  ${(p) => p.theme.typography.variants.buttonLg}
`;

interface PredictionControlDrawerProps {
  children?: React.ReactNode;
  label: React.ReactNode;
  "data-testid"?: string;
}

const PredictionControlDrawer = ({
  children,
  label,
  "data-testid": dataTestId,
}: PredictionControlDrawerProps) => {
  return (
    <StyledPredictionControlDrawer data-testid={dataTestId}>
      {label && <StyledHeader>{label}</StyledHeader>}
      {children}
    </StyledPredictionControlDrawer>
  );
};

export default PredictionControlDrawer;
