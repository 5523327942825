import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import useMapContext from "../Map/useMapContext";
import { getAerialImageryGISUrls, type AerialImageryLayer } from "./utils";

interface AerialImageryRasterLayerProps {
  aerialImageryLayer: AerialImageryLayer;
  layerId: string;
  opacity?: number;
}

const AerialImageryRasterLayer = ({
  aerialImageryLayer,
  opacity = 1,
  ...props
}: AerialImageryRasterLayerProps) => {
  const layerId = `${props.layerId}-raster`;
  const map = useMapContext();
  const { tileUrl } = getAerialImageryGISUrls();

  useEffect(() => {
    if (!map) {
      return;
    }

    const controller = new AbortController();
    controller.signal.addEventListener("abort", () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }

      if (map.getSource(layerId)) {
        map.removeSource(layerId);
      }
    });

    if (!map.getSource(layerId)) {
      const query = buildMapboxBBoxQuery({
        SERVICE: "WMS",
        REQUEST: "GetMap",
        FORMAT: "image/png",
        TRANSPARENT: "TRUE",
        STYLES: "",
        VERSION: "1.3.0",
        LAYERS: [
          ...aerialImageryLayer.dataLayers,
          ...aerialImageryLayer.boundaryLayers,
        ].join(","),
        WIDTH: "512",
        HEIGHT: "512",
        CRS: "EPSG:3857",
        TILED: "TRUE",
      });

      map.addSource(layerId, {
        type: "raster",
        tileSize: 512,
        tiles: [`${tileUrl}?${query}`],
      });
    }

    if (!map.getLayer(layerId)) {
      map.addLayer(
        { id: layerId, type: "raster", source: layerId },
        MapLevel.BACKGROUND,
      );
    }

    return () => controller.abort();
  }, [
    aerialImageryLayer.boundaryLayers,
    aerialImageryLayer.dataLayers,
    layerId,
    map,
    tileUrl,
  ]);

  useEffect(() => {
    map?.setPaintProperty(layerId, "raster-opacity", opacity);
  }, [map, opacity, layerId]);

  return null;
};

export default AerialImageryRasterLayer;
