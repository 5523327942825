import NeighbourhoodSaferPlacesPopup from "../../popup/NeighbourhoodSaferPlacesPopup/NeighbourhoodSaferPlacesPopup";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import { getPropertiesFromFeature } from "./interactions";
import useNeighbourhoodSaferPlacesMapLayer from "./useNeighbourhoodSaferPlacesMapLayer";

const NEIGHBOURHOOD_SAFER_PLACES_ID = "neighbourhoodSaferPlaces";

interface NeighbourhoodSaferPlacesLayerProps {
  opacity?: number;
}

const NeighbourhoodSaferPlacesLayer = ({
  opacity,
}: NeighbourhoodSaferPlacesLayerProps) => {
  useNeighbourhoodSaferPlacesMapLayer({
    layerId: NEIGHBOURHOOD_SAFER_PLACES_ID,
    opacity,
  });

  const { clickedState, deactivateClickState } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: NEIGHBOURHOOD_SAFER_PLACES_ID,
  });

  return (
    <NeighbourhoodSaferPlacesPopup
      onClose={deactivateClickState}
      state={clickedState}
    />
  );
};
export default NeighbourhoodSaferPlacesLayer;
