import { fireFeatures } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import BurntAreaPolygonsLayer from "./BurntAreaPolygonsLayer";

const BurntAreaPolygons = () => {
  const { isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(fireFeatures.id)) {
    return null;
  }

  return <BurntAreaPolygonsLayer />;
};

export default BurntAreaPolygons;
