import type { MergedUnion } from "@kablamo/kerosene";
import subDays from "date-fns/subDays";
import subHours from "date-fns/subHours";
import defaults from "lodash/defaults";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import type { ParsedUrlQuery, ParsedUrlQueryInput } from "querystring";
import {
  type GetSocialFeedParams,
  GetSocialFeedSort,
  type GetSocialTweetsLinkedTo,
  type SocialFeedSectionPin,
  type SocialTwitter,
} from "../../../../.rest-hooks/types";
import type {
  TweetFiltersParameter,
  TweetFiltersParameterItem,
} from "../../../../.rest-hooks/types/social-twitter.yml";
import {
  GetSocialFeedFiltersItemValues,
  GetSocialFeedSortValues,
  IngestionDateFilter,
  IngestionDateFilterValues,
} from "../../../config/socialFeed";
import getSecondsFromDate from "../../../utils/getSecondsFromDate";
import {
  type PageQuery,
  type ParsedUrlQueryValue,
  getStringFromQuery,
  getStringArrayFromQuery,
  pluckPageQueryFromQuery,
  getQueryFromPageQuery,
} from "../../ui/SearchResults/pageQuery";

const defaultSocialFeedPageQuery: PageQuery = {
  page: 1,
  perPage: 10,
};

export interface SocialFeedQuery extends Required<PageQuery> {
  authors: string[];
  filters: TweetFiltersParameter;
  hashtags: string[];
  ingestionDateFilter: IngestionDateFilter;
  keywords: string[];
  term: string;
  sort: GetSocialFeedSort;
}

export const defaultSocialFeedQuery: SocialFeedQuery = {
  authors: [],
  filters: [],
  hashtags: [],
  keywords: [],
  ingestionDateFilter: IngestionDateFilter.lastFortyEightHours,
  page: 1,
  perPage: 10,
  term: "",
  sort: GetSocialFeedSort.lastIngestedAtDesc,
};

interface SocialFeedSearchEventHandlerOptions {
  replace?: boolean;
}

export type SocialFeedSearchEventHandler = (
  socialFeedQuery: SocialFeedQuery,
  options?: SocialFeedSearchEventHandlerOptions,
) => Promise<void>;

export const isSocialFeedSortOrder = (
  value: string,
): value is GetSocialFeedSort => {
  return GetSocialFeedSortValues.includes(value as GetSocialFeedSort);
};

export const getSocialFeedSortOrderFromQuery = (
  value: ParsedUrlQueryValue,
): GetSocialFeedSort | undefined => {
  const sortOrder = getStringFromQuery(value);
  if (!sortOrder) {
    return undefined;
  }

  if (isSocialFeedSortOrder(sortOrder)) {
    return sortOrder;
  }

  return undefined;
};

export const isIngestionDateFilter = (
  value: string,
): value is IngestionDateFilter => {
  return IngestionDateFilterValues.includes(value as IngestionDateFilter);
};

export const getIngestionDateFilterFromQuery = (
  value: ParsedUrlQueryValue,
): IngestionDateFilter | undefined => {
  const ingestionDateFilter = getStringFromQuery(value);
  if (!ingestionDateFilter) {
    return undefined;
  }

  if (isIngestionDateFilter(ingestionDateFilter)) {
    return ingestionDateFilter;
  }

  return undefined;
};

export const isTweetFiltersItem = (
  value: string,
): value is TweetFiltersParameterItem => {
  return GetSocialFeedFiltersItemValues.includes(
    value as TweetFiltersParameterItem,
  );
};

export const getSocialFeedFiltersItemsFromQuery = (
  value: ParsedUrlQueryValue,
): TweetFiltersParameter | undefined => {
  const filtersItems = getStringArrayFromQuery(value);
  return filtersItems?.filter(isTweetFiltersItem);
};

export const pluckSocialFeedQueryFromQuery = (
  query: ParsedUrlQuery,
): SocialFeedQuery => {
  const pageQuery = pluckPageQueryFromQuery(query, defaultSocialFeedPageQuery);

  return defaults<
    Record<string, never>,
    PageQuery,
    Partial<SocialFeedQuery>,
    SocialFeedQuery
  >(
    {},
    pageQuery,
    {
      authors: getStringArrayFromQuery(query.authors),
      filters: getSocialFeedFiltersItemsFromQuery(query.filters),
      hashtags: getStringArrayFromQuery(query.hashtags),
      ingestionDateFilter: getIngestionDateFilterFromQuery(
        query.ingestionDateFilter,
      ),
      keywords: getStringArrayFromQuery(query.keywords),
      // Check `query` as well for backwards compatibility.
      term: getStringFromQuery(query.term) || getStringFromQuery(query.query),
      sort: getSocialFeedSortOrderFromQuery(query.sort),
    },
    defaultSocialFeedQuery,
  );
};

export const isSocialFeedQueryEmpty = (query: SocialFeedQuery): boolean => {
  return isEqual(omit(defaultSocialFeedQuery, "sort"), omit(query, "sort"));
};

export const getQueryFromSocialFeedQuery = ({
  authors,
  filters,
  hashtags,
  ingestionDateFilter,
  keywords,
  page,
  perPage,
  term,
  sort,
}: SocialFeedQuery): ParsedUrlQueryInput => {
  return {
    // Don't clutter the user's URL query unless the params diverge from the
    // defaults
    ...getQueryFromPageQuery({ page, perPage }, defaultSocialFeedPageQuery),
    ...(authors?.length && { authors }),
    ...(filters?.length && { filters }),
    ...(hashtags?.length && { hashtags }),
    ...(ingestionDateFilter &&
      ingestionDateFilter !== defaultSocialFeedQuery.ingestionDateFilter && {
        ingestionDateFilter,
      }),
    ...(keywords?.length && { keywords }),
    ...(term && { term }),
    ...(sort && sort !== defaultSocialFeedQuery.sort && { sort }),
  };
};

export const getIngestionDateParamsFromFilter = (
  ingestionDateFilter: IngestionDateFilter,
): Pick<
  GetSocialFeedParams,
  "noUnvalidatedBefore" | "ingestedFrom" | "ingestedTo"
> => {
  const now = new Date();

  switch (ingestionDateFilter) {
    case "lastFortyEightHours": {
      const twoDaysAgo = subDays(now, 2);
      return {
        ingestedFrom: 1,
        noUnvalidatedBefore: getSecondsFromDate(twoDaysAgo),
      };
    }
    case "lastDay": {
      const oneDayAgo = subDays(now, 1);
      return {
        ingestedFrom: 1,
        noUnvalidatedBefore: getSecondsFromDate(oneDayAgo),
      };
    }
    case "lastTwelveHours": {
      const twelveHoursAgo = subHours(now, 12);
      return {
        ingestedFrom: 1,
        noUnvalidatedBefore: getSecondsFromDate(twelveHoursAgo),
      };
    }
  }
};

export type IncidentGetSocialTweetsLinkedTo = keyof Pick<
  typeof GetSocialTweetsLinkedTo,
  "linked_to_incident"
>;
export type NonIncidentGetSocialTweetsLinkedTo = keyof Omit<
  typeof GetSocialTweetsLinkedTo,
  "linked_to_incident"
>;

export type IncidentPostLink = {
  incidentId: string;
  linkedTo: IncidentGetSocialTweetsLinkedTo;
};

export type NonIncidentPostLink = {
  linkedTo: NonIncidentGetSocialTweetsLinkedTo;
};

export type PostLink = MergedUnion<IncidentPostLink | NonIncidentPostLink>;

export const getPostLinkFromPin = (pin: SocialFeedSectionPin): PostLink => {
  switch (pin.type) {
    case "hotspot":
      return { linkedTo: "linked_to_hotspot" };
    case "incident":
      return {
        linkedTo: "linked_to_incident",
        incidentId: pin.incidentId ?? "",
      };
    case "unlinked":
      return { linkedTo: "unlinked" };
  }
};

// This is really just intended to be called from `getPostLink`. Not exported
// intentionally.
const getDefaultPostLink = (tweet: SocialTwitter): PostLink => {
  const { incidentIds, nearHotspot } = tweet.attributes;
  if (incidentIds?.length) {
    return {
      linkedTo: "linked_to_incident",
      incidentId: incidentIds[0],
    };
  }

  return {
    linkedTo: nearHotspot ? "linked_to_hotspot" : "unlinked",
  };
};

interface GetPostLinkParams {
  incidentId?: string;
  tweet: SocialTwitter;
}

export const getPostLink = ({
  incidentId,
  tweet,
}: GetPostLinkParams): PostLink => {
  // Since this `incidentId` can come from the URL, we want to ensure that the
  // incident is actually part of this tweet's linked incidents and not just
  // some random string -- which would break the display of the linked incidents
  // section and the view tweet on map button
  if (!incidentId || !tweet.attributes.incidentIds?.includes(incidentId)) {
    return getDefaultPostLink(tweet);
  }

  return {
    linkedTo: "linked_to_incident",
    incidentId,
  };
};
