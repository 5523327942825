/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  GetSocialTweets200,
  GetSocialTweetsId200,
  GetSocialTweetsParams,
  GetSocialTweetsValidated200,
  GetSocialTweetsValidatedParams,
  PatchSocialTweetsValidateId200,
  PatchSocialTweetsValidateIdBody,
} from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";

/**
 * @summary Get a list of tweets
 */
export const getSocialTweets = (
  params?: GetSocialTweetsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetSocialTweets200>> => {
  return axios.get(`/social/tweets`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetSocialTweetsQueryKey = (params?: GetSocialTweetsParams) => {
  return [`/social/tweets`, ...(params ? [params] : [])] as const;
};

export const getGetSocialTweetsQueryOptions = <
  TData = Awaited<ReturnType<typeof getSocialTweets>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetSocialTweetsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSocialTweets>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSocialTweetsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSocialTweets>>> = ({
    signal,
  }) => getSocialTweets(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSocialTweets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSocialTweetsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSocialTweets>>
>;
export type GetSocialTweetsQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get a list of tweets
 */
export const useGetSocialTweets = <
  TData = Awaited<ReturnType<typeof getSocialTweets>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetSocialTweetsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSocialTweets>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSocialTweetsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a list of validated tweets
 */
export const getSocialTweetsValidated = (
  params?: GetSocialTweetsValidatedParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetSocialTweetsValidated200>> => {
  return axios.get(`/social/tweets/validated`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetSocialTweetsValidatedQueryKey = (
  params?: GetSocialTweetsValidatedParams,
) => {
  return [`/social/tweets/validated`, ...(params ? [params] : [])] as const;
};

export const getGetSocialTweetsValidatedQueryOptions = <
  TData = Awaited<ReturnType<typeof getSocialTweetsValidated>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetSocialTweetsValidatedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSocialTweetsValidated>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSocialTweetsValidatedQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSocialTweetsValidated>>
  > = ({ signal }) =>
    getSocialTweetsValidated(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSocialTweetsValidated>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSocialTweetsValidatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSocialTweetsValidated>>
>;
export type GetSocialTweetsValidatedQueryError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get a list of validated tweets
 */
export const useGetSocialTweetsValidated = <
  TData = Awaited<ReturnType<typeof getSocialTweetsValidated>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  params?: GetSocialTweetsValidatedParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSocialTweetsValidated>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSocialTweetsValidatedQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get an individual tweet
 */
export const getSocialTweetsId = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetSocialTweetsId200>> => {
  return axios.get(`/social/tweets/${id}`, options);
};

export const getGetSocialTweetsIdQueryKey = (id: string) => {
  return [`/social/tweets/${id}`] as const;
};

export const getGetSocialTweetsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSocialTweetsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSocialTweetsId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSocialTweetsIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSocialTweetsId>>
  > = () => getSocialTweetsId(id, axiosOptions);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    staleTime: 300000,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getSocialTweetsId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSocialTweetsIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSocialTweetsId>>
>;
export type GetSocialTweetsIdQueryError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Get an individual tweet
 */
export const useGetSocialTweetsId = <
  TData = Awaited<ReturnType<typeof getSocialTweetsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getSocialTweetsId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSocialTweetsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Validate tweet
 */
export const patchSocialTweetsValidateId = (
  id: string,
  patchSocialTweetsValidateIdBody: PatchSocialTweetsValidateIdBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PatchSocialTweetsValidateId200>> => {
  return axios.patch(
    `/social/tweets/validate/${id}`,
    patchSocialTweetsValidateIdBody,
    options,
  );
};

export const getPatchSocialTweetsValidateIdMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchSocialTweetsValidateId>>,
    TError,
    { id: string; data: PatchSocialTweetsValidateIdBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchSocialTweetsValidateId>>,
  TError,
  { id: string; data: PatchSocialTweetsValidateIdBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchSocialTweetsValidateId>>,
    { id: string; data: PatchSocialTweetsValidateIdBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return patchSocialTweetsValidateId(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchSocialTweetsValidateIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchSocialTweetsValidateId>>
>;
export type PatchSocialTweetsValidateIdMutationBody =
  PatchSocialTweetsValidateIdBody;
export type PatchSocialTweetsValidateIdMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Validate tweet
 */
export const usePatchSocialTweetsValidateId = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchSocialTweetsValidateId>>,
    TError,
    { id: string; data: PatchSocialTweetsValidateIdBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions =
    getPatchSocialTweetsValidateIdMutationOptions(options);

  return useMutation(mutationOptions);
};
