import truncate from "lodash/truncate";
import type { SocialTwitter } from "../../../../.rest-hooks/types";
import type { SocialTwitterAttributesTagsItem } from "../../../../.rest-hooks/types/social-twitter.yml";
import {
  type SocialTwitterWithLocation,
  type TweetFeature,
  type TweetFeatureCollection,
  isTweetWithLocation,
} from "./types";

export const getTimeSteps = () => {
  const nowInSeconds = Math.floor(new Date().getTime() / 1000);
  const oneHourInSeconds = 60 * 60;
  return {
    twoHoursAgo: nowInSeconds - 2 * oneHourInSeconds,
    sixHoursAgo: nowInSeconds - 6 * oneHourInSeconds,
    twelveHoursAgo: nowInSeconds - 12 * oneHourInSeconds,
    twentyFourHoursAgo: nowInSeconds - 24 * oneHourInSeconds,
  };
};

export const getSocialHashtagMatch = (
  tags: SocialTwitterAttributesTagsItem[] | undefined,
) => {
  if (!tags || !tags.length) {
    return;
  }

  return tags.find((hashtag) => hashtag.matched === true)?.tag;
};

export const getSocialKeywordMatch = (
  keywords: string[] | undefined,
): string | undefined => {
  if (!keywords || !keywords.length) return;

  return truncate(keywords.join(", "), { length: 20 });
};

export const getTweetFeature = (
  tweet: SocialTwitterWithLocation,
): TweetFeature => {
  return {
    type: "Feature",
    properties: {
      tags: tweet.attributes.tags,
      keywords: tweet.attributes.keywords,
      media: tweet.attributes.media,
      postedAt: tweet.attributes.postedAt ?? 0,
      tweetId: tweet.attributes.id,
      validated: !!tweet.attributes.validated,
    },
    geometry: tweet.attributes.location,
  };
};

export function getTweetFeatureCollection(
  tweets: SocialTwitter[],
): TweetFeatureCollection {
  return {
    type: "FeatureCollection",
    features: tweets
      .filter(isTweetWithLocation)
      .map<TweetFeature>(getTweetFeature),
  };
}
