import { avlEmergencyLayer } from "../../../config/layers/layers";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import AVLEmergencyLayer from "./AVLEmergencyLayer";

interface AVLEmergencyProps {
  isCopView: boolean;
}

const AVLEmergency = ({ isCopView }: AVLEmergencyProps) => {
  const { isLayerActive } = useActiveLayersContext();
  const authAccessToken = useAuthAccessToken();

  if (!isLayerActive(avlEmergencyLayer.id) || !authAccessToken) {
    return null;
  }

  return (
    <AVLEmergencyLayer accessToken={authAccessToken} isCopView={isCopView} />
  );
};

export default AVLEmergency;
