import closestTo from "date-fns/closestTo";
import { getRFSUrlEnvironmentString } from "../../../config/general";

interface GetFireConditionsTimestampParams {
  accessToken: string | undefined;
  layerName: string;
  param: string;
}

const getFireConditionsTimestamp = async ({
  accessToken,
  layerName,
  param,
}: GetFireConditionsTimestampParams) => {
  try {
    const res = await fetch(
      `https://shared-athena.${getRFSUrlEnvironmentString()}apps.rfs.nsw.gov.au/geoserver/fireweather/wms?SERVICE=WMS&REQUEST=GetCapabilities`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    const xmlString = await res.text();

    const domParser = new DOMParser();

    const xmlDom = domParser.parseFromString(xmlString, "text/xml");

    const layers = Array.from(xmlDom.querySelectorAll("Layer"));
    const fireConditionsParamsLayer = layers.find(
      (layer) => layer.querySelector("Name")?.innerHTML.trim() === layerName,
    );

    const time = fireConditionsParamsLayer
      ?.querySelector(`Dimension[name="${param}"`)
      ?.innerHTML.split(",");

    const date = new Date();
    const timeDates = time?.map((dates) => new Date(dates));
    const closestTimestamp = closestTo(date, timeDates || [date]);

    // We can assert that this will never be null as we are always passing at least a date & date[]
    // to the closestTo utility as it expects. The return value of closestTo can be undefined by default,
    // but only if the values passed are incorrect, this will never be the case.
    return closestTimestamp!.toISOString();
  } catch (error) {
    return null;
  }
};

export default getFireConditionsTimestamp;
