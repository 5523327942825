import {
  GetSocialFeedSort,
  type GetSocialTweetsLinkedTo,
} from "../../.rest-hooks/types";
import { TweetFiltersParameterItem } from "../../.rest-hooks/types/social-twitter.yml";

export const GetSocialFeedSortValues = Object.values(GetSocialFeedSort);
export const GetSocialFeedFiltersItemValues = Object.values(
  TweetFiltersParameterItem,
);

export const GetSocialFeedFilterLabels: Record<
  TweetFiltersParameterItem,
  string
> = {
  linked_to_incident: "Linked to incident",
  linked_to_hotspot: "Linked to hotspot",
  unlinked: "Unlinked",
  has_media: "Has media",
  unvalidated: "Unvalidated",
  validated: "Validated",
  near_hotspot: "Near hotspot",
};

export const SocialFeedSectionTypeLabels: Record<
  GetSocialTweetsLinkedTo,
  string
> = {
  linked_to_hotspot: "linked to hotspot",
  linked_to_incident: "incident",
  unlinked: "unlinked",
};

export type IngestionDateFilter =
  (typeof IngestionDateFilter)[keyof typeof IngestionDateFilter];

export const IngestionDateFilter = {
  lastFortyEightHours: "lastFortyEightHours",
  lastDay: "lastDay",
  lastTwelveHours: "lastTwelveHours",
} as const;

export const IngestionDateFilterValues = Object.values(IngestionDateFilter);

export const IngestionDateFilterLabels: Record<IngestionDateFilter, string> = {
  lastFortyEightHours: "Last 48 hours",
  lastDay: "Last 24 hours",
  lastTwelveHours: "Last 12 hours",
};
