import { fieldObsWeatherUnder1HrLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FieldObsWeatherLayer from "./components/FieldObsWeatherLayer";

const FIELD_OBS_WEATHER_1HR_ID = "fieldObsWeatherUnder1Hr";
const FIELD_OBS_WEATHER_1HR_SOURCE_LAYER_ID = 0;

const FieldObsWeatherUnder1Hr = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(fieldObsWeatherUnder1HrLayer.id)) {
    return null;
  }

  const state = getLayerState(fieldObsWeatherUnder1HrLayer.id);

  return (
    <FieldObsWeatherLayer
      mapLayerId={FIELD_OBS_WEATHER_1HR_ID}
      opacity={state?.opacity}
      popupTitle="Under 1 hr"
      sourceLayerId={FIELD_OBS_WEATHER_1HR_SOURCE_LAYER_ID}
    />
  );
};

export default FieldObsWeatherUnder1Hr;
