import {
  Alert,
  Close,
  FieldGrid,
  IconButton,
  useIsMinWidth,
} from "@app/design-system";
import capitalize from "lodash/capitalize";
import styled from "styled-components";
import type { BrigadeCoverageSnapshotValid } from "../../../../.rest-hooks/types/coverage.yml";
import AssessCoverageGapButton from "../../coverage/AssessCoverageGapButton/AssessCoverageGapButton";
import BrigadeCoverageAssessmentBadge from "../../coverage/BrigadeCoverageAssessmentBadge/BrigadeCoverageAssessmentBadge";
import { getCategoryGroupCount } from "../../coverage/BrigadeCoverageSnapshotList/columns";
import CoverageNetIndicator from "../../coverage/CoverageNetIndicator/CoverageNetIndicator";
import type { BrigadeInteractionProperties } from "../../map/RFSBrigades/interactions";
import FireDangerRatingIndicator from "../../ui/FireDangerRatingIndicator/FireDangerRatingIndicator";
import ViewDistrictButton from "./ViewDistrictButton";

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
`;

export const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

export const StyledTitle = styled.div`
  ${(p) => p.theme.typography.variants.subtitleMd}
`;

export const StyledFooter = styled.div`
  display: grid;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const formatCoverageCategoryGroup = (categoryGroup: string) => {
  return capitalize(categoryGroup.replace("-", " "));
};

export interface BrigadeCoverageSnapshotPopupViewProps {
  onClose: () => void;
  properties: BrigadeInteractionProperties | null;
}

const BrigadeCoverageSnapshotPopupView = ({
  onClose,
  properties,
}: BrigadeCoverageSnapshotPopupViewProps) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("lg");

  // Mapbox flattens nested objects into strings
  // So we need to parse it out to be usable as an object
  let parsedSnapshot: BrigadeCoverageSnapshotValid | undefined;

  if (typeof properties?.coverage === "string") {
    parsedSnapshot = JSON.parse(properties.coverage);
  } else {
    parsedSnapshot = undefined;
  }

  if (
    !parsedSnapshot ||
    !properties?.coverage ||
    !parsedSnapshot.fireDangerRating
  ) {
    return (
      <>
        <StyledHeader>
          <StyledTitle>{properties?.brigadeName}</StyledTitle>
          <IconButton
            icon={Close}
            label="close"
            onClick={onClose}
            size="xs"
            variant="ghost"
          />
        </StyledHeader>
        <StyledBody>
          <Alert variant="warning" title="Unable to calculate coverage gaps">
            There is currently no data to calculate the coverage gap for this
            brigade
          </Alert>
        </StyledBody>
      </>
    );
  }

  return (
    <>
      <StyledHeader>
        <FireDangerRatingIndicator
          fireDangerRating={parsedSnapshot.fireDangerRating}
          size="eyebrow"
        />
        <IconButton
          icon={Close}
          label="close"
          onClick={onClose}
          size="xs"
          variant="ghost"
        />
      </StyledHeader>
      <StyledBody>
        <StyledTitle>{properties.brigadeName}</StyledTitle>
        {parsedSnapshot?.assessment && (
          <BrigadeCoverageAssessmentBadge
            assessment={parsedSnapshot.assessment}
          />
        )}
        <FieldGrid>
          <FieldGrid.Item label="Coverage:">
            <CoverageNetIndicator snapshot={parsedSnapshot} />
          </FieldGrid.Item>
          {Object.keys(parsedSnapshot.categoryGroups).map((key) => {
            return (
              <FieldGrid.Item
                label={`${formatCoverageCategoryGroup(key)}:`}
                key={key}
              >
                {parsedSnapshot?.categoryGroups &&
                  getCategoryGroupCount({
                    category: parsedSnapshot.categoryGroups[key],
                  })}
              </FieldGrid.Item>
            );
          })}
        </FieldGrid>
      </StyledBody>
      <StyledFooter>
        <ViewDistrictButton districtId={properties?.districtId} />
        {isTabletLandscapeAndAbove && properties?.districtId && (
          <AssessCoverageGapButton
            districtId={properties.districtId}
            snapshot={parsedSnapshot}
            size="sm"
          />
        )}
      </StyledFooter>
    </>
  );
};

export default BrigadeCoverageSnapshotPopupView;
