/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */

export type SocialTwitterAttributesMediaItemType =
  (typeof SocialTwitterAttributesMediaItemType)[keyof typeof SocialTwitterAttributesMediaItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocialTwitterAttributesMediaItemType = {
  photo: "photo",
  video: "video",
} as const;
