import styled from "styled-components";
import TweetValidationReasons, {
  type TweetValidationReasonsProps,
} from "./TweetValidationReasons";

const StyledDrawerTweetValidationReasons = styled.div`
  background-color: ${(p) => p.theme.colors.neutrals.background};
  border-radius: 0px 0px 4px 4px;
`;

const DrawerTweetValidationReasons = (props: TweetValidationReasonsProps) => {
  return (
    <StyledDrawerTweetValidationReasons>
      <TweetValidationReasons {...props} />
    </StyledDrawerTweetValidationReasons>
  );
};

export default DrawerTweetValidationReasons;
