import { useCurrentTime } from "@kablamo/kerosene-ui";
import { useEffect } from "react";
import { MapLevel } from "../../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../../utils/getMapServerProxyBasepath";
import useMapContext from "../../Map/useMapContext";
import { type FESMTimeSpan, formatCqlFilter, getFESMDateRange } from "./utils";

const FESM_LAYER = "aig:fesm";
const FESM_BOUNDARY_LAYER = "aig:fesm_boundary";

interface BuildFESMQueryParams {
  currentTime: number;
  layerId: string;
  timeSpan: FESMTimeSpan;
}

const buildFESMQuery = ({
  currentTime,
  layerId,
  timeSpan,
}: BuildFESMQueryParams) => {
  const dateRange = getFESMDateRange({ currentTime, timeSpan });

  return buildMapboxBBoxQuery({
    SERVICE: "WMS",
    REQUEST: "GetMap",
    FORMAT: "image/png",
    TRANSPARENT: "TRUE",
    STYLES: "",
    VERSION: "1.1.1",
    LAYERS: layerId,
    WIDTH: "512",
    HEIGHT: "512",
    SRS: "EPSG:3857",
    CQL_FILTER: formatCqlFilter(dateRange),
  });
};

const FESM_BASE_ID = "fesm";

interface FireExtentSeverityMappingLayerProps {
  opacity?: number;
  timeSpan: FESMTimeSpan;
}

const FireExtentSeverityMappingLayer = ({
  opacity = 1,
  timeSpan,
}: FireExtentSeverityMappingLayerProps) => {
  const map = useMapContext();
  const currentTime = useCurrentTime();
  const query = buildFESMQuery({ currentTime, layerId: FESM_LAYER, timeSpan });
  const boundaryQuery = buildFESMQuery({
    currentTime,
    layerId: FESM_BOUNDARY_LAYER,
    timeSpan,
  });

  const id = `${FESM_BASE_ID}-${timeSpan}`;
  const boundaryId = `${FESM_BASE_ID}-boundary-${timeSpan}`;

  useEffect(() => {
    map.addSource(id, {
      type: "raster",
      tileSize: 512,
      tiles: [`${getMapServerProxyBasepath()}/geoserver/aig/wms?${query}`],
    });

    map.addSource(boundaryId, {
      type: "raster",
      tileSize: 512,
      tiles: [
        `${getMapServerProxyBasepath()}/geoserver/aig/wms?${boundaryQuery}`,
      ],
    });

    map.addLayer(
      {
        id,
        type: "raster",
        source: id,
      },
      MapLevel.BACKGROUND,
    );

    map.addLayer(
      {
        id: boundaryId,
        type: "raster",
        source: boundaryId,
      },
      MapLevel.BACKGROUND,
    );

    return () => {
      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
      if (map.getLayer(boundaryId)) {
        map.removeLayer(boundaryId);
      }
      if (map.getSource(boundaryId)) {
        map.removeSource(boundaryId);
      }
    };
  }, [boundaryId, boundaryQuery, id, map, query]);

  useEffect(() => {
    map?.setPaintProperty(id, "raster-opacity", opacity);
    map?.setPaintProperty(boundaryId, "raster-opacity", opacity);
  }, [boundaryId, id, map, opacity]);

  return null;
};

export default FireExtentSeverityMappingLayer;
