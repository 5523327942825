import type { ParsedUrlQuery } from "querystring";
import getStringQueryParam from "../utils/getStringQueryParam/getStringQueryParam";
import QueryParams from "./queryParams";

const getActiveLayersFromQuery = (
  query: ParsedUrlQuery,
): string[] | undefined => {
  const activeLayers = getStringQueryParam(query, QueryParams.ACTIVE_LAYERS);

  return activeLayers?.split(",");
};

export default getActiveLayersFromQuery;
