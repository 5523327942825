import { FieldGrid, Text } from "@app/design-system";
import styled from "styled-components";
import MapPopupView from "../../popup/SpatialPopup/MapPopupView";
import SpatialPopup from "../../popup/SpatialPopup/SpatialPopup";
import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isMultiPolygonFeature, isPolygonFeature } from "../types";

interface WildfireHistoryFeatureProperties {
  fireSeason: string;
  fireName: string;
  fireYear: number;
  objectId: number;
}

export interface WildfireHistoryInteractionProperties
  extends FeatureInteractionProperties {
  data: WildfireHistoryFeatureProperties;
}

export type WildfireHistoryInteractionState =
  FeatureInteractionState<WildfireHistoryInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  WildfireHistoryInteractionProperties
> = (feature, event) => {
  if (!isMultiPolygonFeature(feature) && !isPolygonFeature(feature)) {
    return null;
  }
  return {
    lngLat: event.lngLat,
    featureId: feature.properties.OBJECTID,
    data: {
      fireName: feature.properties.FIRENAME,
      fireSeason: feature.properties.FIRESEASON,
      fireYear: feature.properties.YEAROFFIRE,
      objectId: feature.properties.OBJECTID,
    },
  };
};

const StyledBody = styled.div`
  display: grid;
  gap: 0.5rem;
`;

interface WildfireHistoryPopupProps {
  label: React.ReactNode;
  onClose: () => void;
  state: WildfireHistoryInteractionState;
}

const WildfireHistoryPopup = ({
  label,
  onClose,
  state: { id, isActive, properties },
}: WildfireHistoryPopupProps) => {
  return (
    <SpatialPopup
      isOpen={isActive}
      lngLat={properties?.lngLat}
      onClose={onClose}
      popupConfig={{
        id,
        fullWidth: true,
        offset: 8,
        size: "xl",
        type: "click",
      }}
    >
      {properties && (
        <MapPopupView
          header={
            <Text size="eyebrowDefault" variant="weak">
              {label}
            </Text>
          }
          onClose={onClose}
        >
          <StyledBody>
            <Text size="subtitleMd">
              {properties.data.fireName || "No name"}
            </Text>
            <FieldGrid layout="compact">
              <FieldGrid.Item label="Fire season:">
                {properties.data.fireSeason}
              </FieldGrid.Item>
              <FieldGrid.Item label="Fire year:">
                {properties.data.fireYear}
              </FieldGrid.Item>
            </FieldGrid>
          </StyledBody>
        </MapPopupView>
      )}
    </SpatialPopup>
  );
};

export default WildfireHistoryPopup;
