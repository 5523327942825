import { fieldObsSitRepUnder3HrsLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FieldObsSitRepLayer from "./components/FieldObsSitRepLayer";

const FIELD_OBS_SIT_REP_UNDER_3_HRS_ID = "fieldObsSitRepUnder3Hrs";
const SOURCE_LAYER_ID = 1;

const FieldObsSitRepUnder3Hrs = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(fieldObsSitRepUnder3HrsLayer.id)) {
    return null;
  }

  const state = getLayerState(fieldObsSitRepUnder3HrsLayer.id);

  return (
    <FieldObsSitRepLayer
      mapLayerId={FIELD_OBS_SIT_REP_UNDER_3_HRS_ID}
      opacity={state?.opacity}
      popupTitle="UNDER 3 HRS"
      sourceLayerId={SOURCE_LAYER_ID}
    />
  );
};

export default FieldObsSitRepUnder3Hrs;
