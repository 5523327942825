import { Slider } from "@app/design-system";
import addHours from "date-fns/addHours";
import isArray from "lodash/isArray";
import range from "lodash/range";
import styled from "styled-components";
import { getFormattedTime } from "../../../utils/formatDate/formatDate";

export const MAX_SLIDER_VALUE = 11;
export const MIN_SLIDER_VALUE = 0;

const StyledHoursSlider = styled.div`
  min-width: 16.5rem;
  margin-top: -0.375rem;
`;

const StyledPointer = styled.div`
  ${(p) => p.theme.typography.variants.footnote}
  display: flex;
  justify-content: space-between;
  padding: 0 0 0.125rem 0;
  width: 100%;
`;

const StyledOption = styled.option`
  display: block;
  background-color: ${(p) => p.theme.colors.neutrals.text};
  height: 0.25rem;
  min-height: 0;
  padding: 0;
  width: 1px;
`;

interface HoursSliderProps {
  onChange: (value: number) => void;
  startDate: Date;
  value: number;
  "data-testid"?: string;
}

const HoursSlider = ({
  onChange,
  startDate,
  value,
  "data-testid": dataTestId,
}: HoursSliderProps) => {
  const formatTooltip = (hours: number) => {
    const date = addHours(startDate, hours);
    return getFormattedTime(date);
  };

  return (
    <StyledHoursSlider>
      <StyledPointer>
        <div>+0</div>
        <div>+3</div>
        <div>+6</div>
        <div>+9</div>
        <div>+12</div>
      </StyledPointer>
      <Slider
        aria-label="Prediction Slider"
        id="prediction-slider"
        formatTooltip={formatTooltip}
        list="pointer-marks"
        maxValue={MAX_SLIDER_VALUE}
        minValue={MIN_SLIDER_VALUE}
        onChange={(hours) => {
          if (!isArray(hours)) {
            onChange(hours);
          }
        }}
        tooltip
        value={value}
        variant="strong"
        data-testid={dataTestId}
      >
        {range(MAX_SLIDER_VALUE + 1).map((i) => (
          <StyledOption key={i} value={i} />
        ))}
      </Slider>
    </StyledHoursSlider>
  );
};

export default HoursSlider;
