import { Slider } from "@app/design-system";
import isArray from "lodash/isArray";
import styled from "styled-components";

const MAX_SLIDER_VALUE = 100;
const MIN_SLIDER_VALUE = 0;

const StyledOpacitySlider = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

const StyledOpacityOutput = styled.div`
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  ${(p) => p.theme.typography.variants.footnote};
  font-variant-numeric: tabular-nums;
`;

interface OpacitySliderProps {
  onChange: (opacity: number) => void;
  value: number;
}

const OpacitySlider = ({
  onChange,
  value: sliderValue,
}: OpacitySliderProps) => {
  const onSliderChange = (value: number | number[]) => {
    if (!isArray(value)) {
      onChange(value / 100);
    }
  };

  return (
    <StyledOpacitySlider>
      <Slider
        id="opacity-slider"
        maxValue={MAX_SLIDER_VALUE}
        minValue={MIN_SLIDER_VALUE}
        onChange={onSliderChange}
        value={sliderValue * 100}
      />
      <StyledOpacityOutput>
        {Math.round(sliderValue * 100)}%
      </StyledOpacityOutput>
    </StyledOpacitySlider>
  );
};

export default OpacitySlider;
