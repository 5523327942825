export class TypedMap<T extends Record<string, unknown>> extends Map<
  keyof T,
  T[keyof T]
> {
  override set<K extends keyof T>(key: K, value: T[K]): this {
    return super.set(key, value);
  }

  override get<K extends keyof T>(key: K): T[K] | undefined {
    const value = super.get(key);
    return value as T[K] | undefined; // Type assertion
  }
}
