import { siteInformationLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import SiteInformationLayer from "./SiteInformationLayer";

const SiteInformation = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(siteInformationLayer.id)) {
    return null;
  }

  const state = getLayerState(siteInformationLayer.id);

  return <SiteInformationLayer opacity={state?.opacity} />;
};

export default SiteInformation;
