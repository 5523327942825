import { alertStatusDistrictTodayLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import AlertStatusDistrictLayer from "./AlertStatusDistrict";

const AlertStatusDistrictToday = () => {
  const { isLayerActive, getLayerState } = useActiveLayersContext();

  if (!isLayerActive(alertStatusDistrictTodayLayer.id)) {
    return null;
  }

  return (
    <AlertStatusDistrictLayer
      layer="1"
      opacity={getLayerState(alertStatusDistrictTodayLayer.id)?.opacity}
    />
  );
};

export default AlertStatusDistrictToday;
