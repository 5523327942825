import type mapboxgl from "mapbox-gl";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";

export const addMapSource = (
  map: mapboxgl.Map | maplibregl.Map,
  id: string,
) => {
  return map.addSource(id, {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });
};

export const getMapDataUrl = (layerId: string) => {
  return `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/${layerId}/query`;
};
