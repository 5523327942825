import {
  Tooltip,
  buttonReset,
  focusStyles,
  weakStyles,
} from "@app/design-system";
import styled from "styled-components";
import type { MapRailStatus } from "./useMapRail";

const StyledMapRailMenuButton = styled.button`
  ${buttonReset}
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  background: ${(p) => p.theme.colors.neutrals.background};
  box-shadow: ${(p) => p.theme.boxShadows.md};
  color: ${(p) => p.theme.colors.neutrals.text};
  transition:
    background-color
      ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`},
    color ${(p) => `${p.theme.anim.duration.sm}ms ${p.theme.anim.curve}`};
  pointer-events: all;
  z-index: ${(p) => p.theme.zIndexes.overlayDrawer};

  &:hover {
    ${weakStyles.background.hover}
  }

  &:active {
    ${weakStyles.background.active}
  }

  &[data-selected="true"] {
    color: ${(p) => p.theme.colors.primary.icon};
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    background-color: ${(p) => p.theme.colors.neutrals.backgroundDisabled};
    color: ${(p) => p.theme.colors.neutrals.textDisabled};
  }

  &[data-pinned="true"] {
    color: ${(p) => p.theme.colors.primary.backgroundMedium};
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

const StyledIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;

  svg {
    height: 1.375rem;
    width: 1.375rem;
  }
`;

const StyledBadge = styled.span`
  position: absolute;
  padding: 0 0.25rem;
  left: calc(100% - 0.75rem);
  top: -0.125rem;
  height: 1rem;
  min-width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) => p.theme.typography.variants.captionStrong}
  background-color: ${(p) => p.theme.colors.informative.backgroundStrong};
  color: ${(p) => p.theme.colors.neutrals.textInverse};
  border-radius: ${(p) => p.theme.borderRadiuses.full}px;
  text-align: center;
`;

export type MapRailMenuButtonProps = {
  icon: ReactSVGComponent;
  isPinned?: boolean;
  isSelected: boolean;
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  status?: MapRailStatus;
};

const MapRailMenuButton = ({
  icon: Icon,
  isSelected,
  isPinned,
  label,
  onClick,
  status,
}: MapRailMenuButtonProps) => {
  return (
    <Tooltip delay message={label}>
      <StyledMapRailMenuButton
        // Label for screen readers, we show a tooltip separately so using this instead of title
        aria-label={label}
        data-selected={isSelected}
        data-pinned={isPinned}
        disabled={isPinned || status?.disabled}
        onClick={onClick}
      >
        <StyledIcon>
          <Icon />
          {!!status?.count && <StyledBadge>{status.count}</StyledBadge>}
        </StyledIcon>
      </StyledMapRailMenuButton>
    </Tooltip>
  );
};

export default MapRailMenuButton;
