import { AlertModal } from "@app/design-system";
import type { AlertModalProps } from "@app/design-system/src/components/Modal/AlertModal";
import type { ResourceAppliance } from "../../../../.rest-hooks/types/resources.yml/resourceAppliance";
import CopyLinkToResourceButton from "../CopyLinkToResourceButton/CopyLinkToResourceButton";
import type { ApplianceActivitySummaryProps } from "./ApplianceActivitySummary";
import ApplianceDetail from "./ApplianceDetail";

export interface ApplianceDetailModalProps
  extends Pick<AlertModalProps, "isOpen" | "onDismiss">,
    ApplianceActivitySummaryProps {
  appliance: ResourceAppliance;
}

const ApplianceDetailModal = ({
  appliance,
  isOpen,
  onDismiss,
}: ApplianceDetailModalProps) => {
  return (
    <AlertModal
      actions={
        <CopyLinkToResourceButton
          resourceId={appliance.id}
          size="md"
          variant="ghost"
        />
      }
      dismissLabel="Close"
      isOpen={isOpen}
      onDismiss={onDismiss}
      size="lg"
      title={appliance.attributes.callSign || "Unknown appliance"}
    >
      <ApplianceDetail appliance={appliance} />
    </AlertModal>
  );
};

export default ApplianceDetailModal;
